export default theme => ({
  loader: {
    margin: 14
  },
  left: {
    textAlign: 'left'
  },
  center: {
    textAlign: 'center',
    justifyContent: 'center'
  },
  right: {
    textAlign: 'right'
  }
});
