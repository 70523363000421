import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, reset } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { Button, CircularProgress, Divider, Grid, MenuItem, Switch, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import feathersServices from '../../../services/feathersServices';
import KLIPasswordStrength from '../../../components/login/forgot/KLIPasswordStrength';
import Notification from '../../../components/notification/Notification';
import RenderTextField from '../../../components/renders/RenderTextField';
import RenderTooltipPasswordField from '../../../components/renders/RenderTooltipPasswordField';
import { required, validEmail } from '../../../utils/validation';
import { USER_ROLE } from '../../../constants/user.constants';
import UserCompanyFormStyles from './UserManagementForm.styles';

const FORM_ID = 'userManagementForm';
const defaultNotification = { open: false, msg: ' ' };

let UserManagementForm = ({
  user,
  classes,
  valid,
  userManagementForm,
  onCallBack,
  reset,
  saveUser,
  updateUser,
  selectedUser,
  getKliApplications,
  kliApplications,
  selectedApplications
}) => {
  const [openError, setOpenError] = useState(defaultNotification);
  const [helpMessage, setHelpMessage] = useState('');
  const [helpSuggestions, setHelpSuggestions] = useState([]);
  const [checkedApplications, setCheckedApplications] = useState(selectedApplications);
  const [onSubmitForm, setOnSubmitForm] = useState(false);

  useEffect(() => {
    getKliApplications({
      query: {
        name: 'INSIGHTS'
      }
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    setOnSubmitForm(true);

    if (!userManagementForm.values._id) {
      saveUser({
        ...userManagementForm.values,
        applications: checkedApplications
      }).then(
        resp => {
          reset(FORM_ID);
          if (onCallBack) {
            onCallBack();
          }
          setOnSubmitForm(false);
        },
        error => {
          if (error.code === 406) {
            setOpenError({
              open: !openError.open,
              msg: 'Email exist, please select other'
            });
          } else {
            setOpenError({
              open: !openError.open,
              msg: error.message
            });
          }
          setOnSubmitForm(false);
        }
      );
    } else {
      updateUser(userManagementForm.values._id, {
        ...userManagementForm.values,
        applications: checkedApplications
      }).then(
        ({ value }) => {
          reset(FORM_ID);
          if (onCallBack) {
            onCallBack();
          }
          setOnSubmitForm(false);
        },
        error => {
          setOpenError({
            open: !openError.open,
            msg: error.message
          });
          setOnSubmitForm(false);
        }
      );
    }
  };

  const updateApplication = (appId, checked) => {
    if (checked) {
      setCheckedApplications([...checkedApplications, appId]);
    } else {
      setCheckedApplications(checkedApplications.filter(cA => cA !== appId));
    }
  };

  return (
    <form onSubmit={() => handleSubmit()} className={classes.root}>
      <Grid container className={classes.header}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.headerTitle}>
            {selectedUser ? 'Update user' : 'Create user'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.fieldsGroup}>
        <Grid item xs={12}>
          <Field
            color="secondary"
            validate={[required, validEmail]}
            name="email"
            label="Email *"
            component={RenderTextField}
            fullWidth
            variant="outlined"
            disabled={selectedUser ? true : false}
          />
        </Grid>

        {!selectedUser && (
          <Grid item xs={12}>
            <Field
              color="secondary"
              name="password"
              label="Password *"
              validate={[required]}
              props={{ meta: { touched: true, invalid: helpMessage !== '', error: helpMessage } }}
              component={RenderTooltipPasswordField}
              open={helpMessage !== ''}
              title={
                <ul>
                  {helpSuggestions?.map(s => (
                    <li>
                      <Typography variant="body2">{s}</Typography>
                    </li>
                  ))}
                </ul>
              }
              fullWidth
              variant="outlined"
            />
            {userManagementForm?.values.password !== '' && (
              <KLIPasswordStrength
                password={userManagementForm?.values.password}
                onScoreChange={(helpMessage, helpSuggestions) => {
                  setHelpMessage(helpMessage);
                  setHelpSuggestions(helpSuggestions);
                }}
              />
            )}
          </Grid>
        )}

        <Grid item xs={12}>
          <Field color="secondary" validate={[required]} name="name" label="First Name *" component={RenderTextField} fullWidth variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <Field
            color="secondary"
            validate={[required]}
            name="lastName"
            label="Last Name *"
            component={RenderTextField}
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <Field color="secondary" name="phone" label="Phone" component={RenderTextField} fullWidth variant="outlined" />
        </Grid>

        <Grid item xs={12}>
          <Field color="secondary" name="title" label="Title" component={RenderTextField} fullWidth variant="outlined" />
        </Grid>

        <Grid item xs={12}>
          <Field
            id="user-login-type"
            select
            name="loginType"
            variant="outlined"
            color="secondary"
            component={RenderTextField}
            className={classes.textField}
            fullWidth
            label="Role"
            SelectProps={{
              classes: {
                select: classes.resize
              }
            }}
          >
            <MenuItem key="senior" value={USER_ROLE.SENIOR}>
              {USER_ROLE.SENIOR}
            </MenuItem>
            <MenuItem key="researcher" value={USER_ROLE.ADMIN}>
              RESEARCHER
            </MenuItem>
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.applicationFieldset}>
            <FormLabel component="legend" filled color="secondary">
              Assign applications
            </FormLabel>

            {!kliApplications.isLoading &&
              kliApplications.queryResult &&
              kliApplications.queryResult.data.map(app => (
                <FormGroup key={app._id}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checkedApplications.findIndex(a => a === app._id) !== -1}
                        disabled={user && selectedUser && app.name === 'INSIGHTS' && user._id === selectedUser._id}
                        onChange={(e, checked) => updateApplication(app._id, checked)}
                        name={app.cannonicalName}
                        inputProps={{
                          'aria-label': 'secondary checkbox'
                        }}
                      />
                    }
                    labelPlacement="end"
                    label={app.cannonicalName}
                  />
                </FormGroup>
              ))}
          </FormControl>
        </Grid>
      </Grid>

      <Box mt={3} className={classes.cancelButton}>
        <div>
          <Button onClick={() => onCallBack()}>Cancel</Button>
        </div>
        &nbsp;&nbsp;
        <div>
          <Button
            onClick={() => handleSubmit()}
            color="secondary"
            variant="contained"
            disableElevation
            disabled={!valid || onSubmitForm || helpMessage !== ''}
          >
            {onSubmitForm && <CircularProgress color="secondary" size={25} thickness={3} />}
            {selectedUser ? 'Update' : 'Create'}
          </Button>
        </div>
      </Box>
      <Notification message={openError.msg} open={openError.open} variant="error" onClose={() => setOpenError({ defaultNotification })} />
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  const companyCustomerSelected = state.ui.listCompaniesCustomer.companyCustomerSelected;

  let initValues = {
    email: null,
    password: '',
    name: '',
    lastName: '',
    phone: '',
    loginType: USER_ROLE.ADMIN,
    title: null,
    _id: null
  };

  if (ownProps.selectedUser) {
    initValues = Object.assign(initValues, ownProps.selectedUser);
    delete initValues.password;
  }

  return {
    user: state.auth.user,
    initialValues: initValues,
    userManagementForm: state.form[FORM_ID],
    kliApplications: state.services.kliApplications,
    selectedApplications: ownProps.selectedUser ? ownProps.selectedUser.applications.reduce((apps, app) => apps.concat(app._id), []) : [],
    companyCustomerSelected
  };
};

const mapDispatchToProps = {
  saveUser: feathersServices.users.create,
  updateUser: feathersServices.users.update,
  getKliApplications: feathersServices['kli-applications'].find,
  reset
};

UserManagementForm = compose(
  withStyles(UserCompanyFormStyles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(
    {
      form: FORM_ID,
      enableReinitialize: true,
      validate: (values, props) => {
        const errors = {};
        return errors;
      }
    },
    mapStateToProps
  )
)(UserManagementForm);

export default UserManagementForm;
