import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
//region Material UI
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import Close from '@material-ui/icons/Close';
import MaintenanceService from './services/Maintenance.service';
import SingletonMomentUtils from '../../utils/SingletonMomentUtils';
import { setUpcomingMaintenance } from './actions/maintenance.actions';
import MaintenanceBannerStyles from './MaintenanceBanner.style';

//endregion

class MaintenanceBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openReleaseNotes: false
    };
  }

  closeBanner = () => {
    this.props.setUpcomingMaintenance(null);
    localStorage.setItem('dismiss-maintenance-insights', true);
  };

  render() {
    const { openReleaseNotes } = this.state;
    const { classes, maintenance, dismissedBanner } = this.props;
    const show = maintenance && !dismissedBanner;
    return (
      <Fragment>
        <Slide direction="down" in={show} timeout={1000}>
          <Grid
            container
            className={classes.root}
            justifyContent="center"
            alignItems="flex-end"
            direction="column"
            style={{ height: MaintenanceService.getBannerHeight() }}
          >
            {maintenance && (
              <div className={classes.content}>
                <div>
                  <Typography component="span" variant="body1">
                    {maintenance.name}&nbsp;
                  </Typography>
                  <Typography component="span" variant="body1">
                    {SingletonMomentUtils.moment(maintenance.date).format('LLL')}
                  </Typography>
                </div>
                <Grid container alignItems="center">
                  <Typography component="span" variant="body1">
                    {maintenance.details[0]}&nbsp;
                  </Typography>
                  <Typography component="span" variant="body1">
                    <Link className={classes.link} variant="body1" target="_blank" href="mailto:support@keylimeinteractive.com">
                      Have questions?&nbsp;
                    </Link>
                  </Typography>
                  {maintenance.details && maintenance.details.length > 1 && (
                    <Tooltip title="More information">
                      <AddBoxOutlined className={classes.moreInfo} onClick={() => this.setState({ openReleaseNotes: true })} />
                    </Tooltip>
                  )}
                </Grid>
              </div>
            )}
            <div>
              <IconButton onClick={this.closeBanner}>
                <Close className={classes.close} />
              </IconButton>
            </div>
          </Grid>
        </Slide>
        <Dialog open={openReleaseNotes} onClose={() => this.setState({ openReleaseNotes: false })}>
          <DialogTitle>Release Notes</DialogTitle>
          <Divider />
          <DialogContent>
            {maintenance &&
              maintenance.details.map((detail, i) => {
                return (
                  i !== 0 && (
                    <Typography
                      variant="body1"
                      component="p"
                      dangerouslySetInnerHTML={{
                        __html: detail.replace('\n', '<br/>')
                      }}
                    />
                  )
                );
              })}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openReleaseNotes: false })}>Close</Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenance: state.ui.maintenanceStore.maintenance,
    dismissedBanner: localStorage.getItem('dismissMaintenanceBanner')
  };
};

const mapDispatchToProps = {
  setUpcomingMaintenance
};

export default compose(withStyles(MaintenanceBannerStyles), connect(mapStateToProps, mapDispatchToProps))(MaintenanceBanner);
