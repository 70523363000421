export default theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '200px'
  },
  uploading: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20
  },
  text: {
    marginLeft: 5
  }
});
