import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
// defaults to localStorage for web
import feathersAuthentication from '../services/AuthService';
import feathersServices from '../services/feathersServices';
import createRouterReducers from '../reducers/app.reducers';
import migrations from './migrations';

export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['form', 'router', 'contentBuilding', 'consumerSurvey', 'services'],
  version: 27,
  migrate: createMigrate(migrations, { debug: false }),
  stateReconciler: autoMergeLevel2
};

const rootReducer = createRouterReducers(history, feathersServices, feathersAuthentication, formReducer);

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = process.env.BRANCH === 'production' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(routerMiddleware(history), promise, thunk)));
export const persistor = persistStore(store);
