export const CONSUMER_SURVEY_SAVE = 'CONSUMER_SURVEY_SAVE';
export const CONSUMER_SURVEY_SET_CSV_ROW_DATA = 'CONSUMER_SURVEY_SET_CSV_ROW_DATA';
export const CONSUMER_SURVEY_CLEAR = 'CONSUMER_SURVEY_CLEAR';
export const CONSUMER_SURVEY_TAB = 'CONSUMER_SURVEY_TAB';

export function setRawData(rawData) {
  return {
    type: CONSUMER_SURVEY_SET_CSV_ROW_DATA,
    payload: rawData
  };
}

export function setConsumerSurvey(consumerSurver) {
  return {
    type: CONSUMER_SURVEY_SAVE,
    payload: consumerSurver
  };
}

export function clearConsumerSurvey() {
  return {
    type: CONSUMER_SURVEY_CLEAR
  };
}

export function setConsumerSurveyTab(tab) {
  return {
    type: CONSUMER_SURVEY_TAB,
    payload: tab
  };
}
