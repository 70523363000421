import { insertItemToArray, removeItemFromArray, updateObjectInArray } from '../../../utils/immutable-utils';
import { getApplications } from '../../../utils/report.utils';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import {
  CLEAR_PREVIOUS_CAPABILITY_IMAGES,
  NEW_REPORT_ADD_FILE,
  NEW_REPORT_CAPABILITY_REPORT_UPDATE,
  NEW_REPORT_CLEAN_SELECT_DB_REPORT,
  NEW_REPORT_CLEAR_CAPABILITY_REPORT,
  NEW_REPORT_CLEAR_FILES,
  NEW_REPORT_CLEAR_MARKED_IMAGES_FOR_DELETE,
  NEW_REPORT_CONSUMER_SURVEY_COMPLETED,
  NEW_REPORT_CONTENT_BUILDING_COMPLETED,
  NEW_REPORT_GENERATE_REPORT_CAPABILITIES,
  NEW_REPORT_MARK_IMAGE_FOR_DELETE,
  NEW_REPORT_PREVIOUS,
  NEW_REPORT_REMOVE_FILE,
  NEW_REPORT_SCORING_RANKING_COMPLETED,
  NEW_REPORT_SELECT_APPLICATION,
  NEW_REPORT_SELECT_CAPABILITY_REPORT,
  NEW_REPORT_SELECT_CATEGORY,
  NEW_REPORT_SELECT_COMPANY,
  NEW_REPORT_SELECT_DATE,
  NEW_REPORT_SELECT_DB_REPORT,
  NEW_REPORT_SELECT_INDUSTRY,
  NEW_REPORT_SET_CAPABILITY_REPORTS,
  SET_COMPLETE_MAP,
  SET_PREVIOUS_CAPABILITY_IMAGES,
  SET_REPORT_PREVIEW
} from '../actions/reports.actions';
import REPORT_CONSTANTS from '../report.constants';

const initialState = {
  selectedDate: {
    date: new Date()
  },
  dateValid: true,
  selectedIndustry: {},
  selectedApplication: null,
  selectedCompany: {},
  selectedCategory: {},
  selectedCapabilityReport: {},
  files: [],
  previousReport: null,
  markedImagesForDelete: [],
  capabilityCompleted: false,
  consumerSurveyCompleted: false,
  scoringRankingCompleted: false,
  contentBuildingCompleted: false,
  isPreview: false,
  capabilityReports: [],
  completedMap: {
    capabilitiesTable: false,
    companies: {
      applications: {}
    }
  }
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEW_REPORT_SELECT_APPLICATION:
      return Object.assign({}, state, {
        selectedApplication: action.payload
      });
    case NEW_REPORT_SELECT_INDUSTRY:
      return Object.assign({}, state, {
        selectedIndustry: action.payload
      });
    case NEW_REPORT_SELECT_COMPANY:
      return Object.assign({}, state, {
        selectedCompany: { ...action.payload, categories: [] }
      });
    case NEW_REPORT_SELECT_CATEGORY:
      return Object.assign({}, state, {
        selectedCategory: { ...action.payload, capabilityReports: [] }
      });
    case NEW_REPORT_SELECT_DATE:
      let dateValid = true;
      if (action.payload.usedDates) {
        action.payload.usedDates.forEach(usedDate => {
          let newDate = SingletonMomentUtils.date(action.payload.date);
          if (newDate.month() === SingletonMomentUtils.date(usedDate).month() && newDate.year() === SingletonMomentUtils.date(usedDate).year()) {
            dateValid = false;
          }
        });
      }
      if (dateValid) {
        return Object.assign({}, state, {
          selectedDate: action.payload,
          dateValid: true
        });
      } else {
        return Object.assign({}, state, {
          dateValid: false
        });
      }

    case NEW_REPORT_SET_CAPABILITY_REPORTS:
      return Object.assign({}, state, {
        capabilityReports: [...action.payload]
      });

    case NEW_REPORT_CAPABILITY_REPORT_UPDATE:
      return Object.assign({}, state, {
        capabilityReports: updateObjectInArray(state.capabilityReports, action.payload)
      });

    case NEW_REPORT_GENERATE_REPORT_CAPABILITIES:
      let capabilityReports = [];
      action.payload.allCapabilities.forEach(capability => {
        let capabilityReportExistsInReport = action.payload.reportCapabilities.find(
          reportCapability => reportCapability.capability === capability._id
        );
        if (capabilityReportExistsInReport) {
          capabilityReports.push(capabilityReportExistsInReport);
        } else {
          capabilityReports.push({
            capability,
            capabilityChange: {},
            images: [],
            status: REPORT_CONSTANTS.CAPABILITY_REPORT.STATUS.NOT_COMPLETED
          });
        }
      });
      return Object.assign({}, state, {
        capabilityReports: Object.assign({}, state.capabilityReports, {
          capabilityReports
        })
      });

    case NEW_REPORT_SELECT_CAPABILITY_REPORT:
      return Object.assign({}, state, {
        selectedCapabilityReport: action.payload
      });

    case NEW_REPORT_SELECT_DB_REPORT:
      let category = {};
      let company = {};
      if (state.selectedCompany) {
        company = action.payload.companies.find(c => c._id === state.selectedCompany._id);
        if (company && state.selectedCategory) {
          category = company.categories.find(c => c._id === state.selectedCategory._id);
        }
      }
      return Object.assign({}, state, {
        dbReport: action.payload,
        selectedCategory: Object.assign({}, { ...category, capabilityReports: [] }),
        selectedCompany: Object.assign({}, { ...company, categories: [] }),
        applications: getApplications(action.payload)
      });

    case NEW_REPORT_CLEAN_SELECT_DB_REPORT:
      return Object.assign({}, state, {
        dbReport: {},
        selectedCategory: {},
        selectedCompany: {}
      });

    case NEW_REPORT_PREVIOUS:
      return Object.assign({}, state, {
        previousReport: action.payload
      });

    case NEW_REPORT_ADD_FILE:
      return Object.assign({}, state, {
        files: insertItemToArray(state.files, {
          index: state.files.length - 1, //refactor if we want to specify index in payload
          item: action.payload
        })
      });

    case NEW_REPORT_CLEAR_FILES:
      return Object.assign({}, state, {
        files: []
      });

    case NEW_REPORT_CLEAR_CAPABILITY_REPORT:
      return Object.assign({}, state, {
        capabilityReports: []
      });

    case NEW_REPORT_REMOVE_FILE:
      return Object.assign({}, state, {
        files: removeItemFromArray(state.files, {
          index: action.payload
        })
      });

    case NEW_REPORT_MARK_IMAGE_FOR_DELETE:
      return Object.assign({}, state, {
        markedImagesForDelete: insertItemToArray(state.markedImagesForDelete, {
          index: state.markedImagesForDelete.length - 1,
          item: action.payload.image
        })
      });

    case NEW_REPORT_CLEAR_MARKED_IMAGES_FOR_DELETE:
      return Object.assign({}, state, {
        markedImagesForDelete: []
      });

    case NEW_REPORT_CONSUMER_SURVEY_COMPLETED:
      return Object.assign({}, state, {
        consumerSurveyCompleted: action.payload.completed
      });

    case NEW_REPORT_SCORING_RANKING_COMPLETED:
      return Object.assign({}, state, {
        scoringRankingCompleted: action.payload.completed
      });

    case NEW_REPORT_CONTENT_BUILDING_COMPLETED:
      return Object.assign({}, state, {
        contentBuildingCompleted: action.payload.completed
      });

    case SET_REPORT_PREVIEW:
      return Object.assign({}, state, {
        isPreview: action.payload
      });

    case SET_COMPLETE_MAP:
      return Object.assign({}, state, {
        completedMap: action.payload
      });

    case SET_PREVIOUS_CAPABILITY_IMAGES: {
      return Object.assign({}, state, {
        previousReportImages: action.payload
      });
    }

    case CLEAR_PREVIOUS_CAPABILITY_IMAGES: {
      return Object.assign({}, state, {
        previousReportImages: []
      });
    }

    default:
      return state;
  }
};

export default reportsReducer;
