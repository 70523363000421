import { CATEGORY_PERFORMANCE_SET_APPLICATION } from '../actions/CategoryPerformance.actions';

const initialState = {
  tab: 0
};

const categoryPerformanceUiReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_PERFORMANCE_SET_APPLICATION:
      return Object.assign({}, state, {
        tab: action.payload
      });
    default:
      return state;
  }
};

export default categoryPerformanceUiReducer;
