import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/styles';
import { Box, ButtonBase, Grid, Grow, Paper, Typography } from '@material-ui/core';
import viewReportsImage from '../../list/view-reports.png';
import newReportImage from '../images/new-report.png';
import TabReportStyles from './TabReport.styles';

const TabReport = ({ classes, push }) => {
  const onNewReport = () => {
    push('/report/select-industry');
  };

  const onViewAllReport = () => {
    push('/report-list');
  };

  return (
    <Grid item lg={10} xl={6} className={classes.container}>
      {/* Reports Pane */}
      <Grid container>
        <Grid item lg={12}>
          <Box>
            <Typography variant="h5">What would you like to work on?</Typography>
          </Box>
        </Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item>
            <Box mt={4}>
              <Grow in={true} timeout={300}>
                <Paper className={classes.paper} onClick={onViewAllReport} elevation={0}>
                  <ButtonBase className={classes.paperButton}>
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                      <Grid item>
                        <img src={viewReportsImage} alt="view reports" />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" className={classes.title}>
                          View All Reports
                        </Typography>
                      </Grid>
                    </Grid>
                  </ButtonBase>
                </Paper>
              </Grow>
            </Box>
          </Grid>
          <Grid item>
            <Box mt={4}>
              <Grow in={true} timeout={600}>
                <Paper className={classes.paper} onClick={onNewReport} elevation={0}>
                  <ButtonBase className={classes.paperButton}>
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                      <Grid item>
                        <img src={newReportImage} alt="new report" />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" className={classes.title}>
                          New Report
                        </Typography>
                      </Grid>
                    </Grid>
                  </ButtonBase>
                </Paper>
              </Grow>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  push
};

export default compose(withStyles(TabReportStyles), connect(null, mapDispatchToProps))(TabReport);
