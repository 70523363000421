export default theme => ({
  root: {
    justifyContent: 'space-between',
    display: 'flex',
    marginBottom: '1rem'
  },
  table: {
    width: '100%',
    border: '1px solid ' + theme.palette.divider,
    overflow: 'hidden',
    '& .MuiTableCell-footer': {
      border: 'none'
    }
  },
  row: {
    backgroundColor: '#f3f3f3'
  },
  oddRow: {
    backgroundColor: theme.palette.primary.main
  }
});
