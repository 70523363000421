import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import RenderPasswordField from './RenderPasswordField';
import RenderTextFieldStyle from './RenderTextField.style';

const RenderTooltipPasswordField = ({ open, title, classes, ...rest }) => {
  return (
    <Tooltip open={open} arrow placement="left" title={title} className={classes.root}>
      <RenderPasswordField {...rest} />
    </Tooltip>
  );
};

export default withStyles(RenderTextFieldStyle)(RenderTooltipPasswordField);
