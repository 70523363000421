import React, { useState } from 'react';
import clsx from 'clsx';
import { AppBar, Box, Button, Toolbar, Typography } from '@material-ui/core';
import KLDialog from '../../../dialog/KLDialog';
import RegisterForm from '../../../login/register/RegisterForm';
import AppBarStyles from './AppBar.styles';

const useStyles = AppBarStyles;

const AppBarLogin = ({ application, redirectTo }) => {
  const classes = useStyles();
  const [isOpenModal, setOpenModal] = useState(false);

  return (
    <AppBar position="absolute" className={clsx(classes.appBar)} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.title}>
          <img
            style={{ cursor: 'pointer' }}
            onClick={() => (window.location = redirectTo)}
            className={classes.formIcon}
            src={application === 'CJM' ? '/cjm_logo.png' : '/logo.png'}
            alt="Key Lime labs logo"
            height="30px"
          />
        </Box>
        <Button onClick={() => setOpenModal(!isOpenModal)} className={classes.barOption}>
          <Typography variant="body2">Register</Typography>
        </Button>

        <KLDialog
          isOpen={isOpenModal}
          onHandleClose={() => {
            setOpenModal(!isOpenModal);
          }}
          title="Register"
          body={<RegisterForm onCallBack={() => setOpenModal(!isOpenModal)} />}
          showButtonConfirm={false}
          showButtonClose={false}
        />
      </Toolbar>
    </AppBar>
  );
};

export default AppBarLogin;
