export default theme => ({
  paper: {
    width: '10rem',
    height: '8rem',
    border: '1px solid ' + theme.palette.divider,
    '&:hover': {
      color: theme.palette.secondary.main,
      fontWeight: 400
    }
  },
  borderBottom: {
    borderBottom: '1px solid ' + theme.palette.divider
  },
  paperButton: {
    width: '100%',
    height: '100%'
  },
  thin: {
    fontWeight: 300
  },
  title: {
    lineHeight: '1.25',
    fontSize: '0.85rem'
  }
});
