import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Card, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import MaintenanceService from '../../modules/maintenance/services/Maintenance.service';
import feathersAuthentication from '../../services/AuthService';
import AppBarLogin from '../layout/appBar/components/AppBarLogin';
import customThemes from '../customTheme';
import { setForgotLinkExpired, setForgotPasswordSuccess } from '../layout/actions/layout.actions';
import Notification from '../notification/Notification';
import LoginStyles from './Login.styles';
import LoginForm from './loginForm';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openNotification: {},
      onSubmitState: false
    };
  }

  componentDidMount() {
    this.props.onLogout();
  }

  login = auth => {
    const { email, password } = auth;
    this.setState({ onSubmitState: true });
    this.props.onAuthenticate({ strategy: 'local', email, password, app: 'INSIGHTS' }).then(
      data => {
        MaintenanceService.getUpcomingMaintenance();
        localStorage.removeItem('dismiss-maintenance-insights');
        localStorage.setItem('user', JSON.stringify(data.value.user));
        this.props.push('/');
      },
      error => {
        if (error.code === 429) {
          this.setState({
            openNotification: { open: true, message: 'Too many attempts, wait for a minute to try again.', variant: 'error' },
            onSubmitState: false
          });
        } else {
          this.setState({
            openNotification: { open: true, message: 'Email or Password are incorrect.', variant: 'warning' },
            onSubmitState: false
          });
        }
      }
    );
  };

  render() {
    const { classes, forgotLinkExpired, forgotPasswordSuccess } = this.props;
    const { openNotification, onSubmitState } = this.state;
    return (
      <MuiThemeProvider theme={createTheme(customThemes)}>
        <Notification
          message={'Your new password has been updated'}
          open={forgotPasswordSuccess}
          variant="success"
          onClose={() => {
            this.props.setForgotPasswordSuccess(false);
          }}
        />
        <Notification
          message={'Forgot Password link has expired!'}
          open={forgotLinkExpired}
          variant="error"
          onClose={() => this.props.setForgotLinkExpired(false)}
        />
        {openNotification?.open && (
          <Notification
            open
            duration={60000}
            message={openNotification?.message || ''}
            variant={openNotification?.variant || 'error'}
            onClose={() =>
              this.setState({
                openNotification: { open: false }
              })
            }
          />
        )}

        <AppBarLogin />
        <div className={classes.main}>
          <Card className={classes.card} elevation={0}>
            <Box mt={3} style={{ textAlign: 'center' }}>
              <img className={classes.formIcon} src="/logo.png" alt="keylime interactive logo" />{' '}
            </Box>

            <Box m={3}>
              <Typography className={classes.hint}>Sign In</Typography>
              <LoginForm classes={classes} onSubmit={this.login} onSubmitState={onSubmitState} />
            </Box>
          </Card>
        </div>
      </MuiThemeProvider>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  form: state.form,
  forgotLinkExpired: state.ui.layout.forgotLinkExpired,
  forgotPasswordSuccess: state.ui.layout.forgotPasswordSuccess
});

const mapDispatchToProps = {
  onAuthenticate: feathersAuthentication.authenticate,
  onLogout: feathersAuthentication.logout,
  setForgotLinkExpired,
  setForgotPasswordSuccess,
  push
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withStyles(LoginStyles));

const EnhancedLogin = enhance(Login);

export default EnhancedLogin;
