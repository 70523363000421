import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { EXTENSION_COMMON } from 'react-file-picker-providers/constants';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as XLSX from 'xlsx';
import PapaParse from 'papaparse';
import { Button, ButtonBase, DialogContentText, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import feathersServices from '../../../services/feathersServices';
import KLDialog from '../../../components/dialog/KLDialog';
import { fireNotification } from '../../../components/layout/actions/layout.actions';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import UploadSources from '../../../components/uploadSources/UploadSources';
import { ImportDataIcon, InfoIcon, SortedDataIcon } from '../../../images';
import { setScoringRankingCompleted } from '../../reports/actions/reports.actions';
import { setConsumerSurvey, setConsumerSurveyTab, setRawData } from '../action/ConsumerSurvey.actions';
import rawDataStyles from './RawData.styles';
import RowDataTable from './RowDataTable';

const normalizeArray = data => {
  let minTopBottom = 100;
  let maxTopBottom = 0;

  data.forEach((row, index) => {
    if (row[0] !== '') {
      if (row.topBottom > maxTopBottom) maxTopBottom = row.topBottom;
      if (row.topBottom < minTopBottom) minTopBottom = row.topBottom;
    }
  });
  data.forEach(row => {
    if (row[0] !== '') {
      row.normalized = parseFloat(((100 * (row.topBottom - minTopBottom)) / (maxTopBottom - minTopBottom) / 20).toFixed(2));
      if (row.normalized > 0) {
        row.nearestPointFive = parseFloat((Math.round(row.normalized * 2) / 2).toFixed(1)) || 0.5;
      } else {
        row.nearestPointFive = 0.5;
      }
    }
  });
  return data;
};

const MSG_FILE = 'File uploaded and processed successfully';
const MSG_FILE_ERROR = 'The content of the file is wrong';
class RawData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isOpenModalSource: false,
      scoringWarning: false,
      partiallyUploadedFile: null
    };
  }
  componentDidMount() {
    if (this.props.dbReport._id) {
      this.props
        .findConsumerSurveyService({
          query: { reportId: this.props.dbReport._id, $limit: 1 }
        })
        .then(
          data => {
            this.setState({ isLoading: false });
            this.props.setConsumerSurvey(data.value[0]);
          },
          error => push('/login')
        );
    } else {
      this.setState({ isLoading: false });
      this.props.setConsumerSurvey(null);
    }
  }

  handleChangeModalSource = () => {
    this.setState({
      isOpenModalSource: !this.state.isOpenModalSource
    });
  };

  processData = file => {
    let rowDataReader = [];
    if (file?.type === 'text/csv') {
      PapaParse.parse(file, {
        complete: async ({ data }) => {
          let normalizedArray;
          data.shift(); // delete header file

          let hasAllColumnsInSheet = false;
          try {
            data.forEach(row => {
              let aux = {};
              if (row && row[0]) {
                aux.name = row[0];
                aux.one = parseInt(row[1].replace('%', ''));
                aux.two = parseInt(row[2].replace('%', ''));
                aux.three = parseInt(row[3].replace('%', ''));
                aux.four = parseInt(row[4].replace('%', ''));
                aux.five = parseInt(row[5].replace('%', ''));
                aux.fourFive = parseInt(row[4].replace('%', '')) + parseInt(row[5].replace('%', ''));
                aux.topBottom =
                  parseInt(row[5].replace('%', '')) +
                  parseInt(row[4].replace('%', '')) -
                  parseInt(row[2].replace('%', '')) -
                  parseInt(row[1].replace('%', ''));

                rowDataReader.push(aux);
              }
              normalizedArray = hasAllColumnsInSheet ? rowDataReader : normalizeArray(rowDataReader);
            });
            let validation = await this.validateSurveyData(normalizedArray);
            if (validation.valid) {
              this.props.setRawData(normalizedArray);
              this.props.fireNotification('success', MSG_FILE, 1000);
            } else {
              let message = '';
              if (validation.errors.length < 10) {
                message = validation.errors.reduce((acc, e) => acc.concat(`<div>${e.message}</div>`), '');
              } else {
                message = 'we found several issues in the import, probably there is an error with the structure';
              }
              this.props.fireNotification('error', message);
            }
          } catch (error) {
            this.props.fireNotification('error', MSG_FILE_ERROR, 2000);
          }
        }
      });
    } else if (file?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file?.type === 'application/vnd.ms-excel') {
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          const workbook = XLSX.read(reader.result, { type: 'binary' });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const range = XLSX.utils.decode_range(sheet['!ref']);

          const getCellValue = (row, i) => {
            return sheet[XLSX.utils.encode_cell({ r: row, c: i })] !== undefined && sheet[XLSX.utils.encode_cell({ r: row, c: i })].w
              ? sheet[XLSX.utils.encode_cell({ r: row, c: i })].w
              : '';
          };

          // Skip first row (header) .-
          // let minTopBottom = 100;
          // let maxTopBottom = 0;
          let hasAllColumnsInSheet = false;
          for (let row = range.s.r + 1; row <= range.e.r; row++) {
            if (getCellValue(row, 0) !== '') {
              let aux = {};
              aux.name = sheet[XLSX.utils.encode_cell({ r: row, c: 0 })].w;
              aux.one = parseInt(getCellValue(row, 1).replace('%', ''));
              aux.two = parseInt(getCellValue(row, 2).replace('%', ''));
              aux.three = parseInt(getCellValue(row, 3).replace('%', ''));
              aux.four = parseInt(getCellValue(row, 4).replace('%', ''));
              aux.five = parseInt(getCellValue(row, 5).replace('%', ''));
              aux.fourFive = parseInt(getCellValue(row, 4).replace('%', '')) + parseInt(getCellValue(row, 5).replace('%', ''));
              aux.topBottom =
                parseInt(getCellValue(row, 5).replace('%', '')) +
                parseInt(getCellValue(row, 4).replace('%', '')) -
                parseInt(getCellValue(row, 2).replace('%', '')) -
                parseInt(getCellValue(row, 1).replace('%', ''));

              rowDataReader.push(aux);
            }
          }
          let normalizedArray = hasAllColumnsInSheet ? rowDataReader : normalizeArray(rowDataReader);
          let validation = await this.validateSurveyData(normalizedArray);
          if (validation.valid) {
            this.props.setRawData(normalizedArray);
            this.props.fireNotification('success', MSG_FILE, 1000);
          } else {
            let message = '';
            if (validation.errors.length < 10) {
              message = validation.errors.reduce((acc, e) => acc.concat(`<div>${e.message}</div>`), '');
            } else {
              message = 'we found several issues in the import, probably there is an error with the structure';
            }
            this.props.fireNotification('error', message);
          }
        } catch (error) {
          this.props.fireNotification('error', MSG_FILE_ERROR, 2000);
        }
      };
      reader.readAsBinaryString(file);
    }
    this.handleChangeModalSource();
  };

  validateSurveyData = async normalizedData => {
    let validation = {
      valid: false,
      errors: []
    };
    normalizedData.forEach((dataRow, index) => {
      let dataRowColumns = Object.keys(dataRow);
      if (!dataRow[dataRowColumns[0]].length) {
        validation.errors.push({
          message: `row ${index + 1} has an invalidName`
        });
      }
      for (let i = 1; i < dataRowColumns.length; i++) {
        if (isNaN(dataRow[dataRowColumns[i]])) {
          validation.errors.push({
            message: `value in row ${index + 1} column ${i} is invalid`
          });
          return false;
        }
      }
    });

    let industryId = this.props.dbReport.industry._id || this.props.dbReport.industry;
    const dbSurveyMappings = await this.props.getSurveyQuestions({
      query: { industry: industryId, $limit: 'infinity' }
    });

    if (normalizedData.length !== dbSurveyMappings.value.length) {
      validation.errors.push({
        message: 'There is a missmatch between the survey in the file and the categories mapping'
      });
    }

    dbSurveyMappings.value.forEach(dbSQ => {
      let matchingQuestion = normalizedData.find(nD => nD.name === dbSQ.title);
      if (!matchingQuestion) {
        validation.errors.push({
          message: 'No match found in file for: ' + dbSQ.title
        });
      }
    });

    if (validation.errors.length === 0) {
      validation.valid = true;
    }
    return validation;
  };

  handleMyComputerFiles = file => {
    this.processData(file[0]);
  };

  handleCloudProviderSuccess = file => {
    this.processData(file[0].fileBlob);
  };

  saveRowData = save => {
    if (save) {
      this.props.save();
    }

    this.props.setConsumerSurveyTab(1);
  };

  getUploadSource = () => {
    return (
      this.state.isOpenModalSource && (
        <UploadSources
          showAlerts={false}
          isMultiselect={false}
          className={this.props.classes.sourceModal}
          elevation={3}
          modalTitle={'Please upload consumer survey results'}
          modalSubTitle={'Accepted files types CSV, Excel'}
          handleClose={() =>
            this.setState({
              isOpenModalSource: !this.state.isOpenModalSource
            })
          }
          handleMyComputerFiles={file => {
            if (this.props.isScoringRankingCompleted) {
              this.setState({
                scoringWarning: true,
                partiallyUploadedFile: file
              });
            } else if (file) {
              if (file.length) {
                this.handleMyComputerFiles(file);
              }
            }
          }}
          myComputerAcceptedFiles={['.csv', '.xls', '.xlsx']}
          handleCloudProviderSuccess={this.handleCloudProviderSuccess}
          providerAcceptedFiles={[EXTENSION_COMMON.CSV, EXTENSION_COMMON.XLS, EXTENSION_COMMON.XLSX]}
        />
      )
    );
  };

  loading = () => {
    return (
      <LoadingIndicator color="secondary" isLoading={this.state.isLoading} width={40}>
        <Typography>Loading Raw Data</Typography>
      </LoadingIndicator>
    );
  };

  render() {
    const { classes, consumerSurvey } = this.props;
    const { isLoading } = this.state;

    return !isLoading ? (
      <Grid container>
        {!consumerSurvey.rawData ? (
          // icons
          <Grid item container wrap="nowrap" alignItems="center">
            <Grid item lg={1}>
              <Typography variant="body1">Step 1</Typography>
            </Grid>
            <Grid item lg={3}>
              <ButtonBase>
                <Paper className={classes.paper} onClick={() => this.handleChangeModalSource()} elevation={0}>
                  <Grid container>
                    <Grid item lg={2} />
                    <Grid item lg={2} className={classes.centerRaw}>
                      <Typography className={classes.textRawData}>%</Typography>
                      <Typography className={classes.textRawData}>-</Typography>
                    </Grid>
                    <Grid item lg={6}>
                      <ImportDataIcon width="6rem" height="6rem" />
                    </Grid>
                  </Grid>

                  <Grid container className={classes.center}>
                    <Typography>Import Data</Typography>
                    <InfoIcon className={classes.icon} />
                  </Grid>
                </Paper>
              </ButtonBase>
            </Grid>
            <Grid item lg={1}>
              <Typography variant="body1">Step 2</Typography>
            </Grid>
            <Grid item lg={3}>
              <ButtonBase>
                <Paper className={classes.paper} elevation={0}>
                  <SortedDataIcon width="6rem" height="6rem" />
                  <Grid item lg={12}>
                    <Typography variant="body1" className={classes.center}>
                      Sort Data
                      <InfoIcon className={classes.icon} />
                    </Typography>
                  </Grid>
                </Paper>
              </ButtonBase>
            </Grid>
            {this.getUploadSource()}
          </Grid>
        ) : (
          // TABLE
          <Grid container className={classes.rowDataContainer}>
            <RowDataTable openSourceModal={this.handleChangeModalSource} />
            <Button variant="outlined" color="secondary" className={classes.button} onClick={this.saveRowData}>
              Sort
            </Button>
            {this.getUploadSource()}
          </Grid>
        )}
        <KLDialog
          title={'Scoring & Ranking actions'}
          body={
            <DialogContentText>
              This change will reset scoring and rankings calculations. You will need to review them. Do you want to proceed?
            </DialogContentText>
          }
          labelButtonConfirm={'Accept'}
          onHandleConfirm={async () => {
            await this.handleMyComputerFiles(this.state.partiallyUploadedFile);

            this.setState({
              scoringWarning: false,
              partiallyUploadedFile: null
            });
            this.props.deleteScoringAndRanking(this.props.scoringAndRanking._id);
            this.props.setScoringRankingCompleted(false);
          }}
          onHandleClose={() =>
            this.setState({
              scoringWarning: false,
              partiallyUploadedFile: null,
              isOpenModalSource: false
            })
          }
          isOpen={this.state.scoringWarning}
        />
      </Grid>
    ) : (
      this.loading()
    );
  }
}

const mapStateToProps = state => {
  return {
    consumerSurvey: state.consumerSurvey,
    dbReport: state.currentReport.dbReport,
    scoringAndRanking: state.scoringAndRanking.scoringAndRanking,
    isScoringRankingCompleted: state.currentReport.scoringRankingCompleted
  };
};

const mapDispatchToProps = {
  fireNotification,
  setRawData: setRawData,
  setConsumerSurvey: setConsumerSurvey,
  setConsumerSurveyTab,
  findConsumerSurveyService: feathersServices['consumer-survey'].find,
  getSurveyQuestions: feathersServices['survey-question'].find,
  push,
  deleteScoringAndRanking: feathersServices['scoring-ranking'].remove,
  setScoringRankingCompleted
};

export default compose(withStyles(rawDataStyles), connect(mapStateToProps, mapDispatchToProps))(RawData);
