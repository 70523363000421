import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, reset } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';
import feathersServices from '../../../../services/feathersServices';
import RenderTextField from '../../../../components/renders/RenderTextField';
import { required, validEmail } from '../../../../utils/validation';
import SalesPersonFormStyles from './SalesPersonForm.styles';

const FORM_ID = 'salesPersonForm';

export class SalesPersonForm extends Component {
  handleSubmit = () => {
    const { salesPersonForm, onCallBack, salesPersonCreateService, reset } = this.props;
    salesPersonCreateService(salesPersonForm.values).then(
      () => {
        if (onCallBack) {
          onCallBack();
        }
        reset(FORM_ID);
      },
      error => {
        console.error(error);
      }
    );
  };

  render() {
    const { classes, valid } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Field
              color="secondary"
              validate={[required]}
              name="name"
              label="Name *"
              autoComplete="salesName"
              component={RenderTextField}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              color="secondary"
              validate={[required, validEmail]}
              name="email"
              autoComplete="salesEmail"
              label="Email *"
              component={RenderTextField}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              <Button onClick={this.handleSubmit} color="secondary" variant="contained" disabled={!valid} disableElevation>
                Create
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = state => {
  let initValues = {
    name: '',
    email: ''
  };

  return {
    initialValues: initValues,
    salesPersonForm: state.form[FORM_ID]
  };
};

const mapDispatchToProps = {
  salesPersonCreateService: feathersServices['sales-person'].create,
  reset
};

SalesPersonForm = compose(
  withStyles(SalesPersonFormStyles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(
    {
      form: FORM_ID,
      enableReinitialize: true,
      validate: (values, props) => {
        const errors = {};
        return errors;
      }
    },
    mapStateToProps
  )
)(SalesPersonForm);

export default SalesPersonForm;
