import {
  NEW_REPORT_CAPABILITY_REPORT_CHANGES_SAVING,
  NEW_REPORT_CAPABILITY_REPORT_CHANGES_SAVING_DONE,
  NEW_REPORT_CAPABILITY_REPORT_CHANGES_WARNING,
  NEW_REPORT_CAPABILITY_REPORT_CHANGES_WARNING_CLOSE,
  NEW_REPORT_TOGGLE_CAPABILITY_REPORT_DRAWER
} from '../actions/capability-detail.actions';

const initialState = {
  drawer_opened: false,
  warning_opened: false,
  saving_report: false
};

const capabilityDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEW_REPORT_TOGGLE_CAPABILITY_REPORT_DRAWER: {
      return Object.assign({}, state, {
        drawer_opened: !state.drawer_opened
      });
    }
    case NEW_REPORT_CAPABILITY_REPORT_CHANGES_WARNING: {
      return Object.assign({}, state, {
        warning_opened: true
      });
    }
    case NEW_REPORT_CAPABILITY_REPORT_CHANGES_WARNING_CLOSE: {
      return Object.assign({}, state, {
        warning_opened: false
      });
    }

    case NEW_REPORT_CAPABILITY_REPORT_CHANGES_SAVING: {
      return Object.assign({}, state, {
        saving_report: true
      });
    }

    case NEW_REPORT_CAPABILITY_REPORT_CHANGES_SAVING_DONE: {
      return Object.assign({}, state, {
        saving_report: false
      });
    }

    default:
      return state;
  }
};

export default capabilityDetailReducer;
