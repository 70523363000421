import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/styles';
import { Box, Breadcrumbs, ButtonBase, Container, Grid, Grow, Paper, Typography } from '@material-ui/core';
import feathersServices from '../../../services/feathersServices';
import LinkRouter from '../../../components/router-link/RouterLink';
import { CapabilityIcon, SurveyIcon } from '../../../images';
import TabReportStyles from '../../reports/home/tabReport/TabReport.styles';

function CategoryManagementHome(props) {
  const { classes, match, push } = props;

  const [industry, setIndustry] = useState(null);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    props.getIndustry(match.params.industry).then(res => setIndustry(res.value));
    props.getCategory(match.params.category).then(res => setCategory(res.value));
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Box mt={5}>
        <Grid item xs={12} className={classes.container}>
          <Box className={classes.box} mb={3}>
            <Breadcrumbs separator="›">
              <LinkRouter to="/" color="secondary" underline="none">
                <Typography color="secondary" variant="body2">
                  Admin
                </Typography>
              </LinkRouter>
              <LinkRouter to="/admin/industry-management" color="secondary" underline="none">
                <Typography color="secondary" variant="body2">
                  Industry Management
                </Typography>
              </LinkRouter>
              <LinkRouter to={`/admin/industry-management/${industry?._id}/categories`} color="secondary" underline="none">
                <Typography color="secondary" variant="body2">
                  {industry?.title}
                </Typography>
              </LinkRouter>
              <Typography variant="body2">{category?.title}</Typography>
            </Breadcrumbs>
          </Box>

          {/* Reports Pane */}
          <Grid container>
            <Grid item lg={12}>
              <Box>
                <Typography variant="h5">What would you like to work on?</Typography>
              </Box>
            </Grid>
            <Grid container direction="row" spacing={3}>
              <Grid item>
                <Box mt={4}>
                  <Grow in={true} timeout={300}>
                    <Paper className={classes.paper} elevation={0}>
                      <ButtonBase
                        className={classes.paperButton}
                        onClick={() => push(`/admin/industry-management/${match.params.industry}/categories/${category._id}/capabilities`)}
                      >
                        <Grid container justifyContent="center" alignItems="center" direction="column">
                          <Grid item>
                            <CapabilityIcon width="3em" height="3em" />
                          </Grid>
                          <Grid item>
                            <Typography variant="body1" className={classes.title}>
                              Capabilities
                            </Typography>
                          </Grid>
                        </Grid>
                      </ButtonBase>
                    </Paper>
                  </Grow>
                </Box>
              </Grid>
              <Grid item>
                <Box mt={4}>
                  <Grow in={true} timeout={600}>
                    <Paper className={classes.paper} elevation={0}>
                      <ButtonBase
                        className={classes.paperButton}
                        onClick={() => push(`/admin/industry-management/${match.params.industry}/categories/${category._id}/surveyQuestions`)}
                      >
                        <Grid container justifyContent="center" alignItems="center" direction="column">
                          <Grid item>
                            <SurveyIcon width="3em" height="3em" />
                          </Grid>
                          <Grid item>
                            <Typography variant="body1" className={classes.title}>
                              Survey Questions
                            </Typography>
                          </Grid>
                        </Grid>
                      </ButtonBase>
                    </Paper>
                  </Grow>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

const mapDispatchToProps = {
  push,
  getCategory: feathersServices.categories.get,
  getIndustry: feathersServices.industries.get
};

export default compose(withStyles(TabReportStyles), connect(null, mapDispatchToProps))(CategoryManagementHome);
