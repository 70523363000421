import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import {
  Box,
  Breadcrumbs,
  Dialog,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Edit from '@material-ui/icons/Edit';
import feathersServices from '../../services/feathersServices';
import { fireNotification } from '../../components/layout/actions/layout.actions';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import LinkRouter from '../../components/router-link/RouterLink';
import TabPanel from '../../components/tabPanel/TabPanel';
import { groupBy, groupByArray, unique } from '../../utils/immutable-utils';
import { validateProps, validateRoute } from '../../utils/routeUtils';
import SingletonMomentUtils from '../../utils/SingletonMomentUtils';
import { setConsumerSurvey } from '../consumer-survey/action/ConsumerSurvey.actions';
import { setContentBuilding } from '../content-building/action/ContentBuilding.actions';
import {
  previousReport as previousReportAction,
  selectApplication,
  selectCategory,
  selectCompany,
  selectDbReport,
  setScoringRankingCompleted
} from '../reports/actions/reports.actions';
import REPORT_CONSTANTS from '../reports/report.constants';
import {
  setCapabilityRankByCategoryUnweighted,
  setDeviceWeight,
  setIsLoading,
  setOrderedOverallRanks,
  setPreviousOrderedOverallRanks,
  setScoringAndRanking,
  setScoringAndRankingCalculated,
  setScoringRankingTab,
  toggleDeviceWeightDialog
} from './action/ScoringRanking.actions';
import DeviceWeightForm from './deviceWeightForm/DeviceWeightForm';
import ScoringRankingStyles from './ScoringRanking.styles';

class KLScoringRanking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applications: [],
      capabilityReportsByCompany: []
    };
  }

  async componentDidMount() {
    this.props.previousReportAction({});
    this.props.setPreviousOrderedOverallRanks({ overall: [] });
    this.props.setScoringAndRanking({});
    this.props.setOrderedOverallRanks({});
    this.props.setCapabilityRankByCategoryUnweighted({});
    this.props.setDeviceWeight({});

    validateProps(
      this.props.push,
      this.props.fireNotification,
      this.props.currentReport,
      this.props.currentReport.dbReport,
      this.props.currentReport.completedMap,
      this.props.selectedIndustry
    );
    validateRoute(this.props.push, this.props.currentReport.completedMap.capabilitiesTable && this.props.isConsumerSurveyCompleted);

    this.props.setIsLoading(true);
    this.props.setScoringAndRankingCalculated(false);
    if (this.props.deviceDialogOpen) {
      this.props.toggleDeviceWeightDialog();
    }

    // Get old report if exists
    const report = await this.props.getReport({
      query: {
        _id: this.props.currentReport.dbReport._id,
        $populate: true,
        $limit: 1
      }
    });
    this.props.selectDbReport(report.value.data[0]);
    if (this.props.currentReport.dbReport.industry) {
      const reportsFilter = await this.props.getReport({
        query: {
          industry: this.props.currentReport.dbReport.industry._id,
          date: { $lt: this.props.currentReport.dbReport.date },
          status: REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS,
          $populate: true,
          $limit: 1,
          $sort: {
            date: -1
          }
        }
      });
      const { 0: re } = reportsFilter.value.data;
      let previousScoringAndRanking;
      //TODO: check if previous report is completed
      if (re) {
        previousScoringAndRanking = await this.props
          .getScoringAndRanking({
            query: {
              reportId: re._id,
              $limit: 1
            }
          })
          .catch(err => console.error(err));
      }
      if (previousScoringAndRanking && previousScoringAndRanking.value.data.length) {
        this.orderCompanyRanks(previousScoringAndRanking.value.data[0]);
      }
      this.props.previousReportAction(re);
    }
    let applications = unique(
      groupByArray(
        this.props.dbReport && this.props.dbReport.companies.length > 0 && this.props.dbReport.companies[0].categories
          ? this.props.dbReport.companies[0].categories
          : [],
        'applicationTitle'
      )
    );
    applications.forEach(a => {
      a.values.forEach(c => {
        const finded = c.capabilityReports.filter(
          cr => cr.status === REPORT_CONSTANTS.CAPABILITY_REPORT.STATUS.DRAFT || cr.status === REPORT_CONSTANTS.CAPABILITY_REPORT.STATUS.NOT_COMPLETED
        );
        c.completed = c.capabilityReports.length && !finded.length;
      });
    });
    this.setState({ applications });
    this.props
      .getDeviceWeight({
        query: { reportId: this.props.currentReport.dbReport._id, $limit: 1 }
      })
      .then(({ value: deviceWeight }) => {
        this.props.setDeviceWeight(deviceWeight.data[0]);
        if (deviceWeight.data[0]) {
          this.initScoringAndRankingCalculation();
        } else {
          this.props.setIsLoading(false);
        }
      })
      .catch(err => {
        this.props.setIsLoading(false);
        console.error(err);
      });

    this.props
      .getCapabilityReports({
        query: {
          report: this.props.dbReport._id,
          $limit: 'infinity'
        }
      })
      .then(({ value }) => {
        this.setState({
          capabilityReportsByCompany: value
        });
      });
  }

  async initScoringAndRankingCalculation(forceRecalculate) {
    let { value: surveyQuestions } = await this.props.getSurveyQuestion({
      query: {
        industry: this.props.currentReport.dbReport.industry._id,
        $limit: 'infinity'
      }
    });
    let { value: survey } = await this.props.getSurvey({
      query: {
        reportId: this.props.currentReport.dbReport._id,
        $limit: 1
      }
    });
    this.props.setConsumerSurvey(survey[0]);
    let { value: scoringAndRanking } = await this.props.getScoringAndRanking({
      query: {
        reportId: this.props.currentReport.dbReport._id,
        $limit: 1
      }
    });
    if (scoringAndRanking.data.length) {
      // checkear cuando se calculo para saber si recalcularlo o no
      if (forceRecalculate || new Date(scoringAndRanking.data[0].updatedAt).getTime() < new Date(this.props.dbReport.updatedAt).getTime()) {
        //calular y actualizar
        this.calculateAndSaveScoringAndRanking(surveyQuestions, scoringAndRanking.data[0]);
      } else {
        this.props.setScoringAndRanking(scoringAndRanking.data[0]);
        this.orderCompanyRanks(null, scoringAndRanking.data[0]);
        this.buildDeviceCapabilityRanks(scoringAndRanking.data[0]);
        this.props.setIsLoading(false);
        this.props.setScoringAndRankingCalculated(true);
      }
    } else {
      this.calculateAndSaveScoringAndRanking(surveyQuestions);
    }
    this.props.setScoringRankingCompleted(true);
  }

  calculateAndSaveScoringAndRanking(surveyQuestions, ranking) {
    let companiesScores = this.getCompaniesScores(surveyQuestions, Object.assign({}, this.props.dbReport));
    if (ranking) {
      this.props
        .updateScoringAndRanking(ranking._id, {
          reportId: this.props.dbReport._id,
          scoringAndRanking: companiesScores
        })
        .then(
          ({ value: savedScoringAndRanking }) => {
            this.props.setScoringAndRanking(savedScoringAndRanking);
            this.orderCompanyRanks(null, savedScoringAndRanking);
            this.buildDeviceCapabilityRanks(savedScoringAndRanking);
            this.props.setIsLoading(false);
            this.props.setScoringAndRankingCalculated(true);
          },
          ({ code, message }) => {
            this.props.fireNotification('error', message, 3000);
          }
        );
    } else {
      this.props
        .saveScoringAndRanking({
          reportId: this.props.dbReport._id,
          scoringAndRanking: companiesScores
        })
        .then(
          async ({ value: savedScoringAndRanking }) => {
            this.props.setScoringAndRanking(savedScoringAndRanking);
            this.orderCompanyRanks(null, savedScoringAndRanking);
            this.buildDeviceCapabilityRanks(savedScoringAndRanking);
            this.props.setIsLoading(false);
            this.props.setScoringAndRankingCalculated(true);
            const contentBuilding = await this.props.findContentBuilding({
              query: {
                reportId: this.props.dbReport._id,
                $limit: 1
              }
            });
            if (contentBuilding.value && !contentBuilding.value.length) {
              const newContentBuilding = {
                _id: null,
                reportId: this.props.dbReport._id,
                executiveSummary: {
                  availableDate: new Date()
                },
                productRating: {},
                categoryPerformance: [],
                categoryRankByDevice: {},
                trendsInnovations: [],
                back2ContentBuilding: false
              };
              this.props.saveContentBuilding(newContentBuilding);
              this.props.setContentBuilding(newContentBuilding);
            }
          },
          ({ code, message }) => {
            this.props.fireNotification('error', message, 3000);
          }
        );
    }
  }

  handleChangeTabs = (event, newValue) => {
    this.props.setScoringRankingTab(newValue);
  };

  getCompaniesScores(surveyQuestions, report) {
    const { capabilityReportsByCompany } = this.state;
    let companies = [];
    report.companies.forEach(company => {
      companies.push({
        companyName: company.title,
        applications: groupBy(company.categories, 'applicationTitle'),
        userRatings: company.userRatings
      });

      let companyFound = companies.find(c => c.companyName === company.title);
      Object.keys(companyFound.applications).forEach(app => {
        companyFound.applications[app].forEach(cat => {
          cat.capabilityReports = capabilityReportsByCompany.filter(
            cr => cr.company === company.companyId && cr.application === cat.applicationId && cr.category === cat.categoryId
          );
        });
      });
    });
    companies.forEach(company => {
      let companyInArray = companies.find(c => c.companyName === company.companyName);
      companyInArray.overallDevicesSum = 0;

      Object.keys(companyInArray.applications).forEach(application => {
        companyInArray.applications[application] = {
          categories: company.applications[application],
          unweightedDeviceScore: 0,
          totalCategoryWeight: 0.2, // this adds user ratings fixed value of 0.2
          unweightedDeviceCategoryPercentage: 0
        };
        companyInArray.applications[application].categories.forEach(category => {
          let grouped = groupBy(category.capabilityReports, 'hasCapability');
          let unweightedDeviceScore = grouped[true] ? ((grouped[true].length / category.capabilityReports.length) * 100) / 100 : 0;
          let serverQuestionFound = surveyQuestions.find(survQuest => survQuest.category === category.categoryId);
          let categoryWeight = { nearestPointFive: 0.5 };
          if (serverQuestionFound) {
            const rawData = this.props.consumerSurvey.rawData;
            if (rawData) {
              let surveyQuestionFound = rawData.find(categoryQuestion => {
                return categoryQuestion.name === serverQuestionFound.title;
              });
              if (surveyQuestionFound) {
                categoryWeight = surveyQuestionFound;
              }
            }
          } else {
            let defaultValue = this.props.dbReport.companies
              .find(compan => compan.title === companyInArray.companyName)
              .categories.find(cat => cat.categoryId === category.categoryId);
            if (defaultValue.fixedScoringWeight) {
              categoryWeight = {
                nearestPointFive: defaultValue.fixedScoringWeight || 0.5
              };
            }
          }

          if (categoryWeight) {
            category.deviceScore = Number.parseFloat(Number.parseFloat(unweightedDeviceScore * categoryWeight.nearestPointFive).toFixed(4));
            companyInArray.applications[application].unweightedDeviceScore = Number.parseFloat(
              (companyInArray.applications[application].unweightedDeviceScore + category.deviceScore).toFixed(4)
            );
            companyInArray.applications[application].totalCategoryWeight += categoryWeight.nearestPointFive;
            category.unweightedDeviceCategoryPercentage = unweightedDeviceScore;
          }
        });
        // USER RATINGS
        if (company.userRatings && company.userRatings[application]) {
          companyInArray.applications[application].unweightedDeviceScore += company.userRatings[application] * 0.2;
        }

        companyInArray.applications[application].unweightedDevicePercentage = Number.parseFloat(
          Number.parseFloat(
            (companyInArray.applications[application].unweightedDeviceScore / companyInArray.applications[application].totalCategoryWeight).toFixed(4)
          )
        );

        companyInArray.applications[application].weightedDevicePercentage = Number.parseFloat(
          Number.parseFloat(
            (companyInArray.applications[application].unweightedDevicePercentage * this.props.deviceWeight.deviceWeights[application]).toFixed(4)
          )
        );
        companyInArray.overallDevicesSum = Number.parseFloat(
          (companyInArray.overallDevicesSum + companyInArray.applications[application].weightedDevicePercentage).toFixed(2)
        );
      });
      let totalDeviceWeight = 0;
      for (let applicationWeight in this.props.deviceWeight.deviceWeights) {
        totalDeviceWeight += Number.parseFloat(this.props.deviceWeight.deviceWeights[applicationWeight]);
      }

      companyInArray.overallPercentage = Number.parseFloat((companyInArray.overallDevicesSum / totalDeviceWeight).toFixed(4));
    });
    return companies;
  }

  orderCompanyRanks(previousScoringAndRanking, scorAndRank = this.props.scoringAndRanking) {
    const { scoringAndRanking } = previousScoringAndRanking || scorAndRank;
    let ranks = { overall: [] };
    for (let company in scoringAndRanking) {
      ranks.overall.push({
        company: scoringAndRanking[company].companyName,
        overallScore: scoringAndRanking[company].overallPercentage
      });
      for (let application in scoringAndRanking[company].applications) {
        if (!ranks[application]) ranks[application] = [];

        ranks[application].push({
          company: scoringAndRanking[company].companyName,
          overallScore: scoringAndRanking[company].applications[application].unweightedDevicePercentage
        });
      }
    }
    for (let key in ranks) {
      ranks[key] = this.orderBy(ranks[key], 'overallScore');
    }
    if (previousScoringAndRanking) {
      this.props.setPreviousOrderedOverallRanks(ranks);
    } else {
      this.props.setOrderedOverallRanks(ranks);
    }
  }

  buildDeviceCapabilityRanks = (savedScoringAndRanking = this.props.scoringAndRanking) => {
    let capabilityRankByCategoryUnweighted = {};

    this.state.applications.forEach(application => {
      capabilityRankByCategoryUnweighted[application.key] = [];
      this.props.dbReport.companies[0].categories
        .filter(c => c.applicationTitle === application.key)
        .forEach((category, categoryIndex) => {
          capabilityRankByCategoryUnweighted[application.key][categoryIndex] = {};
          this.props.dbReport.companies.forEach(company => {
            let categoryScore = savedScoringAndRanking.scoringAndRanking
              .find(s => s.companyName === company.title)
              .applications[application.key].categories.find(ca => {
                return ca.title === category.title;
              });

            Object.assign(capabilityRankByCategoryUnweighted[application.key][categoryIndex], {
              [company.title]: categoryScore.unweightedDeviceCategoryPercentage
            });
          });
          capabilityRankByCategoryUnweighted[application.key][categoryIndex].categoryName = category.title;
        });
    });
    this.props.setCapabilityRankByCategoryUnweighted(capabilityRankByCategoryUnweighted);
  };

  orderBy(data, value) {
    return data.sort((a, b) => {
      if (a[value] > b[value]) {
        return -1;
      }
      if (a[value] < b[value]) {
        return 1;
      }
      return 0;
    });
  }

  redirectToCapabilityReports(company, applicationTitle, categoryTitle) {
    this.props.selectCompany(company);
    this.props.selectApplication(applicationTitle);
    let category = company.categories.find(c => {
      return c.title === categoryTitle && c.applicationTitle === applicationTitle;
    });
    this.props.selectCategory(category);
    this.props.push('/report/capability-detail');
  }

  handleClose() {
    this.props.toggleDeviceWeightDialog();
  }

  saveDeviceWeight() {
    if (Object.keys(this.props.deviceWeight).length > 0) {
      this.props
        .updateDeviceWeight(
          this.props.deviceWeight._id,
          Object.assign({}, this.props.deviceWeight, {
            deviceWeights: this.props.deviceWeightForm.values
          })
        )
        .then(({ value: savedDeviceWeight }) => {
          this.props.setDeviceWeight(savedDeviceWeight);
          this.props.setIsLoading(true);
          this.initScoringAndRankingCalculation(true);
          this.props.setScoringRankingCompleted(true);
          this.handleClose();
        })
        .catch(err => {
          this.props.setIsLoading(false);
          console.error(err);
        });
    } else {
      this.props
        .saveDeviceWeight({
          reportId: this.props.currentReport.dbReport._id,
          deviceWeights: this.props.deviceWeightForm.values
        })
        .then(({ value: savedDeviceWeight }) => {
          this.props.setDeviceWeight(savedDeviceWeight);
          this.props.setIsLoading(true);
          this.initScoringAndRankingCalculation(true);
          this.props.setScoringRankingCompleted(true);
          this.handleClose();
        })
        .catch(err => {
          this.props.setIsLoading(false);
          console.error(err);
        });
    }
  }

  getDeviceWeightWaring() {
    return <caption>Please Provide Device Weights</caption>;
  }

  getLoadingRow() {
    return (
      <caption>
        <LoadingIndicator color="secondary" isLoading={this.props.isLoading} width={40} />
      </caption>
    );
  }

  calculateDifference(columnName, index) {
    const { orderedOverallRanks, previousOrderedOverallRanks, classes } = this.props;
    if (
      previousOrderedOverallRanks &&
      previousOrderedOverallRanks[columnName] &&
      previousOrderedOverallRanks[columnName][index] &&
      Math.abs(orderedOverallRanks[columnName][index].overallScore - previousOrderedOverallRanks[columnName][index].overallScore) >= 0.05
    ) {
      if (orderedOverallRanks[columnName][index].overallScore - previousOrderedOverallRanks[columnName][index].overallScore > 0) {
        return (
          <span>
            &nbsp;(<span className={classes.green}>↑</span>
            {Math.round(
              Math.abs(orderedOverallRanks[columnName][index].overallScore - previousOrderedOverallRanks[columnName][index].overallScore) * 100
            ) + '%)'}
          </span>
        );
      } else {
        return (
          <span>
            &nbsp;(<span className={classes.red}>↓</span>
            {Math.round(
              Math.abs(orderedOverallRanks[columnName][index].overallScore - previousOrderedOverallRanks[columnName][index].overallScore) * 100
            ) + '%)'}
          </span>
        );
      }
    }
    return null;
  }

  renderCaption() {
    const { isLoading, classes, scoringAndRankingCalculated } = this.props;
    if (!isLoading && scoringAndRankingCalculated) {
      return (
        <caption>
          Note:
          <span className={classes.green}>↑</span>
          <span className={classes.red}>↓</span>= Significant change since last report (Differences of +/- 5% are significan at 95% confidence)
        </caption>
      );
    }
    return null;
  }

  renderBackToContentBuilding() {
    const { classes } = this.props;
    if (this.props.back2ContentBuilding) {
      return (
        <div className={classes.backContentBuilding}>
          <LinkRouter to="/content-building/executive-summary" color="secondary" underline="none">
            <Typography color="secondary" variant="body2">
              Back to Content Building
            </Typography>
          </LinkRouter>
        </div>
      );
    }
    return null;
  }

  render() {
    const { applications } = this.state;
    const {
      tabSelected,
      classes,
      dbReport,
      capabilityRankByCategoryUnweighted,
      selectedIndustry,
      orderedOverallRanks,
      toggleDeviceWeightDialog,
      deviceDialogOpen,
      isLoading,
      deviceWeight,
      scoringAndRankingCalculated
    } = this.props;

    return (
      <Box>
        <Grid container direction="column" className={classes.root}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/report" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                Home
              </Typography>
            </LinkRouter>
            <LinkRouter to="/report/report-publish-date" color="secondary" underline="none">
              {dbReport && (
                <Typography color="secondary" variant="body2">
                  {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
                </Typography>
              )}
            </LinkRouter>
            <Typography variant="body2">Scoring & Ranking</Typography>
          </Breadcrumbs>
          <Grid item container justifyContent="space-between" alignItems="flex-end" lg={12}>
            <Grid item>
              <Box mt={5}>
                <Typography variant="h5">Scoring & Ranking</Typography>
                <Typography>Research Notes</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Grow in={true} timeout={300}>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    <Typography variant="h5" className={classes.legendTitle}>
                      Device Weight
                    </Typography>
                    <IconButton onClick={toggleDeviceWeightDialog} size="small">
                      <Edit className={classes.icon} fontSize="small" />
                    </IconButton>
                  </legend>
                  {applications &&
                    applications.length &&
                    applications.map(application => {
                      return (
                        <Typography key={application.key} variant="caption" className={classes.weights}>
                          {`${application.key}: ${deviceWeight && deviceWeight.deviceWeights ? deviceWeight.deviceWeights[application.key] : ''}`}
                        </Typography>
                      );
                    })}
                </fieldset>
              </Grow>
              <Dialog open={deviceDialogOpen} onClose={() => this.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Device Weights</DialogTitle>
                <DeviceWeightForm
                  applications={applications}
                  handleSubmit={() => this.saveDeviceWeight()}
                  handleClose={() => this.handleClose()}
                ></DeviceWeightForm>
              </Dialog>
            </Grid>
          </Grid>
        </Grid>
        {/* Tabs */}
        <Grid item lg={12} className={classes.gridBottom}>
          <Box className={classes.root}>
            <Tabs value={tabSelected} onChange={this.handleChangeTabs}>
              <Tab key="Overall" label="Overall" />
              {applications && applications.length && applications.map(application => <Tab key={application.key} label={application.key} />)}
            </Tabs>
          </Box>
        </Grid>
        {/* Tabs Panes */}
        <TabPanel value={tabSelected} index={0} className={classes.tabPanel}>
          <Grid item container>
            <Box mb={2}>
              <Typography variant="h6">Comparative Company Rankings</Typography>
            </Box>
            <Paper elevation={0} className={classes.table}>
              <Table size="medium" aria-label="a dense table">
                {this.renderCaption()}
                {!isLoading && !scoringAndRankingCalculated && this.getDeviceWeightWaring()}
                {isLoading && this.getLoadingRow()}
                <TableHead>
                  <TableRow className={classes.row}>
                    <TableCell align="center" className={classes.tableHeader}>
                      Rank
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeader}>
                      Overall Score
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeader}>
                      Mobile Web
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeader}>
                      IPhone App
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeader}>
                      Android App
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading &&
                    scoringAndRankingCalculated &&
                    orderedOverallRanks &&
                    orderedOverallRanks.overall &&
                    orderedOverallRanks.overall.map((score, index) => {
                      return (
                        <TableRow key={index} className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}>
                          <TableCell align="center">
                            <Typography className={clsx(classes.tableText, classes.floatLeft)} color="textSecondary">
                              {index + 1}
                            </Typography>
                          </TableCell>
                          {Object.keys(orderedOverallRanks).map(columnName => {
                            return (
                              <TableCell key={columnName} align="justify">
                                <Typography className={clsx(classes.tableText, classes.floatLeft)} color="textSecondary">
                                  {orderedOverallRanks[columnName][index].company}
                                </Typography>
                                <span className={classes.positionRight}>
                                  <Typography component="span">
                                    <Typography className={clsx(classes.tableText, classes.floatLeft)} color="textSecondary">
                                      {`${Math.round(orderedOverallRanks[columnName][index].overallScore * 100)}%`}
                                    </Typography>
                                  </Typography>
                                  <Typography component="span">
                                    <Typography className={clsx(classes.tableText, classes.floatLeft)} color="textSecondary">
                                      {this.calculateDifference(columnName, index)}
                                    </Typography>
                                  </Typography>
                                </span>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          {this.renderBackToContentBuilding()}
        </TabPanel>

        {applications &&
          applications.length > 0 &&
          applications.map((application, i) => (
            <TabPanel value={tabSelected} index={++i} key={i} className={classes.tabPanel}>
              <Grid item container direction="column">
                <Box mb={2}>
                  <Typography variant="h6">Brand Ranking</Typography>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Paper elevation={0} className={classes.brandRankingTable}>
                    <Table size="medium" aria-label="a dense table">
                      {!isLoading && !scoringAndRankingCalculated && this.getDeviceWeightWaring()}
                      {isLoading && this.getLoadingRow()}
                      <TableHead>
                        <TableRow className={classes.row}>
                          <TableCell align="center" className={classes.tableHeader}>
                            Rank
                          </TableCell>
                          <TableCell align="left" className={classes.tableHeader}>
                            Brand
                          </TableCell>
                          <TableCell align="center" className={classes.tableHeader}>
                            {application.key + ' Score'}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!isLoading &&
                          orderedOverallRanks &&
                          orderedOverallRanks.overall &&
                          orderedOverallRanks.overall.map((score, index) => {
                            return (
                              <TableRow key={index} className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}>
                                <TableCell align="center">
                                  <Typography className={classes.tableText} color="textSecondary">
                                    {index + 1}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.tableText} color="textSecondary">
                                    {orderedOverallRanks[application.key][index].company}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography className={classes.tableText} color="textSecondary">
                                    {Math.round(orderedOverallRanks[application.key][index].overallScore * 100) + '%'}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Paper>
                </Box>
                <Box mt={2} mb={2}>
                  <Typography variant="h6">Capability Ranking by Category (Unweighted)</Typography>
                </Box>
                <Paper elevation={0} className={classes.table}>
                  <Table size="medium" aria-label="a dense table">
                    {!isLoading && !scoringAndRankingCalculated && this.getDeviceWeightWaring()}
                    {isLoading && this.getLoadingRow()}
                    <TableHead>
                      <TableRow className={classes.row}>
                        <TableCell align="left" className={classes.tableHeader}>
                          Category
                        </TableCell>
                        {dbReport.companies.map((company, i) => {
                          return (
                            <TableCell key={i} align="center" className={classes.tableHeader}>
                              {company.title}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isLoading &&
                        capabilityRankByCategoryUnweighted &&
                        capabilityRankByCategoryUnweighted[application.key] &&
                        capabilityRankByCategoryUnweighted[application.key].map((row, index) => {
                          return (
                            <TableRow key={index} className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}>
                              <TableCell align="left">
                                <Typography className={classes.tableText} color="textSecondary">
                                  {row.categoryName}
                                </Typography>
                              </TableCell>
                              {dbReport.companies.map((company, i) => {
                                return (
                                  <TableCell
                                    onClick={() => this.redirectToCapabilityReports(company, application.key, row.categoryName)}
                                    className={classes.clickableCell}
                                    key={i}
                                    align="center"
                                  >
                                    <Typography className={classes.tableText}>{Math.round(row[company.title] * 100) + '%'}</Typography>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
              <Box mt={4}>{this.renderBackToContentBuilding()}</Box>
            </TabPanel>
          ))}
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentReport: state.currentReport,
    dbReport: state.currentReport.dbReport,
    deviceWeightForm: state.form.deviceWeightForm,
    consumerSurvey: state.consumerSurvey,
    selectedIndustry: state.currentReport.selectedIndustry,
    tabSelected: state.ui.scoringAndRanking.tab,
    deviceDialogOpen: state.ui.scoringAndRanking.device_dialog_open,
    isLoading: state.ui.scoringAndRanking.isLoading,
    scoringAndRankingCalculated: state.ui.scoringAndRanking.scoringAndRankingCalculated,
    scoringAndRanking: state.scoringAndRanking.scoringAndRanking,
    previousScoringAndRanking: state.scoringAndRanking.previousScoringAndRanking,
    deviceWeight: state.scoringAndRanking.deviceWeight,
    orderedOverallRanks: state.scoringAndRanking.orderedOverallRanks,
    previousOrderedOverallRanks: state.scoringAndRanking.previousOrderedOverallRanks,
    capabilityRankByCategoryUnweighted: state.scoringAndRanking.capabilityRankByCategoryUnweighted,
    back2ContentBuilding: state.contentBuilding.back2ContentBuilding,
    isConsumerSurveyCompleted: state.currentReport.consumerSurveyCompleted,
    contentBuilding: state.contentBuilding
  };
};

const mapDispatchToProps = {
  setScoringRankingTab,
  setScoringAndRanking,
  selectDbReport,
  setOrderedOverallRanks,
  setCapabilityRankByCategoryUnweighted,
  setConsumerSurvey,
  selectCompany,
  selectApplication,
  selectCategory,
  toggleDeviceWeightDialog,
  setIsLoading,
  previousReportAction,
  setScoringAndRankingCalculated,
  setPreviousOrderedOverallRanks,
  getSurvey: feathersServices['consumer-survey'].find,
  getSurveyQuestion: feathersServices['survey-question'].find,
  getScoringAndRanking: feathersServices['scoring-ranking'].find,
  saveScoringAndRanking: feathersServices['scoring-ranking'].create,
  updateScoringAndRanking: feathersServices['scoring-ranking'].update,
  getReport: feathersServices.reports.find,
  setDeviceWeight,
  getDeviceWeight: feathersServices['device-weight'].find,
  saveDeviceWeight: feathersServices['device-weight'].create,
  updateDeviceWeight: feathersServices['device-weight'].update,
  getCapabilityReports: feathersServices['capability-reports'].find,
  setScoringRankingCompleted,
  push,
  fireNotification,
  setContentBuilding,
  saveContentBuilding: feathersServices['content-building'].create,
  findContentBuilding: feathersServices['content-building'].find
};

export default compose(withStyles(ScoringRankingStyles), connect(mapStateToProps, mapDispatchToProps))(KLScoringRanking);
