import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Box, Breadcrumbs, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import feathersServices from '../../services/feathersServices';
import { toggleSideMenu } from '../../components/layout/actions/layout.actions';
import LinkRouter from '../../components/router-link/RouterLink';
import TabPanel from '../../components/tabPanel/TabPanel';
import { validateRoute } from '../../utils/routeUtils';
import SingletonMomentUtils from '../../utils/SingletonMomentUtils';
import { clearContentBuilding, setContentBuilding } from '../content-building/action/ContentBuilding.actions';
import REPORT_CONSTANTS from '../reports/report.constants';
import { setScoringAndRanking } from '../scoring-ranking/action/ScoringRanking.actions';
import { clearConsumerSurvey, setConsumerSurvey, setConsumerSurveyTab } from './action/ConsumerSurvey.actions';
import ConsumerSurveyStyles from './ConsumerSurvey.styles';
import RawData from './rawData/RawData';
import SortedData from './sortData/SortedData';

class KLConsumerSurvey extends Component {
  componentDidMount() {
    validateRoute(
      this.props.push,
      this.props.dbReport.status !== REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS &&
        this.props.dbReport.status !== REPORT_CONSTANTS.STATUS.PUBLISHED_EDITED.STATUS &&
        this.props.dbReport.status !== REPORT_CONSTANTS.STATUS.PUBLISHED_EDITING.STATUS
    );

    if (!this.props.contentBuilding._id) {
      this.props
        .getContentBuilding({
          query: {
            reportId: this.props.dbReport._id,
            $limit: 1
          }
        })
        .then(({ value }) => {
          value[0] ? this.props.setContentBuilding(value[0]) : this.props.clearContentBuilding();
        });
    }

    // Set scoring and ranking for later calculation.-
    this.props
      .getScoringAndRanking({
        query: {
          reportId: this.props.dbReport._id,
          $limit: 1
        }
      })
      .then(({ value: scoringAndRanking }) => {
        if (scoringAndRanking.data.length) {
          this.props.setScoringAndRanking(scoringAndRanking.data[0]);
        }
      });
  }

  componentWillUnmount() {
    this.props.clearConsumerSurvey();
  }

  handleChangeTabs = (event, newValue) => {
    this.saveConsumerSurvey();
    this.props.setConsumerSurveyTab(newValue);
  };

  saveConsumerSurvey = redirect => {
    const { consumerSurvey, createConsumerSurveyService, updateConsumerSurveyService, dbReport, setConsumerSurvey, push } = this.props;

    if (!consumerSurvey._id) {
      createConsumerSurveyService({
        reportId: dbReport._id,
        rawData: consumerSurvey.rawData,
        sortedData: []
      }).then(consumerSurveyDB => {
        setConsumerSurvey(consumerSurveyDB.value);
        if (redirect) {
          push(redirect);
        }
      });
    } else {
      updateConsumerSurveyService(consumerSurvey._id, consumerSurvey).then(consumerSurveyDB => {
        setConsumerSurvey(consumerSurveyDB.value);
        if (redirect) {
          push(redirect);
        }
      });
    }
    setConsumerSurveyTab(1);
  };

  render() {
    const { tabSelected, classes, dbReport, selectedIndustry, rawData } = this.props;
    return (
      <Box>
        <Grid container direction="column" className={classes.root}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/report" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                Home
              </Typography>
            </LinkRouter>
            <LinkRouter to="/report/report-publish-date" color="secondary" underline="none">
              {dbReport && (
                <Typography color="secondary" variant="body2">
                  {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
                </Typography>
              )}
            </LinkRouter>
            <Typography variant="body2">Consumer Survey</Typography>
          </Breadcrumbs>
          <Grid item lg={7}>
            <Box mt={5}>
              <Typography variant="h5">Consumer Survey</Typography>
            </Box>
          </Grid>
        </Grid>
        {/* Tabs */}
        <Grid item lg={12} className={classes.gridBottom}>
          <Box className={classes.root}>
            <Tabs value={tabSelected} onChange={this.handleChangeTabs}>
              <Tab label="Raw Data" />
              <Tab label="Sorted Data" disabled={!rawData} />
            </Tabs>
          </Box>
        </Grid>
        {/* Tabs Panes */}
        <TabPanel value={tabSelected} index={0} className={classes.tabPanel}>
          <RawData save={this.saveConsumerSurvey} />
        </TabPanel>
        <TabPanel value={tabSelected} index={1} className={classes.tabPanel}>
          <SortedData save={this.saveConsumerSurvey} />
        </TabPanel>
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    consumerSurvey: state.consumerSurvey,
    tabSelected: state.consumerSurvey.tab,
    rawData: state.consumerSurvey.rawData,
    user: state.auth.user,
    dbReport: state.currentReport.dbReport,
    selectedIndustry: state.currentReport.selectedIndustry,
    contentBuilding: state.contentBuilding
  };
};

const mapDispatchToProps = {
  onToggleSideMenu: toggleSideMenu,
  setConsumerSurveyTab,
  clearConsumerSurvey: clearConsumerSurvey,
  setConsumerSurvey: setConsumerSurvey,
  createConsumerSurveyService: feathersServices['consumer-survey'].create,
  updateConsumerSurveyService: feathersServices['consumer-survey'].update,
  getContentBuilding: feathersServices['content-building'].find,
  getScoringAndRanking: feathersServices['scoring-ranking'].find,
  clearContentBuilding,
  setContentBuilding,
  setScoringAndRanking,
  push
};

export default compose(withStyles(ConsumerSurveyStyles), connect(mapStateToProps, mapDispatchToProps))(KLConsumerSurvey);
