import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Box, Breadcrumbs, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Done from '@material-ui/icons/Done';
import LinkRouter from '../../../components/router-link/RouterLink';
import { getReportCategoriesGrouped } from '../../../utils/report.utils';
import { validateProps, validateRoute } from '../../../utils/routeUtils';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { canEdit } from '../../../utils/user.utils';
import { USER_ROLE } from '../../../constants/user.constants';
import REPORT_CONSTANTS from '../../reports/report.constants';
import CategoryPerformanceHomeStyles from './CategoryPerformanceHome.styles';

class KLCategoryPerformanceHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: []
    };
  }

  componentDidMount() {
    const { push, fireNotification, dbReport, isScoringRankingCompleted } = this.props;

    validateProps(push, fireNotification, dbReport);
    validateRoute(push, dbReport.status !== REPORT_CONSTANTS.STATUS.DRAFT.STATUS || isScoringRankingCompleted);

    this.setState({
      categories: getReportCategoriesGrouped(this.props.dbReport)
    });
  }

  isCategoryCompleted = category => {
    let hasCategoryPerformance = true;
    const { contentBuilding, applications } = this.props;

    applications.forEach(app => {
      const found = contentBuilding.categoryPerformance.filter(c => c.categoryGroup === category.categoryGroup && c.applicationId === app.id);
      if (found && found.length > 0) {
        found.forEach(category => {
          hasCategoryPerformance = hasCategoryPerformance && category.applicationId && category.notableCallouts !== undefined;
        });
      } else {
        hasCategoryPerformance = false;
      }
    });
    return hasCategoryPerformance;
  };

  render() {
    const { categories } = this.state;
    const { dbReport, selectedIndustry, classes, user, isPreview } = this.props;
    return (
      <Box className={classes.centerContainer}>
        <Grid container item direction="column" className={classes.container} lg={10} xl={8}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/report" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                {user.loginType !== USER_ROLE.CUSTOMER ? 'Home' : 'Report'}
              </Typography>
            </LinkRouter>
            <LinkRouter to={canEdit(user, dbReport) ? '/report/report-publish-date' : '/content-building'} color="secondary" underline="none">
              {dbReport && (
                <Typography color="secondary" variant="body2">
                  {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
                </Typography>
              )}
            </LinkRouter>
            {user.loginType !== USER_ROLE.CUSTOMER && (
              <LinkRouter to="/content-building" color="secondary" underline="none">
                <Typography color="secondary" variant="body2">
                  Content Building
                </Typography>
              </LinkRouter>
            )}
            <Typography variant="body2">Category Performance</Typography>
          </Breadcrumbs>
          <Box mt={5} mb={4} className={classes.width100}>
            <Typography variant="h4">Category Performance</Typography>
          </Box>
          <Grid container item xs={10} spacing={2}>
            {categories.map((c, index) => {
              if (isPreview && index > 0) {
                return null;
              }
              return (
                <Grid item xs={6} key={index}>
                  <LinkRouter
                    to={`/content-building/category-performance&category${encodeURIComponent(c.categoryGroup)}`}
                    color="secondary"
                    underline="none"
                  >
                    <Typography color="secondary" variant="body2" style={{ float: 'left' }}>
                      {c.categoryGroup}
                    </Typography>
                    {this.isCategoryCompleted(c) && <Done color="secondary" fontSize="small" />}
                  </LinkRouter>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    dbReport: state.currentReport.dbReport,
    isPreview: state.currentReport.isPreview,
    contentBuilding: state.contentBuilding,
    applications: state.currentReport.applications,
    selectedIndustry: state.currentReport.selectedIndustry,
    isScoringRankingCompleted: state.currentReport.scoringRankingCompleted
  };
};

const mapDispatchToProps = {
  push
};

export default compose(withStyles(CategoryPerformanceHomeStyles), connect(mapStateToProps, mapDispatchToProps))(KLCategoryPerformanceHome);
