import feathers from '../../../setup/feathersClient';
import { store } from '../../../setup/store';
import { setUpcomingMaintenance } from '../actions/maintenance.actions';

const BANNER_HEIGHT = 80;

class MaintenanceService {
  static getBannerHeight() {
    return BANNER_HEIGHT;
  }

  static getUpcomingMaintenance() {
    const dismissedBanner = localStorage.getItem('dismiss-maintenance-insights');
    if (!dismissedBanner) {
      feathers
        .service('status-io')
        .find({
          query: {
            entity: 'maintenance'
          }
        })
        .then(maintenance => {
          store.dispatch(setUpcomingMaintenance(maintenance));
        })
        .catch(e => {
          console.error(e);
        });
    }
  }
}

export default MaintenanceService;
