export default theme => ({
  root: {
    marginTop: theme.spacing(4),
    padding: '0 10%',
    [theme.breakpoints.down('lg')]: {
      padding: '0 5%'
    }
  },
  gridBottom: {
    borderBottom: '1px solid ' + theme.palette.divider
  },
  tabPanel: {
    padding: '10%',
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      padding: '0 5%',
      paddingTop: theme.spacing(2)
    }
  },
  paper: {
    width: '100%'
  },
  floatRight: {
    float: 'right'
  },
  clickableCell: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    '&:hover': {
      'font-weight': 'bold'
    }
  },
  rightMinus10: {
    position: 'relative',
    right: -10
  },
  red: {
    color: 'red'
  },
  green: {
    color: 'green'
  },
  positionRight: {
    width: '85px',
    float: 'right'
  },
  table: {
    width: '100%',
    border: '1px solid ' + theme.palette.divider,
    overflow: 'hidden',
    '& tbody > tr:last-child > td': {
      border: 'none'
    }
  },
  brandRankingTable: {
    width: '70%',
    height: 'fit-content',
    border: '1px solid ' + theme.palette.divider,
    overflow: 'hidden',
    '& tbody > tr:last-child > td': {
      border: 'none'
    }
  },
  row: {
    backgroundColor: '#f3f3f3'
  },
  oddRow: {
    backgroundColor: theme.palette.primary.main
  },
  fieldset: {
    borderRadius: 10,
    border: '2px solid' + theme.palette.secondary.main
  },
  legend: {
    display: 'flex',
    alignItems: 'center'
  },
  legendTitle: {
    float: 'left',
    marginRight: 5
  },
  weights: {
    float: 'left',
    marginRight: 20
  },
  backContentBuilding: {
    float: 'right'
  },
  icon: {
    cursor: 'pointer'
  },
  tableText: {
    fontSize: '0.7rem'
  },
  floatLeft: {
    float: 'left'
  },
  tableHeader: {
    fontSize: '0.7rem',
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  }
});
