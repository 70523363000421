const drawerWidth = 500;

export default theme => ({
  paper: {
    width: drawerWidth,
    zIndex: 1300
  },
  root: {
    width: 0 // to allow click on the background
  }
});
