import { MAINTENANCE_SET_UPCOMING } from '../actions/maintenance.actions';

const initialState = {
  maintenance: null
};

export function maintenanceReducer(state = initialState, action) {
  switch (action.type) {
    case MAINTENANCE_SET_UPCOMING:
      return Object.assign({}, state, { maintenance: action.payload });

    default:
      return state;
  }
}
