import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, reset } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';
import feathersServices from '../../../services/feathersServices';
import { required, validEmail } from '../../../utils/validation';
import RenderTextField from '../../renders/RenderTextField';
import RegisterFormStyles from './Register.styles';

const FORM_ID = 'registerForm';

let RegisterForm = ({ classes, registerForm, onCallBack, register, reset, valid }) => {
  const handleSubmit = () => {
    register(Object.assign(registerForm.values, { url: window.location.origin })).then(
      registerBKN => {
        if (onCallBack) {
          onCallBack();
        }
        reset(FORM_ID);
      },
      errorBKN => {
        console.error(errorBKN);
      }
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Field color="secondary" validate={[required]} name="name" label="First Name *" component={RenderTextField} fullWidth variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <Field
            color="secondary"
            validate={[required]}
            name="lastName"
            label="Last Name *"
            component={RenderTextField}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            color="secondary"
            validate={[required, validEmail]}
            name="email"
            label="Email *"
            component={RenderTextField}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Field color="secondary" validate={[required]} name="phone" label="Phone *" component={RenderTextField} fullWidth variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <Field color="secondary" validate={[required]} name="title" label="Title *" component={RenderTextField} fullWidth variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <Field color="secondary" validate={[required]} name="company" label="Company *" component={RenderTextField} fullWidth variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Button onClick={handleSubmit} color="secondary" variant="contained" disableElevation disabled={!valid}>
              Register
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = state => {
  let initValues = {
    name: '',
    lastName: '',
    email: '',
    phone: '',
    title: '',
    company: ''
  };

  return {
    initialValues: initValues,
    registerForm: state.form[FORM_ID]
  };
};

const mapDispatchToProps = {
  register: feathersServices['register'].create,
  reset
};

RegisterForm = compose(
  withStyles(RegisterFormStyles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(
    {
      form: FORM_ID,
      enableReinitialize: true,
      validate: (values, props) => {
        const errors = {};
        return errors;
      }
    },
    mapStateToProps
  )
)(RegisterForm);

export default RegisterForm;
