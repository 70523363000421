export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    height: '100%'
  },
  header: {
    height: '4rem',
    backgroundColor: theme.palette.secondary.main
  },
  fieldsGroup: {
    padding: theme.spacing(2)
  },
  headerTitle: {
    marginTop: '1rem',
    marginLeft: '1rem',
    color: theme.palette.primary.main
  },
  closeButton: {
    float: 'right',
    color: theme.palette.primary.main
  },
  cancelButton: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  mTop: {
    marginTop: '1rem'
  },
  resize: {
    fontSize: '0.875rem',
    color: theme.palette.secondary.main
  },
  applicationFieldset: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    marginTop: 25,
    padding: 20,
    width: 'calc(100% - 40px)',
    '&:hover': {
      borderColor: '#000'
    }
  }
});
