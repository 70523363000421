import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Box,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  Typography,
  withStyles
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AboutCompanyStyles from './AboutCompany.styles';

let AboutCompany = ({ classes, companyCustomerSelected, editCallback }) => {
  return (
    <ExpansionPanel elevation={0} className={classes.border}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{
          content: classes.summaryContent
        }}
      >
        <Typography className={classes.title}>About {companyCustomerSelected.name}</Typography>
        &nbsp;&nbsp;
        <IconButton onClick={editCallback} size="small">
          <Edit fontSize="small" />
        </IconButton>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Box className={classes.fullWidth}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.titleSecondary}>
                Address
              </Typography>
              <Typography variant="body2">{companyCustomerSelected.address}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.titleSecondary}>
                City
              </Typography>
              <Typography variant="body2">{companyCustomerSelected.city}</Typography>
            </Grid>
            <Grid item xs={12}>
              {''}
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.titleSecondary}>
                State, Zip
              </Typography>
              <Typography variant="body2">
                {companyCustomerSelected.state}
                {', '}
                {companyCustomerSelected.zip}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.titleSecondary}>
                Sales Person
              </Typography>
              <Typography variant="body2">{companyCustomerSelected.salesPerson.name}</Typography>
            </Grid>
          </Grid>
        </Box>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const mapStateToProps = state => {
  return {
    companyCustomerSelected: state.ui.listCompaniesCustomer.companyCustomerSelected
  };
};

const mapDispatchToProps = {};

AboutCompany = compose(withStyles(AboutCompanyStyles), connect(mapStateToProps, mapDispatchToProps))(AboutCompany);

export default AboutCompany;
