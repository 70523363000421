import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import {
  Box,
  Breadcrumbs,
  DialogContentText,
  Drawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Alert } from '@material-ui/lab';
import feathersServices from '../../../services/feathersServices';
import KLDialog from '../../../components/dialog/KLDialog';
import LoadingIndicator from '../../../components/loadingIndicator';
import LinkRouter from '../../../components/router-link/RouterLink';
import HeaderTable from '../../../components/tables/HeaderTable';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import IndustryManagementStyles from './IndustryManagement.styles';
import SurveyQuestionManagementForm from './SurveyQuestionManagementForm';

const cells = [
  { label: 'Question', value: 'title', sort: true },
  { label: 'Created at', value: 'createdAt', sort: true },
  { label: 'Status', value: 'active', sort: true },
  { label: '' }
];

const rowsPerPage = 8;

function SurveyQuestionManagement(props) {
  const { classes, surveyQuestions, match, getIndustry, getCategory, getReports } = props;

  const [sort, setSort] = useState({
    key: 'title',
    value: 1
  });
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [industry, setIndustry] = useState(null);
  const [category, setCategory] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [industryInReports, setIndustryInReports] = useState(0);

  useEffect(() => {
    findSurveyQuestions(0);
    getIndustry(match.params.industry).then(res => setIndustry(res.value));
    getCategory(match.params.category).then(res => setCategory(res.value));
    // eslint-disable-next-line
  }, []);

  const findSurveyQuestions = (page = 0) => {
    const { key, value } = sort;
    const query = {
      $sort: {
        [key]: value
      },
      $limit: rowsPerPage,
      $skip: rowsPerPage * page,
      category: match.params.category,
      industry: match.params.industry
    };
    props.getSurveyQuestions({ query });
  };

  const createSortHandler = value => {
    setSort({
      key: value,
      value: sort.value === -1 ? 1 : -1
    });
    findSurveyQuestions(0);
  };

  const deleteElement = async () => {
    await props.deleteSurveyQuestion(selectedElement._id);
    setOpenDelete(false);
    setSelectedElement(null);
    findSurveyQuestions(page);
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Box className={classes.box} mb={3}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                Admin
              </Typography>
            </LinkRouter>
            <LinkRouter to="/admin/industry-management" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                Industry Management
              </Typography>
            </LinkRouter>
            <LinkRouter to={`/admin/industry-management/${industry?._id}/categories`} color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                {industry?.title}
              </Typography>
            </LinkRouter>
            <Typography variant="body2">{category?.title}</Typography>
          </Breadcrumbs>
        </Box>
        <Grid container justifyContent={'space-between'} alignItems="center">
          <Typography variant="h5" className={classes.title}>
            Survey Questions
          </Typography>

          <Button
            style={{ height: 40 }}
            variant="contained"
            color="secondary"
            onClick={() => {
              setSelectedElement(null);
              setOpen(true);
            }}
            disableElevation
          >
            New survey question
          </Button>
        </Grid>

        <Box my={3}>
          <Paper elevation={0} className={classes.table}>
            <LoadingIndicator isLoading={surveyQuestions.isLoading} width={40}>
              <Table aria-label="simple table" size="medium">
                <HeaderTable classes={classes} cells={cells.map(c => c.label)} />
              </Table>
            </LoadingIndicator>

            {!surveyQuestions.isLoading && (
              <Table aria-label="simple dense table" size="medium">
                <TableHead>
                  <TableRow className={classes.headerRow}>
                    {cells.map((cell, index) => (
                      <TableCell key={index}>
                        {cell.sort ? (
                          <TableSortLabel
                            active={sort.key === cell.value}
                            direction={sort.value === 1 ? 'asc' : 'desc'}
                            onClick={() => createSortHandler(cell.value)}
                          >
                            {cell.label}
                          </TableSortLabel>
                        ) : (
                          cell.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {surveyQuestions &&
                    surveyQuestions.queryResult &&
                    surveyQuestions.queryResult.data?.map((capability, index) => (
                      <TableRow key={index} hover className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}>
                        <TableCell width="60%">
                          <Typography className={classes.tableText} color="textSecondary">
                            {capability.title}
                          </Typography>
                        </TableCell>
                        <TableCell width="20%">
                          <Typography className={classes.tableText} color="textSecondary">
                            {SingletonMomentUtils.moment(capability.createdAt).format('LLL')}
                          </Typography>
                        </TableCell>
                        <TableCell width="15%">
                          <Typography className={classes.tableText} color="textSecondary">
                            {capability.active ? 'Active' : 'Disabled'}
                          </Typography>
                        </TableCell>
                        <TableCell onClick={event => event.stopPropagation()} width="5%">
                          <IconButton
                            size="small"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={e => {
                              setAnchorEl(e.currentTarget);
                              setSelectedElement(capability);
                            }}
                          >
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[rowsPerPage]}
                      count={surveyQuestions.queryResult ? surveyQuestions.queryResult.total : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={(e, p) => {
                        setPage(p);
                        findSurveyQuestions(p);
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </Paper>
        </Box>
      </Grid>

      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            setOpen(true);
            setAnchorEl(null);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            getReports({
              query: {
                industry: match.params.industry,
                $limit: 0
              }
            }).then(({ value }) => {
              setIndustryInReports(value.total);
              setOpenDelete(true);
              setAnchorEl(null);
            });
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      <Drawer anchor="right" open={open} classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}>
        <SurveyQuestionManagementForm
          selectedElement={selectedElement}
          category={category}
          industry={industry}
          onClose={() => {
            setOpen(false);
            setSelectedElement(null);
          }}
          classes={classes}
          onSave={() => findSurveyQuestions(page)}
        />
      </Drawer>

      {selectedElement && (
        <KLDialog
          isOpen={openDelete}
          onHandleClose={() => setOpenDelete(false)}
          onHandleConfirm={deleteElement}
          title="DELETE"
          showButtonConfirm={industryInReports === 0}
          labelButtonClose={industryInReports === 0 ? 'Cancel' : 'Close'}
          body={
            <DialogContentText>
              {industryInReports === 0 ? (
                'The survey question will be deleted, do you want to continue?'
              ) : (
                <Alert severity="error">You can not delete a survey question for an industry that is in use.</Alert>
              )}
            </DialogContentText>
          }
          labelButtonConfirm="Delete"
        />
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    surveyQuestions: state.services.surveyQuestion
  };
}

const mapDispatchToProps = {
  getReports: feathersServices.reports.find,
  getSurveyQuestions: feathersServices['survey-question'].find,
  deleteSurveyQuestion: feathersServices['survey-question'].remove,
  getCategory: feathersServices.categories.get,
  getIndustry: feathersServices.industries.get
};

export default compose(withStyles(IndustryManagementStyles), connect(mapStateToProps, mapDispatchToProps))(SurveyQuestionManagement);
