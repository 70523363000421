export const SCREENSHOT_IMAGES = 'SCREENSHOT_IMAGES';
export const SCREENSHOT_IMAGES_SELECTED = 'SCREENSHOT_IMAGES_SELECTED';
export const SCREENSHOT_FILTER_APPLICATION = 'SCREENSHOT_FILTER_APPLICATION';
export const SCREENSHOT_FILTER_COMPANY = 'SCREENSHOT_FILTER_COMPANY';
export const SCREENSHOT_FILTER_CATEGORY = 'SCREENSHOT_FILTER_CATEGORY';
export const SCREENSHOT_CLEAN = 'SCREENSHOT_CLEAN';

export function setImages(images) {
  return {
    type: SCREENSHOT_IMAGES,
    payload: images
  };
}

export function setImagesSelected(images) {
  return {
    type: SCREENSHOT_IMAGES_SELECTED,
    payload: images
  };
}

export function setApplication(application) {
  return {
    type: SCREENSHOT_FILTER_APPLICATION,
    payload: application
  };
}

export function setCompanies(companies) {
  return {
    type: SCREENSHOT_FILTER_COMPANY,
    payload: companies
  };
}

export function setCategories(categories) {
  return {
    type: SCREENSHOT_FILTER_CATEGORY,
    payload: categories
  };
}

export function clean() {
  return {
    type: SCREENSHOT_CLEAN,
    payload: null
  };
}
