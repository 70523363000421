import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { Box, Button, DialogActions, DialogContent, DialogContentText, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import RenderTextField from '../../../components/renders/RenderTextField';
import { maxValue, minValue, number, required } from '../../../utils/validation';
import DeviceWeightFormStyles from './DeviceWeightForm.styles';

const minValue0 = minValue(0);
const maxValue1 = maxValue(1);

class DeviceWeightForm extends Component {
  render() {
    const { valid, applications, handleSubmit, classes, handleClose } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Box>
          <DialogContent>
            <DialogContentText>Define Device Weights for the current report:</DialogContentText>
            <Grid container>
              {applications &&
                applications.map((application, index) => {
                  return (
                    <Grid item key={index}>
                      <Field
                        color="secondary"
                        variant="outlined"
                        margin="dense"
                        validate={[required, number, minValue0, maxValue1]}
                        name={application.key}
                        label={application.key}
                        component={RenderTextField}
                        className={classes.textField}
                      />
                    </Grid>
                  );
                })}
            </Grid>
            <Box mt={4}>
              <InfoOutlined className={classes.infoIcon} fontSize="small" />
              <a
                href="https://www.comscore.com/Insights/Rankings#tab_mobile_smartphone_platforms"
                rel="noopener noreferrer"
                target="_blank"
                className={classes.link}
              >
                <Typography color="secondary" variant="body2">
                  Source for Device Weights
                </Typography>
              </a>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="text">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="secondary" variant="contained" disabled={!valid} disableElevation>
              SAVE
            </Button>
          </DialogActions>
        </Box>
      </form>
    );
  }
}

const mapStateToProps = state => {
  let initValues = {};
  if (state.scoringAndRanking && state.scoringAndRanking.deviceWeight && state.scoringAndRanking.deviceWeight.deviceWeights) {
    Object.keys(state.scoringAndRanking.deviceWeight.deviceWeights).map(value => {
      return (initValues[value] = state.scoringAndRanking.deviceWeight.deviceWeights[value]);
    });
  }
  return {
    initialValues: initValues
  };
};

const mapDispatchToProps = {};

DeviceWeightForm = compose(
  withStyles(DeviceWeightFormStyles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(
    {
      form: 'deviceWeightForm',
      enableReinitialize: true,
      validate: (values, props) => {
        const errors = {};
        return errors;
      }
    },
    mapStateToProps
  )
)(DeviceWeightForm);

export default DeviceWeightForm;
