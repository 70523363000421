export default theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  pictureContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer'
  },
  screenshots: {
    backgroundColor: '#eaeaea',
    width: 200,
    height: 240,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  picture: {
    width: 'auto',
    height: 'auto',
    maxWidth: 'calc(100%)',
    maxHeight: 'calc(100%)'
  },
  close: {
    position: 'absolute',
    marginTop: -15,
    marginLeft: 15,
    padding: 3,
    backgroundColor: '#999',
    '&:hover': {
      backgroundColor: '#555'
    }
  },
  select: {
    width: 200,
    marginTop: 10
  },
  selectItem: {
    marginLeft: 5
  },
  title: {
    width: 200,
    marginTop: 10
  },
  experienceSelect: {
    padding: 0
  },
  experienceSelectIcon: {
    width: 23,
    height: 23
  },
  itemTitle: {
    '& .MuiInputBase-input': {
      color: 'green'
    }
  },
  itemTitleRed: {
    '& .MuiInputBase-input': {
      color: 'red'
    }
  },
  itemTitleBlue: {
    '& .MuiInputBase-input': {
      color: 'blue'
    }
  },
  itemText: {
    '& .MuiInputBase-input': {
      fontSize: '0.75rem'
    }
  },
  textGreen: {
    color: 'green'
  },
  textRed: {
    color: 'red'
  },
  textBlue: {
    color: 'blue'
  },
  media: {
    padding: 5,
    width: '95%',
    cursor: 'pointer',
    minHeight: 250,
    '& svg': {
      color: theme.palette.secondary.main
    },
    '& img': {
      borderRadius: 5,
      width: 'auto !important',
      height: '12rem !important',
      position: 'unset !important',
      maxWidth: 'calc(100%) !important'
    },
    '& div': {
      padding: 'calc(1%) !important',
      textAlign: 'center'
    }
  },
  screenshotsContainer: {
    padding: 20,
    marginBottom: 20
  },
  noMedia: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 280
  },
  screenshotsCard: {
    width: '85%',
    textAlign: 'center'
  }
});
