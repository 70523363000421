import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import Close from '@material-ui/icons/Close';
import NotInterested from '@material-ui/icons/NotInterested';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';
import feathersServices from '../../services/feathersServices';
import Image from 'material-ui-image/lib/components/Image/Image';
import { getApplications, getReportCategoriesGrouped } from '../../utils/report.utils';
import SingletonMomentUtils from '../../utils/SingletonMomentUtils';
import { setScreenshotModal } from '../layout/actions/layout.actions';
import ScreenshotModalStyles from './ScreenshotModal.styles';

class ScreenshotModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenshots: [],
      capabilities: [],
      filterCompanies: [],
      filterCategories: [],
      expandImageInfo: true,
      filterApplication: props.selectedApp || getApplications(props.dbReport)[0].id
    };
  }

  async componentDidMount() {
    const { value: capabilities } = await this.props.getCapabilities({
      query: {
        $select: ['title'],
        $limit: 'infinity'
      }
    });
    this.setState({ capabilities });
    this.buildCategoryScreenshots();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.allCapabilities !== this.props.allCapabilities ||
      prevState.filterApplication !== this.state.filterApplication ||
      prevState.filterCompanies !== this.state.filterCompanies ||
      prevState.filterCategories !== this.state.filterCategories ||
      prevProps.selectedApp !== this.props.selectedApp
    ) {
      if (prevProps.selectedApp !== this.props.selectedApp) {
        this.setState({
          filterApplication: this.props.selectedApp
        });
      }
      this.buildCategoryScreenshots();
    }
  }

  buildCategoryScreenshots() {
    const { dbReport, allCapabilities } = this.props;
    const { filterApplication, filterCompanies, filterCategories, capabilities } = this.state;
    const images = [];
    const allCategories = [];
    dbReport.companies.forEach(comp => {
      comp.categories.forEach(cat => {
        allCategories.push(cat);
      });
    });

    const capabilitiesImages = allCapabilities[dbReport._id].filter(c => {
      const fCompany = !filterCompanies.length || filterCompanies.filter(comp => comp.companyId === c.company).length;

      const capCategory = allCategories.find(cat => cat.categoryId === c.category);

      const fCategory = !filterCategories.length || filterCategories.filter(cat => cat.categoryGroup === capCategory.belongsTo).length;

      return fCompany && fCategory && c.application === filterApplication;
    });

    // Iterate each capability report.-
    capabilitiesImages.forEach(cr => {
      cr.images.forEach(image => {
        const crImage = Object.assign({}, image);
        crImage.report = `${dbReport.industry.title} - ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`;
        crImage.company = dbReport.companies.find(c => c.companyId === cr.company).title;
        crImage.category = allCategories.find(rc => rc.categoryId === cr.category).title;
        crImage.capability = capabilities.find(c => c._id === cr.capability)?.title;
        images.push(crImage);
      });
    });

    this.setState({
      screenshots: images
    });
  }

  setFilter = async (element, objects, callback) => {
    let output = [...objects];
    if (objects.indexOf(element) === -1) {
      output.push(element);
    } else {
      output = objects.filter(item => item !== element);
    }
    await callback(output);
  };

  setFilterArray = async (element, objects, callback) => {
    let output = [...objects];
    if (!objects.filter(o => o.categoryGroup === element.categoryGroup).length) {
      output.push(element);
    } else {
      output = objects.filter(item => item.categoryGroup !== element.categoryGroup);
    }
    await callback(output);
  };

  render() {
    const { screenshots, filterCompanies, filterCategories, filterApplication, expandImageInfo } = this.state;
    const { classes, screenshotModal, setScreenshotModal, dbReport } = this.props;
    return (
      <Dialog
        maxWidth="xl"
        open={screenshotModal.open}
        onClose={() => {
          this.setState({ expandImageInfo: true });
          setScreenshotModal({
            open: false,
            onComplete: null
          });
        }}
        classes={{
          paper: classes.dialog
        }}
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography>Select Image</Typography>
            <IconButton
              onClick={() => {
                this.setState({ expandImageInfo: true });
                setScreenshotModal({
                  open: false,
                  onComplete: null
                });
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            {this.props.filters && (
              <Grid item xs={2}>
                {/* COMPANIES */}
                <Box>
                  <Typography>Company</Typography>
                  {dbReport &&
                    dbReport.companies &&
                    dbReport.companies
                      .sort((a, b) => {
                        if (a.title.toLowerCase() > b.title.toLowerCase()) {
                          return 1;
                        }
                        if (a.title.toLowerCase() < b.title.toLowerCase()) {
                          return -1;
                        }
                        return 0;
                      })
                      .map(company => {
                        return (
                          <Box key={company.companyId}>
                            <FormControlLabel
                              className={classes.options}
                              control={
                                <Checkbox
                                  size="small"
                                  className={classes.checkbox}
                                  checked={filterCompanies.indexOf(company) !== -1}
                                  value="checkedA"
                                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                                  checkedIcon={<CheckBox fontSize="small" />}
                                  onChange={() =>
                                    this.setFilter(company, filterCompanies, companies =>
                                      this.setState({
                                        filterCompanies: companies
                                      })
                                    )
                                  }
                                />
                              }
                              label={
                                <Tooltip title={company.title}>
                                  <Typography
                                    color="textSecondary"
                                    className={filterCompanies.indexOf(company) !== -1 ? classes.optionLabelSelected : classes.optionLabel}
                                  >
                                    {company.title}
                                  </Typography>
                                </Tooltip>
                              }
                            />
                          </Box>
                        );
                      })}
                </Box>

                <Box mt={4}>
                  <Typography>Category</Typography>
                  {dbReport &&
                    getReportCategoriesGrouped(dbReport).map((category, i) => {
                      return (
                        <Box key={i}>
                          <FormControlLabel
                            className={classes.options}
                            control={
                              <Checkbox
                                size="small"
                                className={classes.checkbox}
                                checked={filterCategories.find(c => c.categoryGroup === category.categoryGroup) !== undefined}
                                onChange={() =>
                                  this.setFilterArray(category, filterCategories, categories =>
                                    this.setState({
                                      filterCategories: categories
                                    })
                                  )
                                }
                                value="checkedA"
                                icon={<CheckBoxOutlineBlank fontSize="small" />}
                                checkedIcon={<CheckBox fontSize="small" />}
                              />
                            }
                            label={
                              <Tooltip title={category.categoryGroup}>
                                <Typography
                                  color="textSecondary"
                                  className={
                                    filterCategories.find(c => c.categoryGroup === category.categoryGroup) !== undefined
                                      ? classes.optionLabelSelected
                                      : classes.optionLabel
                                  }
                                >
                                  {category.categoryGroup}
                                </Typography>
                              </Tooltip>
                            }
                          />
                        </Box>
                      );
                    })}
                </Box>
              </Grid>
            )}

            <Grid item xs={this.props.filters ? 10 : 12}>
              <Box mt={4} className={classes.box} style={{ display: 'flex', justifyContent: 'space-between' }}>
                {this.props.filters && (
                  <Tabs value={filterApplication} onChange={(e, value) => this.setState({ filterApplication: value })}>
                    {getApplications(dbReport).map((application, i) => {
                      return <Tab key={i} label={application.title} value={application.id} />;
                    })}
                  </Tabs>
                )}

                <Box />
                <Typography
                  color={'secondary'}
                  className={classes.selectLabels}
                  onClick={() => {
                    this.setState({
                      expandImageInfo: !expandImageInfo
                    });
                  }}
                >
                  {expandImageInfo ? 'Hide info' : 'Show info'}
                </Typography>
              </Box>

              <Grid container spacing={2}>
                {screenshots && screenshots.length ? (
                  <Fragment>
                    {screenshotModal.allowEmpty && (
                      <Grid item xs={3}>
                        <Card>
                          <div
                            className={clsx(classes.media, classes.noMedia)}
                            onClick={() => {
                              screenshotModal.onComplete('');
                              setScreenshotModal({
                                open: false,
                                onComplete: null
                              });
                            }}
                          >
                            <NotInterested fontSize="large" style={{ color: 'gray' }} />
                          </div>
                        </Card>
                      </Grid>
                    )}
                    {screenshots.map((image, i) => {
                      return (
                        <Grid item xs={3} key={`screenshot_${i}`}>
                          <Card className={classes.card}>
                            <div
                              className={classes.media}
                              onClick={async () => {
                                if (screenshotModal.onComplete) {
                                  await screenshotModal.onComplete(image.url);
                                }
                                setScreenshotModal({
                                  open: false,
                                  onComplete: null
                                });
                              }}
                            >
                              <Image src={image.url || ''} disableSpinner={true} disableError={true} />
                            </div>
                            <CardContent
                              style={{
                                width: '100%',
                                textAlign: 'center',
                                padding: 0
                              }}
                            >
                              <Collapse
                                in={expandImageInfo}
                                style={{
                                  textAlign: 'left',
                                  padding: 5,
                                  backgroundColor: '#5555551a',
                                  borderTop: '1px solid #0000001f'
                                }}
                              >
                                <Tooltip title={image.report}>
                                  <Typography variant="body2" color="textSecondary" component="p" noWrap>
                                    <small>
                                      <b>Report: </b>
                                      {image.report}
                                    </small>
                                  </Typography>
                                </Tooltip>
                                <Tooltip title={image.company}>
                                  <Typography variant="body2" color="textSecondary" component="p" noWrap>
                                    <small>
                                      <b>Company: </b>
                                      {image.company}
                                    </small>
                                  </Typography>
                                </Tooltip>
                                <Tooltip title={image.category}>
                                  <Typography noWrap variant="body2" color="textSecondary" component="p">
                                    <small>
                                      <b>Category: </b> {image.category}
                                    </small>
                                  </Typography>
                                </Tooltip>
                                <Tooltip title={image.capability}>
                                  <Typography noWrap variant="body2" color="textSecondary" component="p">
                                    <small>
                                      <b>Capability: </b> {image.capability}
                                    </small>
                                  </Typography>
                                </Tooltip>
                              </Collapse>
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Fragment>
                ) : (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      padding: 30
                    }}
                  >
                    <ReportProblemOutlined />
                    &nbsp;
                    <Typography>No screenshots</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  screenshotModal: state.ui.layout.screenshotModal,
  dbReport: state.currentReport.dbReport
});

const mapDispatchToProps = {
  getCapabilities: feathersServices['capabilities'].find,
  setScreenshotModal
};

export default compose(withStyles(ScreenshotModalStyles), connect(mapStateToProps, mapDispatchToProps))(ScreenshotModal);
