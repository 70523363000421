import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import appendixReducer from '../modules/appendix/reducers/Appendix.reducer';
import listCompaniesCustomerPageReducer from '../modules/clientManagement/reducers/CompaniesCustomerList.reducer';
import consumerSurveyPageReducer from '../modules/consumer-survey/reducers/ConsumerSurvey.reducer';
import categoryPerformanceUiReducer from '../modules/content-building/category-performance/reducers/CategoryPerformance.ui.reducer';
import contentBuildingReducer from '../modules/content-building/reducers/ContentBuilding.reducer';
import { maintenanceReducer } from '../modules/maintenance/reducers/maintenance.reducer';
import capabilitiesTableReducer from '../modules/reports/capabilities-table/reducers/capabilities-table.reducer';
import capabilityDetailReducer from '../modules/reports/capability-detail/reducers/capability-detail.reducer';
import homePageReducer from '../modules/reports/home/reducers/home.reducer';
import listReportPageReducer from '../modules/reports/list/reducers/ReportList.reducer';
// defaults to localStorage for web
import reportsReducer from '../modules/reports/reducers/reports.reducer';
import screenshotReducer from '../modules/reports/screenshot/reducers/Screenshot.reducer';
import scoringAndRankingReducer from '../modules/scoring-ranking/reducers/ScoringAndRanking.reducer';
import scoringAndRankingUiReducer from '../modules/scoring-ranking/reducers/ScoringAndRanking.ui.reducer';
import layoutReducer from '../components/layout/reducers/layout.reducer';
import migrations from './migration.app.reducer';

const reportsConfig = {
  key: 'reports',
  storage: storage,
  blacklist: ['files'],
  version: 18,
  migrate: createMigrate(migrations, { debug: false }),
  stateReconciler: autoMergeLevel2
};

const createRootReducer = (history, reduxifiedServices, feathersAuthentication, formReducer) =>
  combineReducers({
    router: connectRouter(history),
    currentReport: persistReducer(reportsConfig, reportsReducer),
    consumerSurvey: consumerSurveyPageReducer,
    scoringAndRanking: scoringAndRankingReducer,
    contentBuilding: contentBuildingReducer,
    appendix: appendixReducer,
    ui: combineReducers({
      layout: layoutReducer,
      home: homePageReducer,
      capabilityDetail: capabilityDetailReducer,
      capabilitiesTable: capabilitiesTableReducer,
      listReport: listReportPageReducer,
      screenshots: screenshotReducer,
      scoringAndRanking: scoringAndRankingUiReducer,
      categoryPerformance: categoryPerformanceUiReducer,
      listCompaniesCustomer: listCompaniesCustomerPageReducer,
      maintenanceStore: maintenanceReducer
    }),
    services: combineReducers({
      industries: reduxifiedServices.industries.reducer,
      companies: reduxifiedServices.companies.reducer,
      categories: reduxifiedServices.categories.reducer,
      capabilities: reduxifiedServices.capabilities.reducer,
      capabilityChanges: reduxifiedServices['capability-changes'].reducer,
      applications: reduxifiedServices.applications.reducer,
      reports: reduxifiedServices.reports.reducer,
      reportDates: reduxifiedServices['reports-date'].reducer,
      consumerSurvey: reduxifiedServices['consumer-survey'].reducer,
      reportAndRanking: reduxifiedServices['scoring-ranking'].reducer,
      surveyQuestions: reduxifiedServices['survey-question'].reducer,
      deviceWeight: reduxifiedServices['device-weight'].reducer,
      upload: reduxifiedServices.upload.reducer,
      deleteFromStorage: reduxifiedServices['delete-from-storage'].reducer,
      googleDrive: reduxifiedServices['google-drive'].reducer,
      googleDriveLogin: reduxifiedServices['google-drive-login'].reducer,
      users: reduxifiedServices.users.reducer,
      surveyQuestion: reduxifiedServices['survey-question'].reducer,
      contentBuilding: reduxifiedServices['content-building'].reducer,
      forgotPassword: reduxifiedServices['forgot-password'].reducer,
      companiesCustomer: reduxifiedServices['companies-customer'].reducer,
      salesPerson: reduxifiedServices['sales-person'].reducer,
      reportStatus: reduxifiedServices['report-status'].reducer,
      userCompany: reduxifiedServices['user-company'].reducer,
      register: reduxifiedServices['register'].reducer,
      companyReports: reduxifiedServices['companies-reports'].reducer,
      capabilityReports: reduxifiedServices['capability-reports'].reducer,
      methodology: reduxifiedServices['methodology'].reducer,
      kliApplications: reduxifiedServices['kli-applications'].reducer,
      activity: reduxifiedServices['activity'].reducer
    }),
    auth: feathersAuthentication.reducer,
    form: formReducer
  });

export default createRootReducer;
