import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = {
  viewBox: '0 0 80 80'
};

// viewBox
const ScoringIcon = props => (
  <SvgIcon {...useStyles} style={props}>
    <g id="Group-2" transform="translate(8.000000, 10.000000)" fillRule="nonzero">
      <polygon stroke={props.fill || '#a6a6a6'} id="Path-5" points="16 1 48 1 48 0 16 0" />
      <path
        stroke={props.fill || '#a6a6a6'}
        d="M12.538135,29.4649822 L3.84677694,19.9637547 C1.80815667,17.4087417 0.790123457,15.1133967 0.790123457,13.105343 C0.790123457,11.1105618 1.79634187,9.09393395 3.83490638,7.05112395 C5.34672193,5.53615992 6.83240978,4.79176896 8.2962963,4.79176896 L32,4.79176896 L32,4 L8.2962963,4 C6.59968899,4 4.92488301,4.83914695 3.27620473,6.49125875 C1.10077747,8.67121652 0,10.8773564 0,13.105343 C0,15.3200571 1.08896267,17.7753258 3.24706417,20.4785707 L11.9556922,30 L12.538135,29.4649822 Z"
        id="Path-6"
      />
      <path
        stroke={props.fill || '#a6a6a6'}
        d="M44.538135,29.4649822 L35.8467769,19.9637547 C33.8081567,17.4087417 32.7901235,15.1133967 32.7901235,13.105343 C32.7901235,11.1105618 33.7963419,9.09393395 35.8349064,7.05112395 C37.3467219,5.53615992 38.8324098,4.79176896 40.2962963,4.79176896 L64,4.79176896 L64,4 L40.2962963,4 C38.599689,4 36.924883,4.83914695 35.2762047,6.49125875 C33.1007775,8.67121652 32,10.8773564 32,13.105343 C32,15.3200571 33.0889627,17.7753258 35.2470642,20.4785707 L43.9556922,30 L44.538135,29.4649822 Z"
        id="Path-6"
        transform="translate(48.000000, 17.000000) scale(-1, 1) translate(-48.000000, -17.000000) "
      />
      <path
        stroke={props.fill || '#a6a6a6'}
        d="M16.7703278,6 L16,6 L16,29.9782997 L16.0140516,30.0818101 C16.6079198,32.2285218 18.360445,34.6041537 21.2630214,37.2272082 C24.1324052,39.8202664 27.5725175,42.4109538 31.5834394,45 L32,44.3493539 C28.0223374,41.7817765 24.6150916,39.2158403 21.7783436,36.6522749 C18.9983716,34.140018 17.3318297,31.891507 16.7703278,29.9247814 L16.7703278,6 Z"
        id="Path-7"
      />
      <path
        stroke={props.fill || '#a6a6a6'}
        d="M31.7703278,6 L31,6 L31,29.9782997 L31.0140516,30.0818101 C31.6079198,32.2285218 33.360445,34.6041537 36.2630214,37.2272082 C39.1324052,39.8202664 42.5725175,42.4109538 46.5834394,45 L47,44.3493539 C43.0223374,41.7817765 39.6150916,39.2158403 36.7783436,36.6522749 C33.9983716,34.140018 32.3318297,31.891507 31.7703278,29.9247814 L31.7703278,6 Z"
        id="Path-7"
        transform="translate(39.000000, 25.500000) scale(-1, 1) translate(-39.000000, -25.500000) "
      />
      <polygon id="Line-6" stroke={props.fill || '#a6a6a6'} points="31 43 31 54 32 54 32 43" />
      <path
        stroke={props.fill || '#a6a6a6'}
        d="M22.0588235,54 L41.9411765,54 C43.6305181,54 45,55.3431458 45,57 L45,60 L19,60 L19,57 C19,55.3431458 20.3694819,54 22.0588235,54 Z M44.2352941,59.25 L44.2352941,57 C44.2352941,55.7573593 43.2081827,54.75 41.9411765,54.75 L22.0588235,54.75 C20.7918173,54.75 19.7647059,55.7573593 19.7647059,57 L19.7647059,59.25 L44.2352941,59.25 Z"
        id="Rectangle"
      />
    </g>
  </SvgIcon>
);

export default ScoringIcon;
