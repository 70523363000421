import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { Button, CircularProgress, Grid, ListItem, ListItemText, MenuItem, Typography } from '@material-ui/core';
import feathersServices from '../../../services/feathersServices';
import KLDialog from '../../../components/dialog/KLDialog';
import Notification from '../../../components/notification/Notification';
import RenderTextField from '../../../components/renders/RenderTextField';
import { onlyLetters, required, validEmail, zipCode } from '../../../utils/validation';
import CompaniesFormStyles from './CompaniesForm.styles';
import SalesPersonForm from './salesPerson/SalesPersonForm';

export class CompaniesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      salesPerson: [],
      openError: { open: false, msg: ' ' },
      onSubmitState: false
    };
  }

  onSubmitState = () => {
    this.setState((prevState, props) => ({
      onSubmitState: !prevState.onSubmitState
    }));
  };

  setCloseError = () => {
    this.setState({ openError: { open: false, msg: ' ' } });
  };

  setModalOpen = () => {
    this.getSalesPerson();
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen
    }));
  };

  getSalesPerson = () => {
    const { salesPersonFindService } = this.props;
    salesPersonFindService({ query: { $limit: 'infinity' } });
  };

  componentDidMount() {
    this.getSalesPerson();
  }

  handleSubmit = () => {
    const { companiesForm, onCallBack, companiesCustomerCreateService, companiesCustomerUpdateService, initialValues } = this.props;
    this.onSubmitState();
    if (!initialValues._id) {
      companiesCustomerCreateService({
        ...companiesForm.values,
        originUrl: window.location.origin
      }).then(
        () => {
          if (onCallBack) {
            onCallBack();
          }
          this.onSubmitState();
        },
        ({ code }) => {
          if (code === 409) {
            this.setState({
              openError: {
                open: true,
                msg: 'Email/User exist, please select other'
              }
            });
          }
          this.onSubmitState();
        }
      );
    } else {
      companiesCustomerUpdateService(companiesForm.values._id, companiesForm.values, {
        query: {
          $populate: true
        }
      }).then(
        data => {
          if (onCallBack) {
            onCallBack(data.value);
          }
          this.onSubmitState();
        },
        error => {
          this.onSubmitState();
        }
      );
    }
  };

  render() {
    const { classes, valid, salesPerson, initialValues, companyCustomerSelected } = this.props;
    const { isModalOpen, openError, onSubmitState } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Field
              color="secondary"
              validate={[required]}
              name="name"
              autoComplete="companyName"
              label="Company Name *"
              component={RenderTextField}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              color="secondary"
              validate={[required]}
              name="url"
              autoComplete="companyUrl"
              label="Company URL *"
              component={RenderTextField}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              color="secondary"
              validate={[required]}
              name="address"
              autoComplete="companyAddress"
              label="Address *"
              component={RenderTextField}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <Field
              color="secondary"
              validate={[required]}
              name="city"
              autoComplete="companyCity"
              label="City *"
              component={RenderTextField}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <Field
              color="secondary"
              validate={[required, onlyLetters]}
              name="state"
              autoComplete="companyState"
              label="State *"
              component={RenderTextField}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <Field
              color="secondary"
              validate={[required, zipCode]}
              name="zip"
              placeholder="1234/12345-6789"
              autoComplete="companyZip"
              label="Zip *"
              component={RenderTextField}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              id="salesPerson"
              select
              validate={[required]}
              label="Sales Person *"
              name="salesPerson"
              autoComplete="salesPerson"
              component={RenderTextField}
              variant="outlined"
              color="secondary"
              fullWidth
              SelectProps={{
                classes: {
                  select: classes.resize
                }
              }}
            >
              {!salesPerson.isLoading &&
                salesPerson.queryResult &&
                salesPerson.queryResult.total !== 0 &&
                salesPerson.queryResult.map((option, index) => (
                  <MenuItem key={index} value={option._id}>
                    {option.name}
                  </MenuItem>
                ))}
              <ListItem>
                <ListItemText onClick={() => this.setModalOpen()} className={classes.itemTextLink}>
                  ADD NEW
                </ListItemText>
              </ListItem>
            </Field>
          </Grid>
        </Grid>

        {!companyCustomerSelected && (
          <Grid container direction="row">
            <Typography variant="h6">Primary User *</Typography>
            <Grid item xs={12}>
              <Field
                color="secondary"
                validate={[required]}
                name="firstName"
                autoComplete="customerFirstName"
                label="First Name *"
                component={RenderTextField}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                color="secondary"
                validate={[required]}
                name="lastName"
                autoComplete="customerLastName"
                label="Last Name *"
                component={RenderTextField}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                color="secondary"
                validate={[required, validEmail]}
                name="email"
                autoComplete="customerEmail"
                label="Email *"
                component={RenderTextField}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        )}

        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Button onClick={this.handleSubmit} color="secondary" variant="contained" disableElevation disabled={!valid || onSubmitState}>
              {onSubmitState && <CircularProgress color="secondary" size={25} thickness={3} />}
              {!initialValues._id ? 'Create' : 'Update'}
            </Button>
          </Grid>
        </Grid>

        {openError.open && <Notification message={openError.msg} open={openError.open} variant="error" onClose={() => this.setCloseError()} />}

        {isModalOpen && (
          <KLDialog
            isOpen={isModalOpen}
            onHandleClose={() => {
              this.setModalOpen();
            }}
            title={'Create Sales Person'}
            body={<SalesPersonForm onCallBack={() => this.setModalOpen()} />}
            showButtonConfirm={false}
            showButtonClose={false}
          />
        )}
      </form>
    );
  }
}

const mapStateToProps = state => {
  let initValues = {
    name: '',
    url: '',
    address: '',
    state: '',
    zip: '',
    salesPerson: '',
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  };

  const companyCustomerSelected = state.ui.listCompaniesCustomer.companyCustomerSelected;

  if (companyCustomerSelected) {
    initValues = Object.assign(initValues, companyCustomerSelected);
    initValues.salesPerson = companyCustomerSelected.salesPerson._id; // not populate
  }

  return {
    initialValues: initValues,
    companiesForm: state.form.companiesForm,
    salesPerson: state.services.salesPerson,
    companyCustomerSelected: companyCustomerSelected
  };
};

const mapDispatchToProps = {
  salesPersonFindService: feathersServices['sales-person'].find,
  companiesCustomerCreateService: feathersServices['companies-customer'].create,
  companiesCustomerUpdateService: feathersServices['companies-customer'].update
};

CompaniesForm = compose(
  withStyles(CompaniesFormStyles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(
    {
      form: 'companiesForm',
      enableReinitialize: true,
      validate: (values, props) => {
        const errors = {};
        return errors;
      }
    },
    mapStateToProps
  )
)(CompaniesForm);

export default CompaniesForm;
