import React from 'react';
import { DropboxChooser, GoogleChooser } from 'react-file-picker-providers';
import { DropzoneArea } from 'material-ui-dropzone';
import { withStyles } from '@material-ui/styles';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Computer from '@material-ui/icons/Computer';
import { debounce } from '../../utils/immutable-utils';
import { DriveIcon, DropboxIcon } from '../../images';
import UploadSourcesStyles from './UploadSources.styles';

const DROPBOX_API_KEY = process.env.DROPBOX_API_KEY;
const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
const GOOGLE_DEVELOPER_KEY = process.env.GOOGLE_DEVELOPER_KEY;
const SCOPE = ['https://www.googleapis.com/auth/drive.readonly'];

const iconProps = {
  width: '2.8rem',
  height: '2.8rem'
};

const UploadSources = ({
  className,
  classes,
  modalTitle,
  modalSubTitle,
  elevation,
  handleClose,
  handleClick,
  handleMyComputerFiles,
  myComputerAcceptedFiles,
  providerAcceptedFiles,
  handleCloudProviderSuccess,
  isMultiselect = true,
  showAlerts = true
}) => {
  return (
    <Paper elevation={elevation} className={className}>
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.box}>
            <Box className={classes.close}>
              <Close onClick={handleClose} />
            </Box>
            <Typography className={classes.textInfo}>{modalTitle}</Typography>
            <Typography className={classes.textInfoSmall}>{modalSubTitle}</Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.grid}>
          <DropzoneArea
            dropzoneText=""
            showPreviewsInDropzone={false}
            dropzoneClass={classes.dropzone}
            onClick={handleClick}
            acceptedFiles={myComputerAcceptedFiles}
            onChange={debounce(files => handleMyComputerFiles(files), 500)}
            multiple={isMultiselect}
            showAlerts={showAlerts}
          />
          <Box className={classes.sourceContainer2}>
            <Computer className={classes.icon} />
            <Typography className={classes.textSource}>My Computer</Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.grid}>
          <GoogleChooser
            clientId={GOOGLE_CLIENT_ID}
            developerKey={GOOGLE_DEVELOPER_KEY}
            success={files => handleCloudProviderSuccess(files)}
            scope={SCOPE}
            multiselect={isMultiselect}
            navHidden={true}
            authImmediate={false}
            extensions={providerAcceptedFiles}
          >
            <Box className={classes.sourceContainer}>
              <DriveIcon className={classes.icon} {...iconProps} />
              <Typography className={classes.textSource}>Google Drive</Typography>
            </Box>
          </GoogleChooser>
        </Grid>
        <Grid item xs={4} className={classes.grid}>
          <DropboxChooser
            appKey={DROPBOX_API_KEY}
            success={files => handleCloudProviderSuccess(files)}
            multiselect={isMultiselect}
            extensions={providerAcceptedFiles}
          >
            <Box className={classes.sourceContainer}>
              <DropboxIcon className={classes.icon} {...iconProps} />
              <Typography className={classes.textSource}>Dropbox</Typography>
            </Box>
          </DropboxChooser>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(UploadSourcesStyles)(UploadSources);
