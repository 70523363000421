import {
  LIST_COMPANIES_CUSTOMER_PAGINATION_PAGE,
  LIST_COMPANIES_CUSTOMER_SELECTED,
  LIST_REPORT_COMPANY_SELECTED,
  LIST_REPORT_PAGINATION_PAGE,
  LIST_USER_COMPANY_SELECTED
} from '../action/CompaniesCustomertList.actions';

const initialState = {
  companyCustomerSelected: null,
  userCompanySelected: null,
  reportCompanySelected: null,
  rowsPerPage: 8,
  page: 0,
  reportsPage: 0
};

const listCompaniesCustomerPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_COMPANIES_CUSTOMER_SELECTED:
      return Object.assign({}, state, {
        companyCustomerSelected: action.payload
      });
    case LIST_COMPANIES_CUSTOMER_PAGINATION_PAGE:
      return Object.assign({}, state, {
        page: action.payload
      });
    case LIST_REPORT_PAGINATION_PAGE:
      return Object.assign({}, state, {
        reportsPage: action.payload
      });

    case LIST_USER_COMPANY_SELECTED:
      return Object.assign({}, state, {
        userCompanySelected: action.payload
      });

    case LIST_REPORT_COMPANY_SELECTED:
      return Object.assign({}, state, {
        reportCompanySelected: action.payload
      });
    default:
      return state;
  }
};

export default listCompaniesCustomerPageReducer;
