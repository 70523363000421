export const LIST_COMPANIES_CUSTOMER_SELECTED = 'LIST_COMPANIES_CUSTOMER_SELECTED';
export const LIST_COMPANIES_CUSTOMER_PAGINATION_PAGE = 'LIST_COMPANIES_CUSTOMER_PAGINATION_PAGE';
export const LIST_USER_COMPANY_SELECTED = 'LIST_USER_COMPANY_SELECTED';
export const LIST_REPORT_COMPANY_SELECTED = 'LIST_REPORT_COMPANY_SELECTED';
export const LIST_REPORT_PAGINATION_PAGE = 'LIST_REPORT_PAGINATION_PAGE';

export function setCompanyCustomerSelected(companyCustomer) {
  return {
    type: LIST_COMPANIES_CUSTOMER_SELECTED,
    payload: companyCustomer
  };
}

export function setPage(page) {
  return {
    type: LIST_COMPANIES_CUSTOMER_PAGINATION_PAGE,
    payload: page
  };
}

export function setUserCompanySelected(userCompany) {
  return {
    type: LIST_USER_COMPANY_SELECTED,
    payload: userCompany
  };
}

export function setReportCompanySelected(reportCompany) {
  return {
    type: LIST_REPORT_COMPANY_SELECTED,
    payload: reportCompany
  };
}

export function setReportPage(page) {
  return {
    type: LIST_REPORT_PAGINATION_PAGE,
    payload: page
  };
}
