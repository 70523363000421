export function validateProps(push, fireNotification, ...props) {
  if (!props.every(prop => prop !== undefined && Object.keys(prop).length > 0)) {
    fireNotification('warning', 'Please select a report', 3000);
    push('/report-list');
  }
}

export function validateRoute(push, condition) {
  if (!condition) {
    push('/');
  }
}
