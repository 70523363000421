export default theme => ({
  container: {
    marginTop: theme.spacing(4),
    padding: '0 20%',
    [theme.breakpoints.down('lg')]: {
      padding: '0 10%'
    }
  },
  paper: {
    width: '10rem',
    height: '8rem'
  },
  borderBottom: {
    borderBottom: '1px solid ' + theme.palette.divider
  },
  paperButton: {
    width: '100%',
    height: '100%'
  },
  thin: {
    fontWeight: 300,
    color: '#555'
  }
});
