import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Box, ButtonBase, Grid, Grow, Paper, Typography, withStyles } from '@material-ui/core';
import AdminStyles from './Admin.styles';

class Admin extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid item lg={10} xl={12} className={classes.container}>
        {/* Reports Pane */}
        <Grid container>
          <Grid item lg={12}>
            <Box>
              <Typography variant="h5">What would you like to work on?</Typography>
            </Box>
          </Grid>
          <Grid container direction="row" spacing={3}>
            <Grid item>
              <Box mt={4}>
                <Grow in={true} timeout={300}>
                  <Paper className={classes.paper} onClick={() => this.props.history.push('/admin/user-management')} elevation={0}>
                    <ButtonBase className={classes.paperButton}>
                      <Grid container justifyContent="center" alignItems="center" direction="column">
                        <Grid item>
                          <img src="./images/users.png" alt="users" style={{ height: 60 }} />
                        </Grid>
                        &nbsp;
                        <Grid item>
                          <Typography variant="body1" className={classes.title}>
                            User Management
                          </Typography>
                        </Grid>
                      </Grid>
                    </ButtonBase>
                  </Paper>
                </Grow>
              </Box>
            </Grid>
            <Grid item>
              <Box mt={4}>
                <Grow in={true} timeout={600}>
                  <Paper className={classes.paper} onClick={() => this.props.history.push('/admin/industry-management')} elevation={0}>
                    <ButtonBase className={classes.paperButton}>
                      <Grid container justifyContent="center" alignItems="center" direction="column">
                        <Grid item>
                          <img src="./images/industry.svg" alt="users" style={{ height: 60 }} />
                        </Grid>
                        &nbsp;
                        <Grid item>
                          <Typography variant="body1" className={classes.title}>
                            Industry Management
                          </Typography>
                        </Grid>
                      </Grid>
                    </ButtonBase>
                  </Paper>
                </Grow>
              </Box>
            </Grid>
            <Grid item>
              <Box mt={4}>
                <Grow in={true} timeout={900}>
                  <Paper className={classes.paper} onClick={() => this.props.history.push('/admin/company-management')} elevation={0}>
                    <ButtonBase className={classes.paperButton}>
                      <Grid container justifyContent="center" alignItems="center" direction="column">
                        <Grid item>
                          <img src="./images/company.svg" alt="users" style={{ height: 60 }} />
                        </Grid>
                        &nbsp;
                        <Grid item>
                          <Typography variant="body1" className={classes.title}>
                            Company Management
                          </Typography>
                        </Grid>
                      </Grid>
                    </ButtonBase>
                  </Paper>
                </Grow>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default compose(withRouter, withStyles(AdminStyles))(Admin);
