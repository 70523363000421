import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { EXTENSION_COMMON } from 'react-file-picker-providers/constants';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, reset } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import feathersServices from '../../../../../services/feathersServices';
import KLDialog from '../../../../../components/dialog/KLDialog';
import RenderRadioGroup from '../../../../../components/renders/RenderRadioGroup';
import RenderTextField from '../../../../../components/renders/RenderTextField';
import UploadSources from '../../../../../components/uploadSources/UploadSources';
import Image from 'material-ui-image/lib/components/Image/Image';
import SingletonMomentUtils from '../../../../../utils/SingletonMomentUtils';
import {
  addFile,
  clearFiles,
  clearMarkedImagesForDelete,
  markImageForDelete,
  removeFile,
  setScoringRankingCompleted
} from '../../../actions/reports.actions';
import { closeCapabilityReportWarning, openCapabilityReportWarning, toggleCapabilityReportDrawer } from '../../actions/capability-detail.actions';
import CapabilityChangeFormStyles from './CapabilityChangeForm.styles';

class CapabilityChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      selectedImage: null,
      openImagePreview: false,
      scoringWarning: false,
      compare: [],
      openCompare: false
    };
  }

  closeDrawer = () => {
    if (this.props.savingReport) return;
    if (this.props.files.length || this.props.capabilityChangeForm.anyTouched || this.props.markedImagesForDelete.length) {
      this.props.openCapabilityReportWarning();
    } else {
      this.doCloseDrawer();
    }
  };

  doCloseDrawer = () => {
    this.props.closeCapabilityReportWarning();
    this.props.toggleCapabilityReportDrawer();
    this.props.clearFiles();
    this.props.clearMarkedImagesForDelete();
    this.props.reset('capabilityChangeForm');
    this.setState({ mountScoring: false, anchorEl: null, open: false });
  };

  handlePopover = event => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  deleteReportImage = file => {
    this.props.markImageForDelete(file);
  };

  deleteUnsavedImage = index => {
    this.props.removeFile(index);
  };

  handleCloudProviderSuccess = async files => {
    const parsedFiles = files.map(file => {
      file.fileBlob.lastModifiedDate = new Date();
      file.fileBlob.name = file.fileName;
      return file.fileBlob;
    });
    this.base64Files = await this.readFiles(parsedFiles);
    this.base64Files.forEach(({ name, uri }) => {
      this.props.addFile({
        fileName: name,
        uri
      });
    });
    this.setState({ anchorEl: null, open: false });
  };

  handleMyComputerFiles = async files => {
    if (!files.length) return;
    this.base64Files = await this.readFiles(files);
    this.base64Files.forEach(({ name, uri }) => {
      this.props.addFile({
        fileName: name,
        uri
      });
    });
    this.setState({ anchorEl: null, open: false });
  };

  readFiles = async files => {
    const reader = new FileReader();
    const base64Files = [];

    function readFile(index, resolve) {
      if (index >= files.length) {
        resolve(base64Files);
      } else {
        const file = files[index];
        reader.onloadend = () => {
          base64Files.push({
            name: file.name,
            uri: reader.result
          });
          readFile(index + 1, resolve);
        };
        reader.readAsDataURL(file);
      }
    }

    return new Promise((resolve, rejector) => {
      readFile(0, resolve);
    });
  };

  closeWarning = () => {
    this.props.closeCapabilityReportWarning();
  };

  render() {
    const {
      handleSubmit,
      classes,
      selectedCapabilityReport,
      capabilityChanges,
      pristine,
      valid,
      warningOpen,
      previousReportImages,
      markedImagesForDelete,
      files,
      savingReport
    } = this.props;

    const { open, selectedImage, openImagePreview, openCompare, compare } = this.state;

    let capabilityImages = selectedCapabilityReport.images || [];
    if (markedImagesForDelete.length) {
      capabilityImages = selectedCapabilityReport.images.filter(image => {
        return !markedImagesForDelete.find(i => i._id === image._id);
      });
    }

    return (
      <>
        <form onSubmit={handleSubmit}>
          <Box className={classes.header} display="flex" alignItems="center" p={2}>
            <Typography variant="h5" color="primary">
              {selectedCapabilityReport._id &&
                selectedCapabilityReport.capability &&
                selectedCapabilityReport.index + 1 + '. ' + selectedCapabilityReport.capability['title']}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="space-between" p={2} height="84vh">
            <Grid container spacing={1}>
              <Grid item xs={12} container justifyContent="space-between">
                <Typography variant="body1">Report Screenshots</Typography>
                {compare.length > 1 && (
                  <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    endIcon={<LaunchOutlinedIcon style={{ fontSize: '1rem' }} />}
                    onClick={() =>
                      this.setState({
                        openCompare: true
                      })
                    }
                  >
                    <Typography variant="caption">
                      <strong>Compare</strong>
                    </Typography>
                  </Button>
                )}
              </Grid>
              <Grid item xs={12}>
                {open ? (
                  <UploadSources
                    elevation={3}
                    modalTitle={'Please upload screenshots'}
                    modalSubTitle={"Accepted files types JPG's, PNG's, PDF's"}
                    handleClose={this.handleClose}
                    handleClick={this.handleClick}
                    handleMyComputerFiles={this.handleMyComputerFiles}
                    myComputerAcceptedFiles={['image/jpg', 'image/png', 'image/jpeg', 'image/gif']}
                    handleCloudProviderSuccess={this.handleCloudProviderSuccess}
                    providerAcceptedFiles={[EXTENSION_COMMON.PNG, EXTENSION_COMMON.JPEG, EXTENSION_COMMON.JPG, EXTENSION_COMMON.GIF]}
                  />
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={4} onClick={this.handlePopover}>
                      <Grid className={classes.uploadBox}>
                        <CloudUploadOutlined fontSize={'large'} />
                        <Typography variant="body1" className={classes.uploadText}>
                          Upload Screenshots
                        </Typography>
                      </Grid>
                    </Grid>
                    {files &&
                      files.map((file, index) => (
                        <Grid container key={index} item xs={4}>
                          <Box ml={1} mb={0.5}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="secondary"
                                  size="small"
                                  className={classes.checkboxTiny}
                                  checked={this.state.compare.findIndex(c => c.file.url === file.uri && !c.old) !== -1}
                                  onChange={() =>
                                    this.setState(prevState => {
                                      const exists = prevState.compare.findIndex(c => c.file.url === file.uri) !== -1;
                                      return {
                                        ...prevState,
                                        compare: exists
                                          ? prevState.compare.filter(c => c.file.url !== file.uri)
                                          : [...prevState.compare, { file: { ...file, url: file.uri }, old: false }]
                                      };
                                    })
                                  }
                                />
                              }
                              label={<Typography className={classes.checkboxLabel}>COMPARE</Typography>}
                            />
                          </Box>
                          <div
                            className={classes.previewImage}
                            onClick={() => {
                              this.setState({
                                openImagePreview: true,
                                selectedImage: file.uri
                              });
                            }}
                          >
                            <Image src={file.uri || ''} aspectRatio={0.7} />
                          </div>
                          <div className={classes.imageInfo}>
                            <Typography variant="caption">
                              <small>{`${SingletonMomentUtils.moment(new Date()).format('MM/DD/YYYY')}`}</small>
                            </Typography>
                            <DeleteOutlined className={classes.removeIcon} onClick={() => this.deleteUnsavedImage(index)} />
                          </div>
                        </Grid>
                      ))}
                    {capabilityImages.map((file, index) => (
                      <Grid container key={index} item xs={4}>
                        <Box ml={1} mb={0.5}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="secondary"
                                size="small"
                                className={classes.checkboxTiny}
                                checked={this.state.compare.findIndex(c => c.file === file && !c.old) !== -1}
                                onChange={() =>
                                  this.setState(prevState => {
                                    const exists = prevState.compare.findIndex(c => c.file === file) !== -1;
                                    return {
                                      ...prevState,
                                      compare: exists ? prevState.compare.filter(c => c.file !== file) : [...prevState.compare, { file, old: false }]
                                    };
                                  })
                                }
                              />
                            }
                            label={<Typography className={classes.checkboxLabel}>COMPARE</Typography>}
                          />
                        </Box>
                        <div
                          className={classes.previewImage}
                          onClick={() => {
                            this.setState({
                              openImagePreview: true,
                              selectedImage: file.url
                            });
                          }}
                        >
                          <Image src={file.thumbnail || file.url || ''} aspectRatio={0.7} />
                        </div>
                        <div className={classes.imageInfo}>
                          <Typography variant="caption">
                            <small>{`${SingletonMomentUtils.moment(file.date).format('MM/DD/YYYY')}`}</small>
                          </Typography>
                          <DeleteOutlined className={classes.removeIcon} onClick={() => this.deleteReportImage(file)} />
                        </div>
                      </Grid>
                    ))}
                    {previousReportImages &&
                      previousReportImages.map((file, index) => (
                        <Grid container key={index} item xs={4}>
                          <Box ml={1} mb={0.5}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="secondary"
                                  size="small"
                                  className={classes.checkboxTiny}
                                  checked={this.state.compare.findIndex(c => c.file === file && c.old) !== -1}
                                  onChange={() =>
                                    this.setState(prevState => {
                                      const exists = prevState.compare.findIndex(c => c.file === file) !== -1;
                                      return {
                                        ...prevState,
                                        compare: exists ? prevState.compare.filter(c => c.file !== file) : [...prevState.compare, { file, old: true }]
                                      };
                                    })
                                  }
                                />
                              }
                              label={<Typography className={classes.checkboxLabel}>COMPARE</Typography>}
                            />
                          </Box>
                          <div
                            className={classes.previewImage}
                            onClick={() => {
                              this.setState({
                                openImagePreview: true,
                                selectedImage: file.url
                              });
                            }}
                          >
                            <Image src={file.thumbnail || file.url || ''} aspectRatio={0.7} />
                          </div>
                          <div className={classes.imageInfo}>
                            <Typography variant="caption">
                              <small>{`${SingletonMomentUtils.moment(file.date).format('MM/DD/YYYY')}`}</small>
                            </Typography>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                )}

                <Dialog
                  open={openImagePreview}
                  onClose={() => {
                    this.setState({ openImagePreview: false, selectedImage: null });
                  }}
                  maxWidth={'xl'}
                >
                  <img src={selectedImage} alt="" />
                </Dialog>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="hasCapability"
                  id="hasCapability"
                  component={RenderRadioGroup}
                  label="Is this feature available?"
                  radios={[
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' }
                  ]}
                  className={classes.textField}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">What Changes, if any, were there?</Typography>
                <Field
                  id="select-capability-change"
                  select
                  name="changeType"
                  color="secondary"
                  component={RenderTextField}
                  className={classes.textField}
                  fullWidth
                  variant="outlined"
                  SelectProps={{
                    classes: {
                      select: classes.resize
                    }
                  }}
                >
                  {!capabilityChanges.isLoading &&
                    capabilityChanges.queryResult &&
                    capabilityChanges.queryResult.data.map((option, index) => (
                      <MenuItem key={index} value={option._id}>
                        {option.title}
                      </MenuItem>
                    ))}
                </Field>
                <Box mt={2}>
                  <Typography variant="body1">Enter any important changes</Typography>
                  <Field
                    name="notes"
                    multiline
                    variant="outlined"
                    color="secondary"
                    component={RenderTextField}
                    fullWidth
                    className={classes.textField}
                    minRows={6}
                    maxRows={6}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box py={1} display="flex" justifyContent="flex-end" position="sticky" bottom={0} bgcolor="common.white">
              <Button onClick={this.closeDrawer}>Close</Button>
              &nbsp;
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                disabled={savingReport || (pristine && !files.length && !markedImagesForDelete.length) || !valid}
                onClick={() => {
                  if (
                    this.props.isScoringRankingCompleted &&
                    this.props.capabilityChangeForm.initial.hasCapability !== this.props.capabilityChangeForm.values.hasCapability
                  ) {
                    this.setState({ scoringWarning: true });
                  } else {
                    handleSubmit();
                  }
                }}
              >
                SAVE
              </Button>
            </Box>
          </Box>

          <KLDialog
            title={'You Have Unsaved Changes'}
            body={<DialogContentText>Your unsaved changes will be lost, Are you sure you want to leave? </DialogContentText>}
            onHandleConfirm={this.doCloseDrawer}
            onHandleClose={this.closeWarning}
            isOpen={warningOpen}
          />
          <KLDialog
            title={'Scoring & Ranking actions'}
            body={
              <DialogContentText>
                This change will reset scoring and rankings calculations. You will need to review them. Do you want to proceed?
              </DialogContentText>
            }
            labelButtonConfirm={'Accept'}
            onHandleConfirm={async () => {
              await handleSubmit();

              this.setState({ scoringWarning: false });
              this.props.deleteScoringAndRanking(this.props.scoringAndRanking._id);
              this.props.setScoringRankingCompleted(false);
            }}
            onHandleClose={() => this.setState({ scoringWarning: false })}
            isOpen={this.state.scoringWarning}
          />
        </form>

        <Dialog open={openCompare} maxWidth="xl" fullScreen>
          <DialogTitle disableTypography>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6">{selectedCapabilityReport?.capability['title']}</Typography>
              <div>
                <IconButton onClick={() => this.setState({ openCompare: false, compare: [] })}>
                  <CloseIcon />
                </IconButton>
              </div>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box display="flex" width={1} pb={3}>
              {compare
                .sort((a, b) => (a.old ? -1 : 1))
                .map(el => (
                  <Box width={1} mx={3} key={el.file.url}>
                    {el.old ? (
                      <Typography variant="h6" color="textSecondary" paragraph>
                        <strong>Previous report</strong>
                      </Typography>
                    ) : (
                      <Typography variant="h6" paragraph>
                        <strong>Current report</strong>
                      </Typography>
                    )}
                    <Box border="1px solid" borderColor="grey.400" mb={1}>
                      <Image src={el.file.url || ''} aspectRatio={0.7} />
                    </Box>
                    <Typography variant="subtitle1">
                      <strong>{SingletonMomentUtils.moment(el.file.date).format('MM/DD/YYYY')}</strong>
                    </Typography>
                  </Box>
                ))}
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    scoringAndRanking: state.scoringAndRanking.scoringAndRanking,
    selectedCompany: state.currentReport.selectedCompany,
    selectedCategory: state.currentReport.selectedCategory,
    selectedCapabilityReport: state.currentReport.selectedCapabilityReport,
    capabilityChanges: state.services.capabilityChanges,
    files: state.currentReport.files,
    previousReport: state.currentReport.previousReport,
    capabilityChangeForm: state.form.capabilityChangeForm,
    warningOpen: state.ui.capabilityDetail.warning_opened,
    previousReportImages: state.currentReport.previousReportImages,
    markedImagesForDelete: state.currentReport.markedImagesForDelete,
    savingReport: state.ui.capabilityDetail.saving_report,
    isScoringRankingCompleted: state.currentReport.scoringRankingCompleted,
    initialValues: {
      notes: state.currentReport.selectedCapabilityReport.capabilityChangeNote || '',
      changeType: state.currentReport.selectedCapabilityReport.capabilityChange || '',
      hasCapability:
        state.currentReport.selectedCapabilityReport.hasCapability === undefined
          ? state.currentReport.selectedCapabilityReport.hasCapability
          : state.currentReport.selectedCapabilityReport.hasCapability + ''
    }
  };
};

const mapDispatchToProps = {
  toggleCapabilityReportDrawer,
  addFile,
  removeFile,
  openCapabilityReportWarning,
  closeCapabilityReportWarning,
  clearFiles,
  markImageForDelete,
  clearMarkedImagesForDelete,
  setScoringRankingCompleted,
  deleteScoringAndRanking: feathersServices['scoring-ranking'].remove,
  reset,
  push
};

CapabilityChangeForm = compose(
  withStyles(CapabilityChangeFormStyles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(
    {
      form: 'capabilityChangeForm',
      enableReinitialize: true,
      validate: (values, props) => {
        const errors = {};
        if (!values.changeType) {
          errors.changeType = 'Required';
        }
        if (!values.hasCapability) {
          errors.hasCapability = 'Required';
        }
        return errors;
      }
    },
    mapStateToProps
  )
)(CapabilityChangeForm);

export default CapabilityChangeForm;
