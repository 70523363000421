import React, { Component, Fragment } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from '@material-ui/core/Button';
import {
  Box,
  ButtonBase,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Tabs,
  Typography
} from '@material-ui/core/index';
import { withStyles } from '@material-ui/core/styles';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import feathersServices from '../../../services/feathersServices';
import { toggleAppBar, toggleSideMenu } from '../../../components/layout/actions/layout.actions';
import LoadingIndicator from '../../../components/loadingIndicator';
import Notification from '../../../components/notification/Notification';
import KLSelect from '../../../components/select/KLSelect';
import TabPanel from '../../../components/tabPanel/TabPanel';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { selectDate, selectDbReport, selectIndustry, setPreview } from '../../reports/actions/reports.actions';
import { changeHomeTabAction } from '../../reports/home/actions/home.actions';
import { setFilters, setPage, setSelectedReport } from '../../reports/list/action/ReportList.actions';
import viewReportsImage from '../../reports/list/view-reports.png';
import REPORT_CONST from '../../reports/report.constants';
import HomeStyles from './HomeCustomer.styles';

const filterType = { STATUS: 'STATUS', INDUSTRY: 'INDUSTRY' };
const sortingTypes = [
  { _id: 1, label: 'Date (Most Recent)', key: 'date', value: -1 },
  { _id: 2, label: 'Date (Last Recent)', key: 'date', value: 1 },
  { _id: 3, label: 'Title (Ascending)', key: 'industry.title', value: -1 },
  { _id: 4, label: 'Title (Descending)', key: 'industry.title', value: 1 }
];

class KLHomeCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSort: sortingTypes[0],
      buyAction: false
    };
  }

  componentDidMount() {
    this.props.selectDate(new Date());
    this.props.onToggleSideMenu(false);
    this.props.toggleAppBar(true);
    this.getMyReportsReports();
    this.getAllReportsReports();
    this.props.getIndustries({ query: { active: true } });
  }

  componentDidUpdate(nextProps) {
    if (JSON.stringify(this.props.filters) !== JSON.stringify(nextProps.filters)) {
      this.getAllReportsReports();
    }
  }

  onChangeTab = (event, index) => {
    this.props.onChangeTab(index);
    if (index === 0) {
      this.getMyReportsReports();
    }
    if (index === 1) {
      this.getAllReportsReports();
    }
  };

  getMyReportsReports() {
    const { user, getCompanyReports } = this.props;

    const query = {
      query: {
        $populate: true,
        $sort: {
          'report.date': 1
        },
        $limit: 1e10,
        company: user.company
      }
    };

    getCompanyReports(query);
  }

  getAllReportsReports() {
    const { filterIndustry, rowsPerPage, page } = this.props;
    const { selectedSort } = this.state;

    const query = {
      query: {
        $populate: true,
        status: {
          $in: [REPORT_CONST.STATUS.PUBLISHED.STATUS, REPORT_CONST.STATUS.PUBLISHED_EDITING.STATUS]
        },
        $sort: {
          [selectedSort.key]: selectedSort.value
        },
        $limit: rowsPerPage,
        $skip: rowsPerPage * page
      }
    };
    if (filterIndustry) {
      query.query.industry = filterIndustry._id;
    }

    this.props.getReports(query).then(
      data => data,
      error => this.props.push('/login')
    );
  }

  handleChangePage = async (event, newPage) => {
    await this.props.setPage(newPage);
    this.getAllReportsReports();
  };

  handleClickOnView = (selectedReport, isPreview = false) => {
    this.props.setSelectedReport(selectedReport);
    this.props.selectDbReport(selectedReport);
    this.props.selectIndustry(selectedReport.industry);
    this.props.selectDate(selectedReport.date);
    this.props.onToggleSideMenu(true);
    this.props.setPreview(isPreview);
    this.props.push('/content-building');
  };

  handlePrintReport = async report => {
    if (report && report.urlPdf) {
      window.open(report.urlPdf);
    } else {
      this.props.fireNotification('warning', 'PDF Report is being generated, please tray again in a few minutes', 6000);
    }
  };

  render() {
    const { classes, reports, industries, filterIndustry, setFilters, rowsPerPage, page, user, companiesReports } = this.props;
    const { selectedSort, buyAction } = this.state;
    const selectedTab = this.props.homeState.tab_selected;
    return (
      <Box>
        <Grid container direction="column" className={classes.container}>
          {/* Greeting */}
          <Grid item lg={9} className={classes.margin30}>
            <Box mt={5}>
              <Typography variant="h3">Hello, {user.name}</Typography>
            </Box>
            <Box mt={2} mb={4}>
              <Typography variant="body2" className={classes.thin}>
                Welcome to Key Lime Interactive's Comparative Index Reports.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* Tabs */}
        <Grid item lg={12} className={classes.borderBottom}>
          <Box className={classes.container}>
            <Tabs value={selectedTab} onChange={this.onChangeTab}>
              <Tab label="My Reports" />
              <Tab label="All Reports" />
            </Tabs>
          </Box>
        </Grid>

        <TabPanel value={selectedTab} index={0} className={classes.tabPanel}>
          {/* Tabs Panes */}
          <Grid item lg={8} className={classes.container}>
            {/* Reports Pane */}
            <Grid container>
              <Grid item lg={12}>
                <Box mb={4}>
                  <Typography variant="h5">My Reports</Typography>
                </Box>
              </Grid>
              <Grid container spacing={2}>
                {companiesReports.isLoading && (
                  <Grid item md={4}>
                    <Paper className={classes.paperGray} elevation={0}>
                      <ButtonBase className={classes.paperButton}>
                        <LoadingIndicator color="secondary" isLoading={companiesReports.isLoading} width={40} />
                      </ButtonBase>
                    </Paper>
                  </Grid>
                )}

                {!companiesReports.isLoading &&
                  companiesReports.queryResult &&
                  companiesReports.queryResult.data.map(companyReport => {
                    return (
                      <Grid item md={4} key={companyReport.report._id}>
                        <Box onClick={() => this.handleClickOnView(companyReport.report)}>
                          <Paper className={classes.paper} elevation={0}>
                            <ButtonBase className={classes.paperButton}>
                              <Grid className={classes.boxContainer} container direction="column">
                                <Grid item className={classes.imageContainer}>
                                  <img className={classes.image} src={viewReportsImage} alt="view reports" />
                                </Grid>
                                <Grid item className={classes.textContainer}>
                                  <Typography variant="body1" className={classes.title}>
                                    {SingletonMomentUtils.moment(companyReport.report.date).format('MMMM YYYY')}
                                  </Typography>
                                  <Typography className={classes.subtitle}>{companyReport.report.industry.title}</Typography>
                                </Grid>
                              </Grid>
                            </ButtonBase>
                          </Paper>
                        </Box>
                      </Grid>
                    );
                  })}
                <Grid item md={4}>
                  <Box
                    onClick={() => {
                      this.props.onChangeTab(1);
                    }}
                  >
                    <Paper className={classes.paperGray} elevation={0}>
                      <ButtonBase className={classes.paperButton}>
                        <Typography>More Reports </Typography>
                        <ChevronRightOutlinedIcon color="action" fontSize="small" />
                      </ButtonBase>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={selectedTab} index={1} className={classes.tabPanel}>
          <div className={classes.tabContainer}>
            <div className={classes.divContainer}>
              <Box className={classes.tableContainer}>
                <Typography variant="h5">All Reports</Typography>
                <div className={classes.flex}>
                  {!industries.isLoading && industries.queryResult && (
                    <KLSelect
                      id="industry"
                      selectedValue={filterIndustry}
                      acceptNone={true}
                      label="Industries"
                      margin="dense"
                      onChange={value => {
                        const val = Object.assign({}, value);
                        val.type = filterType.INDUSTRY;
                        setFilters(val);
                      }}
                      values={industries.queryResult.data}
                    />
                  )}
                  &nbsp;
                  <KLSelect
                    id="state"
                    selectedValue={selectedSort}
                    label="Sorted By"
                    margin="dense"
                    onChange={value => {
                      this.setState({ selectedSort: value }, () => {
                        this.getAllReportsReports();
                      });
                    }}
                    values={sortingTypes}
                  />
                </div>
              </Box>
              <Grid container justifyContent="center">
                <Grid item md={12}>
                  <Box mt={3}>
                    <LoadingIndicator color="secondary" isLoading={reports.isLoading} width={40} />
                    <Paper elevation={0} className={classes.table}>
                      <TableContainer>
                        <Table aria-label="simple table" size="small">
                          <TableBody>
                            {!reports.isLoading &&
                              reports.queryResult &&
                              reports.queryResult.data.map((report, index) => (
                                <TableRow key={index} hover>
                                  <TableCell>
                                    <Box className={classes.rowTitle}>
                                      <img src={viewReportsImage} alt="view reports" height="40" />
                                      {SingletonMomentUtils.moment(report.date).format('MMMM YYYY')}
                                    </Box>
                                  </TableCell>
                                  <TableCell>{report.industry.title}</TableCell>
                                  <TableCell className={classes.cellEnd}>
                                    {!companiesReports.isLoading &&
                                    companiesReports.queryResult &&
                                    companiesReports.queryResult.data.find(r => r.report._id === report._id) ? (
                                      <Fragment>
                                        <Button
                                          color="default"
                                          variant="contained"
                                          size="small"
                                          disableElevation
                                          style={{
                                            width: 150,
                                            padding: 0
                                          }}
                                          onClick={() => this.handlePrintReport(report)}
                                        >
                                          <Typography variant="overline">
                                            <strong>Print Report</strong>
                                          </Typography>
                                        </Button>
                                        &nbsp;&nbsp;
                                        <Button
                                          style={{
                                            width: 120,
                                            padding: 0
                                          }}
                                          color="secondary"
                                          variant="outlined"
                                          size="small"
                                          disableElevation
                                          onClick={() => this.handleClickOnView(report)}
                                        >
                                          <Typography variant="overline" color="secondary">
                                            <strong>Open Report</strong>
                                          </Typography>
                                        </Button>
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        <Button
                                          color="default"
                                          variant="contained"
                                          disableElevation
                                          size="small"
                                          style={{
                                            width: 150,
                                            padding: 0
                                          }}
                                        >
                                          <Typography
                                            variant="overline"
                                            onClick={() => {
                                              this.handleClickOnView(report, true);
                                            }}
                                          >
                                            Preview Report
                                          </Typography>
                                        </Button>
                                        &nbsp;&nbsp;
                                        <Button
                                          color="secondary"
                                          variant="contained"
                                          disableElevation
                                          size="small"
                                          style={{
                                            width: 120,
                                            padding: 0
                                          }}
                                        >
                                          <Typography
                                            variant="overline"
                                            onClick={() => {
                                              this.setState({
                                                buyAction: true
                                              });
                                              this.props.buyReport({
                                                rid: report._id
                                              });
                                            }}
                                          >
                                            Buy Report
                                          </Typography>
                                        </Button>
                                      </Fragment>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                rowsPerPageOptions={[rowsPerPage]}
                                count={reports.queryResult ? reports.queryResult.total : 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={this.handleChangePage}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </TabPanel>
        <Notification
          message={'An email has been sent to Sales Department, we will contact you soon.'}
          open={buyAction}
          variant="success"
          duration={10000}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ buyAction: false });
          }}
        />
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    reports: state.services.reports,
    companiesReports: state.services.companyReports,
    page: state.ui.listReport.page,
    rowsPerPage: state.ui.listReport.rowsPerPage,
    homeState: state.ui.home,
    user: state.auth.user,
    industries: state.services.industries,
    filters: state.ui.listReport.filters,
    filterIndustry: state.ui.listReport.filters ? state.ui.listReport.filters.find(ele => ele.type === filterType.INDUSTRY) : ''
  };
};

const mapDispatchToProps = {
  onChangeTab: changeHomeTabAction,
  onToggleSideMenu: toggleSideMenu,
  getReports: feathersServices.reports.find,
  getIndustries: feathersServices.industries.find,
  getCompanyReports: feathersServices['companies-reports'].find,
  buyReport: feathersServices['report-status'].create,
  selectDbReport,
  selectIndustry,
  setSelectedReport,
  toggleAppBar,
  selectDate,
  setFilters,
  setPage,
  setPreview,
  push
};

export default compose(withStyles(HomeStyles), connect(mapStateToProps, mapDispatchToProps))(KLHomeCustomer);
