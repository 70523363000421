import reduxifyServices from 'feathers-redux';
import feathersClient from '../setup/feathersClient';

export default reduxifyServices(feathersClient, [
  'activity',
  'industries',
  'companies',
  'categories',
  'applications',
  'capabilities',
  'capability-changes',
  'reports-date',
  'reports',
  'upload',
  'delete-from-storage',
  'google-drive',
  'google-drive-login',
  'consumer-survey',
  'users',
  'scoring-ranking',
  'survey-question',
  'device-weight',
  'content-building',
  'forgot-password',
  'companies-customer',
  'sales-person',
  'report-status',
  'report-generator',
  'report-edit',
  'user-company',
  'register',
  'companies-reports',
  'capability-reports',
  'methodology',
  'kli-applications',
  'status-io',
  'industry-copy'
]);
