import React from 'react';
import { Checkbox, FormControl } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const RenderCheckbox = ({ input, label, name, className, meta: { touched, invalid, error }, ...custom }) => {
  return (
    <div>
      <FormControl component="fieldset">
        <FormControlLabel
          label={label}
          control={<Checkbox label={label} checked={input.value ? true : false} onChange={input.onChange} fullWidth {...input} {...custom} />}
        />
      </FormControl>
    </div>
  );
};

export default RenderCheckbox;
