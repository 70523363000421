import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Card, CardContent, Typography, withStyles } from '@material-ui/core';
import CompanyStyles from './Company.styles';

let Company = ({ classes, companyCustomerSelected }) => {
  return (
    <Card className={classes.root} elevation={0}>
      <CardContent>
        <Typography variant="h5" className={classes.companyTitle}>
          {companyCustomerSelected.name}
        </Typography>

        <Typography variant="body1">{companyCustomerSelected.url}</Typography>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    companyCustomerSelected: state.ui.listCompaniesCustomer.companyCustomerSelected
  };
};

const mapDispatchToProps = {};

Company = compose(withStyles(CompanyStyles), connect(mapStateToProps, mapDispatchToProps))(Company);

export default Company;
