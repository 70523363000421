export const APPENDIX_METHODOLOGY_DATA = 'APPENDIX_METHODOLOGY_DATA';

export const CLEAR_APPENDIX_METHODOLOGY_DATA = 'CLEAR_APPENDIX_METHODOLOGY_DATA';

export function setAppendixMethodologyData(methodology) {
  return {
    type: APPENDIX_METHODOLOGY_DATA,
    payload: methodology
  };
}

export function clearAppendixMethodologyData() {
  return {
    type: CLEAR_APPENDIX_METHODOLOGY_DATA
  };
}
