import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import {
  Box,
  Breadcrumbs,
  DialogContentText,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Search from '@material-ui/icons/Search';
import feathersServices from '../../../services/feathersServices';
import KLDialog from '../../../components/dialog/KLDialog';
import LoadingIndicator from '../../../components/loadingIndicator';
import LinkRouter from '../../../components/router-link/RouterLink';
import HeaderTable from '../../../components/tables/HeaderTable';
import { debounce } from '../../../utils/immutable-utils';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { USER_ROLE } from '../../../constants/user.constants';
import UserManagementStyles from './UserManagement.styles';
import UserManagementForm from './UserMangementForm';

const cells = [
  { label: 'First Name', value: 'name', sort: true },
  { label: 'Last Name', value: 'lastName', sort: true },
  { label: 'Email', value: 'email', sort: true },
  { label: 'Role', value: 'loginType', sort: true },
  { label: 'Last Login', sort: false },
  { label: '' }
];

const ACTIVITY_ROW_PAGE = 8;

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 8,
      isOpen: false,
      anchorEl: null,
      openModal: false,
      openLogModal: false,
      selectedUser: null,
      openDeleteUserModal: false,
      term: '',
      activityPage: 0,
      activityAppFilter: ['INSIGHTS', 'CJM'],
      roleFilter: [USER_ROLE.SENIOR, USER_ROLE.ADMIN],
      sort: {
        key: 'name',
        value: 1
      }
    };
  }

  componentDidMount() {
    this.getPaginatedUsers();
  }

  handleChangePage = async (event, page) => {
    this.setState(
      {
        page
      },
      () => this.getPaginatedUsers()
    );
  };

  getPaginatedUsers = () => {
    const { key, value } = this.state.sort;

    const roles = this.state.roleFilter.length === 0 ? [USER_ROLE.SENIOR, USER_ROLE.ADMIN, USER_ROLE.CUSTOMER] : this.state.roleFilter;
    let query = {
      hasAggregation: true,
      $populate: true,
      $sort: {
        [key]: value
      },
      $limit: this.state.rowsPerPage,
      $skip: this.state.rowsPerPage * this.state.page,
      loginType: {
        $in: roles
      }
    };
    if (this.state.term) {
      query.term = this.state.term;
    }

    this.props
      .getUsers({
        query
      })
      .then(
        data => data,
        error => this.props.push('/login')
      );
  };

  handleClick = (event, user) => {
    this.setState({
      isOpen: true,
      anchorEl: event.currentTarget,
      selectedUser: user
    });
  };

  search = event => {
    this.setState({ term: event.target.value });
    this.debouncedSearch();
  };

  debouncedSearch = debounce(() => this.handleChangePage(null, 0), 1500);

  handleClose = () => {
    this.setState({ isOpen: false, anchorEl: null, selectedUser: null });
  };

  handleClickOnEdit = user => {
    this.setState({ selectedUser: user });
  };

  createSortHandler = value => {
    this.setState(
      {
        sort: {
          key: value,
          value: this.state.sort.value === -1 ? 1 : -1
        }
      },
      this.getPaginatedUsers
    );
  };

  deleteUser = () => {
    this.props.deleteUser(this.state.selectedUser._id).then(() => {
      this.handleClose();
      this.setState({ openDeleteUserModal: false });
      this.getPaginatedUsers();
    });
  };

  getPaginatedActivities = () => {
    const { selectedUser, activityPage, activityAppFilter } = this.state;
    this.props.getActivities({
      query: {
        'subject.type': 'USER',
        'subject.reference': selectedUser._id,
        'activity.app': activityAppFilter.length === 0 ? 'NONE' : activityAppFilter,
        $sort: {
          createdAt: -1
        },
        $limit: ACTIVITY_ROW_PAGE,
        $skip: ACTIVITY_ROW_PAGE * activityPage
      }
    });
  };

  handleChangeRoleFilter = event => {
    this.setState(
      {
        roleFilter: event.target.value
      },
      this.getPaginatedUsers
    );
  };

  handleChangeActivityPage = async (event, newPage) => {
    this.setState(
      {
        activityPage: newPage
      },
      this.getPaginatedActivities
    );
  };

  handleChangeActivityFilter = value => {
    const { activityAppFilter } = this.state;
    let values = activityAppFilter;
    if (activityAppFilter.indexOf(value) === -1) {
      values.push(value);
    } else {
      values = activityAppFilter.filter(v => v !== value);
    }
    this.setState(
      {
        activityAppFilter: values
      },
      this.getPaginatedActivities
    );
  };

  render() {
    const { classes, users, activities } = this.props;
    const { isOpen, anchorEl, page, rowsPerPage, openModal, openLogModal, selectedUser, openDeleteUserModal, sort, roleFilter, activityPage } =
      this.state;
    return (
      <Grid container className={classes.container}>
        <Box className={classes.box} mb={3}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                Admin
              </Typography>
            </LinkRouter>
            <Typography variant="body2">User Management</Typography>
          </Breadcrumbs>
        </Box>
        <Grid container justifyContent={'space-between'} alignItems="center">
          <Typography variant="h5" className={classes.title}>
            Key Lime Users
          </Typography>

          <Box mr={1}>
            <TextField
              select
              id="roleFilter"
              variant="outlined"
              color="secondary"
              value={roleFilter}
              onChange={this.handleChangeRoleFilter}
              style={{
                backgroundColor: 'white',
                width: 200
              }}
              SelectProps={{
                margin: 'dense',
                multiple: true,
                renderValue: selected => {
                  if (selected.length === 3) {
                    return 'All';
                  } else if (selected.indexOf(USER_ROLE.SENIOR) >= 0 && selected.indexOf(USER_ROLE.ADMIN) >= 0) {
                    return 'Keylimers';
                  } else {
                    return selected.join(', ');
                  }
                }
              }}
            >
              {[USER_ROLE.SENIOR, USER_ROLE.ADMIN, USER_ROLE.CUSTOMER].map(name => (
                <MenuItem key={name} value={name} dense>
                  <Checkbox size="small" checked={roleFilter.indexOf(name) > -1} />
                  <ListItemText
                    primary={name === 'ADMIN' ? 'RESEARCHER' : name}
                    primaryTypographyProps={{
                      variant: 'caption'
                    }}
                  />
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box mr={1}>
            <OutlinedInput
              id="search-term"
              onChange={this.search}
              value={this.state.term}
              placeholder="by name, last name, email"
              color="secondary"
              margin="dense"
              style={{
                backgroundColor: 'white'
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Search fontSize="small" color="action" />
                </InputAdornment>
              }
            />
          </Box>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => {
              this.handleClickOnEdit(null);
              this.setState({
                openModal: true
              });
            }}
          >
            New User
          </Button>
        </Grid>

        <Box my={3}>
          <Paper elevation={0} className={classes.table}>
            <LoadingIndicator color="secondary" isLoading={users.isLoading} width={40}>
              <Table aria-label="simple table" size="medium">
                <HeaderTable classes={classes} cells={cells.map(c => c.label)} />
              </Table>
            </LoadingIndicator>

            {!users.isLoading && (
              <Table aria-label="simple dense table" size="medium">
                <TableHead>
                  <TableRow className={classes.headerRow}>
                    {cells.map((cell, index) => (
                      <TableCell key={`sort_${index}`}>
                        {cell.sort ? (
                          <TableSortLabel
                            active={sort.key === cell.value}
                            direction={sort.value === 1 ? 'asc' : 'desc'}
                            onClick={() => this.createSortHandler(cell.value)}
                          >
                            {cell.label}
                          </TableSortLabel>
                        ) : (
                          cell.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users &&
                    users.queryResult &&
                    users.queryResult.data.map((user, index) => (
                      <TableRow
                        key={`row_${index}`}
                        // onClick={async () => {
                        //   await this.handleClickOnEdit(user);
                        //   this.setState({
                        //     openModal: true
                        //   });
                        // }}
                        hover
                        className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}
                      >
                        <TableCell>
                          <Typography className={classes.tableText} color="textSecondary">
                            {user.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableText} color="textSecondary">
                            {user.lastName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableText} color="textSecondary">
                            {user.email}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.tableText} color="textSecondary">
                            {user.loginType === 'ADMIN' ? 'RESEARCHER' : user.loginType}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {user.lastLogin
                            .filter(session => session._id === 'INSIGHTS')
                            .map((session, index) => (
                              <Typography className={classes.tableText} color="textSecondary" key={`date_${index}`}>
                                {`${SingletonMomentUtils.moment(session.date).format('MMM DD, YYYY - HH:mm')}`}
                              </Typography>
                            ))}
                        </TableCell>
                        <TableCell onClick={event => event.stopPropagation()} width="5%">
                          <IconButton
                            size="small"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={event => this.handleClick(event, user)}
                            ref
                          >
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[rowsPerPage]}
                      count={users.queryResult ? users.queryResult.total : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={this.handleChangePage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </Paper>
        </Box>
        <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={isOpen} onClose={this.handleClose}>
          <MenuItem
            onClick={() => {
              this.setState({
                openModal: true,
                isOpen: false,
                anchorEl: null
              });
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.getPaginatedActivities();
              this.setState({
                openLogModal: true,
                isOpen: false,
                anchorEl: null
              });
            }}
          >
            Logs
          </MenuItem>
          <MenuItem
            onClick={() =>
              this.setState({
                openDeleteUserModal: true,
                isOpen: false
              })
            }
          >
            Delete
          </MenuItem>
        </Menu>

        {activities && (
          <KLDialog
            maxWidth="xl"
            keepMounted={true}
            isOpen={openLogModal}
            onHandleClose={() => this.setState({ openLogModal: !openLogModal })}
            onHandleConfirm={() => this.setState({ openLogModal: !openLogModal })}
            showButtonClose={false}
            title={`Logs for ${selectedUser ? selectedUser.name : ''} ${selectedUser ? selectedUser.lastName : ''}`}
            body={
              <Box style={{ width: 600 }} borderRadius="borderRadius" className={classes.table}>
                <LoadingIndicator isLoading={activities.isLoading} width={40} color="secondary" />
                {!activities.isLoading && activities.queryResult && activities.queryResult.total > 0 && (
                  <>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Activity</TableCell>
                          <TableCell>Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {activities.queryResult.data.map(row => (
                          <StyledTableRow key={row.name}>
                            <TableCell component="th" scope="row">
                              {row.activity.action}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {SingletonMomentUtils.moment(row.createdAt).format('llll')}
                            </TableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[ACTIVITY_ROW_PAGE]}
                      component="div"
                      count={activities.queryResult ? activities.queryResult.total : 0}
                      rowsPerPage={ACTIVITY_ROW_PAGE}
                      page={activityPage}
                      onPageChange={this.handleChangeActivityPage}
                    />
                  </>
                )}
                {!activities.isLoading && activities.queryResult.total === 0 && <Typography>There are no activities</Typography>}
              </Box>
            }
            labelButtonConfirm="Close"
          />
        )}
        <Grid container direction="column">
          <Drawer anchor="right" open={openModal} classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}>
            <UserManagementForm
              selectedUser={selectedUser}
              onCallBack={() => {
                this.setState(
                  {
                    openModal: false,
                    selectedUser: null,
                    isOpen: false
                  },
                  this.getPaginatedUsers
                );
              }}
            />
          </Drawer>
        </Grid>
        {selectedUser && (
          <KLDialog
            isOpen={openDeleteUserModal}
            onHandleClose={() => this.setState({ openDeleteUserModal: !openDeleteUserModal })}
            onHandleConfirm={this.deleteUser}
            title={'DELETE'}
            body={<DialogContentText>{`The user will be deleted.`}</DialogContentText>}
            labelButtonConfirm={'Delete'}
          />
        )}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.services.users,
    activities: state.services.activity
  };
}

const mapDispatchToProps = {
  getUsers: feathersServices.users.find,
  deleteUser: feathersServices.users.remove,
  getActivities: feathersServices.activity.find
};

export default compose(withStyles(UserManagementStyles), connect(mapStateToProps, mapDispatchToProps))(UserManagement);
