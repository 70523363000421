import {
  SCREENSHOT_CLEAN,
  SCREENSHOT_FILTER_APPLICATION,
  SCREENSHOT_FILTER_CATEGORY,
  SCREENSHOT_FILTER_COMPANY,
  SCREENSHOT_IMAGES,
  SCREENSHOT_IMAGES_SELECTED
} from '../actions/Screenshots.actions';

const initialState = {
  images: [],
  imagesSelected: [],
  application: 'Mobile Web',
  companies: [],
  categories: []
};

const screenshotReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCREENSHOT_IMAGES:
      return Object.assign({}, state, {
        images: action.payload
      });
    case SCREENSHOT_IMAGES_SELECTED:
      return Object.assign({}, state, {
        imagesSelected: action.payload
      });
    case SCREENSHOT_FILTER_APPLICATION:
      return Object.assign({}, state, {
        application: action.payload
      });
    case SCREENSHOT_FILTER_COMPANY:
      return Object.assign({}, state, {
        companies: action.payload
      });
    case SCREENSHOT_FILTER_CATEGORY:
      return Object.assign({}, state, {
        categories: action.payload
      });
    case SCREENSHOT_CLEAN:
      return Object.assign({}, state, {
        images: [],
        imagesSelected: [],
        application: 'Mobile Web',
        companies: [],
        categories: []
      });
    default:
      return state;
  }
};

export default screenshotReducer;
