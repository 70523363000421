export default theme => ({
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    marginTop: theme.spacing(4),
    overflow: 'hidden'
  },
  grow: {
    flexGrow: 1
  },
  paper: {
    width: '10rem',
    height: '8rem',
    margin: theme.spacing(2),
    border: '1px solid ' + theme.palette.divider,
    cursor: 'pointer',
    '&:hover': {
      '& p': {
        color: theme.palette.secondary.main
      }
    }
  },
  icon: {
    paddingTop: theme.spacing(2),
    display: 'block',
    height: '50%',
    width: 'auto',
    '& svg': {
      height: '80%',
      width: 'auto'
    }
  },
  borderBottom: {
    borderBottom: '1px solid ' + theme.palette.divider
  },
  title: {
    lineHeight: '1.25',
    fontSize: '0.85rem'
  },
  alignLeft: {
    textAlign: 'left'
  },
  title1: {
    lineHeight: '28px',
    fontSize: 16,
    color: '#212121'
  },
  subtitle1: {
    lineHeight: '1.5',
    fontSize: '0.75rem',
    color: '#888'
  },
  mTop: {
    marginTop: '1rem'
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  check: {
    flexGrow: 0,
    padding: 5
  }
});
