import React from 'react';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import RenderTextFieldStyle from './RenderTextField.style';

const RenderTextField = ({ classes, label, input, variant, meta: { touched, invalid, error }, reference, ...custom }) => {
  return (
    <TextField
      ref={reference}
      className={classes.root}
      InputLabelProps={{ className: classes.label }}
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
      variant={variant}
    />
  );
};

export default withStyles(RenderTextFieldStyle)(RenderTextField);
