export default theme => ({
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    marginTop: theme.spacing(2)
  },
  grow: {
    flexGrow: 1
  },
  paper: {
    width: '10rem',
    height: '8rem',
    border: '1px solid ' + theme.palette.divider,
    cursor: 'pointer',
    '&:hover': {
      '& p': {
        color: theme.palette.secondary.main
      }
    }
  },
  borderBottom: {
    borderBottom: '1px solid ' + theme.palette.divider
  },
  disabled: {
    color: '#00000059'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  check: {
    flexGrow: 0,
    padding: 5
  },
  link: {
    textDecoration: 'none'
  },
  title: {
    lineHeight: '1.25',
    fontSize: '0.85rem'
  },
  actionButtons: {
    textAlign: 'right'
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  flex: {
    display: 'flex',
    marginBottom: '1rem'
  }
});
