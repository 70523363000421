export const LIST_REPORT_PAGE_SELECT_REPORT = 'LIST_REPORT_PAGE_SELECT_REPORT';
export const LIST_REPORT_PAGINATION_PAGE = 'LIST_REPORT_PAGINATION_PAGE';
export const LIST_REPORT_FILTERS_PAGES = 'LIST_REPORT_FILTERS_PAGES';

export function setSelectedReport(report) {
  return {
    type: LIST_REPORT_PAGE_SELECT_REPORT,
    payload: report
  };
}

export function setPage(page) {
  return {
    type: LIST_REPORT_PAGINATION_PAGE,
    payload: page
  };
}

export function setFilters(filter) {
  return {
    type: LIST_REPORT_FILTERS_PAGES,
    payload: filter
  };
}
