import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = {
  viewBox: '0 0 80 80'
};

// viewBox
const RawDataIcon = props => (
  <SvgIcon {...useStyles} style={props}>
    <g id="icon/rawData" stroke="none" strokeWidth="1">
      <path
        stroke="null"
        id="svg_1"
        d="m65.85937,41.83889c0,-0.58434 -0.4585,-1.05859 -1.02344,-1.05859l-27.63281,0l0,-28.62014c0,-0.58434 -0.4585,-1.05859 -1.02344,-1.05859c-16.36476,0 -29.67968,13.75113 -29.67968,30.65475c0,17.52608 12.77864,30.74368 29.72267,30.74368c15.78755,0 29.6367,-14.32912 29.6367,-30.66111zm-57.31249,-0.08257c0,-15.38348 11.84526,-27.95957 26.60937,-28.51851l0,28.60108c0,0.58434 0.4585,1.05859 1.02344,1.02048l27.61234,0c-0.57927,14.79914 -13.21258,27.52343 -27.56936,27.52343c-15.77731,0 -27.67579,-12.30509 -27.67579,-28.62649zm33.77343,-5.24851l28.6542,0c0.56494,0.03811 1.02548,-0.47425 1.02548,-1.05859c0,-16.92691 -13.31492,-30.69922 -29.67968,-30.69922c-0.56494,0 -1.02344,0.47425 -1.02344,1.05859l0,29.67873c0,0.58434 0.4585,1.05859 1.02344,1.02048zm1.02344,-29.62157c14.45708,0.54835 26.11608,12.63961 26.59709,27.54249l-26.59709,0l0,-27.54249z"
      />
    </g>
  </SvgIcon>
);

export default RawDataIcon;
