export default theme => {
  return {
    root: {
      marginBottom: '0.8rem'
    },
    label: {
      color: theme.palette.secondary.main
    }
  };
};
