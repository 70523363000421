import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Button, FormControlLabel, Grid, Switch, TextField, Typography } from '@material-ui/core';
import feathersServices from '../../../services/feathersServices';

function SurveyQuestionManagementForm(props) {
  const { onClose, onSave, selectedElement, industry, category, classes } = props;

  const [title, setTitle] = useState(null);
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (selectedElement) {
      setTitle(selectedElement.title);
      setActive(selectedElement.active);
    }
  }, [selectedElement]);

  const handleSave = async () => {
    if (selectedElement?._id) {
      await props.saveSurveyQuestion(selectedElement._id, {
        title,
        active
      });
    } else {
      await props.createSurveyQuestion({
        title,
        active,
        industry: industry._id,
        category: category._id
      });
    }
    onSave();
    onClose();
  };

  return (
    <>
      <Box className={classes.header} display="flex" alignItems="center" p={2} mb={2}>
        <Typography variant="h5" color="primary">
          {selectedElement?.title || 'Add new survey question'}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="space-between" p={2} height={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField required disabled={true} defaultValue={industry.title} color="secondary" label="Industry" fullWidth variant="outlined" />
          </Grid>
          <Grid item xs={12}>
            <TextField required disabled={true} defaultValue={category.title} color="secondary" label="Category" fullWidth variant="outlined" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              value={title || ''}
              color="secondary"
              label="Question"
              fullWidth
              error={title === ''}
              variant="outlined"
              onChange={({ target }) => setTitle(target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              p={1}
              border="1px solid"
              borderColor="grey.400"
              alignItems="center"
              borderRadius="borderRadius"
              className={classes.fieldset}
            >
              <FormControlLabel
                control={<Switch checked={active} onChange={({ target }) => setActive(target.checked)} />}
                labelPlacement="start"
                label="Status"
              />
              &nbsp;&nbsp;
              <Typography component="span">{active ? 'Active' : 'Disabled'}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Button onClick={onClose}>Cancel</Button>
          &nbsp;
          <Button disabled={!title} onClick={handleSave} color="secondary" variant="contained" disableElevation>
            {selectedElement ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </Box>
    </>
  );
}

const mapDispatchToProps = {
  saveSurveyQuestion: feathersServices['survey-question'].patch,
  createSurveyQuestion: feathersServices['survey-question'].create
};

export default connect(null, mapDispatchToProps)(SurveyQuestionManagementForm);
