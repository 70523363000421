import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Box, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { toggleAppBar, toggleSideMenu } from '../../../components/layout/actions/layout.actions';
import TabPanel from '../../../components/tabPanel/TabPanel';
import { USER_ROLE } from '../../../constants/user.constants';
import Admin from '../../admin/Admin';
import CompaniesManagement from '../../clientManagement/CompaniesManagement';
import { changeHomeTabAction } from './actions/home.actions';
import HomeStyles from './Home.styles';
import TabReport from './tabReport/TabReport';

class KLHome extends Component {
  componentDidMount() {
    this.props.onToggleSideMenu(false);
    this.props.toggleAppBar(true);
    if (this.props.user.loginType !== USER_ROLE.SENIOR && this.props.homeState.tab_selected === 2) {
      this.props.onChangeTab(0);
    }
  }

  onChangeTab = (event, index) => {
    this.props.onChangeTab(index);
  };

  render() {
    const { classes, user } = this.props;
    const selectedTab = this.props.homeState.tab_selected;
    return (
      <Box>
        <Grid container direction="column" className={classes.container}>
          {/* Greeting */}
          <Grid item lg={12}>
            <Box mt={5}>
              <Typography variant="h2">Hello, {user.name}</Typography>
            </Box>
            <Box mt={2} mb={4}>
              <Typography variant="body1" className={classes.thin}>
                Welcome to your administrator portal for comparative research reports.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* Tabs */}
        <Grid item lg={12} className={classes.borderBottom}>
          <Box className={classes.container}>
            <Tabs value={selectedTab} onChange={this.onChangeTab}>
              <Tab label="Reports" />
              <Tab label="Client Management" />
              {user.loginType === USER_ROLE.SENIOR && <Tab label="Admin" />}
            </Tabs>
          </Box>
        </Grid>

        {/* Tabs Panes */}
        <TabPanel value={selectedTab} index={0} className={classes.container}>
          <TabReport />
        </TabPanel>
        <TabPanel value={selectedTab} index={1} className={classes.container}>
          <CompaniesManagement />
        </TabPanel>
        {user.loginType === USER_ROLE.SENIOR && (
          <TabPanel value={selectedTab} index={2} className={classes.container}>
            <Admin />
          </TabPanel>
        )}
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    homeState: state.ui.home,
    user: state.auth.user
  };
};

const mapDispatchToProps = {
  onChangeTab: changeHomeTabAction,
  onToggleSideMenu: toggleSideMenu,
  toggleAppBar,
  push
};

export default compose(withStyles(HomeStyles), connect(mapStateToProps, mapDispatchToProps))(KLHome);
