import { APPENDIX_METHODOLOGY_DATA, CLEAR_APPENDIX_METHODOLOGY_DATA } from '../action/Appendix.actions';

const initialState = {
  _id: null,
  report: null,
  methodology: null
};

const appendixReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPENDIX_METHODOLOGY_DATA:
      return Object.assign({}, state, action.payload);
    case CLEAR_APPENDIX_METHODOLOGY_DATA:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
};

export default appendixReducer;
