export default theme => ({
  container: {
    marginTop: theme.spacing(4),
    paddingLeft: '10%'
  },
  grow: {
    flexGrow: 1
  },
  paper: {
    width: '10rem',
    height: '8rem',
    border: '1px solid ' + theme.palette.divider
  },
  borderBottom: {
    borderBottom: '1px solid ' + theme.palette.divider
  },
  paperButton: {
    width: '100%',
    height: '100%'
  },
  center: {
    justifyContent: 'center'
  },
  centerRaw: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: '20%'
  },
  textRawData: {
    fontSize: '1.8rem',
    marginLeft: '0.2rem'
  },
  icon: {
    verticalAlign: 'bottom',
    width: '0.6em',
    marginLeft: '2%'
  },
  sourceModal: {
    position: 'fixed',
    left: 'calc(25%)',
    top: 'calc(32%)'
  },
  rowDataContainer: {
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: '1%'
  }
});
