import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { canEdit } from '../../utils/user.utils';
import { USER_ROLE } from '../../constants/user.constants';
import { SECURITY_ACTIONS } from './Security.constants';

class KLSecurity extends Component {
  validate = () => {
    const { action, user } = this.props;

    if (user) {
      switch (action.toString()) {
        case SECURITY_ACTIONS.PUBLISH:
        case SECURITY_ACTIONS.APPROVE:
          return this.props.user.loginType === USER_ROLE.SENIOR;
        case SECURITY_ACTIONS.EDIT:
          return canEdit(this.props.user, this.props.dbReport);
        default:
          return false;
      }
    }
    return false;
  };

  render() {
    if (this.validate()) {
      return this.props.children;
    }
    return null;
  }
}

KLSecurity.propTypes = {
  action: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    dbReport: state.currentReport.dbReport
  };
};

export default connect(mapStateToProps, null)(KLSecurity);
