import REPORT_CONSTANTS from '../modules/reports/report.constants';
import { groupBy, groupByArray, unique } from './immutable-utils';

export function getReportCategories(report) {
  const categories = [];

  //Iterate all companies.-
  if (report && report.companies) {
    report.companies.map(company =>
      // Iterate all categories.-
      company.categories.forEach(cat => {
        // Create a new category temp object.-
        const newCat = {
          categoryId: cat.categoryId,
          title: cat.title
        };
        // If the category isn't in the array, add it.-
        if (!categories.find(c => c.categoryId === newCat.categoryId)) {
          categories.push(newCat);
        }
      })
    );
  }
  return categories.sort((a, b) => a.title.localeCompare(b.title));
}

export function getReportCategoriesGrouped(report) {
  const categories = [];

  //Iterate all companies.-
  if (report && report.companies) {
    report.companies.map(company =>
      // Iterate all categories.-
      company.categories.forEach(cat => {
        // Create a new category temp object.-
        const newCat = {
          categoryGroup: cat.belongsTo || cat.title
        };
        // If the category isn't in the array, add it.-
        if (!categories.find(c => c.categoryGroup === newCat.categoryGroup)) {
          categories.push(newCat);
        }
      })
    );
  }
  return categories.sort((a, b) => a.categoryGroup.localeCompare(b.categoryGroup));
}

export function getApplications(report) {
  const applications = [];
  // Iterate all companies.-
  if (report && report.companies) {
    report.companies.forEach(company => {
      // Itearate all categories.-
      company.categories.forEach(cat => {
        const exist = applications.find(a => {
          return a.id === cat.applicationId;
        });
        if (!exist) {
          applications.push({
            id: cat.applicationId,
            title: cat.applicationTitle
          });
        }
      });
    });
    return applications;
  }
}

export function isContentBuildingCompleted(report, contentBuilding) {
  if (!contentBuilding) {
    return false;
  }

  // Check for executive summary and top performers.-
  let hasSummary = isExecutiveSummaryCompleted(contentBuilding);

  // Check for each application for key success factors and best in class features.-
  let hasProductRating = isProductRatingCompleted(report, contentBuilding);

  // Check for each category for notable callouts and update history.-
  let hasCategoryPerformance = isCategoryPerformanceCompleted(report, contentBuilding);

  // Check for trends.-
  const hasTrends = isTrendsCompleted(contentBuilding);
  return hasSummary && hasProductRating && hasCategoryPerformance && hasTrends;
}

export function isExecutiveSummaryCompleted(contentBuilding) {
  return contentBuilding && contentBuilding.executiveSummary && contentBuilding.executiveSummary.topPerformers !== undefined;
}

export function isProductRatingCompleted(report, contentBuilding) {
  const applications = getApplications(report);
  let hasProductRating = true;
  if (contentBuilding.productRating) {
    applications.forEach(app => {
      const appProdRating = contentBuilding.productRating[app.id];
      if (appProdRating) {
        hasProductRating = hasProductRating && appProdRating.keySuccessFactor !== undefined && appProdRating.bestInClassFeatures !== undefined;
      } else {
        hasProductRating = false;
      }
    });
  } else {
    hasProductRating = false;
  }
  return hasProductRating;
}

export function isCategoryPerformanceCompleted(report, contentBuilding) {
  const applications = getApplications(report);
  let hasCategoryPerformance = true;
  if (contentBuilding.categoryPerformance) {
    const categories = getReportCategoriesGrouped(report);
    categories.forEach(category => {
      applications.forEach(app => {
        const found = contentBuilding.categoryPerformance.filter(c => c.categoryGroup === category.categoryGroup && c.applicationId === app.id);
        if (found && found.length > 0) {
          found.forEach(category => {
            hasCategoryPerformance =
              hasCategoryPerformance && (!category.applicationId || (category.applicationId && category.notableCallouts !== undefined));
          });
        } else {
          hasCategoryPerformance = false;
        }
      });
    });
  } else {
    hasCategoryPerformance = false;
  }
  return hasCategoryPerformance;
}

export function isTrendsCompleted(contentBuilding) {
  return contentBuilding && contentBuilding.trendsInnovations ? contentBuilding.trendsInnovations.length > 0 : false;
}

export function getReportApplications(report) {
  let applications = unique(
    groupByArray(report && report.companies.length > 0 && report.companies[0].categories ? report.companies[0].categories : [], 'applicationTitle')
  );
  applications.forEach(a => {
    a.values.forEach(c => {
      const finded = c.capabilityReports.filter(
        cr => cr.status === REPORT_CONSTANTS.CAPABILITY_REPORT.STATUS.DRAFT || cr.status === REPORT_CONSTANTS.CAPABILITY_REPORT.STATUS.NOT_COMPLETED
      );
      c.completed = c.capabilityReports.length && !finded.length;
    });
  });
  return applications;
}

export function getDeviceCapabilitiesRank(report, scoringAndRanking, applications) {
  let capabilityRankByCategoryUnweighted = {};
  applications.forEach(application => {
    capabilityRankByCategoryUnweighted[application.key] = [];
    report.companies[0].categories
      .filter(c => c.applicationTitle === application.key)
      .forEach((category, categoryIndex) => {
        capabilityRankByCategoryUnweighted[application.key][categoryIndex] = {};
        report.companies.forEach(company => {
          const scoring = scoringAndRanking.find(c => c.companyName === company.title);
          if (scoring) {
            let categoryScore = scoring.applications[application.key].categories.find(ca => {
              return ca.title === category.title;
            });

            if (categoryScore) {
              Object.assign(capabilityRankByCategoryUnweighted[application.key][categoryIndex], {
                [company.title]: categoryScore.unweightedDeviceCategoryPercentage
              });
            }
          }
        });
        capabilityRankByCategoryUnweighted[application.key][categoryIndex].categoryName = category.title;
      });
  });

  return capabilityRankByCategoryUnweighted;
}

export function checkMarkCompletedCompaniesAndCategories(capabilityReport, dbReport, applications) {
  let completedMap = {};
  let capabilityReportsByCompany = groupBy(capabilityReport, 'company');

  dbReport.companies.forEach(company => {
    completedMap[company.title] = { applications: {}, userRatings: false };
    let categoriesByApplication = groupBy(company.categories, 'applicationTitle');
    if (company.userRatings && Object.keys(company.userRatings).length === 2) {
      completedMap[company.title].userRatings = true;
    }

    applications.forEach(application => {
      completedMap[company.title].applications[application.title] = {
        categories: {}
      };
      categoriesByApplication[application.title].forEach(cat => {
        completedMap[company.title].applications[application.title].categories[cat.title] =
          capabilityReportsByCompany[company.companyId]?.find(cr => {
            return (
              cr.application === application.id &&
              cr.category === cat.categoryId &&
              (cr.status === REPORT_CONSTANTS.CAPABILITY_REPORT.STATUS.DRAFT || cr.status === REPORT_CONSTANTS.CAPABILITY_REPORT.STATUS.NOT_COMPLETED)
            );
          }) === undefined;
      });
    });

    completedMap[company.title].completed =
      capabilityReportsByCompany[company.companyId]?.find(cr => {
        return cr.status === REPORT_CONSTANTS.CAPABILITY_REPORT.STATUS.DRAFT || cr.status === REPORT_CONSTANTS.CAPABILITY_REPORT.STATUS.NOT_COMPLETED;
      }) === undefined && completedMap[company.title].userRatings;
  });

  completedMap.capabilitiesTable = Object.keys(completedMap).reduce((acc, newVal) => {
    if (!acc) {
      return acc;
    } else {
      return completedMap[newVal].completed;
    }
  }, true);

  return completedMap;
}
