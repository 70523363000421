import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Box, Breadcrumbs, ButtonBase, Dialog, DialogTitle, Grid, Grow, Tab, Tabs, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Done from '@material-ui/icons/Done';
import Edit from '@material-ui/icons/Edit';
import feathersServices from '../../../services/feathersServices';
import { fireNotification } from '../../../components/layout/actions/layout.actions';
import LinkRouter from '../../../components/router-link/RouterLink';
import { groupByArray, unique } from '../../../utils/immutable-utils';
import { checkMarkCompletedCompaniesAndCategories, getApplications } from '../../../utils/report.utils';
import { validateProps, validateRoute } from '../../../utils/routeUtils';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { selectApplication, selectCategory, selectDbReport, setCompleteMap } from '../actions/reports.actions';
import REPORT_CONSTANTS from '../report.constants';
import { changeCapabilitiesTableTabAction, setReportApplications, toggleUserRatingsDialog } from './actions/capabilities-table.actions';
import CapabilitiesTableStyles from './CapabilitiesTable.styles';
import UserRatingsForm from './userRatingsForm/UserRatingsForm';

class KLCapabilitiesTable extends Component {
  async componentDidMount() {
    validateProps(
      this.props.push,
      this.props.fireNotification,
      this.props.currentReport,
      this.props.currentReport.selectedCompany,
      this.props.currentReport.selectedIndustry,
      this.props.currentReport.selectedDate
    );

    validateRoute(
      this.props.push,
      this.props.currentReport.dbReport.status !== REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS &&
        this.props.currentReport.dbReport.status !== REPORT_CONSTANTS.STATUS.PUBLISHED_EDITED.STATUS &&
        this.props.currentReport.dbReport.status !== REPORT_CONSTANTS.STATUS.PUBLISHED_EDITING.STATUS
    );

    const company = this.props.currentReport.dbReport.companies.find(c => c.companyId === this.props.currentReport.selectedCompany.companyId);
    if (!company) {
      return;
    }
    let categories = company.categories;
    let applications = unique(groupByArray(categories, 'applicationTitle'));

    applications.forEach(a => {
      a.values.forEach(c => {
        const finded = c.capabilityReports.filter(
          cr => cr.status === REPORT_CONSTANTS.CAPABILITY_REPORT.STATUS.DRAFT || cr.status === REPORT_CONSTANTS.CAPABILITY_REPORT.STATUS.NOT_COMPLETED
        );
        c.completed = c.capabilityReports.length && !finded.length;
      });
    });
    this.props.setReportApplications(applications);

    if (!this.props.currentReport.selectedApplication && applications.length) {
      this.props.selectApplication(applications[0].key);
    }

    this.props
      .getCapabilityReports({
        query: {
          report: this.props.currentReport.dbReport._id,
          $limit: 'infinity'
        }
      })
      .then(({ value: capabilityReport }) => {
        const dbReport = this.props.currentReport.dbReport;
        const applications = getApplications(this.props.currentReport.dbReport);

        const completedMap = checkMarkCompletedCompaniesAndCategories(capabilityReport, dbReport, applications);

        this.props.setCompleteMap(completedMap);
      });
  }

  onChangeTab = (e, index) => {
    this.props.onChangeTab(index);
    this.props.selectApplication(this.props.capabilitiesTablePage.applications[index].key);
  };

  onCategoryClick = category => {
    this.props.onCategorySelect(category);
    this.props.push('/report/capability-detail');
  };

  handleClose() {
    this.props.toggleUserRatingsDialog();
  }

  saveUserRatings = application => {
    this.props
      .patchReport(
        this.props.currentReport.dbReport._id,
        Object.assign({}, this.props.currentReport.selectedCompany, {
          'companies.$.userRatings': this.props.userRatingsForm.values
        }),
        {
          query: {
            'companies._id': this.props.currentReport.selectedCompany._id,
            $populate: true
          }
        }
      )
      .then(data => {
        this.props.selectDbReport(data.value);
        this.props.toggleUserRatingsDialog();
      });
  };

  render() {
    const { classes, currentReport, toggleUserRatingsDialog } = this.props;
    const { selectedIndustry, selectedCompany, selectedDate } = this.props.currentReport;
    const { tab_selected, applications, userRatingsDialogOpen } = this.props.capabilitiesTablePage;
    return (
      <Box mb={4}>
        <Grid container direction="column">
          {/* Greeting */}
          <Grid item lg={12} className={classes.container}>
            <Breadcrumbs separator="›">
              <LinkRouter to="/report" color="secondary" underline="none">
                <Typography color="secondary" variant="body2">
                  Home
                </Typography>
              </LinkRouter>
              <LinkRouter to="/report/report-publish-date" color="secondary" underline="none">
                {selectedIndustry && selectedDate && (
                  <Typography color="secondary" variant="body2">
                    {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(selectedDate.date).format('MMMM YYYY')}`}
                  </Typography>
                )}
              </LinkRouter>
              <LinkRouter to="/report/app-site-select" color="secondary" underline="none">
                <Typography color="secondary" variant="body2">
                  Capabilities Table
                </Typography>
              </LinkRouter>
              <Typography variant="body2">{selectedCompany && selectedCompany.title}</Typography>
            </Breadcrumbs>
            <Box mt={5} mb={4}>
              <Typography variant="h5">Capabilities Table</Typography>
            </Box>
            <Grid container item lg={12} xl={10}>
              <Grid item lg={7}>
                <Typography variant="body1">
                  Select a category to begin data collection. When a category is done, a checkmark will appear next to that category's name here.
                </Typography>
              </Grid>

              <Grid item lg={5}>
                <Grow in={true} timeout={300}>
                  <fieldset className={classes.fieldset}>
                    <legend className={classes.legend}>
                      <Typography variant="h5" className={classes.legendTitle}>
                        User ratings
                      </Typography>
                      <IconButton onClick={toggleUserRatingsDialog} size="small">
                        <Edit className={classes.icon} fontSize="small" />
                      </IconButton>
                    </legend>
                    {applications &&
                      applications.length &&
                      applications
                        .filter(app => app.key !== 'Mobile Web')
                        .map(application => {
                          return (
                            <Typography key={application.key} variant="caption" className={classes.weights}>
                              {`${application.key}: ${(selectedCompany.userRatings && selectedCompany.userRatings[application.key]) || ''}`}
                            </Typography>
                          );
                        })}
                  </fieldset>
                </Grow>
                <Dialog open={Boolean(userRatingsDialogOpen)} onClose={() => this.handleClose()} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">User Ratings</DialogTitle>
                  <UserRatingsForm applications={applications} handleSubmit={() => this.saveUserRatings()} handleClose={() => this.handleClose()} />
                </Dialog>
              </Grid>
            </Grid>
          </Grid>

          {/* Tabs */}
          <Grid item lg={12} className={classes.borderBottom}>
            <Box>
              <Tabs value={tab_selected} onChange={this.onChangeTab} className={classes.container}>
                {applications && applications.length && applications.map((application, index) => <Tab key={index} label={application.key} />)}
              </Tabs>
            </Box>
          </Grid>
          <Box mt={2} mb={2} className={classes.container}>
            <Typography variant="h6">Which category would you like to start with?</Typography>
          </Box>
          {/* Tabs Panes */}
          <Grid item lg={12} xl={10} className={classes.container}>
            {/* Reports Pane */}
            <Grid container spacing={3}>
              {applications &&
                applications.length &&
                applications[tab_selected].values
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map((category, index) => {
                    return (
                      <Grid item xs={4} key={index}>
                        <Grow in={true} timeout={100 * (index + 1)}>
                          <ButtonBase className={classes.categories} onClick={() => this.onCategoryClick(category)}>
                            <Typography color="secondary" variant="body1">
                              {category.title}
                            </Typography>
                            {currentReport.completedMap &&
                              currentReport.completedMap[selectedCompany.title] &&
                              currentReport.completedMap[selectedCompany.title].applications[currentReport.selectedApplication].categories[
                                category.title
                              ] && <Done color="secondary" fontSize="small" />}
                          </ButtonBase>
                        </Grow>
                      </Grid>
                    );
                  })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentReport: state.currentReport,
    capabilitiesTablePage: state.ui.capabilitiesTable,
    userRatingsForm: state.form.userRatingsForm
  };
};

const mapDispatchToProps = {
  onChangeTab: changeCapabilitiesTableTabAction,
  toggleUserRatingsDialog,
  getCapabilityReports: feathersServices['capability-reports'].find,
  patchReport: feathersServices.reports.patch,
  selectApplication,
  onCategorySelect: selectCategory,
  setReportApplications,
  fireNotification,
  setCompleteMap,
  selectDbReport,
  push
};

export default compose(withStyles(CapabilitiesTableStyles), connect(mapStateToProps, mapDispatchToProps))(KLCapabilitiesTable);
