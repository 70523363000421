import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import {
  Button,
  DialogContentText,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import feathersServices from '../../services/feathersServices';
import KLDialog from '../../components/dialog/KLDialog';
import LoadingIndicator from '../../components/loadingIndicator';
import { setCompanyCustomerSelected, setPage } from './action/CompaniesCustomertList.actions';
import CompaniesForm from './companies/CompaniesForm';
import CompaniesManagementStyles from './CompaniesManagement.styles';

const CompaniesManagement = ({
  classes,
  findCompaniesCustomerService,
  companiesCustomerList,
  companyCustomerSelected,
  setCompanyCustomerSelected,
  setPage,
  deleteCompaniesCustomerService,
  rowsPerPage,
  page,
  push
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalDeleteCompany, setOpenModalDeleteCompany] = useState(false);

  const [openMenu, setOpenMenu] = useState({ isOpen: false, anchorEl: null });

  useEffect(() => {
    findCompaniesCustomerService({
      query: {
        $populate: true,
        $limit: rowsPerPage,
        $skip: rowsPerPage * page
      }
    });
  }, [findCompaniesCustomerService, page, rowsPerPage]);

  const findCompaniesCustomer = () => {
    findCompaniesCustomerService({
      query: {
        $populate: true,
        $limit: rowsPerPage,
        $skip: rowsPerPage * page
      }
    });
  };

  const onHandleClick = (event, companyCustomer) => {
    setOpenMenu({ isOpen: true, anchorEl: event.currentTarget });
    setCompanyCustomerSelected(companyCustomer);
  };

  const onHandleClose = () => {
    onHandleCloseIcon();
    setCompanyCustomerSelected(null);
  };

  const onHandleCloseIcon = () => {
    setOpenMenu({ isOpen: false, anchorEl: null });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage, () => findCompaniesCustomer());
  };

  const deleteCompany = () => {
    if (companyCustomerSelected) {
      deleteCompaniesCustomerService(companyCustomerSelected._id).then(
        data => {
          setCompanyCustomerSelected(null);
          findCompaniesCustomer();
        },
        error => {
          console.error(error);
        }
      );
    }
  };

  return !companiesCustomerList.isLoading ? (
    <Grid container justifyContent="center">
      <Grid item container justifyContent="space-between">
        <Typography variant="h5">Companies</Typography>
        <Button
          variant="contained"
          disableElevation
          className={classes.button}
          color="secondary"
          onClick={() => {
            setCompanyCustomerSelected(null);
            setOpenModal(!openModal);
          }}
        >
          New Company
        </Button>
      </Grid>

      <Grid item md={12} className={classes.container}>
        <Box my={1}>
          <Paper className={classes.table} elevation={0}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow className={classes.headerRow}>
                  <TableCell>Name</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell>Sales Person</TableCell>
                  <TableCell align="center">Users Count</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {!companiesCustomerList.isLoading &&
                  companiesCustomerList.queryResult &&
                  companiesCustomerList.queryResult.total !== 0 &&
                  companiesCustomerList.queryResult.data.map((companyCustomer, index) => {
                    return (
                      <TableRow
                        key={index}
                        onClick={event => {
                          onHandleClick(event, companyCustomer);
                          push('/management/company-details');
                        }}
                        className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}
                      >
                        <TableCell component="th" scope="row">
                          <Typography variant="caption" color="textSecondary">
                            {companyCustomer.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption" color="textSecondary">
                            {companyCustomer.url}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="caption" color="textSecondary">
                            {companyCustomer.salesPerson.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="caption" color="textSecondary">
                            {companyCustomer.users.length}
                          </Typography>
                        </TableCell>
                        <TableCell onClick={event => event.stopPropagation()} width="5%">
                          <IconButton
                            size="small"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={event => onHandleClick(event, companyCustomer)}
                          >
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              {!companiesCustomerList.isLoading && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[rowsPerPage]}
                      count={companiesCustomerList.queryResult ? companiesCustomerList.queryResult.total : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </Paper>
        </Box>
      </Grid>

      <Menu id="long-menu" anchorEl={openMenu.anchorEl} keepMounted open={openMenu.isOpen} onClose={onHandleClose}>
        <MenuItem
          onClick={() => {
            setOpenModal(!openModal);
            onHandleCloseIcon();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            push('/management/company-details');
          }}
        >
          View Detail
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenModalDeleteCompany(!openModalDeleteCompany);
            onHandleCloseIcon();
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      {openModal && (
        <KLDialog
          isOpen={openModal}
          onHandleClose={() => {
            setOpenModal(!openModal);
          }}
          title={companyCustomerSelected ? 'Update Company' : 'Create Company'}
          body={
            <CompaniesForm
              onCallBack={() => {
                setOpenModal(!openModal);
                findCompaniesCustomer();
              }}
            />
          }
          showButtonConfirm={false}
          showButtonClose={false}
        />
      )}

      {companyCustomerSelected && (
        <KLDialog
          isOpen={openModalDeleteCompany}
          onHandleClose={() => setOpenModalDeleteCompany(!openModalDeleteCompany)}
          onHandleConfirm={() => deleteCompany()}
          title={'DELETE'}
          body={<DialogContentText>{`The ${companyCustomerSelected.name} Company and assigned users will be deleted.`}</DialogContentText>}
          labelButtonConfirm={'Delete'}
        />
      )}
    </Grid>
  ) : (
    <LoadingIndicator color="secondary" isLoading={companiesCustomerList.isLoading}>
      <Typography>Loading Companies</Typography>
    </LoadingIndicator>
  );
};

const mapStateToProps = state => {
  return {
    companyCustomerSelected: state.ui.listCompaniesCustomer.companyCustomerSelected,
    companiesCustomerList: state.services.companiesCustomer,
    page: state.ui.listCompaniesCustomer.page,
    rowsPerPage: state.ui.listCompaniesCustomer.rowsPerPage
  };
};

const mapDispatchToProps = {
  setCompanyCustomerSelected: setCompanyCustomerSelected,
  setPage: setPage,
  findCompaniesCustomerService: feathersServices['companies-customer'].find,
  deleteCompaniesCustomerService: feathersServices['companies-customer'].remove,
  push
};

export default compose(withStyles(CompaniesManagementStyles), connect(mapStateToProps, mapDispatchToProps))(CompaniesManagement);
