import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import {
  Box,
  Button,
  DialogContentText,
  Drawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import feathersServices from '../../../../services/feathersServices';
import KLDialog from '../../../../components/dialog/KLDialog';
import LoadingIndicator from '../../../../components/loadingIndicator/LoadingIndicator';
import SingletonMomentUtils from '../../../../utils/SingletonMomentUtils';
import { setReportCompanySelected, setReportPage } from '../../action/CompaniesCustomertList.actions';
import ReportsCompanyStyles from './ReportsCompany.styles';
import ReportsCompanyForm from './ReportsCompanyForm';

class ReportsCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      openMenuRow: { isOpen: false, anchorEl: null },
      openDeleteModal: false
    };
  }

  componentDidMount() {
    this.getCompanyReports();
  }

  getCompanyReports() {
    this.props.getCompanyReports({
      query: {
        company: this.props.companyCustomerSelected._id,
        $populate: true,
        $sort: {
          poDate: -1
        },
        $limit: this.props.rowsPerPage,
        $skip: this.props.rowsPerPage * this.props.reportsPage
      }
    });
  }

  onHandleClick = (event, companyReport) => {
    this.setState({
      openMenuRow: { isOpen: true, anchorEl: event.currentTarget }
    });
    this.props.setReportCompanySelected(companyReport);
  };

  handleEditCompanyReport = () => {
    this.setState({
      openModal: true,
      openMenuRow: { isOpen: false, anchorEl: null }
    });
  };

  handleCloseMenu = () => {
    this.setState({
      openMenuRow: { isOpen: false, anchorEl: null }
    });
  };

  handleChangePage = async (event, newPage) => {
    await this.props.setReportPage(newPage);
    this.getCompanyReports();
  };

  deleteReportCompany = () => {
    const { deleteCompanyReport, reportCompanySelected } = this.props;
    if (reportCompanySelected) {
      deleteCompanyReport(reportCompanySelected._id).then(() => {
        this.props.setReportCompanySelected(null);
        this.setState(
          {
            openDeleteModal: false
          },
          () => {
            this.handleCloseMenu();
            this.getCompanyReports();
          }
        );
      });
    }
  };

  render() {
    const { openModal, openMenuRow, openDeleteModal } = this.state;
    const { classes, reportCompanies, companyCustomerSelected, reportsPage, rowsPerPage, reportCompanySelected } = this.props;

    return (
      <Box>
        <Grid container>
          <Grid item xs={12} className={classes.root}>
            <Typography variant="h6">Reports</Typography>
            <div>
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                size="small"
                onClick={() => {
                  this.setState({ openModal: !openModal }, () => this.props.setReportCompanySelected(null));
                }}
              >
                Add Report
              </Button>
            </div>
          </Grid>

          <Paper elevation={0} className={classes.table}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow className={classes.row}>
                  <TableCell>Industry</TableCell>
                  <TableCell>Edition</TableCell>
                  <TableCell>Purchase Date </TableCell>
                  <TableCell>PO#</TableCell>
                  <TableCell size="small" />
                </TableRow>
              </TableHead>
              {reportCompanies && reportCompanies.isLoading && (
                <TableBody>
                  <TableRow className={classes.row}>
                    <TableCell colSpan={5}>
                      <LoadingIndicator width={40} color="secondary" isLoading={reportCompanies && reportCompanies.isLoading} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              <TableBody>
                {reportCompanies &&
                  !reportCompanies.isLoading &&
                  reportCompanies.queryResult &&
                  reportCompanies.queryResult.data.map((company, index) => (
                    <TableRow key={index} className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}>
                      <TableCell>{company.report.industry.title}</TableCell>
                      <TableCell>{SingletonMomentUtils.moment(company.report.date).format('MMMM YYYY')}</TableCell>
                      <TableCell>{SingletonMomentUtils.moment(company.poDate).format('MM/DD/YYYY')}</TableCell>
                      <TableCell>{company.po}</TableCell>
                      <TableCell width="5%">
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          size="small"
                          onClick={event => this.onHandleClick(event, company)}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[rowsPerPage]}
                    count={reportCompanies && reportCompanies.queryResult ? reportCompanies.queryResult.total : 0}
                    rowsPerPage={rowsPerPage}
                    page={reportsPage}
                    onPageChange={this.handleChangePage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        </Grid>

        <Menu id="long-menu" anchorEl={openMenuRow.anchorEl} keepMounted open={openMenuRow.isOpen} onClose={this.handleCloseMenu}>
          <MenuItem onClick={() => this.handleEditCompanyReport(reportCompanySelected)}>Edit</MenuItem>

          {companyCustomerSelected.primaryUser === reportCompanySelected?._id ? null : (
            <MenuItem
              onClick={() => {
                this.setState({
                  openDeleteModal: !openDeleteModal
                });
              }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>

        <KLDialog
          isOpen={openDeleteModal}
          onHandleClose={() => {
            this.handleCloseMenu();
            this.setState({ openDeleteModal: false });
          }}
          onHandleConfirm={() => this.deleteReportCompany()}
          title={'DELETE'}
          body={<DialogContentText>{`The report will be unassigned from this company.`}</DialogContentText>}
          labelButtonConfirm={'Delete'}
        />

        <Drawer anchor="right" open={openModal} classes={{ paper: classes.paper, root: classes.drawerRoot }}>
          <ReportsCompanyForm
            onCallBack={() => {
              this.props.setReportCompanySelected(null);
              this.setState({ openModal: !openModal }, () => {
                this.getCompanyReports();
              });
            }}
          />
        </Drawer>
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    reportCompanies: state.services.companyReports,
    reportsPage: state.ui.listCompaniesCustomer.reportsPage ? state.ui.listCompaniesCustomer.reportsPage : 0,
    rowsPerPage: state.ui.listCompaniesCustomer.rowsPerPage,
    companyCustomerSelected: state.ui.listCompaniesCustomer.companyCustomerSelected,
    reportCompanySelected: state.ui.listCompaniesCustomer.reportCompanySelected
  };
};

const mapDispatchToProps = {
  getCompanyReports: feathersServices['companies-reports'].find,
  saveCompanyReport: feathersServices['companies-reports'].create,
  deleteCompanyReport: feathersServices['companies-reports'].remove,
  setReportPage,
  setReportCompanySelected
};

ReportsCompany = compose(withStyles(ReportsCompanyStyles), connect(mapStateToProps, mapDispatchToProps))(ReportsCompany);

export default ReportsCompany;
