import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = {
  viewBox: '0 0 80 80'
};

// viewBox
const ImportDataIcon = props => (
  <SvgIcon {...useStyles} style={props}>
    <g id="icon/importData" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M20,12 L60,12 C61.1045695,12 62,12.8954305 62,14 L62,66 C62,67.1045695 61.1045695,68 60,68 L20,68 C18.8954305,68 18,67.1045695 18,66 L18,14 C18,12.8954305 18.8954305,12 20,12 Z M20,13 C19.4477153,13 19,13.4477153 19,14 L19,66 C19,66.5522847 19.4477153,67 20,67 L60,67 C60.5522847,67 61,66.5522847 61,66 L61,14 C61,13.4477153 60.5522847,13 60,13 L20,13 Z M23.5,22 L23.5,21 L56.5,21 L56.5,22 L23.5,22 Z M23.5,27 L23.5,26 L56.5,26 L56.5,27 L23.5,27 Z M23.5,32 L23.5,31 L56.5,31 L56.5,32 L23.5,32 Z M23.5,37 L23.5,36 L56.5,36 L56.5,37 L23.5,37 Z M23.5,42 L23.5,41 L56.5,41 L56.5,42 L23.5,42 Z M23.5,47 L23.5,46 L56.5,46 L56.5,47 L23.5,47 Z M23.5,52 L23.5,51 L56.5,51 L56.5,52 L23.5,52 Z M23.5,57 L23.5,56 L56.5,56 L56.5,57 L23.5,57 Z M23.5,62 L23.5,61 L56.5,61 L56.5,62 L23.5,62 Z"
        id="Combined-Shape"
        fill="#636363"
        fillRule="nonzero"
      />
    </g>
  </SvgIcon>
);

export default ImportDataIcon;
