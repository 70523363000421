import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 280;

export default makeStyles(theme => ({
  drawerPaper: {
    background: theme.palette.common.white,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '& .MuiListItemText-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '0.65rem'
    },
    '& .MuiTypography-root': {
      fontSize: '0.65rem',
      paddingLeft: 15
    },
    '& .Mui-selected': {
      background: 'none',
      '& .MuiTypography-root': {
        fontWeight: 600,
        fontSize: '0.65rem'
      }
    }
  },
  drawerPaperClose: {
    display: 'none',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  toolbarContainer: {
    marginTop: '24%'
  },
  mTop: {
    marginTop: '1rem'
  },
  listTitle: {
    fontSize: '0.9rem',
    fontWeight: 400,
    color: '#212121'
  }
}));
