import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { Drawer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import feathersServices from '../../../../services/feathersServices';
import { fireNotification } from '../../../../components/layout/actions/layout.actions';
import { clearFiles, clearMarkedImagesForDelete, selectDbReport, setCapabilityReports } from '../../actions/reports.actions';
import REPORT_CONSTANTS from '../../report.constants';
import { savingCapabilityReport, savingCapabilityReportDone, toggleCapabilityReportDrawer } from '../actions/capability-detail.actions';
import CapabilityChangeForm from './capability-change-form/CapabilityChangeForm';
import CapabilityReportDetailStyles from './CapabilityReportDetail.styles';

class KLCapabilityReportDetail extends Component {
  componentDidMount = () => {
    this.props.savingCapabilityReportDone();
  };
  saveReport = () => {
    this.props.savingCapabilityReport();
    if (this.props.files.length) {
      this.props.fireNotification('warning', 'Uploading files.. this might take some time.', 1e10);
      this.uploadFiles().then(result => {
        this.doSave(result.value);
        this.props.fireNotification('success', 'Upload Files completed', 3000);
      });
    } else {
      this.doSave();
    }
  };

  uploadFiles = () => {
    return this.props.uploadFiles({ files: this.props.files });
  };

  doSave(files) {
    const capabilityReport = Object.assign({}, this.props.selectedCapabilityReport);

    capabilityReport.capabilityChange = this.props.capabilityChangeForm.values.changeType;
    capabilityReport.capabilityChangeNote = this.props.capabilityChangeForm.values.notes;
    capabilityReport.hasCapability = this.props.capabilityChangeForm.values.hasCapability === 'true'; //parse to boolean
    capabilityReport.lastUpdate = {
      userId: this.props.user._id,
      userName: this.props.user.name,
      date: new Date()
    };
    capabilityReport.status = REPORT_CONSTANTS.CAPABILITY_REPORT.STATUS.COMPLETED;

    if (this.props.markedImagesForDelete.length) {
      capabilityReport.images = capabilityReport.images.filter(image => {
        return !this.props.markedImagesForDelete.find(i => i._id === image._id);
      });
    }

    if (files && files.length) {
      capabilityReport.images = [...capabilityReport.images];
      files.forEach(file => {
        capabilityReport.images.push({
          id: file.id,
          name: file.name,
          thumbnail: file.thumbnail,
          url: file.url,
          date: file.date
        });
      });
    }

    this.props.updateCapabilityReport(this.props.selectedCapabilityReport._id, capabilityReport).then(
      async ({ value: savedReport }) => {
        if (!this.props.dbReport.revision && this.props.markedImagesForDelete.length) {
          let imagesUrls = this.props.markedImagesForDelete.map(i => [i.url, i.thumbnail]).flat();
          this.props.deleteImagesStorage({
            filesId: imagesUrls
          });
        }
        this.props.clearFiles();
        this.props.clearMarkedImagesForDelete();
        const capabilityReports = await this.props.getCapabilityReports({
          query: {
            report: this.props.currentReport.dbReport._id,
            application: this.props.currentReport.applications.find(a => a.title === this.props.currentReport.selectedApplication).id,
            category: this.props.currentReport.selectedCategory.categoryId,
            company: this.props.currentReport.selectedCompany.companyId,
            $limit: 'infinity',
            $populate: true
          }
        });
        this.props.setCapabilityReports(capabilityReports.value);
        this.props.savingCapabilityReportDone();
        this.props.reset('capabilityChangeForm');
        this.props.toggleCapabilityReportDrawer();
      },
      error => {
        this.props.savingCapabilityReportDone();
        if (error.code === 409) {
          this.props.getReport(this.props.dbReport._id).then(data => {
            this.props.selectDbReport(data.value);
          });
          this.props.fireNotification('warning', 'The report was not in sync, we have fetch the latest version for you, try again', 3000);
        }
      }
    );
  }

  render() {
    const { classes, drawerOpened, selectedCapabilityReport } = this.props;

    return (
      <Drawer
        anchor="right"
        open={drawerOpened}
        variant={'persistent'}
        PaperProps={{
          elevation: 6
        }}
        classes={{ paper: classes.paper, root: classes.root }}
      >
        {selectedCapabilityReport._id ? (
          <CapabilityChangeForm selectedCapabilityReport={selectedCapabilityReport} onSubmit={this.saveReport} />
        ) : (
          <div />
        )}
      </Drawer>
    );
  }
}

const mapStateToProps = state => {
  return {
    drawerOpened: state.ui.capabilityDetail.drawer_opened,
    capabilityChanges: state.services.capabilityChanges,
    selectedApplication: state.currentReport.selectedApplication,
    selectedCategory: state.currentReport.selectedCategory,
    selectedCompany: state.currentReport.selectedCompany,
    selectedIndustry: state.currentReport.selectedIndustry,
    selectedDate: state.currentReport.selectedDate,
    dbReport: state.currentReport.dbReport,
    currentReport: state.currentReport,
    reports: state.services.reports,
    capabilityChangeForm: state.form.capabilityChangeForm,
    files: state.currentReport.files,
    markedImagesForDelete: state.currentReport.markedImagesForDelete,
    selectedCapabilityReport: state.currentReport.selectedCapabilityReport,
    user: state.auth.user
  };
};

const mapDispatchToProps = {
  selectDbReport,
  clearFiles,
  fireNotification,
  clearMarkedImagesForDelete,
  toggleCapabilityReportDrawer,
  savingCapabilityReport,
  savingCapabilityReportDone,
  reset,
  getReport: feathersServices.reports.get,
  updateCapabilityReport: feathersServices['capability-reports'].update,
  uploadFiles: feathersServices.upload.create,
  deleteImagesStorage: feathersServices['delete-from-storage'].create,
  getCapabilityReports: feathersServices['capability-reports'].find,
  setCapabilityReports
};

export default compose(withStyles(CapabilityReportDetailStyles), connect(mapStateToProps, mapDispatchToProps))(KLCapabilityReportDetail);
