import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = {
  viewBox: '0 0 80 80'
};

// viewBox
const ContentIcon = props => (
  <SvgIcon {...useStyles} style={props}>
    <g id="icon/content" stroke="none" strokeWidth="1" fillRule="evenodd">
      <path
        stroke={props.fill || '#a6a6a6'}
        d="M40.48375,59.1267542 C40.4782937,59.2027112 40.4553416,59.2785622 40.4133952,59.3482835 L36.2887174,66.2041319 C36.0923517,66.5305218 35.6177198,66.5262458 35.4272665,66.196371 L31.5522665,59.4846741 C31.4872094,59.371992 31.4709976,59.2445127 31.4970617,59.1267542 L31.4851397,59.1267542 L31.4851397,8.12675424 C31.4851397,7.02218474 32.3805702,6.12675424 33.4851397,6.12675424 L38.4851397,6.12675424 C39.5897092,6.12675424 40.4851397,7.02218474 40.4851397,8.12675424 L40.4851397,59.1267542 L40.48375,59.1267542 Z M39.3796443,59.1267542 L32.5003219,59.1267542 L35.8691499,64.9617355 L39.3796443,59.1267542 Z M32.4851397,13.5905407 L32.4851397,58.1267542 L39.4851397,58.1267542 L39.4851397,13.5905407 L32.4851397,13.5905407 Z M32.4851397,12.5905407 L39.4851397,12.5905407 L39.4851397,8.12675424 C39.4851397,7.57446949 39.0374245,7.12675424 38.4851397,7.12675424 L33.4851397,7.12675424 C32.932855,7.12675424 32.4851397,7.57446949 32.4851397,8.12675424 L32.4851397,12.5905407 Z"
        id="Combined-Shape"
        fillRule="nonzero"
        transform="translate(35.985140, 36.286563) rotate(35.000000) translate(-35.985140, -36.286563) "
      />
      <polyline
        id="Path-8"
        stroke={props.fill || '#a6a6a6'}
        fill={props.fill || '#a6a6a6'}
        transform="translate(42.860014, 65.054620) rotate(-5.000000) translate(-42.860014, -65.054620) "
        points="20.3600144 63.5545375 29.1799451 60.5051855 28.802146 68.0454463 45.097952 59.9415474 44.5608385 70.1676934 65.3600144 61.2280019"
      />
    </g>
  </SvgIcon>
);

export default ContentIcon;
