export default theme => ({
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer'
  },
  box: {
    marginTop: 30,
    width: '100%'
  },
  title: {
    fontSize: '1rem',
    color: '#212121',
    fontWeight: 500,
    marginTop: 10,
    marginBottom: 10
  },
  column: {
    display: 'flex',
    alignItems: 'center'
  },
  table: {
    border: '1px solid ' + theme.palette.divider,
    overflow: 'hidden',
    '& tbody > tr:last-child > td': {
      border: 'none'
    }
  },
  row: {
    backgroundColor: '#f3f3f3'
  },
  oddRow: {
    backgroundColor: theme.palette.primary.main
  },
  tableText: {
    fontSize: '0.7rem'
  },
  tableHeader: {
    fontSize: '0.7rem',
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  }
});
