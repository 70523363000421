export default theme => ({
  icon: {
    verticalAlign: 'bottom',
    width: '0.6em',
    marginLeft: '2%'
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.overrides.MuiSelected.root['&:hover'].backgroundColor + '!important',
      '& th': {
        color: theme.palette.text.primary
      }
    }
  },
  oddRow: {
    backgroundColor: theme.palette.primary.dark
  },
  panel: {
    marginBottom: '1%'
  },
  paper: {
    width: '100%'
  },
  table: {
    marginTop: '0.5rem'
  },
  itemTextLink: {
    cursor: 'pointer',
    color: theme.palette.secondary.main
  },
  item: {
    padding: '2px'
  }
});
