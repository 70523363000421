const screenshotTabSelectionMigration = {
  27: state => {
    // migration clear out device state
    return {
      ...state,
      ui: Object.assign({}, state.ui, {
        screenshots: Object.assign({}, state.ui.screenshots, {
          application: 'Mobile Web'
        }),
        scoringAndRanking: Object.assign({}, state.ui.scoringAndRanking, {
          device_dialog_open: false,
          isLoading: true,
          scoringAndRankingCalculated: false,
          tab: 0
        })
      }),
      scoringAndRanking: Object.assign({}, state.scoringAndRanking, {
        deviceWeight: {}
      })
    };
  }
};

export default screenshotTabSelectionMigration;
