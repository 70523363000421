import React, { Component, Fragment } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Link } from 'react-scroll';
import { default as Scroll } from 'react-scroll/modules';
import { compose } from 'redux';
import clsx from 'clsx';
import { debounceTime, Subject } from 'rxjs';
import { Box, Breadcrumbs, Grid, Paper, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Edit from '@material-ui/icons/Edit';
import feathersServices from '../../../services/feathersServices';
import KLDialog from '../../../components/dialog/KLDialog';
import KLEditor from '../../../components/editor/KLEditor';
import { clearOverrideModal, fireNotification, setScreenshotModal } from '../../../components/layout/actions/layout.actions';
import Notification from '../../../components/notification/Notification';
import LinkRouter from '../../../components/router-link/RouterLink';
import ScreenshotModal from '../../../components/screenshotModal/ScreenshotModal';
import KLSecurity from '../../../components/security/Security';
import { SECURITY_ACTIONS } from '../../../components/security/Security.constants';
import { getApplications, getDeviceCapabilitiesRank, getReportApplications, getReportCategoriesGrouped } from '../../../utils/report.utils';
import { validateProps, validateRoute } from '../../../utils/routeUtils';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { canEdit } from '../../../utils/user.utils';
import { USER_ROLE } from '../../../constants/user.constants';
import REPORT_CONSTANTS from '../../reports/report.constants';
import { setCapabilityRankByCategoryUnweighted } from '../../scoring-ranking/action/ScoringRanking.actions';
import { clearContentBuilding, setContentBuilding, setProductRating } from '../action/ContentBuilding.actions';
import ContentBuildingSave from '../common/SaveContentBuilding';
import { PRODUCT_RATING_CONSTANTS } from './ProductRating.constants';
import ProductRatingStyles from './ProductRating.styles';

class KLProductRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buyAction: false,
      application: 'Mobile Web',
      appSelected: null,
      appsReport: null,
      editKeySuccess: false,
      editBestClassFeatures: false,
      showReviewCapabilityScoring: false,
      allCapabilities: []
    };
  }

  componentDidMount() {
    const { push, fireNotification, dbReport, isScoringRankingCompleted } = this.props;
    const { application } = this.state;

    validateProps(push, fireNotification, dbReport);
    validateRoute(push, dbReport.status !== REPORT_CONSTANTS.STATUS.DRAFT.STATUS || isScoringRankingCompleted);

    setScreenshotModal({
      open: false,
      allowEmpty: true,
      onComplete: null
    });
    this.props.clearOverrideModal();

    if (!this.props.contentBuilding._id) {
      this.props
        .getContentBuilding({
          query: {
            reportId: this.props.dbReport._id,
            $limit: 1
          }
        })
        .then(({ value }) => {
          value[0] ? this.props.setContentBuilding(value[0]) : this.props.clearContentBuilding();
        });
    }

    Scroll.animateScroll.scrollToTop({
      smooth: 'easeOutQuint',
      duration: 1000,
      delay: 300
    });

    const apps = getApplications(dbReport);
    const appSelected = apps.find(a => a.title === application);
    this.setState({ appsReport: apps, appSelected });
    this.buildDeviceCapabilityRanks();

    // Find capabilities for ckeditor screenshots.-
    this.props
      .getCapabilityReports({
        query: {
          report: dbReport._id,
          $limit: 'infinity'
        }
      })
      .then(data => {
        this.setState({
          allCapabilities: { [dbReport._id]: data.value }
        });
      });

    this.bicInput$ = new Subject();
    this.bicInput$.pipe(debounceTime(500)).subscribe(content => {
      this.handleEditorContentBestInClassFeatures(content);
    });

    this.ksfInput$ = new Subject();
    this.ksfInput$.pipe(debounceTime(500)).subscribe(content => {
      this.handleEditorContentKeySuccessFactor(content);
    });
  }

  buildDeviceCapabilityRanks = () => {
    const { dbReport, scoringAndRanking, isPreview } = this.props;

    if (!isPreview) {
      this.props.setCapabilityRankByCategoryUnweighted(
        getDeviceCapabilitiesRank(dbReport, scoringAndRanking.scoringAndRanking, getReportApplications(dbReport))
      );
    }
  };

  setApplication = application => {
    const { appsReport } = this.state;
    const appSelected = appsReport.find(a => a.title === application);
    this.setState({
      application,
      appSelected,
      editKeySuccess: false,
      editBestClassFeatures: false
    });
  };

  handleEditorContentKeySuccessFactor = content => {
    const { appSelected } = this.state;
    const { productRating, setProductRating } = this.props;

    const productRatingCopy = Object.assign({}, productRating);
    if (productRatingCopy[appSelected.id]) {
      productRatingCopy[appSelected.id].keySuccessFactor = content;
    } else {
      productRatingCopy[appSelected.id] = {
        keySuccessFactor: content
      };
    }
    setProductRating(productRatingCopy);
  };

  handleEditorContentBestInClassFeatures = content => {
    const { appSelected } = this.state;
    const { productRating, setProductRating } = this.props;
    const productRatingCopy = Object.assign({}, productRating);

    if (productRatingCopy[appSelected.id]) {
      productRatingCopy[appSelected.id].bestInClassFeatures = content;
    } else {
      productRatingCopy[appSelected.id] = {
        applicationId: appSelected.id,
        bestInClassFeatures: content
      };
    }

    setProductRating(productRatingCopy);
  };

  render() {
    const { classes, user, dbReport, isPreview, selectedIndustry, productRating, orderedOverallRanks, capabilityRankByCategoryUnweighted } =
      this.props;
    const { buyAction, application, appsReport, editKeySuccess, editBestClassFeatures, appSelected, showReviewCapabilityScoring, allCapabilities } =
      this.state;

    let keySuccessFactor = '';
    let bestInClassFeatures = '';
    if (appSelected && !isPreview) {
      keySuccessFactor =
        productRating && productRating[appSelected.id] && productRating[appSelected.id].keySuccessFactor
          ? productRating[appSelected.id].keySuccessFactor
          : PRODUCT_RATING_CONSTANTS.KEY_SUCCESS_FACTORS;

      bestInClassFeatures =
        productRating && productRating[appSelected.id] && productRating[appSelected.id].bestInClassFeatures
          ? productRating[appSelected.id].bestInClassFeatures
          : PRODUCT_RATING_CONSTANTS.BEST_IN_CLASS_FEATURES;
    }

    let keySuccessFactorView = PRODUCT_RATING_CONSTANTS.KEY_SUCCESS_FACTORS;
    let bestInClassFeaturesView = PRODUCT_RATING_CONSTANTS.BEST_IN_CLASS_FEATURES;
    if (appSelected && !this.props.contentBuildingService.isLoading) {
      const dbProductRating =
        (this.props.contentBuildingService.data && this.props.contentBuildingService.data.productRating) ||
        (this.props.contentBuildingService.queryResult &&
          this.props.contentBuildingService.queryResult[0] &&
          this.props.contentBuildingService.queryResult[0].productRating);
      if (dbProductRating && dbProductRating[appSelected.id]) {
        keySuccessFactorView = dbProductRating[appSelected.id].keySuccessFactor || PRODUCT_RATING_CONSTANTS.KEY_SUCCESS_FACTORS;
        bestInClassFeaturesView = dbProductRating[appSelected.id].bestInClassFeatures || PRODUCT_RATING_CONSTANTS.BEST_IN_CLASS_FEATURES;
      }
    }

    return (
      <Box className={classes.centerContainer}>
        <Grid container item direction="column" className={classes.container} lg={10} xl={8}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/report" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                {user.loginType !== USER_ROLE.CUSTOMER ? 'Home' : 'Report'}
              </Typography>
            </LinkRouter>
            <LinkRouter to={canEdit(user, dbReport) ? '/report/report-publish-date' : '/content-building'} color="secondary" underline="none">
              {dbReport && (
                <Typography color="secondary" variant="body2">
                  {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
                </Typography>
              )}
            </LinkRouter>
            {user.loginType !== USER_ROLE.CUSTOMER && (
              <LinkRouter to="/content-building" color="secondary" underline="none">
                <Typography color="secondary" variant="body2">
                  Content Building
                </Typography>
              </LinkRouter>
            )}
            <Typography variant="body2">Product Rating</Typography>
          </Breadcrumbs>
          <Box mt={5} mb={4}>
            <Typography variant="h4">Product Rating</Typography>
          </Box>

          <Grid item lg={12} className={classes.borderBottom}>
            <Box>
              <Tabs value={application} onChange={({ target }) => this.setApplication(target.textContent)}>
                {appsReport &&
                  appsReport.map((application, i) => {
                    return <Tab key={i} label={application.title} value={application.title} />;
                  })}
              </Tabs>
            </Box>
          </Grid>
          {!isPreview ? (
            <Fragment>
              <Box className={classes.box}>
                <Typography className={classes.title}>{application}</Typography>
              </Box>
              <Box className={classes.box}>
                <Typography className={classes.title}>Contents</Typography>
                <Link to="bp" duration={1000} smooth="easeOutQuint">
                  <Typography color="secondary" variant="body2" className={classes.link}>
                    Brand Performance
                  </Typography>
                </Link>
                <Link to="ksf" duration={1000} smooth="easeOutQuint">
                  <Typography color="secondary" variant="body2" className={classes.link}>
                    Key Success Factors
                  </Typography>
                </Link>
                <Link to="bicf" duration={1000} smooth="easeOutQuint">
                  <Typography color="secondary" variant="body2" className={classes.link}>
                    Best-in-Class Features
                  </Typography>
                </Link>
              </Box>
              <Box className={classes.box} id="bp">
                <Typography className={classes.title}>Brand Performance</Typography>
                <Box mb={3}>
                  <Typography variant="body2" color="textSecondary">
                    The capabilities assessment is created by reviewing the availability of feature coverage. For more details about how we conduct
                    our analysis&nbsp;
                    <LinkRouter to="/appendix/methodology" color="secondary" underline="none">
                      read about our methodology.
                    </LinkRouter>
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Paper elevation={0} className={classes.table} style={{ width: '80%' }}>
                    <Table size="medium" aria-label="a dense table">
                      <TableHead>
                        <TableRow className={classes.row}>
                          <TableCell align="center" className={classes.tableHeader}>
                            Rank
                          </TableCell>
                          <TableCell align="left" className={classes.tableHeader}>
                            Brand
                          </TableCell>
                          <TableCell align="center" className={classes.tableHeader}>
                            {application + ' Score'}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderedOverallRanks &&
                          orderedOverallRanks.overall &&
                          orderedOverallRanks.overall.map((score, index) => {
                            return (
                              <TableRow key={index} className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}>
                                <TableCell align="center">
                                  <Typography className={classes.tableText} color="textSecondary">
                                    #{index + 1}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.tableText} color="textSecondary">
                                    {orderedOverallRanks[application][index].company}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography className={classes.tableText} color="textSecondary">
                                    {Math.round(orderedOverallRanks[application][index].overallScore * 100) + '%'}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Paper>
                </Box>
              </Box>
              <Box className={classes.box} id="ksf">
                <Typography className={classes.title}>
                  {`Key Success Factors: ${orderedOverallRanks && orderedOverallRanks.overall ? orderedOverallRanks[application][0].company : ''}`}
                  <KLSecurity action={SECURITY_ACTIONS.EDIT}>
                    <IconButton
                      disableRipple
                      size="small"
                      disabled={editBestClassFeatures || editKeySuccess}
                      onClick={() => this.setState({ editKeySuccess: !editKeySuccess })}
                    >
                      <Edit
                        className={clsx(editBestClassFeatures || editKeySuccess ? classes.disabled : '')}
                        style={{ marginLeft: 5, cursor: 'pointer' }}
                        color={'secondary'}
                        fontSize={'small'}
                      />
                    </IconButton>
                  </KLSecurity>
                </Typography>
                {editKeySuccess && (
                  <Collapse in={editKeySuccess}>
                    <KLEditor content={keySuccessFactor} setContent={c => this.ksfInput$.next(c)} />

                    <Typography
                      color="secondary"
                      variant="body1"
                      className={classes.reviewCapabilityScoring}
                      onClick={() => this.setState({ showReviewCapabilityScoring: true })}
                    >
                      {`Review Capability Scoring:  ${
                        orderedOverallRanks && orderedOverallRanks.overall ? orderedOverallRanks[application][0].company : ''
                      }`}
                    </Typography>
                    {productRating[appSelected.id] && (
                      <ContentBuildingSave
                        params={{
                          $set: {
                            [`productRating.${appSelected.id}.keySuccessFactor`]: productRating[appSelected.id].keySuccessFactor
                          },
                          loadedAt: this.props.contentBuilding.loadedAt
                        }}
                        onSave={() => this.setState({ editKeySuccess: !editKeySuccess })}
                        onCancel={() => {
                          const newPR = JSON.parse(JSON.stringify(productRating));
                          newPR[appSelected.id].keySuccessFactor = keySuccessFactorView;
                          this.props.setProductRating(newPR);
                          this.setState({ editKeySuccess: !editKeySuccess });
                        }}
                      />
                    )}
                  </Collapse>
                )}
                {!editKeySuccess && appSelected && (
                  <Typography
                    name="ckEditorView"
                    variant="body2"
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: keySuccessFactorView
                    }}
                  />
                )}

                <KLDialog
                  isOpen={showReviewCapabilityScoring}
                  onHandleClose={() => {
                    this.setState({
                      showReviewCapabilityScoring: !showReviewCapabilityScoring
                    });
                  }}
                  title={'Capability Ranking by Category (Unweighted)'}
                  maxWidth="lg"
                  body={
                    <Paper elevation={0} className={classes.table}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow className={classes.row}>
                            <TableCell align="left">Category</TableCell>
                            {dbReport.companies.map((company, i) => {
                              return (
                                <TableCell key={i} align="center">
                                  {company.title}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {capabilityRankByCategoryUnweighted &&
                            capabilityRankByCategoryUnweighted[application] &&
                            capabilityRankByCategoryUnweighted[application].map((row, index) => {
                              return (
                                <TableRow key={index} className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}>
                                  <TableCell align="left">{row.categoryName}</TableCell>
                                  {dbReport.companies.map((company, i) => {
                                    return (
                                      <TableCell key={i} align="center">
                                        {Math.round(row[company.title] * 100) + '%'}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </Paper>
                  }
                  showButtonConfirm={false}
                  showButtonClose={false}
                  dividers={true}
                />
              </Box>
              <Box className={classes.box} id="bicf">
                <Typography className={classes.title}>
                  Best-in-Class Features
                  <KLSecurity action={SECURITY_ACTIONS.EDIT}>
                    <IconButton
                      size="small"
                      disabled={editBestClassFeatures || editKeySuccess}
                      onClick={() =>
                        this.setState({
                          editBestClassFeatures: !editBestClassFeatures
                        })
                      }
                    >
                      <Edit
                        className={clsx(editBestClassFeatures || editKeySuccess ? classes.disabled : '')}
                        style={{ marginLeft: 5, cursor: 'pointer' }}
                        color={'secondary'}
                        fontSize={'small'}
                      />
                    </IconButton>
                  </KLSecurity>
                </Typography>
                {editBestClassFeatures && (
                  <Collapse in={editBestClassFeatures}>
                    <KLEditor content={bestInClassFeatures} setContent={c => this.bicInput$.next(c)} />
                    {productRating[appSelected.id] && (
                      <ContentBuildingSave
                        params={{
                          $set: {
                            [`productRating.${appSelected.id}.bestInClassFeatures`]: productRating[appSelected.id].bestInClassFeatures
                          },
                          loadedAt: this.props.contentBuilding.loadedAt
                        }}
                        onSave={() =>
                          this.setState({
                            editBestClassFeatures: !editBestClassFeatures
                          })
                        }
                        onCancel={() => {
                          const newPR = JSON.parse(JSON.stringify(productRating));
                          newPR[appSelected.id].bestInClassFeatures = bestInClassFeaturesView;
                          this.props.setProductRating(newPR);
                          this.setState({
                            editBestClassFeatures: !editBestClassFeatures
                          });
                        }}
                      />
                    )}
                  </Collapse>
                )}
                {!editBestClassFeatures && appSelected && (
                  <Typography
                    name="ckEditorView"
                    variant="body2"
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: bestInClassFeaturesView
                    }}
                  />
                )}
              </Box>
              <Box className={classes.box} id="bicf">
                <Typography className={classes.title}>Capability Categorization</Typography>
                <Box mt={3}>
                  <Grid container>
                    {getReportCategoriesGrouped(dbReport).map(c => {
                      return (
                        <Grid item xs={6} key={c.categoryGroup}>
                          <LinkRouter to={`/content-building/category-performance&category${c.categoryGroup}`} color="secondary" underline="none">
                            <ListItemText
                              primary={c.categoryGroup}
                              primaryTypographyProps={{
                                variant: 'body2'
                              }}
                            />
                          </LinkRouter>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
            </Fragment>
          ) : (
            <Box mt={3}>
              <div className={classes.buyMessage}>
                <Typography variant="h5">Buy report to see this information.</Typography>
                &nbsp;
                <Button color="secondary" variant="contained" disableElevation>
                  <Typography
                    variant="overline"
                    onClick={() => {
                      this.setState({ buyAction: true });
                      this.props.buyReport({ rid: dbReport._id });
                    }}
                  >
                    Buy Report
                  </Typography>
                </Button>
                <Notification
                  message={'An email has been sent to Sales Department, we will contact you soon.'}
                  open={buyAction}
                  variant="success"
                  duration={10000}
                  onClose={(event, reason) => {
                    if (reason === 'clickaway') {
                      return;
                    }
                    this.setState({ buyAction: false });
                  }}
                />
              </div>
            </Box>
          )}
        </Grid>

        {allCapabilities && Boolean(Object.keys(allCapabilities).length) && (
          <ScreenshotModal filters={true} allCapabilities={allCapabilities} selectedApp={appSelected.id} />
        )}
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    dbReport: state.currentReport.dbReport,
    isPreview: state.currentReport.isPreview,
    selectedIndustry: state.currentReport.selectedIndustry,
    productRating: state.contentBuilding.productRating,
    scoringAndRanking: state.scoringAndRanking.scoringAndRanking,
    orderedOverallRanks: state.scoringAndRanking.orderedOverallRanks,
    capabilityRankByCategoryUnweighted: state.scoringAndRanking.capabilityRankByCategoryUnweighted,
    isScoringRankingCompleted: state.currentReport.scoringRankingCompleted,
    contentBuildingService: state.services.contentBuilding,
    contentBuilding: state.contentBuilding
  };
};

const mapDispatchToProps = {
  buyReport: feathersServices['report-status'].create,
  getContentBuilding: feathersServices['content-building'].find,
  getCapabilityReports: feathersServices['capability-reports'].find,
  clearContentBuilding,
  setContentBuilding,
  setCapabilityRankByCategoryUnweighted,
  setProductRating,
  fireNotification,
  setScreenshotModal,
  clearOverrideModal,
  push
};

export default compose(withStyles(ProductRatingStyles), connect(mapStateToProps, mapDispatchToProps))(KLProductRating);
