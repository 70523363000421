import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';

const HeaderTable = props => {
  return (
    <TableHead>
      <TableRow className={props.classes ? props.classes.headerRow : ''}>
        {props.cells.map((cell, index) => (
          <TableCell key={index} className={props.classes ? props.classes.tableHeader : ''} align={cell.align || 'left'}>
            {cell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default HeaderTable;
