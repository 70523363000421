import { LIST_REPORT_FILTERS_PAGES, LIST_REPORT_PAGE_SELECT_REPORT, LIST_REPORT_PAGINATION_PAGE } from '../action/ReportList.actions';

const initialState = {
  selectedReport: null,
  rowsPerPage: 8,
  page: 0,
  filters: []
};

const listReportPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_REPORT_PAGE_SELECT_REPORT:
      return Object.assign({}, state, {
        selectedReport: action.payload
      });
    case LIST_REPORT_PAGINATION_PAGE:
      return Object.assign({}, state, {
        page: action.payload
      });

    case LIST_REPORT_FILTERS_PAGES:
      const newState = Object.assign({}, state);
      const filtersUi = action.payload;
      //delete before filter
      newState.filters = newState.filters.filter(ele => ele.type !== filtersUi.type);
      if (action.payload._id) {
        newState.filters.push(action.payload);
      }
      newState.page = 0;
      return newState;

    default:
      return state;
  }
};

export default listReportPageReducer;
