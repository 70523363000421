import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { CircularProgress, Grid, withStyles } from '@material-ui/core';
import feathersServices from '../../../services/feathersServices';
import KLDialog from '../../../components/dialog/KLDialog';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import ReportExportStyles from './ReportExport.styles';

const exportHeaders = [
  { label: 'Report Date', key: 'reportDate' },
  { label: 'Report Status', key: 'reportStatus' },
  { label: 'Report Last Updated by', key: 'reportLastUpdatedBy' },
  { label: 'Company Name', key: 'companyName' },
  { label: 'Category Name', key: 'categoryName' },
  { label: 'Application', key: 'application' },
  { label: 'Capability Title', key: 'capabilityTitle' },
  { label: 'Capability Change', key: 'capabilityChange' },
  { label: 'Capability Status', key: 'capabilityStatus' },
  { label: 'Capability Change Status', key: 'capabilityChangeStatus' }
];

class ReportExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exportData: [],
      exportHeaders,
      isExportedRun: false
    };
  }

  componentDidUpdate(nextProps) {
    if (JSON.stringify(this.props.report) !== JSON.stringify(nextProps.report)) {
      this.getData();
    }
  }

  setExportedRun = () => {
    this.setState(state => {
      return { isExportedRun: !state.isExportedRun };
    });
  };

  getData = async () => {
    const { report, onClose } = this.props;
    const output = [];
    this.setExportedRun();
    const {
      value: { data: capabilityChanges }
    } = await this.props.getCapabilityChanges();

    const { value: capabilityReports } = await this.props.getCapabilityReports({
      query: {
        report: report._id,
        $limit: 'infinity',
        $populate: true
      }
    });

    capabilityReports.forEach(cr => {
      const capChange = capabilityChanges.find(c => c._id === cr.capabilityChange);
      output.push({
        reportDate: SingletonMomentUtils.moment(report.date).format('MMMM YYYY'),
        reportStatus: report.status,
        reportLastUpdatedBy: report.lastUpdateBy.name,
        companyName: cr.company.title,
        categoryName: cr.category.title,
        application: cr.application.title,
        capabilityTitle: cr.capability.title,
        capabilityChange: capChange ? capChange.title : '',
        capabilityStatus: cr.status,
        capabilityChangeStatus: cr.hasCapability ? 'Yes' : 'No'
      });
    });

    this.setState(
      {
        exportData: output,
        fileName: `${SingletonMomentUtils.moment(report.date).format('MMMM YYYY')}-${report.industry.title}`
      },
      () => {
        this.csvLink.link.click();
        this.setExportedRun();
        if (onClose) {
          onClose();
        }
      }
    );
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const { exportData, fileName, isExportedRun } = this.state;
    const { report, classes } = this.props;
    return (
      <div>
        <CSVLink ref={r => (this.csvLink = r)} data={exportData} headers={this.state.headers} filename={`${fileName}.csv`} />
        {isExportedRun && (
          <KLDialog
            isOpen={isExportedRun}
            title={''}
            showButtonClose={false}
            showButtonConfirm={false}
            body={
              <Grid container>
                <Grid item xs={12} justifyContent="center" className={classes.flex}>
                  <CircularProgress color="secondary" size={25} thickness={3} />
                </Grid>
                <Grid item xs={12} justifyContent="center">
                  {`Exporting CSV report for ${report.industry.title} - ${SingletonMomentUtils.moment(report.date).format('MMMM YYYY')}`}
                </Grid>
              </Grid>
            }
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  getCapabilityChanges: feathersServices['capability-changes'].find,
  getCapabilityReports: feathersServices['capability-reports'].find
};

const mapStateToProps = state => {
  return {
    capabilityChanges: state.services.capabilityChanges
  };
};

export default compose(withStyles(ReportExportStyles), connect(mapStateToProps, mapDispatchToProps))(ReportExport);
