export default theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  media: {
    padding: 5,
    width: '95%',
    cursor: 'pointer',
    minHeight: 250,
    '& svg': {
      color: theme.palette.secondary.main
    },
    '& img': {
      borderRadius: 5,
      width: 'auto !important',
      height: '12rem !important',
      position: 'unset !important',
      maxWidth: 'calc(100%) !important'
    },
    '& div': {
      padding: 'calc(1%) !important',
      textAlign: 'center'
    }
  },
  noMedia: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 263
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  dialog: {
    minWidth: '97vw'
  },
  options: {
    height: 30
  },
  optionLabel: {
    fontSize: '0.65rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 245
  },
  optionLabelSelected: {
    fontSize: '0.75rem',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 245
  },
  checkbox: {
    color: '#555555a6'
  },
  selectLabels: {
    fontSize: '0.75rem',
    cursor: 'pointer',
    margin: '0 10px'
  }
});
