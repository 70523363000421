export default theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    maxWidth: '23rem',
    marginTop: '6em',
    padding: '1rem',
    border: '1px solid  #0000001f'
  },
  hint: {
    marginTop: '0.3em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500]
  },
  margin: {
    margin: theme.spacing(1)
  },
  formIcon: {
    width: 'auto',
    height: '4rem'
  }
});
