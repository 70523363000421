export const NEW_REPORT_SELECT_APPLICATION = 'NEW_REPORT_SELECT_APPLICATION';
export const NEW_REPORT_SELECT_INDUSTRY = 'NEW_REPORT_SELECT_INDUSTRY';
export const NEW_REPORT_SELECT_COMPANY = 'NEW_REPORT_SELECT_COMPANY';
export const NEW_REPORT_SELECT_CATEGORY = 'NEW_REPORT_SELECT_CATEGORY';
export const NEW_REPORT_SELECT_DATE = 'NEW_REPORT_SELECT_DATE';
export const NEW_REPORT_SELECT_DB_REPORT = 'NEW_REPORT_SELECT_DB_REPORT';
export const NEW_REPORT_CLEAN_SELECT_DB_REPORT = 'NEW_REPORT_CLEAN_SELECT_DB_REPORT';

export const NEW_REPORT_SET_CAPABILITY_REPORTS = 'NEW_REPORT_SET_CAPABILITY_REPORTS';
export const NEW_REPORT_CAPABILITY_REPORT_UPDATE = 'NEW_REPORT_CAPABILITY_REPORT_UPDATE';
export const NEW_REPORT_GENERATE_REPORT_CAPABILITIES = 'NEW_REPORT_GENERATE_REPORT_CAPABILITIES';
export const NEW_REPORT_SELECT_CAPABILITY_REPORT = 'NEW_REPORT_SELECT_CAPABILITY_REPORT';
export const NEW_REPORT_ADD_FILE = 'NEW_REPORT_ADD_FILE';
export const NEW_REPORT_CLEAR_FILES = 'NEW_REPORT_CLEAR_FILES';
export const NEW_REPORT_CLEAR_CAPABILITY_REPORT = 'NEW_REPORT_CLEAR_CAPABILITY_REPORT';
export const NEW_REPORT_PREVIOUS = 'NEW_REPORT_PREVIOUS';
export const NEW_REPORT_MARK_IMAGE_FOR_DELETE = 'NEW_REPORT_MARK_IMAGE_FOR_DELETE';
export const NEW_REPORT_CLEAR_MARKED_IMAGES_FOR_DELETE = 'NEW_REPORT_CLEAR_MARKED_IMAGES_FOR_DELETE';
export const NEW_REPORT_REMOVE_FILE = 'NEW_REPORT_REMOVE_FILE';
export const NEW_REPORT_CONSUMER_SURVEY_COMPLETED = 'NEW_REPORT_CONSUMER_SURVEY_COMPLETED';
export const NEW_REPORT_SCORING_RANKING_COMPLETED = 'NEW_REPORT_SCORING_RANKING_COMPLETED';
export const NEW_REPORT_CONTENT_BUILDING_COMPLETED = 'NEW_REPORT_CONTENT_BUILDING_COMPLETED';
export const SET_REPORT_PREVIEW = 'SET_REPORT_PREVIEW';
export const SET_COMPLETE_MAP = 'SET_COMPLETE_MAP';
export const SET_PREVIOUS_CAPABILITY_IMAGES = 'SET_PREVIOUS_CAPABILITY_IMAGES';
export const CLEAR_PREVIOUS_CAPABILITY_IMAGES = 'CLEAR_PREVIOUS_CAPABILITY_IMAGES';

export function selectApplication(application) {
  return {
    type: NEW_REPORT_SELECT_APPLICATION,
    payload: application
  };
}

export function selectIndustry(industry) {
  return {
    type: NEW_REPORT_SELECT_INDUSTRY,
    payload: industry
  };
}

export function selectCompany(company) {
  return {
    type: NEW_REPORT_SELECT_COMPANY,
    payload: company
  };
}

export function selectCategory(category) {
  return {
    type: NEW_REPORT_SELECT_CATEGORY,
    payload: category
  };
}

export function selectDate(date, usedDates) {
  return {
    type: NEW_REPORT_SELECT_DATE,
    payload: { date, usedDates }
  };
}

export function setCapabilityReports(capabilityReport) {
  return {
    type: NEW_REPORT_SET_CAPABILITY_REPORTS,
    payload: capabilityReport
  };
}

export function generateReportCapabilities(allCapabilities, reportCapabilities) {
  return {
    type: NEW_REPORT_GENERATE_REPORT_CAPABILITIES,
    payload: {
      allCapabilities,
      reportCapabilities
    }
  };
}

export function selectCapabilityReport(capabilityReport, index) {
  return {
    type: NEW_REPORT_SELECT_CAPABILITY_REPORT,
    payload: { ...capabilityReport, index }
  };
}

export function selectDbReport(report) {
  return {
    type: NEW_REPORT_SELECT_DB_REPORT,
    payload: report
  };
}

export function previousReport(previousReport) {
  return {
    type: NEW_REPORT_PREVIOUS,
    payload: previousReport
  };
}

export function addFile(file) {
  return {
    type: NEW_REPORT_ADD_FILE,
    payload: file
  };
}

export function removeFile(index) {
  return {
    type: NEW_REPORT_REMOVE_FILE,
    payload: index
  };
}

export function clearFiles() {
  return {
    type: NEW_REPORT_CLEAR_FILES
  };
}

export function clearCapabilityReport() {
  return {
    type: NEW_REPORT_CLEAR_CAPABILITY_REPORT
  };
}

export function markImageForDelete(image) {
  return {
    type: NEW_REPORT_MARK_IMAGE_FOR_DELETE,
    payload: {
      image
    }
  };
}

export function setConsumerSurveyCompleted(completed) {
  return {
    type: NEW_REPORT_CONSUMER_SURVEY_COMPLETED,
    payload: {
      completed
    }
  };
}

export function setScoringRankingCompleted(completed) {
  return {
    type: NEW_REPORT_SCORING_RANKING_COMPLETED,
    payload: {
      completed
    }
  };
}

export function setContentBuildingCompleted(completed) {
  return {
    type: NEW_REPORT_CONTENT_BUILDING_COMPLETED,
    payload: {
      completed
    }
  };
}

export function clearMarkedImagesForDelete() {
  return {
    type: NEW_REPORT_CLEAR_MARKED_IMAGES_FOR_DELETE
  };
}

export function clearSelectDbReport() {
  return {
    type: NEW_REPORT_CLEAN_SELECT_DB_REPORT
  };
}

export function setPreview(preview) {
  return {
    type: SET_REPORT_PREVIEW,
    payload: preview
  };
}

export function setPreviousCapabilityImages(payload) {
  return {
    type: SET_PREVIOUS_CAPABILITY_IMAGES,
    payload: payload
  };
}

export function clearPreviousCapabilityImages() {
  return {
    type: CLEAR_PREVIOUS_CAPABILITY_IMAGES
  };
}

export function setCompleteMap(payload) {
  return {
    type: SET_COMPLETE_MAP,
    payload
  };
}
