import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import feathersServices from '../../../services/feathersServices';
import { setOverrideModal } from '../../../components/layout/actions/layout.actions';
import LoadingIndicator from '../../../components/loadingIndicator';
import { setContentBuilding } from '../action/ContentBuilding.actions';

class ContentBuildingSave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false
    };
  }

  saveOrUpdate = () => {
    const { contentBuilding, update, setContentBuilding, params, query, setOverrideModal } = this.props;

    const original = JSON.parse(JSON.stringify(contentBuilding.originalContentBuilding));
    const current = JSON.parse(JSON.stringify(contentBuilding));
    delete current.loadedAt;
    delete current.originalContentBuilding;
    delete current.categoryRankByDevice;

    if (JSON.stringify(original) !== JSON.stringify(current)) {
      update(contentBuilding._id, params, query)
        .then(({ value }) => setContentBuilding(value))
        .catch(e => {
          if (e.code === 409)
            setOverrideModal({
              open: true,
              params: params,
              query: query,
              actualContentBuilding: e.data.actualContentBuilding
            });
        })
        .finally(() => {
          this.setState({ isSaving: false });
          this.props.onSave();
        });
    } else {
      this.setState({ isSaving: false });
      this.props.onCancel();
    }
  };

  render() {
    const { onCancel } = this.props;
    return (
      <Box display="flex" alignItems="center" justifyContent="flex-end" style={{ float: 'right' }}>
        <Box m={1}>
          <Button size="small" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
        <Box m={1}>
          {this.state.isSaving ? (
            <LoadingIndicator width={20} color="secondary" isLoading={true} thickness={3} />
          ) : (
            <Button
              size="small"
              onClick={() => {
                this.setState({ isSaving: true });
                setTimeout(this.saveOrUpdate, 500); // debounce 500ms which is the default debounce time of the debounceTime operator
              }}
              color="secondary"
              variant="contained"
              disableElevation
            >
              Save
            </Button>
          )}
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    contentBuilding: state.contentBuilding
  };
};

const mapDispatchToProps = {
  update: feathersServices['content-building'].patch,
  setContentBuilding,
  setOverrideModal
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContentBuildingSave);
