import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import React, { Component } from 'react';
import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
import { Bold, Italic, Strikethrough, Underline } from '@ckeditor/ckeditor5-basic-styles';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { Context } from '@ckeditor/ckeditor5-core';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Font } from '@ckeditor/ckeditor5-font';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Image, ImageResize, ImageStyle, ImageToolbar, ImageUpload } from '@ckeditor/ckeditor5-image';
import { Link } from '@ckeditor/ckeditor5-link';
import { List } from '@ckeditor/ckeditor5-list';
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Table, TableToolbar } from '@ckeditor/ckeditor5-table';
import { ButtonView } from '@ckeditor/ckeditor5-ui';
import { Base64UploadAdapter } from '@ckeditor/ckeditor5-upload';
import { store } from '../../setup/store';
import { withStyles } from '@material-ui/core';
import { LAYOUT_SET_SCREENSHOT_MODAL } from '../layout/actions/layout.actions';
import KLEditorStyles from './KLEditor.styles';

class InsertImage extends Plugin {
  init() {
    const editor = this.editor;
    editor.ui.componentFactory.add('insertImageScreenshot', locale => {
      const view = new ButtonView(locale);
      view.set({
        label: 'Insert screenshot',
        icon: imageIcon,
        tooltip: true
      });
      // Callback executed once the image is clicked.
      view.on('execute', () => {
        store.dispatch({
          type: LAYOUT_SET_SCREENSHOT_MODAL,
          payload: {
            open: true,
            allowEmpty: false,
            onComplete: imageUrl => {
              const imageElement = this.writer.createElement('imageBlock', {
                src: imageUrl,
                width: '200px'
              });

              editor.model.insertContent(imageElement, editor.model.document.selection);
            }
          }
        });
        editor.model.change(writer => {
          this.writer = writer;
        });
      });
      return view;
    });
  }
}

class KLEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }
  handleChangeData = (event, editor) => {
    const data = editor.getData();
    this.props.setContent(data);
  };

  render() {
    const { content } = this.props;

    return (
      <div className="App">
        <CKEditorContext context={Context}>
          <CKEditor
            editor={ClassicEditor}
            config={{
              plugins: [
                Essentials,
                Underline,
                Strikethrough,
                Font,
                Autoformat,
                UploadAdapter,
                Bold,
                Italic,
                BlockQuote,
                MediaEmbed,
                Table,
                TableToolbar,
                Heading,
                Image,
                Base64UploadAdapter,
                ImageStyle,
                ImageToolbar,
                ImageUpload,
                Link,
                List,
                Paragraph,
                Alignment,
                ImageResize,
                InsertImage
              ],
              toolbar: {
                items: [
                  'heading',
                  '|',
                  'fontSize',
                  // "fontFamily",
                  'fontColor',
                  'fontBackgroundColor',
                  'alignment',
                  'bold',
                  'italic',
                  'underline',
                  'strikethrough',
                  '|',
                  'insertTable',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'imageUpload',
                  'mediaEmbed',
                  'blockQuote',
                  'undo',
                  'redo',
                  'insertImageScreenshot'
                ]
              },
              image: {
                toolbar: ['imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight', '|', 'imageTextAlternative'],
                styles: ['alignCenter', 'alignLeft', 'alignRight'],
                resizeUnit: 'px'
              },
              table: {
                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
              },
              // This value must be kept in sync with the language defined in webpack.config.js.
              language: 'en'
            }}
            data={content}
            onChange={this.handleChangeData}
          />
        </CKEditorContext>
      </div>
    );
  }
}

export default withStyles(KLEditorStyles)(KLEditor);
