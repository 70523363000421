import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, withStyles } from '@material-ui/core';
import LoadingIndicatorStyles from './LoadingIndicator.styles';

const LoadingIndicator = ({ width, classes, className, isLoading, children, align, thickness, color, ...rest }) => {
  return (
    isLoading && (
      <div className={classes[align]}>
        {children}
        <CircularProgress className={classes.loader} color={color} size={width} thickness={thickness} {...rest} />
      </div>
    )
  );
};

LoadingIndicator.propTypes = {
  align: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  width: PropTypes.number,
  children: PropTypes.node,
  thickness: PropTypes.number,
  color: PropTypes.string
};
LoadingIndicator.defaultProps = {
  width: 20,
  thickness: 5,
  align: 'center',
  color: 'inherit',
  children: null
};

export default withStyles(LoadingIndicatorStyles)(LoadingIndicator);
