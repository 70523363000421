import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, reset } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { Button, CircularProgress, Grid, Switch, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import feathersServices from '../../../../services/feathersServices';
import Notification from '../../../../components/notification/Notification';
import RenderCheckbox from '../../../../components/renders/RenderCheckbox';
import RenderTextField from '../../../../components/renders/RenderTextField';
import { required, validEmail } from '../../../../utils/validation';
import { setCompanyCustomerSelected } from '../../action/CompaniesCustomertList.actions';
import UserCompanyFormStyles from './UserCompanyForm.styles';

const FORM_ID = 'userManagementForm';
const defaultNotification = { open: false, msg: ' ' };

let UserCompanyForm = ({
  classes,
  user,
  companyCustomerSelected,
  valid,
  userCompanyForm,
  onCallBack,
  reset,
  userCompanyCreateService,
  userCompanyUpdateService,
  setCompanyCustomerSelected,
  userCompanySelected,
  getKliApplications,
  kliApplications,
  selectedApplications
}) => {
  const [openError, setOpenError] = useState(defaultNotification);
  const [onSubmitForm, setOnSubmitForm] = useState(false);
  const [checkedApplications, setCheckedApplications] = useState(selectedApplications);

  useEffect(() => {
    getKliApplications({
      query: {
        name: 'INSIGHTS'
      }
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    setOnSubmitForm(true);

    if (!userCompanyForm.values._id) {
      userCompanyCreateService({
        ...userCompanyForm.values,
        applications: checkedApplications,
        url: window.location.origin
      }).then(
        resp => {
          setCompanyCustomerSelected(resp.value);
          reset(FORM_ID);
          if (onCallBack) {
            onCallBack();
          }
          setOnSubmitForm(false);
        },
        error => {
          if (error.code === 406) {
            setOpenError({
              open: !openError.open,
              msg: 'Email exist, please select other'
            });
          } else {
            setOpenError({
              open: !openError.open,
              msg: error.message
            });
          }
          setOnSubmitForm(false);
        }
      );
    } else {
      userCompanyUpdateService(userCompanyForm.values._id, {
        ...userCompanyForm.values,
        applications: checkedApplications,
        url: window.location.origin
      }).then(
        ({ value }) => {
          setCompanyCustomerSelected(value);
          reset(FORM_ID);
          if (onCallBack) {
            onCallBack();
          }
          setOnSubmitForm(false);
        },
        error => {
          setOpenError({
            open: !openError.open,
            msg: error.message
          });
          setOnSubmitForm(false);
        }
      );
    }
  };

  const updateApplication = (appId, checked) => {
    if (checked) {
      setCheckedApplications([...checkedApplications, appId]);
    } else {
      setCheckedApplications(checkedApplications.filter(cA => cA !== appId));
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <Grid container className={classes.header}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.headerTitle}>
            {userCompanySelected ? 'Update user' : 'Create user'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.fieldsGroup}>
        <Grid item xs={12}>
          <Field
            color="secondary"
            validate={[required, validEmail]}
            name="email"
            label="Email *"
            component={RenderTextField}
            fullWidth
            variant="outlined"
            disabled={userCompanySelected ? true : false}
          />
        </Grid>

        <Grid item xs={12}>
          <Field color="secondary" validate={[required]} name="name" label="First Name *" component={RenderTextField} fullWidth variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <Field
            color="secondary"
            validate={[required]}
            name="lastName"
            label="Last Name *"
            component={RenderTextField}
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <Field color="secondary" name="phone" label="Phone" component={RenderTextField} fullWidth variant="outlined" />
        </Grid>

        <Grid item xs={12}>
          <Field color="secondary" name="title" label="Title" component={RenderTextField} fullWidth variant="outlined" />
        </Grid>

        <Grid item xs={12}>
          <Box mb={3}>
            <FormControl component="fieldset" className={classes.applicationFieldset}>
              <FormGroup key="primary">
                <Field color="secondary" label="Primary Contact Person " name="isPrimary" component={RenderCheckbox} />
              </FormGroup>
              <FormGroup key="billing">
                <Field color="secondary" label="Billing Contact Person" name="isBilling" component={RenderCheckbox} />
              </FormGroup>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.applicationFieldset}>
            <FormLabel component="legend" filled color="secondary">
              Assign applications
            </FormLabel>

            {!kliApplications.isLoading &&
              kliApplications.queryResult &&
              kliApplications.queryResult.data.map(app => (
                <FormGroup key={app._id}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checkedApplications.findIndex(a => a === app._id) !== -1}
                        disabled={app.name === 'INSIGHTS' && user._id === companyCustomerSelected._id}
                        onChange={(e, checked) => updateApplication(app._id, checked)}
                        name={app.cannonicalName}
                        inputProps={{
                          'aria-label': 'secondary checkbox'
                        }}
                      />
                    }
                    labelPlacement="end"
                    label={app.cannonicalName}
                  />
                </FormGroup>
              ))}
          </FormControl>
        </Grid>
      </Grid>

      <Box className={classes.cancelButton}>
        <div>
          <Button onClick={onCallBack}>Cancel</Button>
        </div>
        &nbsp;&nbsp;
        <div>
          <Button onClick={handleSubmit} color="secondary" variant="contained" disabled={!valid || onSubmitForm} disableElevation>
            {onSubmitForm && <CircularProgress color="secondary" size={25} thickness={3} />}
            {userCompanySelected ? 'Update' : 'Create'}
          </Button>
        </div>
      </Box>
      <Notification message={openError.msg} open={openError.open} variant="error" onClose={() => setOpenError({ defaultNotification })} />
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  const companyCustomerSelected = state.ui.listCompaniesCustomer.companyCustomerSelected;

  let initValues = {
    companyId: companyCustomerSelected ? companyCustomerSelected._id : null,
    email: null,
    password: '',
    name: '',
    lastName: '',
    phone: '',
    title: null,
    isBilling: false,
    isPrimary: false,
    _id: null
  };

  const userCompanySelected = state.ui.listCompaniesCustomer.userCompanySelected;

  if (userCompanySelected) {
    initValues = Object.assign(initValues, userCompanySelected);
    initValues.isBilling = userCompanySelected._id === companyCustomerSelected.billingUser;
    initValues.isPrimary = userCompanySelected._id === companyCustomerSelected.primaryUser;
    delete initValues.password;
  }

  return {
    user: state.auth.user,
    initialValues: initValues,
    userCompanyForm: state.form[FORM_ID],
    companyCustomerSelected,
    userCompanySelected: userCompanySelected,
    kliApplications: state.services.kliApplications,
    selectedApplications: userCompanySelected ? userCompanySelected.applications.reduce((apps, app) => apps.concat(app), []) : []
  };
};

const mapDispatchToProps = {
  userCompanyCreateService: feathersServices['user-company'].create,
  userCompanyUpdateService: feathersServices['user-company'].update,
  setCompanyCustomerSelected: setCompanyCustomerSelected,
  getKliApplications: feathersServices['kli-applications'].find,
  reset
};

UserCompanyForm = compose(
  withStyles(UserCompanyFormStyles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(
    {
      form: FORM_ID,
      enableReinitialize: true,
      validate: (values, props) => {
        const errors = {};
        return errors;
      }
    },
    mapStateToProps
  )
)(UserCompanyForm);

export default UserCompanyForm;
