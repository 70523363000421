export default theme => ({
  root: {
    border: '1px solid ' + theme.palette.divider,
    borderTop: '4px solid ' + theme.palette.secondary.main,
    marginBottom: '0.5rem'
  },
  companyTitle: {
    color: theme.palette.secondary.main
  }
});
