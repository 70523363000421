export const NEW_REPORT_TOGGLE_CAPABILITY_REPORT_DRAWER = 'NEW_REPORT_TOGGLE_CAPABILITY_REPORT_DRAWER';
export const NEW_REPORT_CAPABILITY_REPORT_CHANGES_WARNING = 'NEW_REPORT_CAPABILITY_REPORT_CHANGES_WARNING';
export const NEW_REPORT_CAPABILITY_REPORT_CHANGES_WARNING_CLOSE = 'NEW_REPORT_CAPABILITY_REPORT_CHANGES_WARNING_CLOSE';
export const NEW_REPORT_CAPABILITY_REPORT_CHANGES_SAVING = 'NEW_REPORT_CAPABILITY_REPORT_CHANGES_SAVING';
export const NEW_REPORT_CAPABILITY_REPORT_CHANGES_SAVING_DONE = 'NEW_REPORT_CAPABILITY_REPORT_CHANGES_SAVING_DONE';

export function toggleCapabilityReportDrawer() {
  return {
    type: NEW_REPORT_TOGGLE_CAPABILITY_REPORT_DRAWER,
    payload: null
  };
}

export function openCapabilityReportWarning() {
  return {
    type: NEW_REPORT_CAPABILITY_REPORT_CHANGES_WARNING,
    payload: null
  };
}

export function closeCapabilityReportWarning() {
  return {
    type: NEW_REPORT_CAPABILITY_REPORT_CHANGES_WARNING_CLOSE,
    payload: null
  };
}

export function savingCapabilityReport() {
  return {
    type: NEW_REPORT_CAPABILITY_REPORT_CHANGES_SAVING,
    payload: null
  };
}

export function savingCapabilityReportDone() {
  return {
    type: NEW_REPORT_CAPABILITY_REPORT_CHANGES_SAVING_DONE,
    payload: null
  };
}
