import React, { Component, Fragment } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Box, Card, CircularProgress, createTheme, Grid, MuiThemeProvider, Typography, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import feathersServices from '../../../services/feathersServices';
import AppBarLogin from '../../layout/appBar/components/AppBarLogin';
import { required } from '../../../utils/validation';
import customThemes from '../../customTheme';
import { setForgotLinkExpired, setForgotPasswordSuccess } from '../../layout/actions/layout.actions';
import LoadingIndicator from '../../loadingIndicator/LoadingIndicator';
import RenderTooltipPasswordField from '../../renders/RenderTooltipPasswordField';
import ForgotPasswordStyles from './ForgotPassword.styles';
import KLIPasswordStrength from './KLIPasswordStrength';

class KLForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      loading: true,
      helpMessage: '',
      helpSuggestions: []
    };
  }

  async componentDidMount() {
    const { param } = this.props.match.params;
    this.props.setForgotPasswordSuccess(false);
    const data = JSON.parse(atob(param));

    const { value: isValid } = await this.props.checkToken(param);
    if (!isValid) {
      this.props.setForgotLinkExpired(true);
    } else {
      const currentDate = new Date();
      if (currentDate.getTime() > parseInt(data.expiration)) {
        this.props.setForgotLinkExpired(true);
      } else {
        this.props.setForgotLinkExpired(false);
      }
      this.setState({
        uid: data.userId
      });
    }
    this.setState({ loading: false });
  }

  handleChange = target => {
    this.setState(
      {
        [target.name]: target.value
      },
      () => {
        this.setState({
          passwordMatch: this.state.password1 !== undefined && this.state.password1 === this.state.password2
        });
      }
    );
  };

  handleUpdate = () => {
    const { application, redirectUrl } = this.props.match.params;
    const { uid } = this.state;
    const { forgotPassReturnForm } = this.props;
    this.setState({ updating: true });

    this.props
      .updateCustomerPassword(uid, {
        id: uid,
        password: forgotPassReturnForm.values.password1,
        url: decodeURIComponent(redirectUrl),
        application
      })
      .then(response => {
        this.setState({ updating: false }, () => {
          this.props.setForgotPasswordSuccess(true);
          window.location = decodeURIComponent(redirectUrl);
        });
      });
  };

  render() {
    const { classes, valid, forgotLinkExpired, forgotPasswordSuccess, forgotPassReturnForm } = this.props;
    const { updating, loading, helpMessage, helpSuggestions } = this.state;
    const { application, redirectUrl } = this.props.match.params;
    return (
      <MuiThemeProvider theme={createTheme(customThemes)}>
        <Fragment>
          <AppBarLogin application={application} redirectTo={decodeURIComponent(redirectUrl)} />
          <LoadingIndicator isLoading={loading} width={50} color="secondary" style={{ marginTop: 100 }} />
          {!loading && !forgotLinkExpired && !forgotPasswordSuccess && (
            <div className={classes.main}>
              <Card className={classes.card} elevation={0}>
                <Grid container justifyContent="center">
                  <img className={classes.formIcon} src={application === 'CJM' ? '/cjm_logo.png' : '/logo.png'} alt="Keylime Labs logo" />
                </Grid>
                <Box m={3}>
                  <Typography className={classes.hint}>Enter a new password</Typography>
                </Box>
                <form onSubmit={() => this.handleUpdate()} className={classes.root}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Field
                        color="secondary"
                        name="password1"
                        label="New Password *"
                        validate={[required]}
                        component={RenderTooltipPasswordField}
                        open={helpMessage !== ''}
                        props={{ meta: { touched: true, invalid: helpMessage !== '', error: helpMessage } }}
                        title={
                          <ul>
                            {helpSuggestions?.map(s => (
                              <li>
                                <Typography variant="body2">{s}</Typography>
                              </li>
                            ))}
                          </ul>
                        }
                        fullWidth
                        variant="outlined"
                      />
                      {forgotPassReturnForm.values.password1 !== '' && (
                        <Box mt={1}>
                          <KLIPasswordStrength
                            password={forgotPassReturnForm.values.password1}
                            onScoreChange={(helpMessage, helpSuggestions) => {
                              this.setState({
                                helpMessage,
                                helpSuggestions
                              });
                            }}
                          />
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        color="secondary"
                        name="password2"
                        label="Confirm New Password *"
                        validate={[required]}
                        component={RenderTooltipPasswordField}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>

                    <Button
                      onClick={() => this.handleUpdate()}
                      color="secondary"
                      variant="outlined"
                      disabled={!valid || helpMessage !== ''}
                      fullWidth
                    >
                      {updating ? <CircularProgress color="secondary" size={25} thickness={3} /> : 'Update'}
                    </Button>
                  </Grid>
                </form>
              </Card>
            </div>
          )}
          {!loading && forgotLinkExpired && (
            <div className={classes.main}>
              <Card className={classes.card} elevation={0}>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <Typography>Your login token is invalid. It is expired or has already been used.</Typography>
                  &nbsp;
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      this.props.setForgotLinkExpired(false);
                      window.location = decodeURIComponent(redirectUrl);
                    }}
                  >
                    Login
                  </Button>
                </Box>
              </Card>
            </div>
          )}
        </Fragment>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  let initValues = {
    password1: '',
    password2: ''
  };

  return {
    initialValues: initValues,
    forgotPassReturnForm: state.form.forgotPassReturnForm,
    forgotLinkExpired: state.ui.layout.forgotLinkExpired,
    forgotPasswordSuccess: state.ui.layout.forgotPasswordSuccess
  };
};

const mapDispatchToProps = {
  setForgotLinkExpired,
  setForgotPasswordSuccess,
  updateCustomerPassword: feathersServices['forgot-password'].update,
  checkToken: feathersServices['forgot-password'].get,
  push
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(ForgotPasswordStyles),
  reduxForm(
    {
      form: 'forgotPassReturnForm',
      enableReinitialize: true,
      validate: (values, props) => {
        const errors = {};
        if (values.password1 !== values.password2) {
          errors.password2 = 'Passwords should match';
        }

        return errors;
      }
    },
    mapStateToProps
  )
)(KLForgotPassword);
