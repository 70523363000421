import React, { Component } from 'react';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import feathersClient from './setup/feathersClient';
import { history, persistor, store } from './setup/store';
import * as serviceWorker from './serviceWorker';
import App from './App';
import './index.scss';

class Root extends Component {
  async componentDidMount() {
    try {
      console.info(`%c${String.fromCodePoint(0x1f44b)} CI version: ${process.env.BRANCH}`, 'color: #008080');
      await feathersClient.reAuthenticate();
    } catch (e) {
      console.info(`[${e.name}] ${e.message}`);
    }
  }
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={'loading..'} persistor={persistor}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    );
  }
}
const root = createRoot(document.getElementById('root'));
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
