export const CATEGORY_PERFORMANCE_CONSTANTS = {
  TOP_PERFORMERS:
    '<p>When we combine the scores from both the mobile site and mobile app categories the top 3 performing auto insurance providers are:</p><ol><li>Company G</li><li>Company U</li><li>Company S</li></ol><p><br></p><p>Company G remains in the #1 spot across all categories, and continues to rank #1 overall for the 8th consecutive installment.</p><p>Company U remains solidly in 2nd place overall</p><p>Company S maintains 3rd place overall for the 3rd consecutive installment</p>',
  CHART_OPTIONS: {
    tooltip: {
      trigger: 'axis',
      islandFormatter: '{value}%',
      textStyle: {
        color: '#000000'
      },
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
      }
    },
    legend: {
      itemGap: 10,
      itemWidth: 30,
      itemHeight: 30,
      right: 0,
      padding: 0,
      top: 50,
      orient: 'vertical'
    },
    toolbox: {
      show: true,
      language: 'en',
      feature: {
        mark: { show: true },
        magicType: {
          show: true,
          type: ['line', 'bar']
        },
        saveAsImage: { show: true, title: 'Save As Image' }
      }
    },
    color: ['#171614', '#7fb069', '#0081af', '#e06d06', '#ffc759', '#7a3b69', '#728f93', '#dc7f9b', '#856a5d'],
    grid: {
      show: false,
      containLabel: true,
      left: 20,
      right: 20,
      top: 40,
      bottom: 0,
      height: '77%',
      width: '80%'
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: true
      }
    ],
    yAxis: [
      {
        type: 'value',
        min: function (value) {
          let minValue = value.min;
          if (minValue.toFixed(0) >= 5) {
            minValue = minValue - 5;
          } else if (minValue.toFixed(0) < 5) {
            minValue -= minValue;
          }
          return minValue.toFixed(0);
        },
        axisLabel: {
          formatter: '{value}%'
        }
      }
    ]
  }
};
