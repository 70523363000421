export default theme => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    backgroundColor: '#d00000',
    color: 'white'
  },
  content: {
    textAlign: 'center'
  },
  link: {
    color: 'white'
  },
  close: {
    color: 'white'
  },
  moreInfo: {
    fontSize: 20,
    cursor: 'pointer',
    transform: 'rotate(-90deg)',
    transition: 'all 500ms ease 0s',
    '&:hover': {
      transform: 'rotate(90deg)'
    }
  }
});
