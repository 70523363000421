import React, { Component, Fragment } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Box, Breadcrumbs, ButtonBase, Grid, Grow, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import feathersServices from '../../services/feathersServices';
import { fireNotification, toggleCategory, toggleSideMenu } from '../../components/layout/actions/layout.actions';
import LinkRouter from '../../components/router-link/RouterLink';
import { isCategoryPerformanceCompleted, isExecutiveSummaryCompleted, isProductRatingCompleted, isTrendsCompleted } from '../../utils/report.utils';
import { validateProps, validateRoute } from '../../utils/routeUtils';
import SingletonMomentUtils from '../../utils/SingletonMomentUtils';
import { canEdit } from '../../utils/user.utils';
import { USER_ROLE } from '../../constants/user.constants';
import { CategoryPerformanceIcon, ExecutiveSummaryIcon, MethodologyIcon, RawDataIcon, ScoringIcon, SurveyIcon, TrendsIcon } from '../../images';
import ScreenshotsIcon from '../../images/ScreenshotsIcon';
import { setConsumerSurvey } from '../consumer-survey/action/ConsumerSurvey.actions';
import { setConsumerSurveyCompleted, setContentBuildingCompleted, setScoringRankingCompleted } from '../reports/actions/reports.actions';
import REPORT_CONSTANTS from '../reports/report.constants';
import { setOrderedOverallRanks, setScoringAndRanking } from '../scoring-ranking/action/ScoringRanking.actions';
import { clearContentBuilding, setContentBuilding } from './action/ContentBuilding.actions';
import ContentBuildingStyles from './ContentBuilding.styles';

class KLContentBuilding extends Component {
  componentDidMount() {
    const { push, fireNotification, dbReport, isScoringRankingCompleted } = this.props;
    validateProps(push, fireNotification, dbReport);
    validateRoute(push, dbReport.status !== REPORT_CONSTANTS.STATUS.DRAFT.STATUS || isScoringRankingCompleted);

    if (this.props.dbReport) {
      if (
        this.props.dbReport.status === REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS ||
        this.props.dbReport.status === REPORT_CONSTANTS.STATUS.PUBLISHED_EDITING.STATUS ||
        this.props.dbReport.status === REPORT_CONSTANTS.STATUS.PUBLISHED_EDITED.STATUS
      ) {
        this.props.setConsumerSurveyCompleted(true);
        this.props.setScoringRankingCompleted(true);
        this.props.setContentBuildingCompleted(true);
      }
      this.props.onToggleSideMenu(true);
      this.props
        .getContentBuilding({
          query: {
            reportId: this.props.dbReport._id,
            $limit: 1
          }
        })
        .then(({ value }) => {
          value[0] ? this.props.setContentBuilding(value[0]) : this.props.clearContentBuilding();

          // If the user goes directly to content building he will need the consumer survey and scoring ranking store with data.
          if (!this.props.isPreview) {
            this.initConsumerSurveyData();
          }
          this.initScoringRankingData();
        });
    }
  }

  initConsumerSurveyData() {
    this.props
      .getConsumerSurvey({
        query: {
          reportId: this.props.dbReport._id,
          $limit: 1
        }
      })
      .then(({ value }) => {
        if (value) {
          this.props.setConsumerSurvey(value[0]);
        }
      });
  }

  initScoringRankingData() {
    this.props
      .getScoringAndRanking({
        query: {
          reportId: this.props.dbReport._id,
          $limit: 1
        }
      })
      .then(({ value }) => {
        if (value && value.data.length > 0) {
          const { scoringAndRanking } = value.data[0];
          let ranks = { overall: [] };
          for (let company in scoringAndRanking) {
            ranks.overall.push({
              company: scoringAndRanking[company].companyName,
              overallScore: scoringAndRanking[company].overallPercentage
            });
            for (let application in scoringAndRanking[company].applications) {
              if (!ranks[application]) ranks[application] = [];

              ranks[application].push({
                company: scoringAndRanking[company].companyName,
                overallScore: scoringAndRanking[company].applications[application].unweightedDevicePercentage
              });
            }
          }
          for (let key in ranks) {
            ranks[key] = this.orderBy(ranks[key], 'overallScore');
          }
          this.props.setOrderedOverallRanks(ranks);
          this.props.setScoringAndRanking(value.data[0]);
        }
      });
  }

  orderBy(data, value) {
    return data.sort((a, b) => {
      if (a[value] > b[value]) {
        return -1;
      }
      if (a[value] < b[value]) {
        return 1;
      }
      return 0;
    });
  }

  render() {
    const { user, dbReport, selectedIndustry, classes, toggleCategory, contentBuilding } = this.props;

    const esCompleted = isExecutiveSummaryCompleted(contentBuilding);
    const prCompleted = isProductRatingCompleted(dbReport, contentBuilding);
    const cpCompleted = isCategoryPerformanceCompleted(dbReport, contentBuilding);
    const tsCompleted = isTrendsCompleted(contentBuilding);
    return (
      <Fragment>
        {user.loginType === USER_ROLE.CUSTOMER ? (
          <Box mb={5} className={classes.centerContainer}>
            <Grid container item direction="column" className={classes.container} lg={10} xl={8}>
              <Breadcrumbs separator="›">
                <LinkRouter to="/report" color="secondary" underline="none">
                  <Typography color="secondary" variant="body2">
                    Reports
                  </Typography>
                </LinkRouter>
                <Typography variant="body2">
                  {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
                </Typography>
                )}
              </Breadcrumbs>
              <Box mt={5} mb={4}>
                <Typography variant="h4">{`${selectedIndustry.title} Mobile Competitive Index`}</Typography>
              </Box>
              <Box mt={2}>
                <Typography
                  variant="body2"
                  style={{
                    color: '#555',
                    fontWeight: 'bold'
                  }}
                >
                  {`${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {`Welcome to Key Lime Interactive’s competitive analysis of
                  mobile experiences within the ${selectedIndustry.title} industry for ${SingletonMomentUtils.moment(dbReport.date).format(
                    'MMMM YYYY'
                  )}. Here, we measure the capabilities and usability of a number
                  of similar mobile products to determine an overall rating for
                  each.`}
                </Typography>
              </Box>
              <Box mt={3} mb={2}>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: 400,
                    color: '#212121'
                  }}
                >
                  Contents
                </Typography>
              </Box>
              <Grid container item lg={10} xl={8} spacing={3}>
                <Grid item lg={4}>
                  <ButtonBase className={classes.alignLeft}>
                    <Link to={'/content-building/executive-summary'} style={{ textDecoration: 'none' }}>
                      <ExecutiveSummaryIcon width="2em" height="2em" fill={'#008444'} />
                      <Typography variant="body2" className={classes.title1}>
                        Executive Summary
                      </Typography>
                      <Typography variant="caption" className={classes.subtitle1}>
                        Key takeaways and a high-level overview of our findings
                      </Typography>
                    </Link>
                  </ButtonBase>
                </Grid>
                <Grid item lg={4}>
                  <ButtonBase className={classes.alignLeft}>
                    <Link to={'/content-building/consumer-survey'} style={{ textDecoration: 'none' }}>
                      <SurveyIcon width="2em" height="2em" fill={'#008444'} />
                      <Typography variant="body2" className={classes.title1}>
                        Consumer Survey
                      </Typography>
                      <Typography variant="caption" className={classes.subtitle1}>
                        The results of our survey and how it informed our analysis
                      </Typography>
                    </Link>
                  </ButtonBase>
                </Grid>
                <Grid item lg={4}>
                  <ButtonBase className={classes.alignLeft}>
                    <Link to={'/content-building/product-rating'} style={{ textDecoration: 'none' }}>
                      <ScoringIcon width="2em" height="2em" fill={'#008444'} />
                      <Typography variant="body2" className={classes.title1}>
                        Product Rating
                      </Typography>
                      <Typography variant="caption" className={classes.subtitle1}>
                        Overall rating of each product’s performance
                      </Typography>
                    </Link>
                  </ButtonBase>
                </Grid>
                <Grid item lg={4}>
                  <ButtonBase className={classes.alignLeft}>
                    <Link to={'/content-building/trends-innovations'} style={{ textDecoration: 'none' }}>
                      <TrendsIcon width="2em" height="2em" fill={'#008444'} />
                      <Typography variant="body2" className={classes.title1}>
                        Trends & Innovations
                      </Typography>
                      <Typography variant="caption" className={classes.subtitle1}>
                        Summary analysis on where the industry is headed
                      </Typography>
                    </Link>
                  </ButtonBase>
                </Grid>
                <Grid item lg={4}>
                  <ButtonBase className={classes.alignLeft}>
                    <Link
                      onClick={() => {
                        toggleCategory(true);
                      }}
                      to="/content-building/category-performance"
                      style={{ textDecoration: 'none' }}
                    >
                      <CategoryPerformanceIcon width="2em" height="2em" fill={'#008444'} />
                      <Typography variant="body2" className={classes.title1}>
                        Category Performance
                      </Typography>
                      <Typography variant="caption" className={classes.subtitle1}>
                        Performance details for each respective category
                      </Typography>
                    </Link>
                  </ButtonBase>
                </Grid>
              </Grid>
              <Box mt={5} mb={2}>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: 400,
                    color: '#212121'
                  }}
                >
                  Appendix
                </Typography>
              </Box>
              <Grid container item lg={10} xl={8} spacing={3}>
                <Grid item lg={4}>
                  <ButtonBase className={classes.alignLeft}>
                    <Link to={'/appendix/methodology'} style={{ textDecoration: 'none' }}>
                      <MethodologyIcon width="2em" height="2em" fill={'#008444'} />
                      <Typography variant="body2" className={classes.title1}>
                        Methodology
                      </Typography>
                      <Typography variant="caption" className={classes.subtitle1}>
                        How we conducted our analysis
                      </Typography>
                    </Link>
                  </ButtonBase>
                </Grid>
                <Grid item lg={4}>
                  <ButtonBase className={classes.alignLeft}>
                    <Link to={'/appendix/raw-data'} style={{ textDecoration: 'none' }}>
                      <RawDataIcon width="2em" height="2em" fill={'#008444'} />
                      <Typography variant="body2" className={classes.title1}>
                        Raw Data
                      </Typography>
                      <Typography variant="caption" className={classes.subtitle1}>
                        A look at the data behind our findings
                      </Typography>
                    </Link>
                  </ButtonBase>
                </Grid>
                <Grid item lg={4}>
                  <ButtonBase className={classes.alignLeft}>
                    <Link to={'/appendix/screenshots'} style={{ textDecoration: 'none' }}>
                      <ScreenshotsIcon width="2em" height="2em" fill={'#008444'} />
                      <Typography variant="body2" className={classes.title1}>
                        Screenshots
                      </Typography>
                      <Typography variant="caption" className={classes.subtitle1}>
                        Report's screenshots with filters
                      </Typography>
                    </Link>
                  </ButtonBase>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box className={classes.centerContainer}>
            <Grid container item direction="column" className={classes.container} lg={10} xl={8}>
              <Breadcrumbs separator="›">
                <LinkRouter to="/report" color="secondary" underline="none">
                  <Typography color="secondary" variant="body2">
                    Home
                  </Typography>
                </LinkRouter>
                <LinkRouter to={canEdit(user, dbReport) ? '/report/report-publish-date' : '#'} color="secondary" underline="none">
                  {dbReport && (
                    <Typography color="secondary" variant="body2">
                      {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
                    </Typography>
                  )}
                </LinkRouter>
                <Typography variant="body2">Content Building</Typography>
              </Breadcrumbs>
              <Box mt={5} mb={4}>
                <Typography variant="h4">Content Building</Typography>
                &nbsp;
                <Typography variant="body2" color="textSecondary">
                  The Scoring & Rank is the performance of the mobile apps/sites, based on the data collected. The parenthesis represents a
                  significant change (>5%) of a particular app or site since the last report, with an arrow to represent an increase or decrease. If
                  there is a drastic change in overall or any of the platform scoring or ranking, check the data again for accuracy.
                </Typography>
                <Typography className={classes.mTop} variant="body2" color="textSecondary">
                  For the devices weight to the right, retrieve the iOS and Android percentage from &nbsp;
                  <a
                    className={classes.link}
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.comscore.com/Insights/Rankings#tab_mobile_smartphone_platforms/"
                  >
                    https://www.comscore.com/Insights/Rankings#tab_mobile_smartphone_platforms/,{' '}
                  </a>
                  and the Mobile Site percentage is calculated using the Consumer Survey question "how likely are you to use the mobile site vs. the
                  mobile app?" with the following formula (%site more + 1/2 * %about equal) / (1-%never access from mobile phone).
                </Typography>
              </Box>
            </Grid>
            <Box className={classes.borderBottom} />
            <Grid container item lg={10} xl={8} direction="column" className={classes.container}>
              <Box>
                <Typography variant="h6">Which section would you like to work on?</Typography>
              </Box>
              <Grid item container>
                <Grid item lg={4}>
                  <Grow in={true} timeout={300}>
                    <ButtonBase>
                      <Link to={'/content-building/executive-summary'} style={{ textDecoration: 'none' }}>
                        <Paper className={classes.paper} elevation={0}>
                          <div className={esCompleted ? classes.iconContainer : ''}>
                            <ExecutiveSummaryIcon width="4em" height="4em" />
                            {esCompleted && <Check color="secondary" className={classes.check} />}
                          </div>
                          <Typography variant="body2" className={classes.title}>
                            Executive Summary
                          </Typography>
                        </Paper>
                      </Link>
                    </ButtonBase>
                  </Grow>
                </Grid>
                <Grid item lg={4}>
                  <Grow in={true} timeout={600}>
                    <ButtonBase>
                      <Link to={'/content-building/consumer-survey'} style={{ textDecoration: 'none' }}>
                        <Paper className={classes.paper} elevation={0}>
                          <div className={classes.iconContainer}>
                            <SurveyIcon width="4em" height="4em" />
                            <Check color="secondary" className={classes.check} />
                          </div>
                          <Typography variant="body2" className={classes.title}>
                            Consumer Survey
                          </Typography>
                        </Paper>
                      </Link>
                    </ButtonBase>
                  </Grow>
                </Grid>
                <Grid item lg={4}>
                  <Grow in={true} timeout={900}>
                    <ButtonBase>
                      <Link to={'/content-building/product-rating'} style={{ textDecoration: 'none' }}>
                        <Paper className={classes.paper} elevation={0}>
                          <div className={prCompleted ? classes.iconContainer : ''}>
                            <ScoringIcon width="4em" height="4em" />
                            {prCompleted && <Check color="secondary" className={classes.check} />}
                          </div>
                          <Typography variant="body2" className={classes.title}>
                            Product Rating
                          </Typography>
                        </Paper>
                      </Link>
                    </ButtonBase>
                  </Grow>
                </Grid>
                <Grid item lg={4}>
                  <Grow in={true} timeout={1200}>
                    <ButtonBase>
                      <Link to={'/content-building/trends-innovations'} style={{ textDecoration: 'none' }}>
                        <Paper className={classes.paper} elevation={0}>
                          <div className={tsCompleted ? classes.iconContainer : ''}>
                            <TrendsIcon width="4em" height="4em" />
                            {tsCompleted && <Check color="secondary" className={classes.check} />}
                          </div>
                          <Typography variant="body2" className={classes.title}>
                            Trends & Innovations
                          </Typography>
                        </Paper>
                      </Link>
                    </ButtonBase>
                  </Grow>
                </Grid>
                <Grid item lg={4}>
                  <Grow in={true} timeout={1500}>
                    <ButtonBase>
                      <Link
                        onClick={() => {
                          toggleCategory(true);
                        }}
                        to="/content-building/category-performance"
                        style={{ textDecoration: 'none' }}
                      >
                        <Paper className={classes.paper} elevation={0}>
                          <div className={cpCompleted ? classes.iconContainer : ''}>
                            <CategoryPerformanceIcon width="4em" height="4em" />
                            {cpCompleted && <Check color="secondary" className={classes.check} />}
                          </div>
                          <Typography variant="body2" className={classes.title}>
                            Category Performance
                          </Typography>
                        </Paper>
                      </Link>
                    </ButtonBase>
                  </Grow>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Fragment>
    );
  }
}

// export default ConsumerSurvey;

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    dbReport: state.currentReport.dbReport,
    isPreview: state.currentReport.isPreview,
    contentBuilding: state.contentBuilding,
    selectedIndustry: state.currentReport.selectedIndustry,
    isScoringRankingCompleted: state.currentReport.scoringRankingCompleted
  };
};

const mapDispatchToProps = {
  onToggleSideMenu: toggleSideMenu,
  getContentBuilding: feathersServices['content-building'].find,
  getScoringAndRanking: feathersServices['scoring-ranking'].find,
  getConsumerSurvey: feathersServices['consumer-survey'].find,
  toggleCategory,
  setContentBuilding,
  setScoringAndRanking,
  setOrderedOverallRanks,
  setConsumerSurvey,
  clearContentBuilding,
  fireNotification,
  push,
  setConsumerSurveyCompleted,
  setScoringRankingCompleted,
  setContentBuildingCompleted
};

export default compose(withStyles(ContentBuildingStyles), connect(mapStateToProps, mapDispatchToProps))(KLContentBuilding);
