import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, reset } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import feathersServices from '../../../services/feathersServices';
import { required, validEmail } from '../../../utils/validation';
import Notification from '../../notification/Notification';
import RenderTextField from '../../renders/RenderTextField';
import ForgotPasswordStyles from './ForgotPasswordForm.styles';

const defaultNotification = { open: false, msg: ' ' };
const FORM_ID = 'forgotPasswordForm';

let ForgotPasswordForm = ({ classes, forgotPasswordForm, onCallBack, updateForgotPassword, reset, valid }) => {
  const [onSubmitState, setOnSubmitState] = useState(false);
  const [openError, setOpenError] = useState(defaultNotification);
  const [captchaValid, setCaptchaValid] = useState(false);

  const onCaptchaChange = v => {
    setCaptchaValid(true);
  };
  const handleSubmit = () => {
    setOnSubmitState(true);

    updateForgotPassword(
      Object.assign(forgotPasswordForm.values, {
        url: window.location.origin,
        application: 'INSIGHTS'
      })
    ).then(
      data => {
        setOnSubmitState(false);
        if (onCallBack) {
          onCallBack();
        }
        reset(FORM_ID);
      },
      error => {
        setOpenError({
          open: !openError.open,
          msg: error.message
        });
        setOnSubmitState(false);
      }
    );
  };

  return (
    <form onSubmit={() => handleSubmit()} className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography color="secondary" variant="body1" className={classes.title}>
            Enter your email address and we will send you a password reset link.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Field
            color="secondary"
            validate={[required, validEmail]}
            name="email"
            label="Email"
            component={RenderTextField}
            fullWidth
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Box mb={2}>
        <ReCAPTCHA sitekey={process.env.CAPTCHA_KEY} onChange={onCaptchaChange} />
      </Box>
      <Grid container justifyContent="flex-end">
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            onClick={() => handleSubmit()}
            color="secondary"
            variant="contained"
            disableElevation
            disabled={!valid || !captchaValid || onSubmitState}
            fullWidth
          >
            {onSubmitState && <CircularProgress color="secondary" size={25} thickness={3} />}
            Send email
          </Button>
        </Grid>
      </Grid>
      <Notification message={openError.msg} open={openError.open} variant="error" onClose={() => setOpenError({ defaultNotification })} />
    </form>
  );
};

const mapStateToProps = state => {
  let initValues = { email: '' };

  return {
    initialValues: initValues,
    forgotPasswordForm: state.form[FORM_ID]
  };
};

const mapDispatchToProps = {
  updateForgotPassword: feathersServices['forgot-password'].create,
  reset
};

ForgotPasswordForm = compose(
  withStyles(ForgotPasswordStyles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(
    {
      form: FORM_ID,
      enableReinitialize: true,
      validate: (values, props) => {
        const errors = {};
        return errors;
      }
    },
    mapStateToProps
  )
)(ForgotPasswordForm);

export default ForgotPasswordForm;
