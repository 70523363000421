import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { setUpcomingMaintenance } from '../../../modules/maintenance/actions/maintenance.actions';
import MaintenanceService from '../../../modules/maintenance/services/Maintenance.service';
import feathersAuthentication from '../../../services/AuthService';
import KLAppBar from '../appBar/components/AppBar';
import KLSideMenu from '../sideMenu/components/SideMenu';
import Notification from '../../notification/Notification';
import { closeNotification } from '../actions/layout.actions';
import LayoutStyles from './Layout.styles';

class KLLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: props.currentReport && props.currentReport.dbReport ? props.currentReport.dbReport._id !== undefined : false
    };
  }

  onNotificationClose = () => {
    this.props.closeNotification();
  };

  onLogout = () => {
    this.props.onLogout();
    this.props.setUpcomingMaintenance(null);
    localStorage.removeItem('dismiss-maintenance-insights');
    localStorage.removeItem('feathers-jws');
    localStorage.removeItem('user');
  };

  render() {
    const { classes, children, layout, maintenance, dismissedBanner } = this.props;

    return (
      <div
        className={classes.root}
        style={{
          marginTop: maintenance && !dismissedBanner ? MaintenanceService.getBannerHeight() : 0
        }}
      >
        {layout.showAppBar && <KLAppBar onLogout={this.onLogout} />}
        <KLSideMenu open={this.props.layout.sideMenu.isOpen} location={this.props.location.pathname} />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {children}
          <Notification
            message={layout.notification.message}
            open={layout.notification.notificationOpen}
            variant={layout.notification.variant}
            duration={layout.notification.duration}
            onClose={() => this.onNotificationClose()}
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    layout: state.ui.layout,
    maintenance: state.ui.maintenanceStore.maintenance,
    dismissedBanner: localStorage.getItem('dismiss-maintenance-insights')
  };
};

const mapDispatchToProps = {
  closeNotification,
  setUpcomingMaintenance,
  onLogout: feathersAuthentication.logout
};

export default compose(withStyles(LayoutStyles), connect(mapStateToProps, mapDispatchToProps))(KLLayout);
