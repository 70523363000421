import React, { Component, Fragment } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { default as Scroll } from 'react-scroll/modules';
import { compose } from 'redux';
import clsx from 'clsx';
import {
  Box,
  Breadcrumbs,
  Grid,
  MenuItem,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Check from '@material-ui/icons/Check';
import feathersServices from '../../../services/feathersServices';
import { fireNotification } from '../../../components/layout/actions/layout.actions';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import Notification from '../../../components/notification/Notification';
import LinkRouter from '../../../components/router-link/RouterLink';
import { groupBy } from '../../../utils/immutable-utils';
import { getApplications, getReportCategories } from '../../../utils/report.utils';
import { validateProps } from '../../../utils/routeUtils';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { canEdit } from '../../../utils/user.utils';
import { USER_ROLE } from '../../../constants/user.constants';
import STATUS from '../../reports/report.constants';
import AppendixRawDataStyles from './AppendixRawData.styles';

class AppendixRawData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allReports: [],
      applications: [],
      selectedApp: 0,
      selectedCategory: [],
      allCapabilities: null,
      buyAction: false
    };
  }

  async componentDidMount() {
    const { push, fireNotification, dbReport } = this.props;

    validateProps(push, fireNotification, dbReport);

    Scroll.animateScroll.scrollToTop({
      smooth: 'easeOutQuint',
      delay: 500,
      duration: 1000
    });

    const reportsFilter = await this.props.getPreviousReport({
      query: {
        industry: dbReport.industry._id,
        date: { $lt: dbReport.date },
        status: STATUS.STATUS.PUBLISHED.STATUS,
        $populate: true,
        $limit: 1,
        $sort: {
          date: -1
        }
      }
    });
    const reports = reportsFilter.value.data;
    reports.push(dbReport);

    const applications = getApplications(dbReport);
    this.setState({
      applications,
      allReports: reports
    });
    this.getCapabilitiesData(reports, applications);
  }

  async getCapabilitiesData(reports = this.state.allReports, applications = this.state.applications) {
    const { selectedCategory, selectedApp } = this.state;

    if (reports.length && applications[selectedApp]?.id) {
      const q = {
        query: {
          report: {
            $in: reports.reduce((ids, report) => {
              return ids.concat(report._id);
            }, [])
          },
          application: applications[selectedApp]?.id,
          $populate: true,
          $limit: 'infinity'
        }
      };

      if (selectedCategory.length !== 0 && selectedCategory.length !== getReportCategories(this.props.dbReport).length) {
        q.query.category = {
          $in: selectedCategory.reduce((ids, category) => {
            return ids.concat(category.categoryId);
          }, [])
        };
      }
      const { value } = await this.props.getCapabilityReports(q);
      this.setState({ allCapabilities: groupBy(value, 'report') });
    }
  }

  getCapabilityByCompany(capabilities, company) {
    return capabilities.find(c => c.company?._id === company.companyId);
  }

  getCapabilityStyle(capability) {
    const { dbReport, classes } = this.props;
    const { allCapabilities } = this.state;

    const oldReportCapabilities = Object.keys(allCapabilities).find(c => c !== dbReport._id);
    let oldCap;
    if (allCapabilities && allCapabilities[oldReportCapabilities]) {
      oldCap = allCapabilities[oldReportCapabilities].find(
        oc =>
          capability &&
          oc.capability?._id === capability.capability?._id &&
          oc.category?._id === capability.category._id &&
          oc.company?._id === capability.company._id &&
          oc.application?._id === capability.application._id
      );
    }

    return oldCap && capability.hasCapability !== oldCap.hasCapability ? classes.changedCapability : '';
  }

  buildCapabilitiesData(capabilities) {
    const { dbReport } = this.props;
    const { allCapabilities } = this.state;

    let categoriesCap = [];
    if (allCapabilities) {
      const sortedCapabilities = allCapabilities[dbReport._id].sort((a, b) => a.category.title.localeCompare(b.category.title));

      sortedCapabilities.forEach(cap => {
        if (!categoriesCap[cap.category.title]) {
          categoriesCap[cap.category.title] = [cap];
        } else {
          categoriesCap[cap.category.title].push(cap);
        }
      });

      Object.keys(categoriesCap).forEach(cat => {
        const sortedCapabilities = categoriesCap[cat].sort((a, b) => a.capability.title.localeCompare(b.capability.title));

        const catCap = sortedCapabilities.reduce((caps, cap) => {
          if (!caps[cap.capability._id]) caps[cap.capability._id] = []; //If this type wasn't previously stored
          caps[cap.capability._id].push(cap);
          return caps;
        }, []);
        capabilities[cat] = catCap;
      });
    }
  }

  handleChangeCategory(value) {
    const { selectedCategory } = this.state;

    let values = [];
    if (!value.find(c => c === 'All')) {
      values = selectedCategory;
      if (selectedCategory.find(c => c.categoryId === value[value.length - 1].categoryId)) {
        values = selectedCategory.filter(c => c.categoryId !== value[value.length - 1].categoryId);
      } else {
        values = value;
      }
    }

    this.setState(
      {
        selectedCategory: values
      },
      () => {
        this.getCapabilitiesData();
      }
    );
  }

  render() {
    const { dbReport, classes, user, isPreview, selectedIndustry, reportsService, capabilityReportService } = this.props;
    const { selectedApp, applications, allCapabilities, selectedCategory, buyAction } = this.state;

    const capabilities = [];
    this.buildCapabilitiesData(capabilities);

    return (
      <Box className={classes.centerContainer}>
        <Grid container item direction="column" className={classes.container} lg={10} xl={8}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/report" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                {user.loginType !== USER_ROLE.CUSTOMER ? 'Home' : 'Report'}
              </Typography>
            </LinkRouter>
            <LinkRouter to={canEdit(user) ? '/report/report-publish-date' : '/content-building'} color="secondary" underline="none">
              {dbReport && (
                <Typography color="secondary" variant="body2">
                  {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
                </Typography>
              )}
            </LinkRouter>
            <Typography variant="body2">Raw Data</Typography>
          </Breadcrumbs>
          <Box mt={5} mb={4}>
            <Typography variant="h4">Raw Data</Typography>
          </Box>
          {isPreview ? (
            <Box>
              <div className={classes.buyMessage}>
                <Typography variant="h5">Buy report to see this information.</Typography>
                &nbsp;
                <Button color="secondary" variant="contained" disableElevation>
                  <Typography
                    variant="overline"
                    onClick={() => {
                      this.setState({ buyAction: true });
                      this.props.buyReport({ rid: dbReport._id });
                    }}
                  >
                    Buy Report
                  </Typography>
                </Button>
                <Notification
                  message={'An email has been sent to Sales Department, we will contact you soon.'}
                  open={buyAction}
                  variant="success"
                  duration={10000}
                  onClose={(event, reason) => {
                    if (reason === 'clickaway') {
                      return;
                    }
                    this.setState({ buyAction: false });
                  }}
                />
              </div>
            </Box>
          ) : (
            <Fragment>
              <Grid item lg={12} className={classes.borderBottom}>
                <Box>
                  <Tabs
                    value={selectedApp}
                    onChange={(event, index) => {
                      this.setState({ selectedApp: index }, () => {
                        this.getCapabilitiesData();
                      });
                    }}
                  >
                    {applications.map((application, i) => {
                      return <Tab key={application + '_' + i} label={application.title} value={i} />;
                    })}
                  </Tabs>
                </Box>
              </Grid>
              <Fragment>
                <Box className={classes.box}>
                  <Typography className={classes.title}>{applications[selectedApp] ? applications[selectedApp].title : ''}</Typography>
                </Box>
                <Box className={classes.box}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="categorySelect"
                      select
                      variant="outlined"
                      color="secondary"
                      margin="dense"
                      value={selectedCategory}
                      SelectProps={{
                        multiple: true,
                        displayEmpty: true,
                        renderValue: els => {
                          return els.map(el => el.title).join(', ');
                        },
                        style: {
                          backgroundColor: 'white'
                        }
                      }}
                      onChange={({ target }) => {
                        this.handleChangeCategory(target.value);
                      }}
                      label="Filter by Category"
                    >
                      <MenuItem key="all" value="All" dense>
                        <Checkbox
                          size="small"
                          checked={selectedCategory.length === 0 || selectedCategory.length === getReportCategories(dbReport).length}
                        />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {getReportCategories(dbReport).map(category => (
                        <MenuItem key={category.categoryId} value={category} dense>
                          <Checkbox size="small" checked={selectedCategory.find(c => c.categoryId === category.categoryId) !== undefined} />
                          <ListItemText primary={category.title} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Box>
                <Box className={classes.box}>
                  {!allCapabilities && (reportsService.isLoading || capabilityReportService.isLoading) ? (
                    <LoadingIndicator isLoading={true} width={40} color="secondary" />
                  ) : (
                    <Paper elevation={0} className={classes.table}>
                      <Table size="medium" aria-label="a dense table">
                        <TableHead>
                          <TableRow className={classes.row}>
                            <TableCell align="center" className={classes.tableHeader}>
                              Capability
                            </TableCell>
                            {dbReport.companies.map((company, index) => {
                              return (
                                <TableCell className={classes.tableHeader} key={company + '_' + index} align="center">
                                  {company.title}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.keys(capabilities).map((category, index) => {
                            if (selectedCategory.length === 0 || selectedCategory.filter(c => c.title === category).length > 0) {
                              return (
                                <Fragment key={category + '_' + index}>
                                  <TableRow className={classes.categoryRow}>
                                    <TableCell colSpan={dbReport.companies.length + 1}>
                                      <Typography className={classes.categoryName} variant="body2" color="textSecondary">
                                        Category: {category}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  {Object.keys(capabilities[category]).map((capability, index) => {
                                    return (
                                      <TableRow key={capability + '_' + index} className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}>
                                        <TableCell>
                                          <Typography className={classes.tableText} color="textSecondary">
                                            {capabilities[category][capability][0].capability.title}
                                          </Typography>
                                        </TableCell>
                                        {dbReport.companies.map((company, index) => {
                                          return capabilityReportService.isLoading ? (
                                            <TableCell key={`loading_${index}`}>
                                              <LoadingIndicator isLoading={true} color="secondary" width={20} thickness={3} />
                                            </TableCell>
                                          ) : (
                                            <TableCell key={company + '_' + index} align="center">
                                              {this.getCapabilityByCompany(capabilities[category][capability], company)?.hasCapability ? (
                                                <Check
                                                  fontSize="small"
                                                  className={clsx(
                                                    classes.checked,
                                                    this.getCapabilityStyle(this.getCapabilityByCompany(capabilities[category][capability], company))
                                                  )}
                                                />
                                              ) : (
                                                <Check
                                                  fontSize="small"
                                                  className={clsx(
                                                    classes.unchecked,
                                                    this.getCapabilityStyle(this.getCapabilityByCompany(capabilities[category][capability], company))
                                                  )}
                                                />
                                              )}
                                            </TableCell>
                                          );
                                        })}
                                      </TableRow>
                                    );
                                  })}
                                </Fragment>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </TableBody>
                      </Table>
                    </Paper>
                  )}
                </Box>
              </Fragment>
            </Fragment>
          )}
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    dbReport: state.currentReport.dbReport,
    isPreview: state.currentReport.isPreview,
    selectedIndustry: state.currentReport.selectedIndustry,
    reportsService: state.services.reports,
    capabilityReportService: state.services.capabilityReports
  };
};

const mapDispatchToProps = {
  getPreviousReport: feathersServices.reports.find,
  getCapabilityReports: feathersServices['capability-reports'].find,
  buyReport: feathersServices['report-status'].create,
  fireNotification,
  push
};

export default compose(withStyles(AppendixRawDataStyles), connect(mapStateToProps, mapDispatchToProps))(AppendixRawData);
