export default theme => ({
  container: {
    marginTop: theme.spacing(4),
    paddingLeft: '20%'
  },
  paper: {
    height: '8rem',
    border: '1px solid ' + theme.palette.divider,
    '&:hover': {
      color: theme.palette.secondary.main,
      fontWeight: 400
    }
  },
  borderBottom: {
    borderBottom: '1px solid ' + theme.palette.divider
  },
  paperButton: {
    width: '100%',
    height: '100%'
  },
  thin: {
    fontWeight: 300,
    textAlign: 'justify',
    color: '#555'
  },
  boxContainer: {
    marginLeft: '1rem'
  },
  imageContainer: {
    textAlign: 'left',
    width: '100%'
  },
  image: {
    width: '30%'
  },
  textContainer: {
    textAlign: 'left'
  },
  subtitle: {
    fontSize: '0.7rem'
  },
  paperGray: {
    height: '8rem',
    backgroundColor: theme.palette.grey['100'],
    border: '1px solid ' + theme.palette.divider
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  divContainer: {
    width: '60%',
    marginBottom: theme.spacing(2)
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(2)
  },
  flex: {
    display: 'flex'
  },
  rowTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  cellEnd: {
    textAlign: 'end'
  },
  title: {
    lineHeight: '1.25',
    fontSize: '0.85rem'
  },
  table: {
    border: '1px solid ' + theme.palette.divider,
    overflow: 'hidden',
    '& .MuiTableCell-footer': {
      border: 'none'
    }
  }
});
