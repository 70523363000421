import { createTheme } from '@material-ui/core';

const customThemes = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto',
    h4: {
      fontSize: 48,
      fontWeight: 300,
      color: '#212121'
    },
    body1: {
      fontSize: '0.85rem',
      lineHeight: 1.75
    },
    body2: {
      fontSize: '0.8rem',
      lineHeight: 1.75
    }
  },
  palette: {
    primary: {
      main: '#FFFFFF',
      dark: '#EAEAEA'
    },
    secondary: {
      main: '#1E8545'
    },
    text: {
      secondary: '#555'
    }
  },
  overrides: {
    MuiSelected: {
      root: {
        '&:hover': {
          backgroundColor: '#1e854552'
        }
      },
      MuiTabWrapper: {
        backgroundColor: '#1E8545'
      }
    },
    MuiStepIcon: {
      root: {
        color: '#d1d1d1',
        '&$active': {
          color: '#1E8545'
        },
        '&$completed': {
          color: '#1E8545'
        },
        width: '30px',
        height: '30px'
      },
      text: {
        fill: '#fff'
      }
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          backgroundColor: '#1e854552'
        }
      }
    },
    MuiStepLabel: {
      label: {
        color: '#2e2e2e',
        fontSize: '12px'
      },
      active: {
        color: '#2e2e2e'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#1E8545'
      }
    },
    MuiPickersYear: {
      yearSelected: {
        color: '#A4A4A4'
      }
    },
    MuiPickersMonth: {
      monthSelected: {
        color: '#A4A4A4'
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8
      }
    },
    MuiTab: {
      root: {
        fontSize: '0.8rem',
        textTransform: 'none',
        '&.Mui-selected': {
          color: '#1E8545'
        }
      }
    }
  }
});

export default customThemes;
