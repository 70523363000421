import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = {
  viewBox: '0 0 80 80'
};

// viewBox
const TrendsIcon = props => (
  <SvgIcon {...useStyles} style={props}>
    <g id="icon/trendsIcon">
      <ellipse ry="4.166667" rx="4.25" id="svg_1" cy="16.242187" cx="74.666666" strokeWidth="2" stroke={props.fill || '#a6a6a6'} fill="#fff" />
      <ellipse ry="4.583333" rx="4.583333" id="svg_2" cy="48.325521" cx="53.833333" strokeWidth="2" stroke={props.fill || '#a6a6a6'} fill="#fff" />
      <ellipse ry="4.583333" rx="4.583333" id="svg_3" cy="63.492187" cx="5.999999" strokeWidth="2" stroke={props.fill || '#a6a6a6'} fill="#fff" />
      <ellipse ry="4.583333" rx="4.583333" id="svg_5" cy="31.825521" cx="25.666666" strokeWidth="2" stroke={props.fill || '#a6a6a6'} fill="#fff" />
      <line
        transform="rotate(-3.8454787731170654 64.08332824707028,31.90885543823241) "
        stroke={props.fill || '#a6a6a6'}
        id="svg_6"
        y2="43.408854"
        x2="55.916666"
        y1="20.408854"
        x1="72.25"
        strokeWidth="2"
        fill="none"
      />
      <line stroke={props.fill || '#a6a6a6'} id="svg_7" y2="34.242187" x2="29.749999" y1="46.242188" x1="50.083333" strokeWidth="2" fill="none" />
      <line
        transform="rotate(-3.8454787731170654 15.08332824707029,46.575523376464844) "
        stroke={props.fill || '#a6a6a6'}
        id="svg_9"
        y2="58.075521"
        x2="6.916666"
        y1="35.075521"
        x1="23.25"
        strokeWidth="2"
        fill="none"
      />
    </g>
  </SvgIcon>
);

export default TrendsIcon;
