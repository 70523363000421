import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = {
  viewBox: '0 0 80 80'
};

// viewBox
const MethodologyIcon = props => (
  <SvgIcon {...useStyles} style={props}>
    <g id="icon/methodology" stroke="none" strokeWidth="1">
      <path
        stroke="null"
        d="m46.80285,8.84154c0.4561,0 0.82627,-0.44395 0.82627,-0.99095s-0.37017,-0.99095 -0.82627,-0.99095l-14.8729,0c-0.4561,0 -0.82627,0.44395 -0.82627,0.99095s0.37017,0.99095 0.82627,0.99095l14.8729,0zm-27.28351,52.78814c-1.77484,2.99466 -1.57653,4.95081 -1.09729,6.06464c0.50733,1.17924 1.82937,2.58639 5.24518,2.58639l33.05089,0c3.41581,0 4.73784,-1.40716 5.24518,-2.58837c0.47924,-1.11383 0.67754,-3.06997 -1.07085,-6.01311l-10.27718,-19.71999l-0.14542,-0.24576c-0.73868,-1.26446 -4.49327,-9.04543 -4.49327,-15.53024l0,-14.36884c0,-0.54701 -0.37017,-0.99095 -0.82627,-0.99095l-11.56781,0c-0.4561,0 -0.82627,0.44395 -0.82627,0.99095l0,14.36884c0,6.91884 -4.07352,15.77005 -4.11979,15.86518l-9.11709,19.58126l-0.00001,0zm40.9765,5.15296c-0.41478,0.96519 -1.79136,1.51616 -3.77772,1.51616l-33.05089,0c-1.98635,0 -3.36293,-0.55097 -3.77772,-1.51616c-0.37843,-0.87997 -0.03305,-2.30298 1.02458,-4.09463l8.26107,-17.73412l0.01157,0.00793c1.50381,1.0405 3.05886,2.11668 6.14581,2.11668s4.64365,-1.07419 6.14581,-2.11271c1.37657,-0.95132 2.67547,-1.85111 5.32119,-1.85111c1.0031,0 1.87234,0.14864 2.67216,0.4162l10.04747,19.2483c1.0097,1.70048 1.35508,3.12349 0.97665,4.00346l0.00002,0zm-26.08707,-40.59941l0,-13.37789l9.91527,0l0,13.37789c0,5.62862 2.50526,11.97469 3.97272,15.06648c-0.47924,-0.0654 -0.96674,-0.11693 -1.49555,-0.11693c-3.0886,0 -4.64365,1.07419 -6.14581,2.11271c-1.37657,0.95132 -2.67547,1.85111 -5.32119,1.85111s-3.94462,-0.89979 -5.31954,-1.85111l-0.03139,-0.0218l0.07602,-0.16252c0.04132,-0.07927 4.3495,-9.28723 4.3495,-16.87794l-0.00002,0z"
        id="svg_1"
      />
    </g>
  </SvgIcon>
);

export default MethodologyIcon;
