export default theme => ({
  root: {},
  title: {
    color: theme.palette.secondary.main,
    fontWeight: 500
  },
  titleSecondary: {
    color: theme.palette.secondary.main
  },
  border: {
    border: '1px solid ' + theme.palette.divider,
    borderTop: '4px solid ' + theme.palette.secondary.main,
    borderRadius: 5
  },
  fullWidth: {
    width: '100%'
  },
  divider: {
    marginTop: '0.8rem',
    marginBottom: '0.8rem'
  },
  summaryContent: {
    alignItems: 'center'
  }
});
