import REPORT_CONSTANTS from '../modules/reports/report.constants';
import { USER_ROLE } from '../constants/user.constants';

export function canEdit(user, dbReport) {
  return (
    (user.loginType === USER_ROLE.ADMIN || user.loginType === USER_ROLE.SENIOR) &&
    (!dbReport ||
      (dbReport &&
        dbReport.status !== REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS &&
        dbReport.status !== REPORT_CONSTANTS.STATUS.PUBLISHED_EDITED.STATUS &&
        dbReport.status !== REPORT_CONSTANTS.STATUS.PUBLISHED_EDITING.STATUS))
  );
}
