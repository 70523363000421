import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Typography } from '@material-ui/core/index';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import feathersServices from '../../services/feathersServices';
import SingletonMomentUtils from '../../utils/SingletonMomentUtils';
import { DriveIcon } from '../../images';
import { toggleAppBar } from '../layout/actions/layout.actions';
import styles from './OAuthCallback.styles';

class OAuthCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false
    };
  }

  componentDidMount() {
    this.props.toggleAppBar(false);
    const code = this.props.location.search ? this.props.location.search.split('?code=')[1].split('&scope')[0] : null;

    const images = [];
    this.props.imagesSelected.map(image => images.push({ url: image.url, name: image.name }));

    const reportName = this.getFolderName();

    // Upload to google drive.-
    this.props
      .googleDrive({
        reportName,
        code,
        images
      })
      .then(status => {
        this.setState({ done: status });
        setTimeout(() => {
          this.props.toggleAppBar(true);
          window.close();
        }, 2000);
      });
  }

  getFolderName() {
    const { selectedReport, application, companies, categories } = this.props;
    let reportName = `${SingletonMomentUtils.moment(selectedReport.date).format('MMMM YYYY')} - ${selectedReport.industry.title} - ${application}`;

    if (companies && companies.length) {
      reportName = reportName.concat(` - (`);
      companies.forEach((c, i, a) => {
        reportName = reportName.concat(c.title);
        if (a.length - 1 !== i) {
          reportName = reportName.concat(`, `);
        }
      });
      reportName = reportName.concat(` )`);
    }

    if (categories && categories.length) {
      reportName = reportName.concat(` - (`);
      categories.forEach((c, i, a) => {
        reportName = reportName.concat(c.title);
        if (a.length - 1 !== i) {
          reportName = reportName.concat(`, `);
        }
      });
      reportName = reportName.concat(` )`);
    }
    return reportName;
  }

  render() {
    const { done } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {!done ? (
          <div>
            <LinearProgress color="secondary" />
            <div className={classes.uploading}>
              <DriveIcon style={{ fontSize: '1rem' }} />
              <Typography className={classes.text}>Uploading files to google drive...</Typography>
            </div>
          </div>
        ) : (
          <Fragment>
            <Check color="secondary" fontSize="large" />
            <Typography>Files uploaded successfully!</Typography>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedReport: state.ui.listReport.selectedReport,
    application: state.ui.screenshots.application,
    companies: state.ui.screenshots.companies,
    categories: state.ui.screenshots.categories,
    imagesSelected: state.ui.screenshots.imagesSelected
  };
};

const mapDispatchToProps = {
  toggleAppBar,
  googleDrive: feathersServices['google-drive'].create
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(OAuthCallback);
