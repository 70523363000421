import React from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { withStyles } from '@material-ui/styles';

const KLIPasswordStrengthStyles = () => ({
  root: {
    '&:first-child': {
      '& div': {
        height: [6, '!important']
      }
    }
  }
});

function KLIPasswordStrength(props) {
  const { classes, password, onScoreChange } = props;

  if (password === '') {
    return null;
  }

  return (
    <PasswordStrengthBar
      password={password}
      minLength={10}
      className={classes.root}
      barColors={['#ddd', '#B00000', '#FFD356', '#1D86E2', '#0FA953']}
      scoreWords={['Weak', 'Weak', 'Medium', 'Good', 'Strong']}
      shortScoreWord="Minimum 10 characters"
      onChangeScore={(score, feedback) => {
        let helpMessage = feedback.warning || '';
        let helpSuggestions = feedback.suggestions;
        if (password?.length < 10) {
          helpMessage = 'Password is too short';
          helpSuggestions = ['Put at least 10 characters'];
        } else if (score < 3 && helpMessage === '') {
          helpMessage = 'This password is not strong enough';
          helpSuggestions = ['Add another word or two. Uncommon words are better.'];
        }

        onScoreChange(helpMessage, helpSuggestions);
      }}
    />
  );
}

export default withStyles(KLIPasswordStrengthStyles)(KLIPasswordStrength);
