import React from 'react';
import { IconButton, InputBase, Paper, withStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import SearchStyle from './Search.style';

const Search = ({ classes, onChange }) => {
  return (
    <Paper className={classes.root} elevation={0}>
      <InputBase
        className={classes.input}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        onChange={event => onChange(event.target.value)}
      />
      <IconButton aria-label="search" size="small">
        <SearchIcon fontSize="small" />
      </IconButton>
    </Paper>
  );
};

export default withStyles(SearchStyle)(Search);
