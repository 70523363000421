import React, { useEffect, useState } from 'react';
import InlineSVG from 'svg-inline-react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import {
  Box,
  Breadcrumbs,
  DialogContentText,
  Drawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Alert } from '@material-ui/lab';
import feathersServices from '../../../services/feathersServices';
import KLDialog from '../../../components/dialog/KLDialog';
import LoadingIndicator from '../../../components/loadingIndicator';
import LinkRouter from '../../../components/router-link/RouterLink';
import HeaderTable from '../../../components/tables/HeaderTable';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import IndustryManagementStyles from './IndustryManagement.styles';
import IndustryManagementForm from './IndustryManagementForm';

const cells = [
  { label: '', value: 'svgIcon', sort: false },
  { label: 'Title', value: 'title', sort: true },
  { label: 'Description', value: 'description', sort: true },
  { label: 'Created at', value: 'createdAt', sort: true },
  { label: 'Status', value: 'active', sort: true },
  { label: '' }
];

const rowsPerPage = 8;

function IndustryManagement(props) {
  const { classes, industries, history, getReports } = props;

  const [sort, setSort] = useState({
    key: 'title',
    value: 1
  });
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [industryInReports, setIndustryInReports] = useState(0);

  useEffect(() => {
    findIndustries(0);
    // eslint-disable-next-line
  }, []);

  const findIndustries = page => {
    const { key, value } = sort;
    const query = {
      $sort: {
        [key]: value
      },
      $limit: rowsPerPage,
      $skip: rowsPerPage * page
    };
    props.getIndustries({ query });
  };

  const createSortHandler = value => {
    setSort({
      key: value,
      value: sort.value === -1 ? 1 : -1
    });
    findIndustries(0);
  };

  const deleteElement = async () => {
    await props.deleteIndustry(selectedElement._id);
    setOpenDelete(false);
    setSelectedElement(null);
    findIndustries(page);
  };

  const handleCopy = async () => {
    setAnchorEl(null);
    setOpenCopy(false);
    await props.copyIndustry({
      type: 'INDUSTRY',
      data: selectedElement
    });
    findIndustries(page);
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Box className={classes.box} mb={3}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                Admin
              </Typography>
            </LinkRouter>
            <Typography variant="body2">Industry Management</Typography>
          </Breadcrumbs>
        </Box>
        <Grid container justifyContent={'space-between'} alignItems="center">
          <Typography variant="h5" className={classes.title}>
            Key Lime Industries
          </Typography>

          <Button
            style={{ height: 40 }}
            variant="contained"
            color="secondary"
            onClick={() => {
              setSelectedElement(null);
              setOpen(true);
            }}
            disableElevation
          >
            New Industry
          </Button>
        </Grid>

        <Box my={3}>
          <Paper elevation={0} className={classes.table}>
            <LoadingIndicator isLoading={industries.isLoading} width={40}>
              <Table aria-label="simple table" size="medium">
                <HeaderTable classes={classes} cells={cells.map(c => c.label)} />
              </Table>
            </LoadingIndicator>

            {!industries.isLoading && (
              <Table aria-label="simple dense table" size="medium">
                <TableHead>
                  <TableRow className={classes.headerRow}>
                    {cells.map((cell, index) => (
                      <TableCell key={index}>
                        {cell.sort ? (
                          <TableSortLabel
                            active={sort.key === cell.value}
                            direction={sort.value === 1 ? 'asc' : 'desc'}
                            onClick={() => createSortHandler(cell.value)}
                          >
                            {cell.label}
                          </TableSortLabel>
                        ) : (
                          cell.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {industries &&
                    industries.queryResult &&
                    industries.queryResult.data.map((industry, index) => (
                      <TableRow
                        key={index}
                        onClick={() => history.push(`/admin/industry-management/${industry._id}/categories`)}
                        hover
                        style={{ cursor: 'pointer' }}
                        className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}
                      >
                        <TableCell width="5%">
                          <Box className={classes.icon}>
                            <InlineSVG element="div" src={industry.svgIcon} />
                          </Box>
                        </TableCell>
                        <TableCell width="20%">
                          <Typography className={classes.tableText} color="textSecondary">
                            {industry.title}
                          </Typography>
                        </TableCell>
                        <TableCell width="40%">
                          <Typography className={classes.tableText} color="textSecondary">
                            {industry.description}
                          </Typography>
                        </TableCell>
                        <TableCell width="20%">
                          <Typography className={classes.tableText} color="textSecondary">
                            {SingletonMomentUtils.moment(industry.createdAt).format('LLL')}
                          </Typography>
                        </TableCell>
                        <TableCell width="10%">
                          <Typography className={classes.tableText} color="textSecondary">
                            {industry.active ? 'Active' : 'Disabled'}
                          </Typography>
                        </TableCell>
                        <TableCell onClick={event => event.stopPropagation()} width="5%">
                          <IconButton
                            size="small"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={e => {
                              setAnchorEl(e.currentTarget);
                              setSelectedElement(industry);
                            }}
                          >
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[rowsPerPage]}
                      count={industries.queryResult ? industries.queryResult.total : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={(e, p) => {
                        setPage(p);
                        findIndustries(p);
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </Paper>
        </Box>
      </Grid>

      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            setOpen(true);
            setAnchorEl(null);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenCopy(true);
            setAnchorEl(null);
          }}
        >
          Copy
        </MenuItem>
        <MenuItem
          onClick={() => {
            getReports({
              query: {
                industry: selectedElement._id,
                $limit: 0
              }
            }).then(({ value }) => {
              setIndustryInReports(value.total);
              setOpenDelete(true);
              setAnchorEl(null);
            });
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      <Drawer anchor="right" open={open} classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}>
        <IndustryManagementForm
          classes={classes}
          selectedElement={selectedElement}
          onClose={() => {
            setOpen(false);
            setSelectedElement(null);
          }}
          onSave={() => findIndustries(page)}
        />
      </Drawer>

      {selectedElement && (
        <KLDialog
          isOpen={openDelete}
          onHandleClose={() => setOpenDelete(false)}
          onHandleConfirm={deleteElement}
          title={`Delete ${selectedElement.title}`}
          showButtonConfirm={industryInReports === 0}
          labelButtonClose={industryInReports === 0 ? 'Cancel' : 'Close'}
          body={
            <DialogContentText>
              {industryInReports === 0 ? (
                'The industry will be deleted with all their categories, capabilities and survey questions, do you want to continue?'
              ) : (
                <Alert severity="error">You can not delete an industry that is in use.</Alert>
              )}
            </DialogContentText>
          }
          labelButtonConfirm="Delete"
        />
      )}

      {selectedElement && (
        <KLDialog
          isOpen={openCopy}
          onHandleClose={() => setOpenCopy(false)}
          onHandleConfirm={handleCopy}
          title={`Copy ${selectedElement.title}`}
          body={
            <DialogContentText>
              You are about to copy this industry with all their categories, capabilities and survey questions, do you want to continue?
            </DialogContentText>
          }
          labelButtonConfirm="Copy"
        />
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    industries: state.services.industries
  };
}

const mapDispatchToProps = {
  getReports: feathersServices.reports.find,
  getIndustries: feathersServices.industries.find,
  deleteIndustry: feathersServices.industries.remove,
  copyIndustry: feathersServices['industry-copy'].create
};

export default compose(withStyles(IndustryManagementStyles), connect(mapStateToProps, mapDispatchToProps))(IndustryManagement);
