import React, { Component, Fragment } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import Image from 'material-ui-image';
import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';
import VerticalAlignTop from '@material-ui/icons/VerticalAlignTop';
import feathersServices from '../../../services/feathersServices';
import { fireNotification, toggleAppBar } from '../../../components/layout/actions/layout.actions';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import LinkRouter from '../../../components/router-link/RouterLink';
import { WindowPopup } from '../../../utils/common.utils';
import { downloadCompressedUrls } from '../../../utils/file.utils';
import { getApplications } from '../../../utils/report.utils';
import { validateProps } from '../../../utils/routeUtils';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { canEdit } from '../../../utils/user.utils';
import { USER_ROLE } from '../../../constants/user.constants';
import { clean, setApplication, setCategories, setCompanies, setImages, setImagesSelected } from './actions/Screenshots.actions';
import styles from './Screenshot.styles';

class KLScreenshot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
      reportCategories: [],
      reportApplications: [],
      allCapabilities: [],
      currentCapabilities: [],
      expandImageInfo: true,
      openImage: null,
      collapseFilter: false
    };
    this.props.toggleAppBar(true);
  }

  async componentDidMount() {
    validateProps(this.props.push, this.props.fireNotification, this.props.dbReport);

    const { value: allCapabilities } = await this.props.getCapabilities({
      query: {
        $select: ['title'],
        $limit: 'infinity'
      }
    });

    this.setState(
      {
        allCapabilities,
        reportCategories: await this.getReportCategories(),
        reportApplications: await this.getReportApplications()
      },
      this.loadImages
    );
  }

  componentWillUnmount() {
    this.props.clean();
  }

  getReportCategories = () => {
    const { dbReport } = this.props;
    const categories = [];

    //Iterate all companies.-
    if (dbReport && dbReport.companies) {
      dbReport.companies.map(company =>
        // Iterate all categories.-
        company.categories.forEach(cat => {
          // Create a new category temp object.-
          const newCat = {
            categoryId: cat.categoryId,
            title: cat.title
          };
          // If the category isn't in the array, add it.-
          if (!categories.find(c => c.categoryId === newCat.categoryId)) {
            categories.push(newCat);
          }
        })
      );
    }
    return categories;
  };

  getReportApplications = () => {
    const { dbReport } = this.props;
    const categoriesSet = new Set();
    // Iterate all companies.-
    if (dbReport && dbReport.companies) {
      dbReport.companies.forEach(company => {
        // Itearate all categories.-
        company.categories.forEach(cat => {
          categoriesSet.add(cat.applicationTitle);
        });
      });
    }
    return Array.from(categoriesSet);
  };

  setFilter = async (element, objects, callback) => {
    let output = objects;
    if (objects.indexOf(element) === -1) {
      output.push(element);
    } else {
      output = objects.filter(item => item !== element);
    }
    await callback(output);
    this.loadImages();
  };

  selectImage = image => {
    const { imagesSelected } = this.props;
    let output = imagesSelected;
    if (!imagesSelected.find(img => img._id === image._id)) {
      output.push(image);
    } else {
      output = imagesSelected.filter(item => item._id !== image._id);
    }
    this.setState({ downloading: false }, () => {
      this.props.setImagesSelected(output);
    });
  };

  selectApplication = async ({ target }) => {
    await this.props.setApplication(target.textContent);
    this.loadImages();
  };

  loadImages = async () => {
    const { dbReport, application, companies, categories } = this.props;
    const { reportCategories, allCapabilities } = this.state;
    const images = [];

    const q = {
      query: {
        report: dbReport._id,
        application: getApplications(dbReport).find(app => app.title === application).id,
        $limit: 'infinity'
      }
    };

    if (companies && companies.length > 0) {
      q.query.company = {
        $in: companies.reduce((ids, company) => {
          return ids.concat(company.companyId);
        }, [])
      };
    }

    if (categories && categories.length > 0) {
      q.query.category = {
        $in: categories.reduce((ids, category) => {
          return ids.concat(category.categoryId);
        }, [])
      };
    }
    const { value: capabilityReports } = await this.props.getCapabilityReports(q);

    // Iterate each capability report.-
    capabilityReports.forEach(cr => {
      cr.images.forEach(image => {
        const crImage = Object.assign({}, image);
        crImage.report = `${dbReport.industry.title} - ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`;
        crImage.company = dbReport.companies.find(c => c.companyId === cr.company).title;
        crImage.category = reportCategories.find(rc => rc.categoryId === cr.category).title;
        crImage.capability = allCapabilities.find(c => c._id === cr.capability).title;
        images.push(crImage);
      });
    });

    this.props.setImages(images);
  };

  downloadImages = async () => {
    const files = [];
    this.setState({ downloading: true });
    let nameIdx = 1;
    try {
      this.props.imagesSelected.forEach(image => {
        let fileName = image.name;
        const name = fileName.substr(0, fileName.lastIndexOf('.'));
        const extension = fileName.substr(fileName.lastIndexOf('.') + 1);
        const sameName = files.filter(file => file.name === fileName);
        if (sameName && sameName.length) {
          fileName = name.concat(` (${nameIdx})`).concat('.').concat(extension);
          nameIdx++;
        }
        files.push({ name: fileName, url: image.url });
      });
      await downloadCompressedUrls(`screenshots_${Date.now()}.zip`, files, () => {
        this.setState({ downloading: false });
      });
    } catch (e) {
      console.error(e);
      this.setState({ downloading: false });
    }
  };

  uploadImages = () => {
    this.props.googleDriveLogin('1').then(url => {
      WindowPopup(url.value, '', 500, 600);
    });
  };

  render() {
    const { reportCategories, reportApplications, downloading, expandImageInfo, openImage, collapseFilter } = this.state;
    const {
      dbReport,
      classes,
      images,
      imagesSelected,
      application,
      companies,
      categories,
      capabilityReportsService,
      viewOnly,
      user,
      selectedIndustry
    } = this.props;

    return (
      <div className={classes.container}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            paddingBottom: '100%'
          }}
        >
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {collapseFilter ? (
              <IconButton onClick={() => this.setState({ collapseFilter: false })}>
                <KeyboardArrowRight fontSize="small" />
              </IconButton>
            ) : (
              <IconButton onClick={() => this.setState({ collapseFilter: true })}>
                <KeyboardArrowLeft fontSize="small" />
              </IconButton>
            )}
          </Box>
          {!collapseFilter && (
            <Box className={clsx(classes.filter)}>
              {/* COMPANIES */}
              <Box
                style={{
                  boxShadow: '1px 0px 0px #DDD'
                }}
              >
                <Typography>Company</Typography>
                {dbReport &&
                  dbReport.companies &&
                  dbReport.companies
                    .sort((a, b) => {
                      if (a.title.toLowerCase() > b.title.toLowerCase()) {
                        return 1;
                      }
                      if (a.title.toLowerCase() < b.title.toLowerCase()) {
                        return -1;
                      }
                      return 0;
                    })
                    .map(company => {
                      return (
                        <Box key={company.companyId}>
                          <FormControlLabel
                            className={classes.options}
                            control={
                              <Checkbox
                                size="small"
                                className={classes.checkbox}
                                checked={companies.indexOf(company) !== -1}
                                value="checkedA"
                                icon={<CheckBoxOutlineBlank fontSize="small" />}
                                checkedIcon={<CheckBox fontSize="small" />}
                                onChange={() => this.setFilter(company, companies, companies => this.props.setCompanies(companies))}
                              />
                            }
                            label={
                              <Tooltip title={company.title}>
                                <Typography
                                  color="textSecondary"
                                  className={companies.indexOf(company) !== -1 ? classes.optionLabelSelected : classes.optionLabel}
                                >
                                  {company.title}
                                </Typography>
                              </Tooltip>
                            }
                          />
                        </Box>
                      );
                    })}
              </Box>

              {/* CATEGORIES */}
              <Box
                mt={4}
                style={{
                  boxShadow: '1px 0px 0px #DDD'
                }}
              >
                <Typography>Category</Typography>
                {dbReport &&
                  reportCategories
                    .sort((a, b) => {
                      if (a.title.toLowerCase() > b.title.toLowerCase()) {
                        return 1;
                      }
                      if (a.title.toLowerCase() < b.title.toLowerCase()) {
                        return -1;
                      }
                      return 0;
                    })
                    .map((category, i) => {
                      return (
                        <Box key={i}>
                          <FormControlLabel
                            className={classes.options}
                            control={
                              <Checkbox
                                size="small"
                                className={classes.checkbox}
                                checked={categories.indexOf(category) !== -1}
                                onChange={() => this.setFilter(category, categories, categories => this.props.setCategories(categories))}
                                value="checkedA"
                                icon={<CheckBoxOutlineBlank fontSize="small" />}
                                checkedIcon={<CheckBox fontSize="small" />}
                              />
                            }
                            label={
                              <Tooltip title={category.title}>
                                <Typography
                                  color="textSecondary"
                                  className={categories.indexOf(category) !== -1 ? classes.optionLabelSelected : classes.optionLabel}
                                >
                                  {category.title}
                                </Typography>
                              </Tooltip>
                            }
                          />
                        </Box>
                      );
                    })}
              </Box>
            </Box>
          )}
        </Box>
        {/* HEADER */}
        <div className={classes.screenshots}>
          <Box className={classes.header}>
            <Box>
              <Breadcrumbs separator="›">
                <LinkRouter to={user.loginType === USER_ROLE.CUSTOMER ? '/report' : '/report-list'} color="secondary" underline="none">
                  <Typography color="secondary" variant="body2">
                    {user.loginType !== USER_ROLE.CUSTOMER ? 'Home' : 'Report'}
                  </Typography>
                </LinkRouter>
                {viewOnly && dbReport && (
                  <LinkRouter to={canEdit(user, dbReport) ? '/report/report-publish-date' : '/content-building'} color="secondary" underline="none">
                    <Typography color="secondary" variant="body2">
                      {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
                    </Typography>
                  </LinkRouter>
                )}
                <Typography variant="body2">Screenshots</Typography>
              </Breadcrumbs>
            </Box>
            {!viewOnly && (
              <Box mt={4}>
                <Typography variant="h5">
                  Screenshots: {`${dbReport.industry.title} - ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
                </Typography>
              </Box>
            )}

            {/* APPLICATIONS */}
            <Box mt={4} className={classes.box}>
              <Tabs value={application} onChange={this.selectApplication}>
                {reportApplications.map((application, i) => {
                  return <Tab key={i} label={application} value={application} />;
                })}
              </Tabs>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                {!viewOnly && !!imagesSelected.length && (
                  <Fragment>
                    <VerticalAlignTop fontSize="small" color={'secondary'} className={classes.download} onClick={this.uploadImages} />
                    <Tooltip title="Upload to Google Drive">
                      <Typography variant="body2" onClick={this.uploadImages} color={'secondary'} className={classes.download}>
                        Upload ({imagesSelected.length})
                      </Typography>
                    </Tooltip>
                    &nbsp;
                    {downloading ? (
                      <CircularProgress color="secondary" size={25} thickness={3} />
                    ) : (
                      <VerticalAlignBottom fontSize="small" color={'secondary'} className={classes.download} onClick={this.downloadImages} />
                    )}
                    <Tooltip title="Download as 'ZIP' file">
                      <Typography variant="body2" color={'secondary'} className={classes.download} onClick={this.downloadImages}>
                        Download ({imagesSelected.length})
                      </Typography>
                    </Tooltip>
                  </Fragment>
                )}

                <Typography
                  color={'secondary'}
                  className={classes.selectLabels}
                  onClick={() => {
                    this.setState({
                      expandImageInfo: !expandImageInfo
                    });
                  }}
                >
                  {expandImageInfo ? 'Hide info' : 'Show info'}
                </Typography>

                {!viewOnly && (
                  <Fragment>
                    <Typography className={classes.selectLabels}>|</Typography>
                    <Typography
                      color={'secondary'}
                      className={classes.selectLabels}
                      onClick={() => {
                        this.props.setImagesSelected(this.props.images);
                      }}
                    >
                      Select All
                    </Typography>
                    <Typography className={classes.selectLabels}>|</Typography>
                    <Typography
                      className={classes.selectLabels}
                      onClick={() => {
                        this.props.setImagesSelected([]);
                      }}
                    >
                      Unselect All
                    </Typography>
                  </Fragment>
                )}
              </Box>
            </Box>
          </Box>

          {/* SCREENSHOTS */}
          <Box mt={4} p={3}>
            {capabilityReportsService.isLoading ? (
              <LoadingIndicator isLoading width={50} color="secondary" thickness={3} />
            ) : (
              <Box className={classes.gridScreenshots}>
                {!images.length && <Typography className={classes.noData}>No Screenshots</Typography>}
                {images.map((image, i) => {
                  return (
                    <Card
                      key={`screenshot_${i}`}
                      className={imagesSelected.find(img => img._id === image._id) ? classes.selectedCard : classes.card}
                      onClick={() => {
                        if (!viewOnly) this.selectImage(image);
                        else this.setState({ openImage: image.url });
                      }}
                    >
                      <div className={classes.media}>
                        <Image src={image.thumbnail || ''} disableSpinner={true} disableError={true} />
                      </div>

                      <CardContent
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          padding: 0
                        }}
                      >
                        <Collapse
                          in={expandImageInfo}
                          style={{
                            textAlign: 'left',
                            padding: 5,
                            backgroundColor: '#5555551a',
                            borderTop: '1px solid #0000001f'
                          }}
                        >
                          <Tooltip title={image.report}>
                            <Typography variant="body2" color="textSecondary" component="p" noWrap>
                              <small>
                                <b>Report: </b>
                                {image.report}
                              </small>
                            </Typography>
                          </Tooltip>
                          <Tooltip title={image.company}>
                            <Typography variant="body2" color="textSecondary" component="p" noWrap>
                              <small>
                                <b>Company: </b>
                                {image.company}
                              </small>
                            </Typography>
                          </Tooltip>
                          <Tooltip title={image.category}>
                            <Typography noWrap variant="body2" color="textSecondary" component="p">
                              <small>
                                <b>Category: </b> {image.category}
                              </small>
                            </Typography>
                          </Tooltip>
                          <Tooltip title={image.capability}>
                            <Typography noWrap variant="body2" color="textSecondary" component="p">
                              <small>
                                <b>Capability: </b> {image.capability}
                              </small>
                            </Typography>
                          </Tooltip>
                        </Collapse>
                      </CardContent>
                    </Card>
                  );
                })}
              </Box>
            )}
          </Box>
        </div>
        <Dialog maxWidth="xl" open={openImage !== null} onClose={() => this.setState({ openImage: null })}>
          <img src={openImage} alt="Insight screenshot" />
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    dbReport: state.currentReport.dbReport,
    selectedIndustry: state.currentReport.selectedIndustry,
    images: state.ui.screenshots.images,
    imagesSelected: state.ui.screenshots.imagesSelected,
    application: state.ui.screenshots.application,
    companies: state.ui.screenshots.companies,
    categories: state.ui.screenshots.categories,
    capabilityReportsService: state.services.capabilityReports
  };
};

const mapDispatchToProps = {
  toggleAppBar,
  setImages,
  setImagesSelected,
  setApplication,
  setCompanies,
  setCategories,
  clean,
  fireNotification,
  googleDriveLogin: feathersServices['google-drive-login'].find,
  getCapabilityReports: feathersServices['capability-reports'].find,
  getCapabilities: feathersServices['capabilities'].find,
  push
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(KLScreenshot);
