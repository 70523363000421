export default theme => ({
  container: {
    marginTop: theme.spacing(4),
    flexDirection: 'column',
    padding: '0 10%',
    [theme.breakpoints.down('lg')]: {
      padding: '0 5%'
    }
  },
  grow: {
    flexGrow: 1
  },
  paper: {
    width: '10rem',
    height: '8rem'
  },
  detailPaper: {
    height: '20rem'
  },
  borderBottom: {
    borderBottom: '1px solid ' + theme.palette.divider
  },
  capabilityName: {
    color: theme.palette.secondary.main
  },
  headerRow: {
    backgroundColor: '#f3f3f3'
  },
  row: {
    cursor: 'pointer',
    backgroundColor: '#f3f3f3',
    '&:hover': {
      backgroundColor: theme.overrides.MuiSelected.root['&:hover'].backgroundColor + '!important',
      '& th': {
        color: theme.palette.text.primary
      }
    }
  },
  oddRow: {
    backgroundColor: theme.palette.primary.main
  },
  filter: {
    display: 'flex'
  },
  box: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableText: {
    fontSize: '0.7rem'
  },
  tableHeader: {
    fontSize: '0.7rem',
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  },
  table: {
    border: '1px solid ' + theme.palette.divider,
    overflow: 'hidden',
    '& .MuiTableCell-footer': {
      border: 'none'
    }
  }
});
