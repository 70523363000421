const appReducerMigration = {
  18: state => {
    // migration clear out device state
    return {
      ...state,
      selectedCapabilityReport: {}
    };
  }
};

export default appReducerMigration;
