export default theme => ({
  rootHeader: {
    marginTop: theme.spacing(4),
    borderBottom: '1px solid ' + theme.palette.divider,
    padding: '0 10%',
    [theme.breakpoints.down('lg')]: {
      padding: '0 5%'
    }
  },
  rootDetail: {
    marginTop: theme.spacing(4),
    padding: '0 10%',
    [theme.breakpoints.down('lg')]: {
      padding: '0 5%'
    }
  },
  title: {
    marginBottom: theme.spacing(4)
  },
  box: {
    width: '100%'
  },
  cardHeader: {
    border: '1px solid ' + theme.palette.divider,
    borderTop: '4px solid ' + theme.palette.secondary.main,
    marginBottom: theme.spacing(2)
  },
  firstGrid: {
    paddingRight: '0.5rem'
  }
});
