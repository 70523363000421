import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import {
  Box,
  Button,
  DialogContentText,
  Drawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import StarIcon from '@material-ui/icons/Star';
import feathersServices from '../../../../services/feathersServices';
import KLDialog from '../../../../components/dialog/KLDialog';
import Search from '../../../../components/search/Search';
import { filterByValue } from '../../../../utils/common.utils';
import { setCompanyCustomerSelected, setUserCompanySelected } from '../../action/CompaniesCustomertList.actions';
import UserCompanyForm from './UserCompanyForm';
import UsersCompanyStyles from './UsersCompany.styles';

const defaultValuesMenuRow = {
  isOpen: false,
  anchorEl: null
};

let UsersCompany = ({
  classes,
  companyCustomerSelected,
  userCompanySelected,
  setUserCompanySelected,
  setCompanyCustomerSelected,
  userCompanyDeleteService
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [filter, setFilter] = useState('');
  const [openModalUserCompany, setOpenModalUserCompany] = useState(false);

  const [openMenuRow, setOpenMenuRow] = useState(defaultValuesMenuRow);

  const onHandleClose = e => {
    e.stopPropagation();
    onHandleCloseIcon();
  };

  const onHandleCloseIcon = () => {
    setOpenMenuRow(defaultValuesMenuRow);
  };

  const onHandleClick = (event, userCompany) => {
    event.stopPropagation();
    setOpenMenuRow({ isOpen: true, anchorEl: event.currentTarget });
    setUserCompanySelected(userCompany);
  };

  const deleteUserCompany = () => {
    if (userCompanySelected) {
      userCompanyDeleteService(userCompanySelected._id, {
        query: {
          companyId: companyCustomerSelected._id
        }
      }).then(data => {
        setCompanyCustomerSelected(data.value);
        setOpenModalUserCompany(!openModalUserCompany);
      });
    }
  };

  const getUsers = () => {
    return companyCustomerSelected ? filterByValue(companyCustomerSelected.users, filter) : [];
  };

  const isPrimaryUser = user => {
    return user?._id === companyCustomerSelected.primaryUser;
  };

  const isBillingUser = user => {
    return user?._id === companyCustomerSelected.billingUser;
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} className={classes.root}>
          <Typography variant="h6">Users</Typography>
          <Box display="flex" alignItems="center">
            <Search onChange={data => setFilter(data)} className={classes.search} />
            &nbsp;
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              size="small"
              onClick={() => {
                setUserCompanySelected(null);
                setOpenModal(!openModal);
              }}
            >
              New User
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.table}>
            <TableContainer>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow className={classes.row}>
                    <TableCell size="small" />
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell size="small" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getUsers().map((user, index) => (
                    <TableRow key={index} className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}>
                      <TableCell>
                        {isPrimaryUser(user) && (
                          <Tooltip title="Primary contact">
                            <StarIcon color="secondary" className={classes.icon} />
                          </Tooltip>
                        )}

                        {isBillingUser(user) && (
                          <Tooltip title="Billing contact">
                            <AttachMoneyIcon color="secondary" className={classes.icon} />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Typography noWrap variant="body2" className={classes.icon}>
                          {user.name + ' ' + user.lastName + ' '}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Tooltip title={user.email}>
                          <Typography noWrap variant="body2">
                            {user.email}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Tooltip title={user.title}>
                          <Typography noWrap variant="body2">
                            {user.title}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Tooltip title={user.phone}>
                          <Typography noWrap variant="body2">
                            {user.phone}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.cell} width="5%">
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          size="small"
                          onClick={event => onHandleClick(event, user)}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      <Menu id="long-menu" anchorEl={openMenuRow.anchorEl} keepMounted open={openMenuRow.isOpen} onClose={onHandleClose}>
        <MenuItem
          onClick={e => {
            e.stopPropagation();
            setOpenModal(!openModal);
            onHandleCloseIcon();
          }}
        >
          Edit
        </MenuItem>

        {isPrimaryUser(userCompanySelected) ? null : (
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              setOpenModalUserCompany(!openModalUserCompany);
              onHandleCloseIcon();
            }}
          >
            Delete
          </MenuItem>
        )}
      </Menu>

      <Drawer anchor="right" open={openModal} classes={{ paper: classes.paper, root: classes.drawerRoot }}>
        <UserCompanyForm
          onCallBack={() => {
            setOpenModal(!openModal);
            setUserCompanySelected(null);
          }}
        />
      </Drawer>

      {userCompanySelected && (
        <KLDialog
          isOpen={openModalUserCompany}
          onHandleClose={() => setOpenModalUserCompany(!openModalUserCompany)}
          onHandleConfirm={() => deleteUserCompany()}
          title={'DELETE'}
          body={<DialogContentText>{`The ${userCompanySelected.name} user will be deleted.`}</DialogContentText>}
          labelButtonConfirm={'Delete'}
        />
      )}
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    companyCustomerSelected: state.ui.listCompaniesCustomer.companyCustomerSelected,
    userCompanySelected: state.ui.listCompaniesCustomer.userCompanySelected
  };
};

const mapDispatchToProps = {
  setUserCompanySelected: setUserCompanySelected,
  setCompanyCustomerSelected: setCompanyCustomerSelected,
  userCompanyDeleteService: feathersServices['user-company'].remove
};

UsersCompany = compose(withStyles(UsersCompanyStyles), connect(mapStateToProps, mapDispatchToProps))(UsersCompany);

export default UsersCompany;
