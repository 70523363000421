import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = {
  viewBox: '0 0 32 32'
};

// viewBox
const DriveIcon = props => (
  <SvgIcon {...useStyles} style={props}>
    <g xmlns="http://www.w3.org/2000/svg">
      <polyline fill="#537ABD" points="31.868,21 9.868,21 4.868,30 26.556,30  "></polyline>
      <polyline fill="#2EB672" points="10.962,2 -0.038,20 4.962,30 15.683,10.345  "></polyline>
      <polyline fill="#FED14B" points="20.962,2 10.962,2 21.712,21 31.962,21 20.962,2  "></polyline>
    </g>
  </SvgIcon>
);

export default DriveIcon;
