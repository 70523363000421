import feathers from '@feathersjs/client';
import { createBrowserHistory } from 'history';

const rest = require('@feathersjs/rest-client');
const apiUrl = process.env.BE_API_URL;
const key = process.env.KEY_LOCALSTORAGE;
// Connect to a different URL
const restClient = rest(apiUrl);
export default feathers()
  .configure(restClient.fetch(window.fetch.bind(window)))
  .configure(
    feathers.authentication({
      jwtStrategy: 'jwt',
      storage: window.localStorage,
      storageKey: key
    })
  )
  .hooks({
    error({ error }) {
      if (error.name === 'NotAuthenticated') {
        //We cannot import 'history' from store since it fails to compile.-
        const history = createBrowserHistory();
        history.replace(`/login`);
        window.localStorage.removeItem(key);
      }
    }
  });
