import React from 'react';
import { FormControl, Radio, RadioGroup, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const renderRadioGroup = ({ input, label, radios, className, meta: { touched, invalid, error }, ...custom }) => {
  return (
    <div>
      <FormControl component="fieldset">
        <Typography variant="body1">{label}</Typography>
        <FormControlLabel
          control={
            <RadioGroup {...custom} onChange={input.onChange} {...input} row>
              {radios.map((radio, key) => (
                <FormControlLabel key={key} value={radio.value} control={<Radio />} label={radio.label} labelPlacement="start" />
              ))}
            </RadioGroup>
          }
        />
      </FormControl>
    </div>
  );
};

export default renderRadioGroup;
