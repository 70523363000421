import CompanyManagement from './modules/admin/company-management/CompanyManagement';
import CapabilityManagement from './modules/admin/industry-management/CapabilitiesManagement';
import CategoryManagement from './modules/admin/industry-management/CategoryManagement';
import CategoryManagementHome from './modules/admin/industry-management/CategoryManagementHome';
import IndustryManagement from './modules/admin/industry-management/IndustryManagement';
import SurveyQuestionManagement from './modules/admin/industry-management/SurveyQuestionManagement';
import UserManagement from './modules/admin/user-management/UserManagement';
import Methodology from './modules/appendix/methodology/Methodology';
import AppendixRawData from './modules/appendix/rawData/AppendixRawData';
import AppendixScreenshots from './modules/appendix/screenshots/AppendixScreenshots';
import CompanyDashboardDetails from './modules/clientManagement/companies/CompanyDashboardDetails';
import KLConsumerSurvey from './modules/consumer-survey/ConsumerSurvey';
import KLCategoryPerformance from './modules/content-building/category-performance/CategoryPerformance';
import KLCategoryPerformanceHome from './modules/content-building/category-performance/CategoryPerformanceHome';
import KLConsumerContentSurvey from './modules/content-building/consumer-content-survey/ConsumerContentSurvey';
import KLContentBuilding from './modules/content-building/ContentBuilding';
import KLExecutiveSummary from './modules/content-building/executive-summary/ExecutiveSummary';
import KLProductRating from './modules/content-building/product-rating/ProductRating';
import KLTrendsInnovations from './modules/content-building/trends-innovations/TrendsInnovations';
import KLHomeCustomer from './modules/customer/home/HomeCustomer';
import KLAppSiteSelect from './modules/reports/app-site-select/AppSiteSelect';
import KLCapabilitiesTable from './modules/reports/capabilities-table/CapabilitiesTable';
import KLCapabilityDetail from './modules/reports/capability-detail/CapabilityDetail';
import KLHome from './modules/reports/home/Home';
import KLReportList from './modules/reports/list/ReportList';
import KLReportPublishDate from './modules/reports/publish-date/ReportPublishDate';
import KLScreenshot from './modules/reports/screenshot/Screenshot';
import KLSelectIndustry from './modules/reports/select-industry/SelectIndustry';
import KLScoringRanking from './modules/scoring-ranking/ScoringRanking';
import OAuthCallback from './components/oAuthCallback/OAuthCallback';
import ReleaseNotes from './components/release-notes/ReleaseNotes';

export default [
  {
    path: '/',
    exact: true,
    component: KLHome,
    customerComponent: KLHomeCustomer
  },
  {
    path: '/report',
    exact: true,
    component: KLHome,
    customerComponent: KLHomeCustomer
  },
  {
    path: '/report-list',
    exact: true,
    component: KLReportList,
    customerComponent: KLHomeCustomer
  },
  {
    path: '/report/select-industry',
    exact: true,
    component: KLSelectIndustry,
    customerComponent: KLHomeCustomer
  },
  {
    path: '/report/report-publish-date',
    exact: true,
    component: KLReportPublishDate,
    customerComponent: KLHomeCustomer
  },
  {
    path: '/report/app-site-select',
    exact: true,
    component: KLAppSiteSelect,
    customerComponent: KLHomeCustomer
  },
  {
    path: '/report/capabilities-table',
    exact: true,
    component: KLCapabilitiesTable,
    customerComponent: KLHomeCustomer
  },
  {
    path: '/report/capability-detail',
    exact: true,
    component: KLCapabilityDetail,
    customerComponent: KLHomeCustomer
  },
  {
    path: '/report-list/screenshot',
    exact: true,
    component: KLScreenshot,
    customerComponent: KLHomeCustomer
  },
  {
    path: '/consumer-survey',
    exact: true,
    component: KLConsumerSurvey,
    customerComponent: KLHomeCustomer
  },
  {
    path: '/oauth2callback',
    exact: true,
    component: OAuthCallback
  },
  {
    path: '/content-building',
    exact: true,
    component: KLContentBuilding
  },
  {
    path: '/content-building/executive-summary',
    exact: true,
    component: KLExecutiveSummary
  },
  {
    path: '/content-building/consumer-survey',
    exact: true,
    component: KLConsumerContentSurvey
  },
  {
    path: '/content-building/product-rating',
    exact: true,
    component: KLProductRating
  },
  {
    path: '/content-building/category-performance',
    exact: true,
    component: KLCategoryPerformanceHome
  },
  {
    path: '/content-building/category-performance&category:category',
    exact: true,
    component: KLCategoryPerformance
  },
  {
    path: '/content-building/trends-innovations',
    exact: true,
    component: KLTrendsInnovations
  },
  {
    path: '/scoring-ranking',
    exact: true,
    component: KLScoringRanking,
    customerComponent: KLHomeCustomer
  },
  {
    path: '/management/company-details',
    exact: true,
    component: CompanyDashboardDetails,
    customerComponent: KLHomeCustomer
  },
  {
    path: '/appendix/methodology',
    exact: true,
    component: Methodology
  },
  {
    path: '/appendix/raw-data',
    exact: true,
    component: AppendixRawData
  },
  {
    path: '/appendix/screenshots',
    exact: true,
    component: AppendixScreenshots
  },
  {
    path: '/admin/user-management',
    exact: true,
    component: UserManagement
  },
  {
    path: '/admin/industry-management',
    exact: true,
    component: IndustryManagement
  },
  {
    path: '/admin/industry-management/:industry/categories',
    exact: true,
    component: CategoryManagement
  },
  {
    path: '/admin/industry-management/:industry/categories/:category',
    exact: true,
    component: CategoryManagementHome
  },
  {
    path: '/admin/industry-management/:industry/categories/:category/capabilities',
    exact: true,
    component: CapabilityManagement
  },
  {
    path: '/admin/industry-management/:industry/categories/:category/surveyQuestions',
    exact: true,
    component: SurveyQuestionManagement
  },
  {
    path: '/admin/company-management',
    exact: true,
    component: CompanyManagement
  },
  {
    path: '/release-notes',
    exact: true,
    component: ReleaseNotes
  }
];
