export const CAPABILITIES_TABLE_PAGE_CHANGE_TAB = 'CAPABILITIES_TABLE_PAGE_CHANGE_TAB';
export const CAPABILITIES_TABLE_SET_APPLICATIONS = 'CAPABILITIES_TABLE_SET_APPLICATIONS';
export const CAPABILITIES_TABLE_TOGGLE_USER_RATINGS_DIALOG = 'CAPABILITIES_TABLE_TOGGLE_USER_RATINGS_DIALOG';
export function changeCapabilitiesTableTabAction(index) {
  return {
    type: CAPABILITIES_TABLE_PAGE_CHANGE_TAB,
    payload: index
  };
}

export function setReportApplications(applications) {
  return {
    type: CAPABILITIES_TABLE_SET_APPLICATIONS,
    payload: applications
  };
}

export function toggleUserRatingsDialog() {
  return {
    type: CAPABILITIES_TABLE_TOGGLE_USER_RATINGS_DIALOG
  };
}
