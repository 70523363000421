import React, { Component, Fragment } from 'react';
import AutosizeInput from 'react-input-autosize';
import { connect } from 'react-redux';
import { compose } from 'redux';
import writtenNumber from 'written-number';
import { Typography, withStyles } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import feathersServices from '../../../services/feathersServices';
import { setOverrideModal } from '../../../components/layout/actions/layout.actions';
import { debounce } from '../../../utils/immutable-utils';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { canEdit } from '../../../utils/user.utils';
import { setContentBuilding, setExucuteSummary } from '../action/ContentBuilding.actions';
import SummaryTemplateStyles from './SummaryTemplate.styles';

class SummaryTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: props.contentBuilding.executiveSummary.availableDate,
      paragraphDate: props.contentBuilding.executiveSummary.paragraphDate
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.contentBuilding !== this.props.contentBuilding) {
      this.setState({
        date: this.props.contentBuilding.executiveSummary.availableDate,
        paragraphDate: this.props.contentBuilding.executiveSummary.paragraphDate
      });
    }
  }

  handleDateParagraph = text => {
    this.setState({ paragraphDate: text });
    this.debouncedSaveContentBuilding('paragraphDate', text);
  };

  debouncedSaveContentBuilding = debounce((attr, value) => this.saveContentBuilding(attr, value), 1000);

  handleDateChange = date => {
    this.setState({ date });
    this.saveContentBuilding('availableDate', date.toDate());
  };

  saveContentBuilding = (attribute, value) => {
    const { update, setContentBuilding, contentBuilding, setOverrideModal } = this.props;

    this.props.contentBuilding.executiveSummary[attribute] = value;
    this.props.setExucuteSummary(this.props.contentBuilding.executiveSummary);

    update(contentBuilding._id, {
      $set: {
        [`executiveSummary.${attribute}`]: value
      },
      loadedAt: contentBuilding.loadedAt
    })
      .then(({ value }) => {
        setContentBuilding(value);
      })
      .catch(e => {
        if (e.code === 409)
          setOverrideModal({
            open: true,
            params: e.data.params,
            query: e.data.query,
            actualContentBuilding: e.data.actualContentBuilding
          });
      });
  };

  render() {
    const { user, dbReport, classes } = this.props;
    const { date, paragraphDate } = this.state;

    let companies = '';
    let industry = '';
    let companiesCount = 0;
    if (dbReport) {
      companiesCount = dbReport.companies.length;
      dbReport.companies.forEach(company => {
        companies = companies.concat(company.title + ', ');
      });
      industry = dbReport.industry.title;
    }

    return (
      <Fragment>
        <Typography component="span" variant="body2" color="textSecondary">
          This is the{' '}
          <AutosizeInput
            className={classes.autosize}
            name="exec_summ_paragraph_date"
            value={paragraphDate !== undefined ? paragraphDate : 'twenty-first'}
            disabled={!canEdit(user, dbReport)}
            onChange={({ target }) => this.handleDateParagraph(target.value)}
          />{' '}
          competitive review of {writtenNumber(companiesCount, { lang: 'en' })} ({companiesCount}) largest {industry?.toLowerCase()} companies in the
          U.S.: {companies} and their respective mobile sites and applications. Each mobile site and app was assessed and included in this report as
          of&nbsp;
          {canEdit(user, dbReport) ? (
            <DatePicker
              className={classes.date}
              variant="inline"
              value={date}
              format={'LL'}
              autoOk={true}
              onChange={date => this.handleDateChange(date)}
            />
          ) : (
            SingletonMomentUtils.moment(date).format('LL')
          )}
          &nbsp;.
        </Typography>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    dbReport: state.currentReport.dbReport,
    contentBuilding: state.contentBuilding
  };
};

const mapDispatchToProps = {
  update: feathersServices['content-building'].patch,
  setOverrideModal,
  setContentBuilding,
  setExucuteSummary
};

export default compose(withStyles(SummaryTemplateStyles), connect(mapStateToProps, mapDispatchToProps))(SummaryTemplate);
