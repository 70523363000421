import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import { debounceTime, Subject } from 'rxjs';
import { Box, Breadcrumbs, Button, Collapse, Grid, Typography, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import feathersServices from '../../../services/feathersServices';
import KLEditor from '../../../components/editor/KLEditor';
import { fireNotification, setScreenshotModal } from '../../../components/layout/actions/layout.actions';
import LoadingIndicator from '../../../components/loadingIndicator';
import LinkRouter from '../../../components/router-link/RouterLink';
import ScreenshotModal from '../../../components/screenshotModal/ScreenshotModal';
import KLSecurity from '../../../components/security/Security';
import { SECURITY_ACTIONS } from '../../../components/security/Security.constants';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { canEdit } from '../../../utils/user.utils';
import { USER_ROLE } from '../../../constants/user.constants';
import { clearAppendixMethodologyData, setAppendixMethodologyData } from '../action/Appendix.actions';
import { METHODOLOGY_CONSTANTS } from './Methodology.constants';
import MethodologyStyles from './Methodology.styles';

const $subject = new Subject();

const Methodology = ({
  classes,
  isPreview,
  dbReport,
  user,
  selectedIndustry,
  getMethodology,
  clearAppendixMethodologyData,
  setAppendixMethodologyData,
  updateMethodology,
  saveMethodology,
  appendix,
  methodologyService,
  getCapabilityReports,
  setScreenshotModal
}) => {
  const [editMethodology, setEditMethodology] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [allCapabilities, setAllCapabilities] = useState([]);

  useEffect(() => {
    initObservable();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    clearAppendixMethodologyData();
    setScreenshotModal({
      open: false,
      onComplete: null
    });
    getMethodology({
      query: {
        $limit: 1,
        report: dbReport._id
      }
    }).then(({ value }) => {
      setAppendixMethodologyData(value[0]);
      setIsLoading(false);
    });
    // Find capabilities for ckeditor screenshots.-
    getCapabilityReports({
      query: {
        report: dbReport._id,
        $limit: 'infinity'
      }
    }).then(data => {
      setAllCapabilities({ [dbReport._id]: data.value });
    });
  }, [getCapabilityReports, getMethodology, dbReport, clearAppendixMethodologyData, setAppendixMethodologyData, setScreenshotModal, setIsLoading]);

  const initObservable = useCallback(() => {
    const subscription = $subject.pipe(debounceTime(500)).subscribe(content => {
      handleEditorContent(content);
    });
    return () => subscription.unsubscribe();
  }, []);

  const saveOrUpdate = () => {
    if (appendix._id) {
      updateMethodology(appendix._id, appendix)
        .then(({ value }) => setAppendixMethodologyData(value))
        .finally(() => {
          setEditMethodology(!editMethodology);
          setIsSaving(false);
        });
    } else {
      appendix.report = dbReport._id;
      delete appendix._id;
      saveMethodology(appendix)
        .then(({ value }) => setAppendixMethodologyData(value))
        .finally(() => {
          setEditMethodology(!editMethodology);
          setIsSaving(false);
        });
    }
  };

  const handleEditorContent = content => {
    const es = Object.assign({}, appendix);
    es.methodology = content;
    setAppendixMethodologyData(es);
  };

  return !isLoading ? (
    <Box className={classes.centerContainer}>
      <Grid container item direction="column" className={classes.container} lg={10} xl={8}>
        <Breadcrumbs separator="›">
          <LinkRouter to="/report" color="secondary" underline="none">
            <Typography color="secondary" variant="body2">
              {user.loginType !== USER_ROLE.CUSTOMER ? 'Home' : 'Report'}
            </Typography>
          </LinkRouter>
          <LinkRouter to={canEdit(user) ? '/report/report-publish-date' : '/content-building'} color="secondary" underline="none">
            {dbReport && (
              <Typography color="secondary" variant="body2">
                {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
              </Typography>
            )}
          </LinkRouter>
          <Typography variant="body2">Methodology</Typography>
        </Breadcrumbs>
        <Box className={clsx(classes.box, isPreview ? classes.gradient : '')} id="methodology">
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4">Methodology</Typography>
            <KLSecurity action={SECURITY_ACTIONS.EDIT}>
              <IconButton onClick={() => setEditMethodology(!editMethodology)} size="small">
                <Edit style={{ marginLeft: 5, cursor: 'pointer' }} color={'secondary'} fontSize={'small'} />
              </IconButton>
            </KLSecurity>
          </Box>
          {editMethodology && (
            <Collapse in={editMethodology}>
              <KLEditor content={appendix.methodology || METHODOLOGY_CONSTANTS.METHODOLOGY} setContent={c => $subject.next(c)} />
              {editMethodology && (
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                  <Box m={1}>
                    <Button size="small" onClick={() => setEditMethodology(!editMethodology)}>
                      Cancel
                    </Button>
                  </Box>
                  <Box m={1}>
                    {isSaving ? (
                      <LoadingIndicator width={20} color="secondary" isLoading={true} thickness={3} />
                    ) : (
                      <Button
                        size="small"
                        onClick={() => {
                          setIsSaving(true);
                          setTimeout(saveOrUpdate, 500); // debounce 500ms which is the default debounce time of the debounceTime operator
                        }}
                        color="secondary"
                        variant="contained"
                        disableElevation
                      >
                        Save
                      </Button>
                    )}
                  </Box>
                </Box>
              )}
            </Collapse>
          )}
          {!editMethodology && (
            <Typography
              name="ckEditorView"
              component="div"
              variant="body2"
              color="textSecondary"
              dangerouslySetInnerHTML={{
                __html: methodologyService.data
                  ? methodologyService.data.methodology
                  : methodologyService.queryResult[0]
                  ? methodologyService.queryResult[0].methodology
                  : METHODOLOGY_CONSTANTS.METHODOLOGY
              }}
            />
          )}
        </Box>
      </Grid>
      {allCapabilities && Boolean(Object.keys(allCapabilities).length) && <ScreenshotModal filters={true} allCapabilities={allCapabilities} />}
    </Box>
  ) : (
    <LoadingIndicator isLoading={isLoading} width={40} color="secondary" />
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    dbReport: state.currentReport.dbReport,
    isPreview: state.currentReport.isPreview,
    selectedIndustry: state.currentReport.selectedIndustry,
    methodologyService: state.services.methodology,
    appendix: state.appendix
  };
};

const mapDispatchToProps = {
  updateMethodology: feathersServices['methodology'].update,
  saveMethodology: feathersServices['methodology'].create,
  getMethodology: feathersServices['methodology'].find,
  getCapabilityReports: feathersServices['capability-reports'].find,
  clearAppendixMethodologyData,
  setAppendixMethodologyData,
  fireNotification,
  setScreenshotModal,
  push
};

export default compose(withStyles(MethodologyStyles), connect(mapStateToProps, mapDispatchToProps))(Methodology);
