import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { IconButton, Snackbar, SnackbarContent, withStyles } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import NotificationStyles from './Notification.style';

const variantIcon = {
  success: CheckCircle,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

class Notification extends Component {
  render() {
    const { classes, message, open, duration, positionVertical, positionHorizontal, variant, onClose } = this.props;
    const Icon = variantIcon[variant];

    return (
      <Snackbar
        anchorOrigin={{
          vertical: positionVertical,
          horizontal: positionHorizontal
        }}
        key={{ vertical: positionVertical, horizontal: positionHorizontal }}
        open={open}
        autoHideDuration={duration}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        onClose={onClose}
        message={<span id="message-id">{message}</span>}
      >
        <SnackbarContent
          className={clsx(classes[variant], classes)}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={clsx(classes.icon, classes.iconVariant)} />
              <div
                dangerouslySetInnerHTML={{
                  __html: message
                }}
              />
            </span>
          }
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
      </Snackbar>
    );
  }
}

Notification.propTypes = {
  message: PropTypes.string,
  positionVertical: PropTypes.string,
  positionHorizontal: PropTypes.string,
  variant: PropTypes.string,
  duration: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

Notification.defaultProps = {
  message: '',
  positionVertical: 'bottom',
  positionHorizontal: 'center',
  duration: 3000,
  open: false,
  onClose: () => {}
};
export default withStyles(NotificationStyles)(Notification);
