import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Box, Grid, MenuItem, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import Close from '@material-ui/icons/Close';
import feathersServices from '../../../services/feathersServices';
import { setOverrideModal, setScreenshotModal } from '../../../components/layout/actions/layout.actions';
import { debounce } from '../../../utils/immutable-utils';
import { canEdit } from '../../../utils/user.utils';
import STATUS from '../../reports/report.constants';
import { setCategoryPerformance, setContentBuilding } from '../action/ContentBuilding.actions';
import ExperienceWidgetStyles from './ExperienceWidget.styles';

const experienceMap = {
  0: 'Good experience',
  1: 'Bad experience',
  2: 'Interesting'
};
class ExperienceWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      experiences: [],
      screenshots: [],
      openImagePreview: false,
      selectedImage: null
    };
  }

  componentDidMount() {
    const { selectedCategoryPerformance } = this.props;
    if (this.props.selectedCategoryPerformance && this.props.selectedCategoryPerformance.experiences) {
      this.setState({
        experiences: selectedCategoryPerformance.experiences
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.selectedCategoryPerformance &&
      this.props.selectedCategoryPerformance.experiences &&
      this.state.experiences.length !== this.props.selectedCategoryPerformance.experiences.length
    ) {
      this.setState({
        experiences: this.props.selectedCategoryPerformance.experiences
      });
    }

    if (prevProps.contentBuilding.categoryPerformance !== this.props.contentBuilding.categoryPerformance) {
      this.setState({
        experiences: this.props.selectedCategoryPerformance.experiences
      });
    }
  }

  handleChangeExperience = (index, key, value) => {
    const { contentBuilding, selectedCategoryPerformance } = this.props;

    const newExperience = Object.assign({}, this.state.experiences[index]);
    newExperience[key] = value;

    // Set to store.-
    const category = contentBuilding.categoryPerformance.find(cp => cp._id === selectedCategoryPerformance._id);
    const catExperience = category.experiences[index];
    catExperience[key] = value;
    this.props.setCategoryPerformance(contentBuilding.categoryPerformance);

    if (key === 'picture' || key === 'experience') {
      this.saveData(newExperience);
    } else {
      const newExperiences = [...this.state.experiences];
      newExperiences.splice(index, 1, newExperience);
      this.setState({
        experiences: newExperiences
      });
      this.debouncedSaveData(newExperience);
    }
  };

  debouncedSaveData = debounce(newExperience => this.saveData(newExperience), 1000);

  saveData = newExperience => {
    const { patchContentBuilding, contentBuilding, selectedCategoryPerformance } = this.props;

    const catIndex = contentBuilding.categoryPerformance.findIndex(cp => cp._id === selectedCategoryPerformance._id);
    const params = {
      $set: {
        [`categoryPerformance.${catIndex}.experiences.$[elem]`]: newExperience
      },
      loadedAt: contentBuilding.loadedAt
    };
    const query = {
      query: {
        $mongoose: {
          arrayFilters: [{ 'elem._id': newExperience._id }]
        }
      }
    };
    patchContentBuilding(contentBuilding._id, params, query)
      .then(contentBuilding => {
        this.props.setContentBuilding(contentBuilding.value);
        this.setState({
          experiences: this.props.selectedCategoryPerformance.experiences
        });
      })
      .catch(e => {
        if (e.code === 409)
          this.props.setOverrideModal({
            open: true,
            params: params,
            query: query,
            actualContentBuilding: e.data.actualContentBuilding
          });
      });
  };

  handleDeleteExperience = index => {
    const { patchContentBuilding, contentBuilding, selectedCategoryPerformance } = this.props;
    const { experiences } = this.state;

    const catIndex = contentBuilding.categoryPerformance.findIndex(cp => cp._id === selectedCategoryPerformance._id);

    patchContentBuilding(contentBuilding._id, {
      $pull: {
        [`categoryPerformance.${catIndex}.experiences`]: {
          _id: experiences[index]._id
        }
      }
    }).then(contentBuilding => {
      this.props.setContentBuilding(contentBuilding.value);
    });
  };

  render() {
    const { experiences, openImagePreview, selectedImage } = this.state;
    const { dbReport, user, classes, selectedCategoryPerformance } = this.props;

    const disabled =
      dbReport.status === STATUS.STATUS.PUBLISHED.STATUS ||
      dbReport.status === STATUS.STATUS.PUBLISHED_EDITED.STATUS ||
      dbReport.status === STATUS.STATUS.PUBLISHED_EDITING.STATUS;
    return (
      <Box m={3}>
        <Grid container spacing={4}>
          {selectedCategoryPerformance && selectedCategoryPerformance.experiences && selectedCategoryPerformance.experiences.length ? (
            selectedCategoryPerformance.experiences.map((item, index) => (
              <Grid item xs={4} className={classes.container} key={`exp_${index}`}>
                <Box className={classes.pictureContainer}>
                  <Box
                    className={classes.screenshots}
                    style={{ cursor: disabled ? 'default' : 'pointer' }}
                    onClick={() => {
                      if (!disabled) {
                        this.props.setScreenshotModal({
                          open: true,
                          allowEmpty: true,
                          onComplete: imageUrl => this.handleChangeExperience(this.state.selectedItem, 'picture', imageUrl)
                        });
                      } else {
                        this.setState({
                          openImagePreview: true,
                          selectedImage: item.picture
                        });
                      }
                      this.setState({ selectedItem: index });
                    }}
                  >
                    {item.picture ? <img src={item.picture} className={classes.picture} alt="Item" /> : <AddAPhoto fontSize="small" />}
                  </Box>
                  {!disabled && (
                    <IconButton className={classes.close} onClick={() => this.handleDeleteExperience(index)}>
                      <Close fontSize="small" color="primary" />
                    </IconButton>
                  )}
                </Box>
                {canEdit(user, dbReport) ? (
                  <Select
                    className={classes.select}
                    variant="outlined"
                    color="secondary"
                    disabled={disabled}
                    value={item.experience}
                    onChange={({ target }) => this.handleChangeExperience(index, 'experience', target.value)}
                    classes={{
                      root: classes.experienceSelect,
                      iconOutlined: classes.experienceSelectIcon
                    }}
                  >
                    <MenuItem value={0}>
                      <Typography variant="caption" className={classes.selectItem}>
                        Good experience
                      </Typography>
                    </MenuItem>
                    <MenuItem value={1}>
                      <Typography variant="caption" className={classes.selectItem}>
                        Bad experience
                      </Typography>
                    </MenuItem>
                    <MenuItem value={2}>
                      <Typography variant="caption" className={classes.selectItem}>
                        Interesting
                      </Typography>
                    </MenuItem>
                  </Select>
                ) : (
                  <Box mt={1}>
                    <Typography
                      variant="caption"
                      classes={{
                        root: item.experience === 0 ? classes.textGreen : item.experience === 1 ? classes.textRed : classes.textBlue
                      }}
                    >
                      {experienceMap[item.experience]}
                    </Typography>
                  </Box>
                )}
                <TextField
                  multiline
                  InputProps={{
                    disableUnderline: true
                  }}
                  classes={{
                    root: item.experience === 0 ? classes.itemTitle : item.experience === 1 ? classes.itemTitleRed : classes.itemTitleBlue
                  }}
                  className={classes.title}
                  style={{
                    borderTop: item.experience === 0 ? '4px solid green' : item.experience === 1 ? '4px solid red' : '4px solid blue'
                  }}
                  value={experiences && experiences[index] ? experiences[index].title : ''}
                  disabled={disabled}
                  placeholder="Title"
                  onChange={({ target }) => {
                    this.handleChangeExperience(index, 'title', target.value);
                  }}
                />
                <TextField
                  multiline
                  InputProps={{
                    disableUnderline: true
                  }}
                  classes={{
                    root: classes.itemText
                  }}
                  style={{ marginTop: 0 }}
                  className={classes.title}
                  onChange={({ target }) => this.handleChangeExperience(index, 'text', target.value)}
                  value={experiences && experiences[index] ? experiences[index].text : ''}
                  disabled={disabled}
                  placeholder="Click to add text..."
                />
              </Grid>
            ))
          ) : (
            <div />
          )}
        </Grid>
        <Dialog
          open={openImagePreview}
          onClose={() => {
            this.setState({ openImagePreview: false, selectedImage: null });
          }}
          maxWidth={'xl'}
        >
          <img src={selectedImage} alt="" />
        </Dialog>
      </Box>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    dbReport: state.currentReport.dbReport,
    contentBuilding: state.contentBuilding
  };
};

const mapDispatchToProps = {
  setContentBuilding,
  patchContentBuilding: feathersServices['content-building'].patch,
  setScreenshotModal,
  setOverrideModal,
  setCategoryPerformance
};

export default compose(withStyles(ExperienceWidgetStyles), connect(mapStateToProps, mapDispatchToProps))(ExperienceWidget);
