import React, { Component, Fragment } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import clsx from 'clsx';
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  Grow,
  InputAdornment,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import { DatePicker } from '@material-ui/pickers';
import feathersServices from '../../../services/feathersServices';
import KLDialog from '../../../components/dialog/KLDialog';
import { fireNotification, toggleSideMenu } from '../../../components/layout/actions/layout.actions';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import LinkRouter from '../../../components/router-link/RouterLink';
import KLSecurity from '../../../components/security/Security';
import { SECURITY_ACTIONS } from '../../../components/security/Security.constants';
import { checkMarkCompletedCompaniesAndCategories, getApplications, isContentBuildingCompleted } from '../../../utils/report.utils';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { CapabilityIcon, ContentIcon, ScoringIcon, SurveyIcon } from '../../../images';
import {
  selectDate,
  selectDbReport,
  setCompleteMap,
  setConsumerSurveyCompleted,
  setContentBuildingCompleted,
  setScoringRankingCompleted
} from '../actions/reports.actions';
import REPORT_CONSTANTS from '../report.constants';
import ReportPublishDateStyles from './ReportPublishDate.styles';

class KLReportPublishDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportComplete: false,
      isCreatingReport: false,
      startOption: null,
      isEdit: props.currentReport.dbReport ? props.currentReport.dbReport._id !== undefined : false,
      preview: false
    };
  }

  setCreatinReport = () => {
    this.setState(state => {
      return { isCreatingReport: !state.isCreatingReport };
    });
  };

  async componentDidMount() {
    this.props.onToggleSideMenu(this.state.isEdit);

    this.props
      .getReportsForIndustry({
        query: {
          industry: this.props.selectedIndustry._id,
          status: REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS,
          $sort: {
            date: -1
          },
          $populate: true,
          $limit: 1
        }
      })
      .then(({ value }) => {
        if (!Boolean(value.total)) {
          this.setState({
            startOption: 'new'
          });
        }
      });

    const promises = [];

    if (this.state.isEdit) {
      this.setState({
        startOption: 'new'
      });

      // Check for consumer survey completed.-
      promises.push(
        this.props.findConsumerSurveyService({
          query: { reportId: this.props.currentReport.dbReport._id, $limit: 1 }
        })
      );

      // check for scoring and ranking completed.-
      promises.push(
        this.props.getScoringAndRanking({
          query: {
            reportId: this.props.currentReport.dbReport._id,
            $limit: 1
          }
        })
      );

      // Check for content building completed.-
      promises.push(
        this.props.getContentBuilding({
          query: {
            reportId: this.props.currentReport.dbReport._id,
            $limit: 1
          }
        })
      );

      Promise.all(promises).then(data => {
        const isConsumerSurveyCompleted = data[0].value && data[0].value.length > 0 && data[0].value[0] != null;
        const isScoringRankingCompleted = data[1].value.total && data[1].value.data[0] != null && data[1].value.data[0].scoringAndRanking?.length > 0;
        const isCBuildingCompleted = isContentBuildingCompleted(this.props.currentReport.dbReport, data[2].value[0]);
        this.props.setConsumerSurveyCompleted(isConsumerSurveyCompleted);
        this.props.setScoringRankingCompleted(isScoringRankingCompleted);
        this.props.setContentBuildingCompleted(isCBuildingCompleted);

        this.setState({
          reportComplete:
            this.props.currentReport.completedMap.capabilitiesTable && isConsumerSurveyCompleted && isScoringRankingCompleted && isCBuildingCompleted
        });
      });

      this.props
        .getCapabilityReports({
          query: {
            report: this.props.currentReport.dbReport._id,
            $limit: 'infinity'
          }
        })
        .then(({ value: capabilityReport }) => {
          const dbReport = this.props.currentReport.dbReport;
          const applications = getApplications(this.props.currentReport.dbReport);

          const completedMap = checkMarkCompletedCompaniesAndCategories(capabilityReport, dbReport, applications);

          this.props.setCompleteMap(completedMap);
        });
    } else {
      this.props.setConsumerSurveyCompleted(false);
      this.props.setScoringRankingCompleted(false);
      this.props.setContentBuildingCompleted(false);
      await this.props.getReportsDatesForIndustry({
        query: { industry: this.props.selectedIndustry._id }
      });
      this.props.setCompleteMap({});
    }
  }

  onCapabilitiesTableClick = () => {
    this.saveReport('/report/app-site-select');
  };

  onConsumerSurveyClick = () => {
    this.saveReport('/consumer-survey');
  };

  saveReport(landing) {
    const { isEdit, isCreatingReport } = this.state;
    if (isCreatingReport) return;

    if (!isEdit) {
      this.setCreatinReport();
      this.props
        .createReport({
          industry: this.props.selectedIndustry._id,
          date: this.props.selectedDate.date,
          status: REPORT_CONSTANTS.STATUS.DRAFT.STATUS
        })
        .then(
          ({ value: savedReport }) => {
            this.props.selectDbReport(savedReport);
            this.props.onToggleSideMenu(true);
            this.props.push(landing);
            this.setCreatinReport();
          },
          ({ code, message }) => {
            this.setCreatinReport();
            this.props.fireNotification('error', message, 3000);
          }
        );
    } else {
      this.props.push(landing);
    }
  }

  handleDateChange(date) {
    this.props.onSelectDate(date.toDate(), this.props.usedDates);
  }

  renderReportActions() {
    const { currentReport } = this.props;

    return (
      <Fragment>
        <Typography variant="body2" paragraph>
          <strong>Current Status:</strong>&nbsp;
          {currentReport.dbReport._id
            ? REPORT_CONSTANTS.STATUS[currentReport.dbReport.status].STATUS_LABEL
            : REPORT_CONSTANTS.STATUS.DRAFT.STATUS_LABEL}
        </Typography>
        {
          {
            DRAFT: this.renderActionButton(REPORT_CONSTANTS.STATUS.REVIEWED.STATUS, 'Finalize for Review'),
            REVIEWED: (
              <KLSecurity action={SECURITY_ACTIONS.APPROVE}>{this.renderActionButton(REPORT_CONSTANTS.STATUS.APPROVED.STATUS, 'Approve')}</KLSecurity>
            ),
            APPROVED: (
              <KLSecurity action={SECURITY_ACTIONS.PUBLISH}>
                {this.renderActionButton(REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS, 'Publish')}
              </KLSecurity>
            )
          }[currentReport.dbReport.status]
        }
      </Fragment>
    );
  }

  renderActionButton(status, label) {
    const { reportStatus } = this.props;
    const { reportComplete } = this.state;
    return (
      <Fragment>
        {reportStatus.isSaving ? (
          <LoadingIndicator width={30} align="right" isLoading={reportStatus.isSaving} thickness={3} color="secondary" />
        ) : (
          <Button variant="outlined" color="secondary" onClick={() => this.updateReportStatus(status)} disabled={!reportComplete}>
            {label}
          </Button>
        )}
      </Fragment>
    );
  }

  updateReportStatus(status) {
    const { currentReport, push } = this.props;

    return this.props
      .updateReportStatus(currentReport.dbReport._id, {
        status
      })
      .then(({ value }) => {
        this.props.selectDbReport(value);
        if (status === REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS) {
          push('../content-building');
        }
      });
  }

  createFromReport(report) {
    this.props
      .editReport({
        industry: report.industry,
        date: report.date,
        reportId: report._id,
        newDate: this.props.selectedDate.date
      })
      .then(() => {
        this.props.push('/report-list');
        this.props.fireNotification('success', 'New report generated', 5000);
      })
      .catch(e => this.props.fireNotification('error', e.message, 5000));

    this.props.fireNotification('warning', 'A new report is being generated, please wait...', 50000);
  }

  render() {
    const { isEdit, isCreatingReport, startOption, preview } = this.state;
    const {
      selectedDate,
      selectedIndustry,
      classes,
      isConsumerSurveyCompleted,
      isScoringRankingCompleted,
      isContentBuildingCompleted,
      consumerSurveyService,
      reportAndRankingService,
      contentBuildingService,
      currentReport,
      reports
    } = this.props;

    return (
      <Box className={classes.centerContainer}>
        <Grid container item direction="column" className={classes.container} lg={11} xl={8}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/report" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                Home
              </Typography>
            </LinkRouter>
            <Typography variant="body2">
              {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(selectedDate.date).format('MMMM YYYY')}`}
            </Typography>
          </Breadcrumbs>
          <Grid item>
            <Box mt={5} mb={4}>
              <Grid container direction="row">
                <Grid item className={classes.grow} lg={8}>
                  <Typography variant="h6">When is this report targeted to be published?</Typography>
                  <Box>
                    <DatePicker
                      autoOk
                      TextFieldComponent={({ inputProps, onClick, inputRef }) => (
                        <TextField
                          value={SingletonMomentUtils.moment(selectedDate.date).format('MMMM YYYY')}
                          color="secondary"
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EventOutlinedIcon fontSize="small" />
                              </InputAdornment>
                            ),
                            style: {
                              cursor: isEdit ? 'default' : 'pointer',
                              backgroundColor: 'white'
                            }
                          }}
                          disabled={isEdit}
                          inputProps={inputProps}
                          onClick={onClick}
                          inputRef={inputRef}
                        />
                      )}
                      views={['year', 'month']}
                      value={selectedDate.date}
                      onChange={date => this.handleDateChange(date)}
                      variant="inline"
                      disabled={isEdit}
                    />
                  </Box>
                </Grid>
                <Grid item className={classes.actionButtons} lg={4}>
                  {this.renderReportActions()}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.borderBottom} />
        <Grid container item direction="column" className={classes.container} lg={11} xl={8}>
          <Box mb={2}>
            <Typography variant="h6">What would you like to start with?</Typography>
          </Box>

          {!isEdit && Boolean(reports.queryResult?.total) && (
            <Box display="flex" alignItems="center" mb={4}>
              <Button
                disableElevation
                variant={startOption === 'new' ? 'outlined' : 'text'}
                color="secondary"
                onClick={() => this.setState({ startOption: 'new' })}
              >
                A new report
              </Button>
              <Box mx={2}>
                <Divider orientation="vertical" style={{ height: 30 }} />
              </Box>
              <Button
                disableElevation
                variant={startOption === 'report' ? 'outlined' : 'text'}
                color="secondary"
                onClick={() => this.setState({ startOption: 'report' })}
              >
                From a published report
              </Button>
            </Box>
          )}

          {startOption === 'new' && (
            <Grid item container wrap="nowrap" alignItems="center" lg={12}>
              <Grid item lg={1}>
                <Typography variant="body1">Step 1</Typography>
              </Grid>

              <Grid item container direction="column" lg={3} justifyContent="center">
                <Box>
                  <Grow in={true} timeout={300}>
                    <ButtonBase style={{ marginBottom: 8 }}>
                      <Paper elevation={0} className={classes.paper} onClick={this.onCapabilitiesTableClick}>
                        <div className={currentReport.completedMap.capabilitiesTable ? classes.iconContainer : ''}>
                          <CapabilityIcon width="4em" height="4em" />
                          {currentReport.completedMap.capabilitiesTable && <Check color="secondary" className={classes.check} />}
                        </div>
                        <Typography variant="body1" className={classes.title}>
                          Capabilities Table
                        </Typography>
                      </Paper>
                    </ButtonBase>
                  </Grow>
                </Box>

                <Box>
                  <Grow in={true} timeout={600}>
                    <ButtonBase style={{ marginTop: 8 }}>
                      <Paper elevation={0} className={classes.paper} onClick={this.onConsumerSurveyClick}>
                        {consumerSurveyService.isLoading ? (
                          <div className={classes.loading}>
                            <LoadingIndicator width={40} isLoading={consumerSurveyService.isLoading} thickness={3} color="secondary" />
                          </div>
                        ) : (
                          <Fragment>
                            <div className={isConsumerSurveyCompleted ? classes.iconContainer : ''}>
                              <SurveyIcon width="4em" height="4em" />
                              {Boolean(isConsumerSurveyCompleted) && <Check color="secondary" className={classes.check} />}
                            </div>
                            <Typography variant="body1" className={classes.title}>
                              Consumer Survey
                            </Typography>
                          </Fragment>
                        )}
                      </Paper>
                    </ButtonBase>
                  </Grow>
                </Box>
              </Grid>
              <Grid item lg={1}>
                <Typography variant="body1">Step 2</Typography>
              </Grid>

              <Grid item lg={3}>
                <Grow in={true} timeout={900}>
                  <ButtonBase disabled={!(currentReport.completedMap.capabilitiesTable && isConsumerSurveyCompleted)}>
                    <Link to={'/scoring-ranking'} className={classes.link}>
                      <Paper elevation={0} className={classes.paper} onClick={this.onCapabilitiesTableClick}>
                        {reportAndRankingService.isLoading ? (
                          <div className={classes.loading}>
                            <LoadingIndicator width={40} isLoading={reportAndRankingService.isLoading} thickness={3} color="secondary" />
                          </div>
                        ) : (
                          <Fragment>
                            <div className={isScoringRankingCompleted ? classes.iconContainer : ''}>
                              <ScoringIcon
                                width="4em"
                                height="4em"
                                color={currentReport.completedMap.capabilitiesTable && isConsumerSurveyCompleted ? '#000000' : '#00000059'}
                              />
                              {Boolean(isScoringRankingCompleted) && <Check color="secondary" className={classes.check} />}
                            </div>
                            <Typography
                              variant="body1"
                              className={clsx(
                                classes.title,
                                !(currentReport.completedMap.capabilitiesTable && isConsumerSurveyCompleted) ? classes.disabled : ''
                              )}
                            >
                              Scoring & Ranking
                            </Typography>
                          </Fragment>
                        )}
                      </Paper>
                    </Link>
                  </ButtonBase>
                </Grow>
              </Grid>
              <Grid item lg={1}>
                <Typography variant="body1">Step 3</Typography>
              </Grid>
              <Grid item lg={3}>
                <Grow in={true} timeout={1200}>
                  <ButtonBase disabled={!isScoringRankingCompleted}>
                    <Link to={'/content-building'} className={classes.link}>
                      <Paper className={classes.paper} elevation={0}>
                        {contentBuildingService.isLoading ? (
                          <div className={classes.loading}>
                            <LoadingIndicator width={40} isLoading={contentBuildingService.isLoading} thickness={3} color="secondary" />
                          </div>
                        ) : (
                          <Fragment>
                            <div className={isContentBuildingCompleted ? classes.iconContainer : ''}>
                              <ContentIcon width="4em" height="4em" color={isScoringRankingCompleted ? '#000000' : '#00000059'} />
                              {Boolean(isContentBuildingCompleted) && <Check color="secondary" className={classes.check} />}
                            </div>
                            <Typography variant="body1" className={clsx(classes.title, !isScoringRankingCompleted ? classes.disabled : '')}>
                              Content Building
                            </Typography>
                          </Fragment>
                        )}
                      </Paper>
                    </Link>
                  </ButtonBase>
                </Grow>
              </Grid>
            </Grid>
          )}

          {startOption === 'report' && reports.queryResult && Array.isArray(reports.queryResult.data) && (
            <Paper elevation={0} style={{ border: '1px solid lightgrey', marginBottom: 16 }}>
              <Box px={4} py={5}>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Box>
                    <Typography variant="h6">
                      Previous Report:&nbsp;
                      {SingletonMomentUtils.moment(reports.queryResult.data[0].date).format('MMMM YYYY')}
                    </Typography>
                    <Typography>
                      Last modified:&nbsp;
                      {`${SingletonMomentUtils.moment(reports.queryResult.data[0].updatedAt).format('ll')}`}
                      {reports.queryResult.data[0].lastUpdateBy &&
                        ` by ${reports.queryResult.data[0].lastUpdateBy.name} ${reports.queryResult.data[0].lastUpdateBy.lastName}`}
                    </Typography>
                  </Box>
                  <div>
                    <Button variant="contained" disableElevation onClick={() => this.setState({ preview: true })}>
                      Preview report
                    </Button>
                    &nbsp; &nbsp;
                    <Button variant="contained" color="secondary" disableElevation onClick={() => this.createFromReport(reports.queryResult.data[0])}>
                      Start from this report
                    </Button>
                  </div>
                </Box>
              </Box>
            </Paper>
          )}
        </Grid>

        {preview && (
          <Dialog open={preview} fullScreen onClose={() => this.setState({ preview: false })}>
            <DialogTitle disableTypography>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">
                  Previous Report: {SingletonMomentUtils.moment(reports.queryResult.data[0].date).format('MMMM YYYY')}
                </Typography>
                <div>
                  <Button variant="contained" disableElevation onClick={() => this.setState({ preview: false })}>
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={() => {
                      this.setState({ preview: false });
                      this.createFromReport(reports.queryResult.data[0]);
                    }}
                  >
                    Start from this report
                  </Button>
                </div>
              </Box>
            </DialogTitle>
            <object data={reports.queryResult.data[0].urlPdf} type="application/pdf" width="100%" height="100%">
              <p>
                <a href={reports.queryResult.data[0].urlPdf}>Download PDF</a>
              </p>
            </object>
          </Dialog>
        )}

        {isCreatingReport && (
          <KLDialog
            isOpen={isCreatingReport}
            title={''}
            showButtonClose={false}
            showButtonConfirm={false}
            body={
              <Box display="flex" flexDirection="column" alignItems="center" style={{ gap: 16 }}>
                <CircularProgress color="secondary" size={25} thickness={3} />
                <Typography>
                  {`Creating report for ${selectedIndustry.title} - ${SingletonMomentUtils.moment(selectedDate.date).format('MMMM YYYY')}`}
                </Typography>
              </Box>
            }
          />
        )}
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentReport: state.currentReport,
    selectedDate: state.currentReport.selectedDate,
    selectedIndustry: state.currentReport.selectedIndustry,
    usedDates: state.services.reportDates.queryResult,
    reports: state.services.reports,
    isCapabilityCompleted: state.currentReport.capabilityCompleted,
    isConsumerSurveyCompleted: state.currentReport.consumerSurveyCompleted,
    isScoringRankingCompleted: state.currentReport.scoringRankingCompleted,
    isContentBuildingCompleted: state.currentReport.contentBuildingCompleted,
    consumerSurveyService: state.services.consumerSurvey,
    reportAndRankingService: state.services.reportAndRanking,
    contentBuildingService: state.services.contentBuilding,
    reportStatus: state.services.reportStatus
  };
};

const mapDispatchToProps = {
  onSelectDate: selectDate,
  createReport: feathersServices.reports.create,
  getReportsDatesForIndustry: feathersServices['reports-date'].find,
  getReportsForIndustry: feathersServices['reports'].find,
  findConsumerSurveyService: feathersServices['consumer-survey'].find,
  getScoringAndRanking: feathersServices['scoring-ranking'].find,
  getContentBuilding: feathersServices['content-building'].find,
  updateReportStatus: feathersServices['report-status'].update,
  getCapabilityReports: feathersServices['capability-reports'].find,
  editReport: feathersServices['report-edit'].create,
  selectDbReport,
  fireNotification,
  onToggleSideMenu: toggleSideMenu,
  setConsumerSurveyCompleted,
  setScoringRankingCompleted,
  setContentBuildingCompleted,
  setCompleteMap,
  push
};

export default compose(withStyles(ReportPublishDateStyles), connect(mapStateToProps, mapDispatchToProps))(KLReportPublishDate);
