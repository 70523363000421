import ReactEcharts from 'echarts-for-react';
import React, { Component, Fragment } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Link, default as Scroll } from 'react-scroll/modules';
import { compose } from 'redux';
import * as _ from 'lodash';
import clsx from 'clsx';
import { debounceTime, Subject } from 'rxjs';
import { Box, Breadcrumbs, Grid, MenuItem, Paper, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import Edit from '@material-ui/icons/Edit';
import feathersServices from '../../../services/feathersServices';
import KLEditor from '../../../components/editor/KLEditor';
import { clearOverrideModal, fireNotification, setScreenshotModal, toggleCategory } from '../../../components/layout/actions/layout.actions';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import LinkRouter from '../../../components/router-link/RouterLink';
import ScreenshotModal from '../../../components/screenshotModal/ScreenshotModal';
import KLSecurity from '../../../components/security/Security';
import { SECURITY_ACTIONS } from '../../../components/security/Security.constants';
import TabPanel from '../../../components/tabPanel/TabPanel';
import { orderBy } from '../../../utils/common.utils';
import { groupBy, updateObjectInArray } from '../../../utils/immutable-utils';
import { getReportCategoriesGrouped } from '../../../utils/report.utils';
import { validateProps, validateRoute } from '../../../utils/routeUtils';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { canEdit } from '../../../utils/user.utils';
import { USER_ROLE } from '../../../constants/user.constants';
import STATUS from '../../reports/report.constants';
import { setScoringAndRanking } from '../../scoring-ranking/action/ScoringRanking.actions';
import { clearContentBuilding, setCategoryPerformance, setCategoryRankByDevice, setContentBuilding } from '../action/ContentBuilding.actions';
import ContentBuildingSave from '../common/SaveContentBuilding';
import { setCategoryPerformanceApplication } from './actions/CategoryPerformance.actions';
import { CATEGORY_PERFORMANCE_CONSTANTS } from './CategoryPerformance.constants';
import CategoryPerformanceStyles from './CategoryPerformance.styles';
import ExperienceWidget from './ExperienceWidget';

class KLCategoryPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextCategory: null,
      previousCategory: null,
      selectedCategory: null,
      editNotableCallouts: false,
      editTopPerformers: false,
      loadingChart: true,
      allCapabilities: [],
      capabilitiesSet: [],
      updateHistory: '',
      options: CATEGORY_PERFORMANCE_CONSTANTS.CHART_OPTIONS,
      barSeries: [],
      lineSeries: []
    };
  }

  componentDidMount() {
    const { push, fireNotification, dbReport, toggleCategory, isScoringRankingCompleted, setScreenshotModal } = this.props;

    setScreenshotModal({
      open: false,
      onComplete: null
    });

    validateProps(push, fireNotification, dbReport);
    validateRoute(push, dbReport.status !== STATUS.STATUS.DRAFT.STATUS || isScoringRankingCompleted);

    this.props.clearOverrideModal();

    if (!this.props.contentBuilding._id) {
      this.props
        .getContentBuilding({
          query: {
            reportId: this.props.dbReport._id,
            $limit: 1
          }
        })
        .then(({ value }) => {
          value[0] ? this.props.setContentBuilding(value[0]) : this.props.clearContentBuilding();
        });
    }

    toggleCategory(true);
    this.onTabChange(null, 0);
    this.populateData();

    this.notableCalloutsInput$ = new Subject();
    this.notableCalloutsInput$.pipe(debounceTime(500)).subscribe(content => {
      this.handleEditorContent(content);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.category !== prevProps.match.params.category) {
      this.setState(
        {
          loadingChart: true,
          options: CATEGORY_PERFORMANCE_CONSTANTS.CHART_OPTIONS
        },
        () => {
          this.onTabChange(null, 0);
          this.populateData();
        }
      );
    }
  }

  async populateData() {
    const series = [];
    const reportsLegend = [];
    const legendData = [];
    const { dbReport } = this.props;
    const categoryId = decodeURIComponent(this.props.match.params.category);

    Scroll.animateScroll.scrollToTop({
      smooth: 'easeOutQuint',
      duration: 1000
    });

    let reportsFilter = [];
    if (dbReport && dbReport.industry) {
      // Find Same and previous reports.-
      reportsFilter = await this.findPreviousReports(dbReport, categoryId);
    }

    await this.findCategoryGroup(categoryId);

    let { value: scoringAndRanking } = await this.props.getScoringAndRanking({
      query: {
        reportId: this.props.dbReport._id,
        $limit: 1
      }
    });
    this.props.setScoringAndRanking(scoringAndRanking.data[0]);
    this.buildCategoriesRanks(scoringAndRanking.data[0]);

    if (dbReport) {
      // Generate chart data.-
      await this.generateChartData(reportsFilter, reportsLegend, legendData, series);
    }
    await this.generateUpdatesHistory(dbReport);
  }

  async generateUpdatesHistory(dbReport) {
    const { capabilitiesSet, options } = this.state;
    let updateHistory = '';
    const { value } = await this.props.getCapabilityChanges({
      query: {
        updatesHistory: true
      }
    });
    const changesIds = value.data.reduce((array, c) => {
      return array.concat(c._id);
    }, []);
    dbReport.companies
      .sort((a, b) => {
        return a.title > b.title ? 1 : -1;
      })
      .forEach(company => {
        const reportCap = this.state.allCapabilities[dbReport._id];
        if (reportCap) {
          const compCaps = reportCap.filter(cap => cap.company === company.companyId && changesIds.indexOf(cap.capabilityChange) !== -1);
          const capabilities = [];
          compCaps.forEach(cc => {
            const fCap = capabilitiesSet.find(cs => cs.capability._id === cc.capability);
            const fApp = this.props.applications.find(a => a.id === cc.application);
            capabilities.push({
              ...cc,
              capability: {
                title: fCap ? fCap.capability.title : ''
              },
              application: {
                title: fApp ? fApp.title : ''
              }
            });
          });

          capabilities.sort((a, b) => {
            if (a.capability.title > b.capability.title) return 1;
            return -1;
          });

          let hasCapabilities = false;
          let serie = options.series?.find(c => c.companyId === company.companyId);
          let compTemp = `<div><span style="color:${company.title === 'State Farm' ? '#e22925' : serie && serie.color}"><b>${
            company.title
          }</b></span>{capabilities}</div>`;
          let capTemp = '';
          hasCapabilities = hasCapabilities || capabilities.length > 0;
          capabilities.forEach(cap => {
            capTemp += `<p>${cap.capability.title} - ${cap.application.title} - ${value.data.find(c => c._id === cap.capabilityChange).title}`;
            if (cap.capabilityChangeNote) {
              capTemp += ` - ${cap.capabilityChangeNote}`;
            }
            capTemp += `</p>`;
          });

          if (hasCapabilities) {
            updateHistory += compTemp.replace('{capabilities}', capTemp);
          }
        }
      });
    this.setState({ updateHistory });
  }

  async findPreviousReports(dbReport, categoryGroup) {
    return new Promise(async (resolve, reject) => {
      const reportsFilter = await this.props.getPreviousReport({
        query: {
          industry: dbReport.industry._id,
          date: { $lt: dbReport.date },
          status: {
            $in: [STATUS.STATUS.PUBLISHED.STATUS, STATUS.STATUS.PUBLISHED_EDITING.STATUS]
          },
          $populate: true,
          $limit: 7,
          $sort: {
            date: -1
          }
        }
      });

      const reports = reportsFilter.value.data;
      reports.push(dbReport);
      let reportCapabilities = [];
      if (reports) {
        let catIds = [];
        for (let key in dbReport.companies) {
          catIds = catIds.concat(
            dbReport.companies[key].categories
              .filter(cat => cat.belongsTo === categoryGroup)
              .reduce((ids, cat) => {
                if (!ids.find(i => i === cat.categoryId)) {
                  ids = ids.concat(cat.categoryId);
                }
                return ids;
              }, [])
          );
        }
        const { value: capabilitiesSet } = await this.props.getCapabilityReports({
          query: {
            report: dbReport._id,
            company: dbReport.companies[0].companyId,
            application: this.props.applications[0].id,
            $populate: true,
            $limit: 'infinity'
          }
        });
        this.setState({ capabilitiesSet });

        // Remove duplicated.-
        catIds = catIds.filter((a, b) => catIds.indexOf(a) === b);

        const { value } = await this.props.getCapabilityReports({
          query: {
            category: {
              $in: catIds
            },
            report: {
              $in: reports.reduce((ids, report) => {
                return ids.concat(report._id);
              }, [])
            },
            $limit: 'infinity'
          }
        });
        reportCapabilities = value;
      }

      this.setState(
        {
          allReports: reports,
          selectedReport: reports[reports.length - 1], // The current one.-
          allCapabilities: groupBy(reportCapabilities, 'report')
        },
        () => {
          resolve(reports);
        }
      );
      // return reports;
    });
  }

  generateChartData(reportsFilter, reportsLegend, legendData, series) {
    const { allCapabilities } = this.state;

    const reports = reportsFilter.sort((a, b) => {
      if (new Date(a.date) < new Date(b.date)) {
        return -1;
      }
      return 1;
    });

    let onlyOneReport = true;
    if (reports.length > 1) {
      onlyOneReport = false;
    }

    const allCompanies = [];
    reports.reduce((arr, el) => arr.concat(el.companies), []).forEach(c => allCompanies.indexOf(c.title) === -1 && allCompanies.push(c.title));

    // For each report.-
    for (const Key in reports) {
      const report = reports[Key];

      // For each company.-
      let hasData = false;
      for (let comp of allCompanies) {
        const company = report.companies.find(c => c.title === comp);
        if (company) {
          if (this.state.selectedCategory) {
            const categoryCapabilities = report.companies
              .find(c => c.title === company.title)
              .categories.filter(c => c.belongsTo === this.state.selectedCategory.title)
              .reduce((capabilitiesReports, cat) => {
                return capabilitiesReports.concat(
                  allCapabilities[report._id].filter(cr => cr.company === company.companyId && cr.category === cat.categoryId)
                );
              }, []);

            const capabilities = groupBy(categoryCapabilities, 'hasCapability')[true];

            let categoryRating = 0;
            if (capabilities) {
              categoryRating = ((capabilities.length / categoryCapabilities.length) * 100).toFixed(3);
            }

            // Put company legend.-
            legendData.push(company.title);
            hasData = true;

            // If the series for that company doesn't exists, create it.-
            if (!series.find(s => s.name === company.title)) {
              series.push({
                id: Math.random(),
                companyId: company.companyId,
                name: company.title,
                type: 'line',
                smooth: false,
                symbolSize: 16,
                symbol: 'circle',
                data: [
                  {
                    value: categoryRating,
                    emphasis: {
                      focus: 'series'
                    },
                    itemStyle: {
                      opacity: onlyOneReport ? 1 : 0
                    }
                  }
                ]
              });
            } else {
              // If exists, find it and add the data.
              const currentSeries = series.find(s => s.name === company.title);
              // eslint-disable-next-line
              if (Key == reports.length - 1) {
                // do not change to ===
                currentSeries.data.push({
                  value: categoryRating,
                  emphasis: {
                    focus: 'series'
                  }
                });
              } else {
                currentSeries.data.push({
                  value: categoryRating,
                  emphasis: {
                    focus: 'series'
                  },
                  itemStyle: {
                    opacity: 0
                  }
                });
              }
            }
          }
        } else {
          // If the series for that company doesn't exists, create it.-
          if (!series.find(s => s.name === comp)) {
            series.push({
              id: Math.random(),
              companyId: null,
              name: comp,
              type: 'line',
              smooth: false,
              symbolSize: 16,
              symbol: 'circle',
              data: [
                {
                  value: null,
                  emphasis: {
                    focus: 'series'
                  },
                  itemStyle: {
                    opacity: onlyOneReport ? 1 : 0
                  }
                }
              ]
            });
          } else {
            // If exists, find it and add the data.
            const currentSeries = series.find(s => s.name === comp);
            // eslint-disable-next-line
            if (Key == reports.length - 1) {
              // do not change to ===
              currentSeries.data.push({
                value: null,
                emphasis: {
                  focus: 'series'
                }
              });
            } else {
              currentSeries.data.push({
                value: null,
                emphasis: {
                  focus: 'series'
                },
                itemStyle: {
                  opacity: 0
                }
              });
            }
          }
        }
      }
      // Report legend.-
      if (hasData) {
        reportsLegend.push(SingletonMomentUtils.moment(report.date).format('MMMM YYYY'));
      }
    }

    series.sort((a, b) => {
      return parseFloat(_.last(a.data)) > parseFloat(_.last(b.data)) ? -1 : 1;
    });
    let addedStateFarm = false;
    series.forEach((s, i) => {
      if (s.name === 'State Farm') {
        s.color = '#92140C';
        addedStateFarm = true;
      } else {
        s.color = CATEGORY_PERFORMANCE_CONSTANTS.CHART_OPTIONS.color[addedStateFarm ? i - 1 : i];
      }
    });

    let seriesCopy = JSON.parse(JSON.stringify(series));
    seriesCopy.forEach(s => {
      s.type = 'bar';
      s.data.forEach(d => {
        d.itemStyle = { opacity: 1 };
        d.type = 'bar';
      }); // always show symbol for bar graph
    });

    let options = Object.assign(
      {},
      this.state.options,
      { series: onlyOneReport ? seriesCopy : series },
      { legend: Object.assign({}, CATEGORY_PERFORMANCE_CONSTANTS.CHART_OPTIONS.legend, { data: legendData }) },
      {
        xAxis: {
          data: reportsLegend
        }
      }
    );

    let toolBoxOptions = CATEGORY_PERFORMANCE_CONSTANTS.CHART_OPTIONS.toolbox;
    if (onlyOneReport) {
      toolBoxOptions.feature.magicType = null;
    } else {
      toolBoxOptions.feature.restore = {
        show: true
      };
      toolBoxOptions.feature.magicType = {
        show: true,
        type: ['line', 'bar']
      };
    }

    this.setState({
      loadingChart: false,
      options: Object.assign({}, options, { toolbox: toolBoxOptions }),
      lineSeries: series,
      barSeries: seriesCopy
    });
  }

  buildCategoriesRanks = scoringAndRanking => {
    let categoryRanksByDevice = {};
    this.props.applications.forEach(application => {
      categoryRanksByDevice[application.title] = [];
      const valueRank = this.props.orderedOverallRanks[application.title];
      if (valueRank) {
        valueRank.forEach(score => {
          if (scoringAndRanking && scoringAndRanking.scoringAndRanking.find(c => c.companyName === score.company)) {
            const scoringRanking = groupBy(
              scoringAndRanking.scoringAndRanking
                .find(c => c.companyName === score.company)
                .applications[application.title].categories.filter(c => c.belongsTo === this.state.selectedCategory.title)
                .reduce((capabilitiesReports, cat) => {
                  return capabilitiesReports.concat(cat.capabilityReports);
                }, []),
              'hasCapability'
            )[true];

            categoryRanksByDevice[application.title].push({
              company: score.company,
              score: scoringRanking
                ? Math.round(
                    (scoringRanking.length * 100) /
                      scoringAndRanking.scoringAndRanking
                        .find(c => c.companyName === score.company)
                        .applications[application.title].categories.filter(c => c.belongsTo === this.state.selectedCategory.title)
                        .reduce((capabilitiesReports, cat) => {
                          return capabilitiesReports.concat(cat.capabilityReports);
                        }, []).length
                  )
                : 0
            });
          }
        });
      }
    });

    for (let application in categoryRanksByDevice) {
      categoryRanksByDevice[application] = orderBy(categoryRanksByDevice[application], 'score');
    }

    this.props.setCategoryRankByDevice(this.state.selectedCategory ? this.state.selectedCategory.title : '', categoryRanksByDevice);
  };

  findCategoryGroup = categoryGroup => {
    const { dbReport, applications, tab } = this.props;
    const { allCapabilities } = this.state;
    if (dbReport && dbReport.companies) {
      dbReport.companies.forEach(company => {
        this.setState(
          {
            selectedCategory: {
              title: categoryGroup,
              capabilityReports: company.categories
                .filter(cat => cat.belongsTo === categoryGroup)
                .reduce((capabilitiesReports, cat) => {
                  if (!allCapabilities[dbReport._id]) return [];
                  allCapabilities[dbReport._id]
                    .filter(cr => cr.company === company.companyId && cr.category === cat.categoryId)
                    .filter(cr => {
                      return tab > 0 ? cr.application === applications[tab - 1].id : cr;
                    })
                    .forEach(catCr => {
                      if (!capabilitiesReports.find(cr => cr._id === catCr._id)) {
                        capabilitiesReports.push(catCr);
                      }
                    });
                  return capabilitiesReports;
                }, [])
            }
          },
          () => {
            const categoriesGrouped = getReportCategoriesGrouped(this.props.dbReport);
            const index = categoriesGrouped.findIndex(c => c.categoryGroup === this.state.selectedCategory.title);
            this.setState({
              previousCategory: categoriesGrouped[index - 1],
              nextCategory: categoriesGrouped[index + 1]
            });
          }
        );
      });
    }
  };

  onTabChange = (e, index) => {
    this.props.setCategoryPerformanceApplication(index);
  };

  handleEditorContent = content => {
    const { selectedCategory } = this.state;
    const { applications, tab, categoryPerformance } = this.props;

    let nc = categoryPerformance.find(nc => nc.applicationId === applications[tab - 1].id && nc.categoryGroup === selectedCategory.title);
    if (!nc) {
      this.props.setCategoryPerformance([
        ...categoryPerformance,
        {
          categoryGroup: selectedCategory.title,
          applicationId: applications[tab - 1].id,
          notableCallouts: content,
          experiences: []
        }
      ]);
    } else {
      nc.notableCallouts = content;
    }

    if (nc) {
      this.props.setCategoryPerformance(categoryPerformance);
    }
  };

  buildCapabilitiesData(capabilities) {
    const { applications, tab, classes } = this.props;
    const { selectedReport, selectedCategory, allReports, allCapabilities, capabilitiesSet } = this.state;

    if (selectedReport) {
      /* Create a capability list */
      selectedCategory.capabilityReports
        .filter(cr => cr.application === applications[tab - 1].id)
        .forEach(async c => {
          const fCap = capabilitiesSet.find(cs => cs.capability._id === c.capability);
          const capability = {
            title: fCap ? fCap.capability.title : ''
          };
          /* Use the company as a key */
          selectedReport.companies.forEach(company => {
            /* Find the category for that company */
            /* Find the capability for that category */
            const cr = company.categories
              .filter(c => c.belongsTo === selectedCategory.title && c.applicationId === applications[tab - 1].id)
              .reduce((capabilitiesReports, cat) => {
                return capabilitiesReports.concat(
                  allCapabilities[selectedReport._id].filter(
                    cr => cr.company === company.companyId && cr.application === applications[tab - 1].id && cr.category === cat.categoryId
                  )
                );
              }, [])
              .find(cr => {
                return cr.capability === c.capability;
              });

            capability[company.title] = {
              hasCapability: cr ? cr.hasCapability : false
            };
          });

          /* Find the capability for the previous report */
          const currentIndex = allReports.findIndex(r => r._id === selectedReport._id);
          if (allReports[currentIndex - 1]) {
            /* For each company */
            for (const key in allReports[currentIndex - 1].companies) {
              const company = allReports[currentIndex - 1].companies[key];
              /* Find the category */
              const cr = company.categories
                .filter(c => c.belongsTo === selectedCategory.title && c.applicationId === applications[tab - 1].id)
                .reduce((capabilitiesReports, cat) => {
                  return capabilitiesReports.concat(
                    allCapabilities[allReports[currentIndex - 1]._id].filter(cr => {
                      return cr.company === company.companyId && cr.application === applications[tab - 1].id && cr.category === cat.categoryId;
                    })
                  );
                }, [])
                .find(cr => cr.capability === c.capability);

              //Check if the capability exists for the company (may be deleted companies).-
              if (capability[company.title]) {
                capability[company.title].hasCapabilityBefore = cr ? cr.hasCapability : false;

                /**
                 * Style guide:
                 * Checked & Orange: did not have it before, and have it now.-
                 * UnChecked & Orange: used to have it, but dont have it anymore.-
                 * Black: Same as previous report.-
                 */
                capability[company.title].classBase =
                  capability[company.title].hasCapability !== capability[company.title].hasCapabilityBefore ? classes.changedCapability : '';
              }
            }
          }
          capabilities.push(capability);
        });
    }
  }

  populateSelectedReport(target) {
    const { allReports } = this.state;
    this.props
      .getContentBuilding({
        query: {
          reportId: target.value,
          $limit: 1
        }
      })
      .then(({ value }) => {
        const selectedReport = allReports.find(r => r._id === target.value);
        this.setState({
          selectedReport,
          oldCategoryPerformance: value[0] ? value[0].categoryPerformance : undefined
        });
        this.generateUpdatesHistory(selectedReport);
      });
  }

  buildCategoryContent(selectedApp) {
    const { categoryPerformance } = this.props;
    const { selectedCategory } = this.state;

    const selectedCategoryPerformance = categoryPerformance.find(
      nc => nc.applicationId === selectedApp && nc.categoryGroup === selectedCategory.title
    );

    return {
      selectedCategoryPerformance
    };
  }

  handleAddExperience = async selectedCategoryPerformance => {
    const { selectedCategory } = this.state;
    const { applications, tab, contentBuilding, patchContentBuilding } = this.props;

    let params = {};
    if (!selectedCategoryPerformance) {
      const newCatPerf = {
        categoryGroup: selectedCategory.title,
        applicationId: applications[tab - 1].id,
        notableCallouts: '',
        experiences: [
          {
            picture: '',
            experience: 0,
            title: '',
            text: ''
          }
        ]
      };
      params = {
        $push: {
          categoryPerformance: newCatPerf
        }
      };
    } else {
      const index = contentBuilding.categoryPerformance.findIndex(cp => cp._id === selectedCategoryPerformance._id);

      params = {
        $push: {
          [`categoryPerformance.${index}.experiences`]: {
            picture: '',
            experience: 0,
            title: '',
            text: ''
          }
        }
      };
    }

    patchContentBuilding(contentBuilding._id, params).then(contentBuilding => {
      this.props.setContentBuilding(contentBuilding.value);
    });
  };

  onChartTypeChanged = ({ currentType }) => {
    const { options, barSeries, lineSeries } = this.state;
    switch (currentType) {
      case 'bar':
        this.setState({
          options: Object.assign({}, options, { series: barSeries })
        });
        break;
      case 'line':
        this.setState({
          options: Object.assign({}, options, { series: lineSeries })
        });
        break;
      default:
        this.setState({
          options: Object.assign({}, options, { series: lineSeries })
        });
        break;
    }
  };

  render() {
    const { dbReport, selectedIndustry, classes, user, applications, tab, categoryRankByDevice, isPreview } = this.props;
    const {
      selectedCategory,
      editNotableCallouts,
      previousCategory,
      nextCategory,
      options,
      loadingChart,
      allReports,
      selectedReport,
      allCapabilities
    } = this.state;

    const capabilities = [];
    let selectedCategoryPerformance = undefined;
    let notableCalloutsView = 'Click on pencil icon to begin writing analysis for this section.';
    if (selectedCategory) {
      const selectedApp = tab !== 0 ? applications[tab - 1].id : undefined;
      const content = this.buildCategoryContent(selectedApp);
      selectedCategoryPerformance = content.selectedCategoryPerformance;

      if (selectedApp) {
        this.buildCapabilitiesData(capabilities);
      }

      if (!this.props.contentBuildingService.isLoading) {
        const categoryPerf =
          (this.props.contentBuildingService.data && this.props.contentBuildingService.data.categoryPerformance) ||
          (this.props.contentBuildingService.queryResult &&
            this.props.contentBuildingService.queryResult[0] &&
            this.props.contentBuildingService.queryResult[0].categoryPerformance);

        if (categoryPerf) {
          const selectedCatPef = categoryPerf.find(cp => cp.applicationId === selectedApp && cp.categoryGroup === selectedCategory.title);

          if (selectedCatPef) {
            notableCalloutsView = selectedCatPef.notableCallouts;
          }
        }
      }
    }

    return (
      <Fragment>
        <Box className={classes.centerContainer}>
          <Grid container direction="column" className={classes.container} item lg={10} xl={8}>
            <Breadcrumbs separator="›">
              <LinkRouter to="/report" color="secondary" underline="none">
                <Typography color="secondary" variant="body2">
                  {user.loginType !== USER_ROLE.CUSTOMER ? 'Home' : 'Report'}
                </Typography>
              </LinkRouter>
              <LinkRouter to={canEdit(user, dbReport) ? '/report/report-publish-date' : '/content-building'} color="secondary" underline="none">
                {dbReport && (
                  <Typography color="secondary" variant="body2">
                    {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
                  </Typography>
                )}
              </LinkRouter>
              {user.loginType !== USER_ROLE.CUSTOMER && (
                <LinkRouter to="/content-building" color="secondary" underline="none">
                  <Typography color="secondary" variant="body2">
                    Content Building
                  </Typography>
                </LinkRouter>
              )}
              <LinkRouter to="/content-building/category-performance" color="secondary" underline="none">
                <Typography color="secondary" variant="body2">
                  Category Performance
                </Typography>
              </LinkRouter>
              <Typography variant="body2">{selectedCategory ? selectedCategory.title : ''}</Typography>
            </Breadcrumbs>
            <Box mt={5} mb={4}>
              <Typography variant="h4">{selectedCategory ? selectedCategory.title : ''}</Typography>
            </Box>
            {this.props.capabilityReportsService.isLoading ? (
              <LoadingIndicator isLoading={true} color="secondary" width={40} />
            ) : (
              <Box mt={4} className={classes.box}>
                <Tabs value={tab || 0} onChange={this.onTabChange} className={classes.borderBottom}>
                  <Tab label="Overview" value={0} />
                  {applications.map((application, i) => {
                    return <Tab key={i} label={application.title} value={i + 1} />;
                  })}
                </Tabs>

                {/* OVERVIEW */}
                <TabPanel value={tab} index={0}>
                  <Box className={classes.box} id="cr">
                    <Typography className={classes.title}>Contents</Typography>
                    <Link to={`${selectedCategory ? selectedCategory.title : ''} Summary`} duration={1000} smooth="easeOutQuint">
                      <Typography color="secondary" variant="body2" className={classes.link}>
                        {selectedCategory ? selectedCategory.title + ' Summary' : ''}
                      </Typography>
                    </Link>
                    <Link to="updatesHistory" duration={1000} smooth="easeOutQuint">
                      <Typography color="secondary" variant="body2" className={classes.link}>
                        Updates History
                      </Typography>
                    </Link>
                    <Box mt={4} id={`${selectedCategory ? selectedCategory.title : ''} Summary`}>
                      <Typography className={classes.title}>{selectedCategory ? selectedCategory.title : ''} Summary</Typography>
                      <LoadingIndicator color="secondary" isLoading={loadingChart} width={40} />
                      {!loadingChart && (
                        <Fragment>
                          <ReactEcharts
                            className={classes.chart}
                            lazyUpdate={true}
                            option={options}
                            onEvents={{
                              magictypechanged: this.onChartTypeChanged
                            }}
                          />
                          <Box mt={4}>
                            <Typography variant="body2" color="textSecondary">
                              Click on a company name above to toggle view.
                            </Typography>
                          </Box>
                        </Fragment>
                      )}
                    </Box>
                    <Box mt={4} id="updatesHistory">
                      <Typography className={classes.title}>Updates History</Typography>

                      {!isPreview && allReports && (
                        <Select
                          className={classes.reportDropdown}
                          displayEmpty={false}
                          variant="outlined"
                          color="secondary"
                          margin="dense"
                          value={selectedReport._id}
                          onChange={({ target }) => {
                            this.populateSelectedReport(target);
                          }}
                          renderValue={value => (
                            <Fragment>
                              <Typography variant="caption">Showing:</Typography>
                              <Typography color="secondary">{`${SingletonMomentUtils.moment(allReports.find(r => r._id === value).date).format(
                                'MMMM YYYY'
                              )}`}</Typography>
                            </Fragment>
                          )}
                        >
                          {allReports.map((report, i) => (
                            <MenuItem key={i} value={report._id}>
                              <Typography color="secondary">{`${SingletonMomentUtils.moment(report.date).format('MMMM YYYY')}`}</Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      <Box mt={4}>
                        <div
                          className={classes.updateHistory}
                          dangerouslySetInnerHTML={{
                            __html: this.state.updateHistory !== '' ? this.state.updateHistory : 'No updates'
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>

                {/* APPLICATIONS */}
                {applications.map((application, i) => {
                  return (
                    <TabPanel key={i} value={tab} index={i + 1}>
                      <Box className={classes.box}>
                        <Typography variant="h5">{application.title}</Typography>
                      </Box>
                      <Box className={classes.box}>
                        <Typography className={classes.title}>Contents</Typography>
                        <Link to={`${selectedCategory ? selectedCategory.title : ''}`} duration={1000} smooth="easeOutQuint">
                          <Typography color="secondary" variant="body2" className={classes.link}>
                            {`${selectedCategory ? selectedCategory.title : ''} Performance`}
                          </Typography>
                        </Link>
                        <Link to="notableCallouts" duration={1000} smooth="easeOutQuint">
                          <Typography color="secondary" variant="body2" className={classes.link}>
                            Notable Callouts
                          </Typography>
                        </Link>
                        <Link to="capabilities" duration={1000} smooth="easeOutQuint">
                          <Typography color="secondary" variant="body2" className={classes.link}>
                            Capabilities
                          </Typography>
                        </Link>
                      </Box>
                      <Box className={classes.box} id={`${selectedCategory ? selectedCategory.title : ''}`}>
                        <Typography className={classes.title}>{`${selectedCategory ? selectedCategory.title : ''} Performance`}</Typography>
                        <Box mb={3}>
                          <Typography variant="body2" color="textSecondary">
                            Overall ratings are determined by combining each respective brand’s capability and usability scores. For more details,
                            &nbsp;
                            <LinkRouter to="/appendix/methodology" color="secondary" underline="none">
                              read about our methodology.
                            </LinkRouter>
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <Paper elevation={0} className={classes.table}>
                            <Table size="medium" aria-label="a dense table">
                              <TableHead>
                                <TableRow className={classes.row}>
                                  <TableCell align="center" className={classes.tableHeader}>
                                    Rank
                                  </TableCell>
                                  <TableCell align="left" className={classes.tableHeader}>
                                    Brand
                                  </TableCell>
                                  <TableCell align="center" className={classes.tableHeader}>
                                    Capability Score
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {categoryRankByDevice &&
                                  selectedCategory &&
                                  categoryRankByDevice[selectedCategory.title] &&
                                  categoryRankByDevice[selectedCategory.title][application.title]
                                    .sort((a, b) => {
                                      return a.score > b.score ? -1 : 1;
                                    })
                                    .map((score, index) => {
                                      return (
                                        <TableRow key={index} className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}>
                                          <TableCell align="center">
                                            <Typography className={classes.tableText} color="textSecondary">
                                              #{index + 1}
                                            </Typography>
                                          </TableCell>
                                          <TableCell align="left">
                                            <Typography className={classes.tableText} color="textSecondary">
                                              {score.company}
                                            </Typography>
                                          </TableCell>
                                          <TableCell align="center">
                                            <Typography className={classes.tableText} color="textSecondary">
                                              {score.score + '%'}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                              </TableBody>
                            </Table>
                          </Paper>
                        </Box>
                      </Box>
                      <Box className={classes.box} id="notableCallouts">
                        <Typography className={classes.title}>
                          Notable Callouts
                          <KLSecurity action={SECURITY_ACTIONS.EDIT}>
                            <IconButton
                              onClick={() =>
                                this.setState({
                                  editNotableCallouts: !editNotableCallouts
                                })
                              }
                              size="small"
                            >
                              <Edit style={{ marginLeft: 5 }} color={'secondary'} fontSize={'small'} />
                            </IconButton>
                          </KLSecurity>
                        </Typography>
                        {editNotableCallouts && (
                          <Collapse in={editNotableCallouts}>
                            <KLEditor
                              content={selectedCategoryPerformance ? selectedCategoryPerformance.notableCallouts : ''}
                              setContent={c => this.notableCalloutsInput$.next(c)}
                            />
                            {selectedCategoryPerformance && (
                              <ContentBuildingSave
                                params={
                                  !selectedCategoryPerformance._id
                                    ? {
                                        $push: {
                                          categoryPerformance: selectedCategoryPerformance
                                        }
                                      }
                                    : {
                                        $set: {
                                          [`categoryPerformance.$[elem].notableCallouts`]: selectedCategoryPerformance.notableCallouts
                                        },
                                        loadedAt: this.props.contentBuilding.loadedAt
                                      }
                                }
                                query={{
                                  query: {
                                    $mongoose: {
                                      arrayFilters: [
                                        {
                                          'elem._id': selectedCategoryPerformance._id
                                        }
                                      ],
                                      multi: true
                                    }
                                  }
                                }}
                                onSave={() =>
                                  this.setState({
                                    editNotableCallouts: !editNotableCallouts
                                  })
                                }
                                onCancel={() => {
                                  const scpClone = JSON.parse(JSON.stringify(selectedCategoryPerformance));
                                  scpClone.notableCallouts = notableCalloutsView;

                                  const index = this.props.contentBuilding.categoryPerformance.findIndex(
                                    cp => cp._id === selectedCategoryPerformance._id
                                  );

                                  this.props.setCategoryPerformance(
                                    updateObjectInArray(this.props.contentBuilding.categoryPerformance, {
                                      index,
                                      item: scpClone
                                    })
                                  );
                                  this.setState({
                                    editNotableCallouts: !editNotableCallouts
                                  });
                                }}
                              />
                            )}
                          </Collapse>
                        )}
                        {!editNotableCallouts && selectedCategory && (
                          <Typography
                            name="ckEditorView"
                            component="div"
                            variant="body2"
                            color="textSecondary"
                            dangerouslySetInnerHTML={{
                              __html: notableCalloutsView
                            }}
                          />
                        )}
                        <KLSecurity action={SECURITY_ACTIONS.EDIT}>
                          <Box mt={3} style={{ display: 'flex' }}>
                            <Button
                              style={{ textTransform: 'none' }}
                              variant="text"
                              color="secondary"
                              onClick={() => this.handleAddExperience(selectedCategoryPerformance)}
                            >
                              Add item
                            </Button>
                          </Box>
                        </KLSecurity>
                        {selectedCategoryPerformance && (
                          <ExperienceWidget dbReport={dbReport} selectedCategoryPerformance={selectedCategoryPerformance} />
                        )}
                      </Box>
                      <Box className={classes.box} id="capabilities">
                        <Typography className={classes.title}>Capabilities</Typography>
                        {!isPreview && allReports && (
                          <Select
                            className={classes.reportDropdown}
                            displayEmpty={false}
                            value={selectedReport._id}
                            variant="outlined"
                            color="secondary"
                            margin="dense"
                            onChange={({ target }) => this.populateSelectedReport(target)}
                            renderValue={value => (
                              <Fragment>
                                <Typography variant="caption">Showing:</Typography>
                                <Typography color="secondary">{`${SingletonMomentUtils.moment(allReports.find(r => r._id === value).date).format(
                                  'MMMM YYYY'
                                )}`}</Typography>
                              </Fragment>
                            )}
                          >
                            {allReports.map((report, i) => (
                              <MenuItem key={i} value={report._id}>
                                <Typography color="secondary">{`${SingletonMomentUtils.moment(report.date).format('MMMM YYYY')}`}</Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        )}

                        {selectedReport && (
                          <Box mt={2} mb={2}>
                            <Typography>{`${SingletonMomentUtils.moment(selectedReport.date).format('MMMM YYYY')}`}</Typography>
                          </Box>
                        )}
                        <Paper elevation={0} className={classes.tableBroad}>
                          <Table size="medium" aria-label="a dense table">
                            <TableHead>
                              <TableRow className={classes.row}>
                                <TableCell align="left" className={classes.tableHeader}>
                                  Capability
                                </TableCell>
                                {selectedCategory &&
                                  tab &&
                                  Object.keys(categoryRankByDevice).length &&
                                  [...categoryRankByDevice[selectedCategory.title][applications[tab - 1].title]]
                                    .sort((a, b) => {
                                      return a.company > b.company ? 1 : -1;
                                    })
                                    .map((company, index) => {
                                      return (
                                        <TableCell key={index} align="center" className={classes.tableHeader}>
                                          {company.company}
                                        </TableCell>
                                      );
                                    })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {capabilities
                                .sort((a, b) => {
                                  return a.title > b.title ? 1 : -1;
                                })
                                .map((capability, index) => {
                                  return (
                                    <TableRow key={index} className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}>
                                      <TableCell align="left">
                                        <Typography className={classes.tableText} color="textSecondary">
                                          {capability.title}
                                        </Typography>
                                      </TableCell>
                                      {categoryRankByDevice[selectedCategory.title][applications[tab - 1].title]
                                        .sort((a, b) => {
                                          return a.company > b.company ? 1 : -1;
                                        })
                                        .map((company, index) => {
                                          return (
                                            <TableCell key={index} align="center">
                                              {capability[company.company] ? (
                                                capability[company.company].hasCapability ? (
                                                  <Check fontSize="small" className={clsx(classes.checked, capability[company.company].classBase)} />
                                                ) : (
                                                  <Check
                                                    fontSize="small"
                                                    className={clsx(classes.unchecked, capability[company.company].classBase)}
                                                  />
                                                )
                                              ) : (
                                                ''
                                              )}
                                            </TableCell>
                                          );
                                        })}
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </Paper>
                      </Box>
                    </TabPanel>
                  );
                })}
              </Box>
            )}
          </Grid>
        </Box>

        {!isPreview && (
          <Box style={{ borderTop: 'solid 1px #e1e1e1' }}>
            <Box pl={8} pr={8} mt={4}>
              {previousCategory && (
                <LinkRouter
                  style={{ float: 'left' }}
                  to={`/content-building/category-performance&category${encodeURIComponent(previousCategory.categoryGroup)}`}
                  color="secondary"
                  underline="none"
                >
                  <ListItemText
                    primary={
                      <Typography>
                        ← <b>Previous</b> <br />
                        {previousCategory.categoryGroup}
                      </Typography>
                    }
                  />
                </LinkRouter>
              )}

              {nextCategory && (
                <LinkRouter
                  style={{ float: 'right' }}
                  to={`/content-building/category-performance&category${encodeURIComponent(nextCategory.categoryGroup)}`}
                  color="secondary"
                  underline="none"
                >
                  <ListItemText
                    primary={
                      <Typography style={{ textAlign: 'right' }}>
                        <b>Next</b> → <br />
                        {nextCategory.categoryGroup}
                      </Typography>
                    }
                  />
                </LinkRouter>
              )}
            </Box>
          </Box>
        )}

        {allCapabilities && Boolean(Object.keys(allCapabilities).length) && (
          <ScreenshotModal filters={false} selectedApp={tab !== 0 ? applications[tab - 1].id : undefined} allCapabilities={allCapabilities} />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    dbReport: state.currentReport.dbReport,
    isPreview: state.currentReport.isPreview,
    selectedIndustry: state.currentReport.selectedIndustry,
    applications: state.currentReport.applications,
    tab: state.ui.categoryPerformance.tab,
    orderedOverallRanks: state.scoringAndRanking.orderedOverallRanks,
    scoringAndRanking: state.scoringAndRanking.scoringAndRanking,
    contentBuilding: state.contentBuilding,
    categoryRankByDevice: state.contentBuilding.categoryRankByDevice,
    categoryPerformance: state.contentBuilding.categoryPerformance,
    capabilityReportsService: state.services.capabilityReports,
    isScoringRankingCompleted: state.currentReport.scoringRankingCompleted,
    contentBuildingService: state.services.contentBuilding,
    screenshotModal: state.ui.layout.screenshotModal
  };
};

const mapDispatchToProps = {
  push,
  fireNotification,
  getPreviousReport: feathersServices.reports.find,
  getContentBuilding: feathersServices['content-building'].find,
  setCategoryPerformanceApplication,
  setCategoryRankByDevice,
  setScoringAndRanking,
  setCategoryPerformance,
  setContentBuilding,
  toggleCategory,
  getScoringAndRanking: feathersServices['scoring-ranking'].find,
  getCapabilityReports: feathersServices['capability-reports'].find,
  getCapabilityChanges: feathersServices['capability-changes'].find,
  patchContentBuilding: feathersServices['content-building'].patch,
  clearContentBuilding,
  setScreenshotModal,
  clearOverrideModal
};

export default compose(withStyles(CategoryPerformanceStyles), connect(mapStateToProps, mapDispatchToProps))(KLCategoryPerformance);
