export default theme => ({
  '@global': {
    '.ck-content': {
      minHeight: 300
    },
    '.ql-toolbar': {
      background: '#f2f2f2',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5
    }
  },
  editor: {
    backgroundColor: theme.palette.primary.main
  }
});
