import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Box, Breadcrumbs, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import feathersServices from '../../../services/feathersServices';
import Notification from '../../../components/notification/Notification';
import LinkRouter from '../../../components/router-link/RouterLink';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { canEdit } from '../../../utils/user.utils';
import { USER_ROLE } from '../../../constants/user.constants';
import KLScreenshot from '../../reports/screenshot/Screenshot';
import AppendixScreenshotsStyles from './AppendixScreenshots.styles';

class AppendixScreenshots extends React.Component {
  state = {
    buyAction: false
  };

  render() {
    const { dbReport, isPreview, buyReport, classes, user, selectedIndustry } = this.props;
    const { buyAction } = this.state;
    return isPreview ? (
      <Box className={classes.centerContainer}>
        <Grid container direction="column" className={classes.container} lg={10} xl={8}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/report" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                {user.loginType !== USER_ROLE.CUSTOMER ? 'Home' : 'Report'}
              </Typography>
            </LinkRouter>
            <LinkRouter to={canEdit(user) ? '/report/report-publish-date' : '/content-building'} color="secondary" underline="none">
              {dbReport && (
                <Typography color="secondary" variant="body2">
                  {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
                </Typography>
              )}
            </LinkRouter>
            <Typography variant="body2">Screenshots</Typography>
          </Breadcrumbs>
          <Box mt={5} mb={4}>
            <Typography variant="h4">Screenshots</Typography>
          </Box>

          <Box>
            <div className={classes.buyMessage}>
              <Typography variant="h5">Buy report to see this information.</Typography>
              &nbsp;
              <Button color="secondary" variant="contained" disableElevation>
                <Typography
                  variant="overline"
                  onClick={() => {
                    this.setState({ buyAction: true });
                    buyReport({ rid: dbReport._id });
                  }}
                >
                  Buy Report
                </Typography>
              </Button>
              <Notification
                message={'An email has been sent to Sales Department, we will contact you soon.'}
                open={buyAction}
                variant="success"
                duration={10000}
                onClose={(event, reason) => {
                  if (reason === 'clickaway') {
                    return;
                  }
                  this.setState({ buyAction: false });
                }}
              />
            </div>
          </Box>
        </Grid>
      </Box>
    ) : (
      <KLScreenshot viewOnly={true} />
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    selectedIndustry: state.currentReport.selectedIndustry,
    dbReport: state.currentReport.dbReport,
    isPreview: state.currentReport.isPreview
  };
};

const mapDispatchToProps = {
  buyReport: feathersServices['report-status'].create
};

export default compose(withStyles(AppendixScreenshotsStyles), connect(mapStateToProps, mapDispatchToProps))(AppendixScreenshots);
