import {
  CLEAR_CONTENT_BUILDING_DATA,
  CONTENT_BUILDING_DATA,
  CONTENT_BUILDING_SET_CATEGORY_PERFORMANCE,
  CONTENT_BUILDING_SET_EXUCUTESUMMARY,
  CONTENT_BUILDING_SET_PRODUCTRATING,
  CONTENT_BUILDING_SET_TRENDS,
  SET_BACK_CONTENT_BUILDING,
  SET_CATEGORY_RANK_BY_DEVICE
} from '../action/ContentBuilding.actions';

const initialState = {
  _id: null,
  reportId: null,
  executiveSummary: {
    availableDate: new Date()
  },
  productRating: {},
  categoryPerformance: [],
  categoryRankByDevice: {},
  trendsInnovations: [],
  back2ContentBuilding: false,
  originalContentBuilding: {
    _id: null,
    reportId: null,
    executiveSummary: {
      availableDate: new Date()
    },
    productRating: {},
    categoryPerformance: [],
    trendsInnovations: []
  }
};

const contentBuildingReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CONTENT_BUILDING_DATA:
      return initialState;
    case CONTENT_BUILDING_SET_EXUCUTESUMMARY:
      return Object.assign({}, state, { executiveSummary: action.payload });
    case CONTENT_BUILDING_SET_PRODUCTRATING:
      return Object.assign({}, state, { productRating: action.payload });
    case CONTENT_BUILDING_SET_TRENDS:
      return Object.assign({}, state, { trendsInnovations: action.payload });
    case CONTENT_BUILDING_DATA:
      return {
        _id: action.payload._id,
        reportId: action.payload.reportId,
        executiveSummary: action.payload.executiveSummary
          ? JSON.parse(JSON.stringify(action.payload.executiveSummary))
          : initialState.executiveSummary,
        productRating: action.payload.productRating ? JSON.parse(JSON.stringify(action.payload.productRating)) : initialState.productRating,
        categoryPerformance: action.payload.categoryPerformance
          ? JSON.parse(JSON.stringify(action.payload.categoryPerformance))
          : initialState.categoryPerformance,
        trendsInnovations: action.payload.trendsInnovations
          ? JSON.parse(JSON.stringify(action.payload.trendsInnovations))
          : initialState.trendsInnovations,
        categoryRankByDevice: state.categoryRankByDevice ? JSON.parse(JSON.stringify(state.categoryRankByDevice)) : initialState.categoryRankByDevice,
        loadedAt: new Date(),
        originalContentBuilding: {
          _id: action.payload._id,
          reportId: action.payload.reportId,
          executiveSummary: action.payload.executiveSummary
            ? JSON.parse(JSON.stringify(action.payload.executiveSummary))
            : initialState.executiveSummary,
          productRating: action.payload.productRating ? JSON.parse(JSON.stringify(action.payload.productRating)) : initialState.productRating,
          categoryPerformance: action.payload.categoryPerformance
            ? JSON.parse(JSON.stringify(action.payload.categoryPerformance))
            : initialState.categoryPerformance,
          trendsInnovations: action.payload.trendsInnovations
            ? JSON.parse(JSON.stringify(action.payload.trendsInnovations))
            : initialState.trendsInnovations
        }
      };
    // return Object.assign({}, state, action.payload);
    case SET_CATEGORY_RANK_BY_DEVICE:
      return Object.assign({}, state, {
        categoryRankByDevice: Object.assign({}, state.categoryRankByDevice, {
          [action.payload.category]: action.payload.scores
        })
      });
    case CONTENT_BUILDING_SET_CATEGORY_PERFORMANCE:
      return Object.assign({}, state, { categoryPerformance: action.payload });
    case SET_BACK_CONTENT_BUILDING:
      return Object.assign({}, state, { back2ContentBuilding: action.payload });
    default:
      return state;
  }
};

export default contentBuildingReducer;
