import * as JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

/**
 * Download a zip file.
 *
 * @param zipName: the name of the zip file.
 * @param files: array of object with {name, url}
 * @callback
 */
export async function downloadCompressedUrls(zipName, files, callback) {
  const zip = new JSZip();
  for (let i = 0; i < files.length; i++) {
    const data = await JSZipUtils.getBinaryContent(files[i].url, null);
    // Create zip file.
    zip.file(files[i].name, data, { binary: true });

    // Last element, generate zip file.-
    if (Object.is(files.length - 1, i)) {
      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, zipName);
        callback();
      });
    }
  }
}
