import React, { Component, Fragment } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Link, default as Scroll } from 'react-scroll/modules';
import { compose } from 'redux';
import clsx from 'clsx';
import { Box, Breadcrumbs, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import LinkRouter from '../../../components/router-link/RouterLink';
import { getReportCategoriesGrouped } from '../../../utils/report.utils';
import { validateProps, validateRoute } from '../../../utils/routeUtils';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { canEdit } from '../../../utils/user.utils';
import { USER_ROLE } from '../../../constants/user.constants';
import REPORT_CONSTANTS from '../../reports/report.constants';
import ConsumerSurveyStyles from './ConsumerContentSurvey.styles';

class KLConsumerContentSurvey extends Component {
  componentDidMount() {
    const { push, fireNotification, dbReport, isScoringRankingCompleted } = this.props;
    validateProps(push, fireNotification, dbReport);
    validateRoute(push, dbReport.status !== REPORT_CONSTANTS.STATUS.DRAFT.STATUS || isScoringRankingCompleted);

    Scroll.animateScroll.scrollToTop({
      smooth: 'easeOutQuint',
      delay: 300,
      duration: 1000
    });
  }

  render() {
    const { dbReport, selectedIndustry, classes, user, consumerSurvey, isPreview } = this.props;

    const categories = getReportCategoriesGrouped(dbReport);
    return (
      <Box className={classes.centerContainer}>
        <Grid container direction="column" className={classes.container} item lg={10} xl={8}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/report" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                {user.loginType !== USER_ROLE.CUSTOMER ? 'Home' : 'Report'}
              </Typography>
            </LinkRouter>
            <LinkRouter to={canEdit(user, dbReport) ? '/report/report-publish-date' : '/content-building'} color="secondary" underline="none">
              {dbReport && (
                <Typography color="secondary" variant="body2">
                  {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
                </Typography>
              )}
            </LinkRouter>
            {user.loginType !== USER_ROLE.CUSTOMER && (
              <LinkRouter to="/content-building" color="secondary" underline="none">
                <Typography color="secondary" variant="body2">
                  Content Building
                </Typography>
              </LinkRouter>
            )}
            <Typography variant="body2">Consumer Survey</Typography>
          </Breadcrumbs>
          <Box mt={5} mb={4}>
            <Typography variant="h4">Consumer Survey</Typography>
          </Box>
          <Typography variant="body2" color="textSecondary">
            We survey consumers to identify which capabilities are most important to them.
          </Typography>
          <Box className={classes.box}>
            <Typography className={classes.title}>Contents</Typography>
            {!isPreview && (
              <Fragment>
                <Link to="hsw" duration={1000} smooth="easeOutQuint">
                  <Typography color="secondary" variant="body2" className={classes.link}>
                    How Scoring Works
                  </Typography>
                </Link>
                <Link to="rr" duration={1000} smooth="easeOutQuint">
                  <Typography color="secondary" variant="body2" className={classes.link}>
                    Ranked Results
                  </Typography>
                </Link>
              </Fragment>
            )}
            <Link to="cc" duration={1000} smooth="easeOutQuint">
              <Typography color="secondary" variant="body2" className={classes.link}>
                Capability Categorization
              </Typography>
            </Link>
          </Box>
          {!isPreview && (
            <Fragment>
              <Box className={classes.box} id="hsw">
                <Typography className={classes.title}>How Scoring Works</Typography>
                <Typography variant="body2" color="textSecondary">
                  We align what consumers value the most in their mobile {selectedIndustry.title.toLowerCase()} offering with our scoring system.
                  Scores in categories that consumers consider the most important are more heavily weighted than those in categories deemed less
                  important. As a result, {selectedIndustry.title.toLowerCase()} companies that are providing customers what they desire most receive
                  the highest scores.
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Consumers are asked to rate the importance of {consumerSurvey && consumerSurvey.rawData && consumerSurvey.rawData.length + ' '}
                  different mobile {selectedIndustry.title.toLowerCase()} capabilities on a scale of 1-5 where 1 = “not important at all” and 5 =
                  “very important/must have” to the consumer.
                </Typography>
              </Box>
              <Box className={classes.box} id="rr">
                <Typography className={classes.title}>Ranked Results</Typography>
                <Typography variant="body2" color="textSecondary">
                  Ordered below from high to low for sum 4s & 5s rating:
                </Typography>
                &nbsp;
                <Paper elevation={0} className={classes.table}>
                  <Table size="medium" aria-label="a dense table">
                    <TableHead>
                      <TableRow className={classes.row}>
                        <TableCell align="center" className={classes.tableHeader}>
                          Rank
                        </TableCell>
                        <TableCell align="left" className={classes.tableHeader}>
                          Capability
                        </TableCell>
                        <TableCell align="center" className={classes.tableHeader}>
                          Ranked 4 or 5
                        </TableCell>
                        <TableCell align="center" className={classes.tableHeader}>
                          Rated 5
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {consumerSurvey &&
                        consumerSurvey.rawData &&
                        consumerSurvey.rawData
                          .sort((a, b) => {
                            if (a.four + a.five < b.four + b.five) {
                              return 1;
                            }
                            if (a.four + a.five > b.four + b.five) {
                              return -1;
                            }

                            if (a.five < b.five) {
                              return 1;
                            }
                            if (a.five > b.five) {
                              return -1;
                            }
                            return 0;
                          })
                          .map((question, index) => {
                            return (
                              <TableRow key={index} className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}>
                                <TableCell align="center">
                                  <Typography className={classes.tableText} color="textSecondary">
                                    #{index + 1}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className={classes.tableText} color="textSecondary">
                                    {question.name}
                                  </Typography>
                                </TableCell>

                                <TableCell align="center">
                                  <Typography className={classes.tableText} color="textSecondary">
                                    {question.four + question.five} %
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography className={classes.tableText} color="textSecondary">
                                    {question.five} %
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
            </Fragment>
          )}
          <Box className={classes.box} id="cc">
            <Typography className={classes.title}>Capability Categorization</Typography>
            <Typography variant="body2" color="textSecondary">
              {`We grouped the key capabilities into the ${categories.length}
              categories based on the feedback we collected from customers to
              identify features that they expect in best-of-class mobile
              products for ${selectedIndustry.title.toLowerCase()}:`}
            </Typography>
          </Box>
          <Box className={classes.box}>
            <Grid container spacing={2}>
              {categories.map((c, i) => {
                if (isPreview && i > 1) {
                  return null;
                }
                return (
                  <Grid item xs={6} key={c.categoryGroup}>
                    <div className={classes.column}>
                      <Check color="secondary" fontSize="small" />
                      &nbsp;
                      <Typography variant="body2" color="textSecondary">
                        {c.categoryGroup}
                      </Typography>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    dbReport: state.currentReport.dbReport,
    isPreview: state.currentReport.isPreview,
    selectedIndustry: state.currentReport.selectedIndustry,
    consumerSurvey: state.consumerSurvey,
    applications: state.currentReport.applications,
    isScoringRankingCompleted: state.currentReport.scoringRankingCompleted
  };
};

const mapDispatchToProps = {
  push
};

export default compose(withStyles(ConsumerSurveyStyles), connect(mapStateToProps, mapDispatchToProps))(KLConsumerContentSurvey);
