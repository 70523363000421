export default theme => ({
  root: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});
