import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DialogTitle, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GetApp from '@material-ui/icons/GetApp';
import Publish from '@material-ui/icons/Publish';
import { clearContentBuilding, setContentBuilding } from '../../modules/content-building/action/ContentBuilding.actions';
import feathersServices from '../../services/feathersServices';
import { get } from '../../utils/immutable-utils';
import { getApplications } from '../../utils/report.utils';
import SingletonMomentUtils from '../../utils/SingletonMomentUtils';
import { clearOverrideModal, setOverrideModal } from '../layout/actions/layout.actions';
import ConcurrencyStyles from './Concurrency.styles';

class ConcurrencyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changes: []
    };
  }

  componentDidMount() {
    const { overrideModal } = this.props;
    if (overrideModal.params && overrideModal.params.$set) {
      const key = Object.keys(overrideModal.params.$set)[0];
      if (key.indexOf('executiveSummary') !== -1) {
        this.buildExecutiveChanges();
      } else if (key.indexOf('productRating') !== -1) {
        this.buildProductRatingChanges();
      } else if (key.indexOf('trendsInnovations') !== -1) {
        this.buildTrendsChanges();
      } else if (key.indexOf('categoryPerformance') !== -1) {
        this.buildCategoryPerformance();
      }
    }
  }

  buildExecutiveChanges = () => {
    const { overrideModal, contentBuilding } = this.props;
    const newChanges = [];
    Object.keys(overrideModal.actualContentBuilding.executiveSummary).forEach(eKey => {
      if (contentBuilding.executiveSummary[eKey] !== overrideModal.actualContentBuilding.executiveSummary[eKey]) {
        switch (eKey) {
          case 'availableDate':
            newChanges.push({
              title: 'Available Date',
              current: SingletonMomentUtils.moment(get(overrideModal.actualContentBuilding.executiveSummary, eKey)).format('lll'),
              original: SingletonMomentUtils.moment(get(contentBuilding.executiveSummary, eKey)).format('lll')
            });
            break;
          case 'topPerformers':
            newChanges.push({
              title: 'Top Performers',
              current: get(overrideModal.actualContentBuilding.executiveSummary, eKey),
              original: get(contentBuilding.executiveSummary, eKey)
            });
            break;
          case 'paragraphDate':
            newChanges.push({
              title: 'Version',
              current: get(overrideModal.actualContentBuilding.executiveSummary, eKey),
              original: get(contentBuilding.executiveSummary, eKey)
            });
            break;
          default:
            break;
        }
      }
    });
    this.setState({
      changes: [...newChanges]
    });
  };

  buildProductRatingChanges = () => {
    const { dbReport, overrideModal, contentBuilding } = this.props;
    const newChanges = [];
    const applications = getApplications(dbReport);
    Object.keys(overrideModal.actualContentBuilding.productRating).forEach(app => {
      if (contentBuilding.productRating[app] && overrideModal.actualContentBuilding.productRating[app]) {
        if (contentBuilding.productRating[app].keySuccessFactor !== overrideModal.actualContentBuilding.productRating[app].keySuccessFactor) {
          newChanges.push({
            title: `${applications.find(a => a.id === app).title} → Key success factor`,
            current: get(overrideModal.actualContentBuilding.productRating, app).keySuccessFactor,
            original: get(contentBuilding.productRating, app).keySuccessFactor
          });
        }
        if (contentBuilding.productRating[app].bestInClassFeatures !== overrideModal.actualContentBuilding.productRating[app].bestInClassFeatures) {
          newChanges.push({
            title: `${applications.find(a => a.id === app).title} → Best-in-class features`,
            current: get(overrideModal.actualContentBuilding.productRating, app).bestInClassFeatures,
            original: get(contentBuilding.productRating, app).bestInClassFeatures
          });
        }
      }
    });
    this.setState({
      changes: [...newChanges]
    });
  };

  buildTrendsChanges = () => {
    const { overrideModal, contentBuilding } = this.props;
    const newChanges = [];
    contentBuilding.trendsInnovations.forEach(trend => {
      const oldTrend = overrideModal.actualContentBuilding.trendsInnovations.find(t => t._id === trend._id);
      if ((oldTrend && (trend.trendTitle !== oldTrend.trendTitle || trend.trendContent !== oldTrend.trendContent)) || !oldTrend) {
        newChanges.push({
          title: `Trend`,
          original: `<p>${trend.trendTitle}</p><p>${trend.trendContent}</p>`,
          current: oldTrend
            ? `<p>${oldTrend.trendTitle}</p><p>${oldTrend.trendContent}</p>`
            : `<span style="color: red;"><b>Trend has been deleted, your changes would not be saved.</b></span>`
        });
      }
    });
    this.setState({
      changes: [...newChanges]
    });
  };

  buildCategoryPerformance = () => {
    const { dbReport, overrideModal, contentBuilding } = this.props;
    const newChanges = [];
    const experienceMap = {
      0: 'Good experience',
      1: 'Bad experience',
      2: 'Interesting'
    };
    const applications = getApplications(dbReport);
    if (overrideModal.actualContentBuilding) {
      contentBuilding.categoryPerformance.forEach(cat => {
        const oldCat = overrideModal.actualContentBuilding.categoryPerformance.find(c => c._id === cat._id);

        if (oldCat) {
          if (oldCat.notableCallouts !== cat.notableCallouts) {
            newChanges.push({
              title: `${cat.categoryGroup} → ${applications.find(a => a.id === cat.applicationId).title} → Notable callouts`,
              original: cat.notableCallouts,
              current: oldCat.notableCallouts
            });
          }
          cat.experiences.forEach(exp => {
            const oldExp = oldCat.experiences.find(e => e._id === exp._id);
            if (
              (oldExp &&
                (exp.title !== oldExp.title || exp.text !== oldExp.text || exp.experience !== oldExp.experience || exp.picture !== oldExp.picture)) ||
              !oldExp
            ) {
              newChanges.push({
                title: `${cat.categoryGroup} → ${applications.find(a => a.id === cat.applicationId).title} → Experience`,
                original: `<div style="display: flex; align-items: center; flex-direction: column;"><img style="width: 200px" src="${
                  exp.picture || '../images/no_picture.png'
                }" /><span style="color: ${exp.experience === 0 ? 'green' : exp.experience === 1 ? 'red' : 'blue'};"><b>${
                  experienceMap[exp.experience]
                }</b></span><span style="color: ${exp.experience === 0 ? 'green' : exp.experience === 1 ? 'red' : 'blue'};">${
                  exp.title
                }</span><span>${exp.text}</span></div>`,
                current: oldExp
                  ? `<div style="display: flex; align-items: center; flex-direction: column;"><img style="width: 200px" src="${
                      oldExp.picture || '../images/no_picture.png'
                    }" /><span style="color: ${oldExp.experience === 0 ? 'green' : oldExp.experience === 1 ? 'red' : 'blue'};"><b>${
                      experienceMap[oldExp.experience]
                    }</b></span><span style="color: ${oldExp.experience === 0 ? 'green' : oldExp.experience === 1 ? 'red' : 'blue'};">${
                      oldExp.title
                    }</span><span>${oldExp.text}</span></div>`
                  : `<span style="color: red;"><b>Experience has been deleted, your changes would not be saved.</b></span>`
              });
            }
          });
        }
      });
    }
    this.setState({
      changes: [...newChanges]
    });
  };

  loadContentBuilding = () => {
    const { dbReport, getContentBuilding, setContentBuilding, clearContentBuilding, clearOverrideModal } = this.props;
    getContentBuilding({
      query: {
        reportId: dbReport._id,
        $limit: 1
      }
    }).then(({ value }) => {
      value[0] ? setContentBuilding(value[0]) : clearContentBuilding();
      clearOverrideModal();
    });
  };

  saveContentBuilding = () => {
    const { overrideModal, setContentBuilding, contentBuilding, patchContentBuilding, clearOverrideModal } = this.props;
    patchContentBuilding(contentBuilding._id, { ...overrideModal.params, loadedAt: new Date() }, overrideModal.query).then(({ value }) => {
      setContentBuilding(value);
      clearOverrideModal();
    });
  };

  render() {
    const { overrideModal, classes, clearOverrideModal } = this.props;
    return (
      <Dialog open={overrideModal.open} maxWidth="lg">
        <DialogTitle>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Conflict!
            <IconButton onClick={() => clearOverrideModal()}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{ minWidth: 800 }}>
          <Box mb={3}>
            <Typography>The following information has been changed by another user:</Typography>
            {this.state.changes.map((change, i) => {
              return (
                <Box mt={2} mb={2} key={`change_${i}`}>
                  <ExpansionPanel style={{ backgroundColor: '#f4f4f4' }}>
                    <ExpansionPanelSummary expandIcon={<ExpandMore fontSize="small" />} aria-controls="panel1a-content" id="panel1a-header">
                      <DescriptionOutlined fontSize="small" />
                      &nbsp;
                      <Typography variant="body2">{change.title}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionDetail}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Box className={classes.compareArea}>
                            <Typography variant="caption">Your change</Typography>
                            {overrideModal.params && (
                              <Typography variant="caption">{SingletonMomentUtils.moment(overrideModal.params.loadedAt).format('lll')}</Typography>
                            )}
                          </Box>
                          <Box className={classes.changeContent}>
                            <Typography
                              name="ckEditorView"
                              component="div"
                              variant="body2"
                              color="textSecondary"
                              dangerouslySetInnerHTML={{
                                __html: change.original
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className={classes.compareArea}>
                            <Typography variant="caption">External change</Typography>
                            {overrideModal.actualContentBuilding && (
                              <Typography variant="caption">
                                {SingletonMomentUtils.moment(overrideModal.actualContentBuilding.updatedAt).format('lll')}
                              </Typography>
                            )}
                          </Box>
                          <Box className={classes.changeContent}>
                            <Typography
                              name="ckEditorView"
                              component="div"
                              variant="body2"
                              color="textSecondary"
                              dangerouslySetInnerHTML={{
                                __html: change.current
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Box>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.saveContentBuilding}
            style={{
              backgroundColor: '#d32f2f'
            }}
            color="secondary"
            variant="contained"
            disableElevation
            startIcon={<Publish />}
          >
            Override
          </Button>
          <Button variant="contained" disableElevation color="secondary" onClick={this.loadContentBuilding} startIcon={<GetApp />}>
            Discard
          </Button>
          <Button onClick={() => clearOverrideModal()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    dbReport: state.currentReport.dbReport,
    contentBuilding: state.contentBuilding,
    overrideModal: state.ui.layout.overrideModal
  };
};

const mapDispatchToProps = {
  getContentBuilding: feathersServices['content-building'].find,
  patchContentBuilding: feathersServices['content-building'].patch,
  clearContentBuilding,
  setContentBuilding,
  setOverrideModal,
  clearOverrideModal
};

export default compose(withStyles(ConcurrencyStyles), connect(mapStateToProps, mapDispatchToProps))(ConcurrencyModal);
