import React, { Component, Fragment } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Element, Link, default as Scroll } from 'react-scroll/modules';
import { compose } from 'redux';
import { debounceTime, Subject } from 'rxjs';
import { Box, Breadcrumbs, DialogContentText, Grid, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Edit from '@material-ui/icons/Edit';
import feathersServices from '../../../services/feathersServices';
import KLDialog from '../../../components/dialog/KLDialog';
import KLEditor from '../../../components/editor/KLEditor';
import { clearOverrideModal, fireNotification, setScreenshotModal } from '../../../components/layout/actions/layout.actions';
import Notification from '../../../components/notification/Notification';
import LinkRouter from '../../../components/router-link/RouterLink';
import ScreenshotModal from '../../../components/screenshotModal/ScreenshotModal';
import KLSecurity from '../../../components/security/Security';
import { SECURITY_ACTIONS } from '../../../components/security/Security.constants';
import { updateObjectInArray } from '../../../utils/immutable-utils';
import { validateProps, validateRoute } from '../../../utils/routeUtils';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { canEdit } from '../../../utils/user.utils';
import { USER_ROLE } from '../../../constants/user.constants';
import REPORT_CONSTANTS from '../../reports/report.constants';
import { clearContentBuilding, setContentBuilding, setTrendsInnovations } from '../action/ContentBuilding.actions';
import ContentBuildingSave from '../common/SaveContentBuilding';
import TrendsInnovationsStyles from './TrendsInnovations.styles';

class KLTrendsInnovations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openTrend: '',
      trendTitle: '',
      buyAction: false,
      deleteTrendAlert: false,
      showTrendsTitle: false,
      allCapabilities: []
    };
  }

  componentDidMount() {
    const { push, fireNotification, dbReport, isScoringRankingCompleted } = this.props;
    validateProps(push, fireNotification, dbReport);
    validateRoute(push, dbReport.status !== REPORT_CONSTANTS.STATUS.DRAFT.STATUS || isScoringRankingCompleted);

    setScreenshotModal({
      open: false,
      allowEmpty: true,
      onComplete: null
    });
    this.props.clearOverrideModal();

    Scroll.animateScroll.scrollToTop({
      smooth: 'easeOutQuint',
      delay: 300,
      duration: 1000
    });

    if (!this.props.contentBuilding._id) {
      this.props
        .getContentBuilding({
          query: {
            reportId: this.props.dbReport._id,
            $limit: 1
          }
        })
        .then(({ value }) => {
          value[0] ? this.props.setContentBuilding(value[0]) : this.props.clearContentBuilding();
        });
    }

    // Find capabilities for ckeditor screenshots.-
    this.props
      .getCapabilityReports({
        query: {
          report: dbReport._id,
          $limit: 'infinity'
        }
      })
      .then(data => {
        this.setState({
          allCapabilities: { [dbReport._id]: data.value }
        });
      });

    this.trendsInput$ = new Subject();
    this.trendsInput$.pipe(debounceTime(500)).subscribe(({ c, i }) => {
      this.handleAddContent(c, i);
    });
  }

  handleAddTrends = () => {
    const { showTrendsTitle } = this.state;
    this.setState({ showTrendsTitle: !showTrendsTitle });
  };

  addTrends = () => {
    const { trendTitle } = this.state;
    const { trendsInnovations } = this.props;
    const trend = {
      tid: Date.now(),
      trendTitle: trendTitle,
      trendContent: ''
    };
    this.setState(
      {
        openTrend: trend.tid,
        trendTitle: '',
        showTrendsTitle: false
      },
      () => {
        Scroll.scroller.scrollTo(trendTitle, {
          smooth: true,
          duration: 1000
        });
      }
    );
    this.props.setTrendsInnovations([...trendsInnovations, trend]);
  };

  handleEditTrend = tid => {
    const { openTrend } = this.state;

    if (openTrend === tid) {
      this.setState({ openTrend: '' });
    } else {
      this.setState({ openTrend: tid });
    }
  };

  handleAddContent = (content, i) => {
    const { trendsInnovations } = this.props;
    trendsInnovations[i].trendContent = content;
    this.updateTrend(trendsInnovations);
  };

  handleTrendTitle = (title, i) => {
    const { trendsInnovations } = this.props;
    trendsInnovations[i].trendTitle = title;
    this.updateTrend(trendsInnovations);
  };

  updateTrend(trendsInnovations) {
    this.props.setTrendsInnovations(trendsInnovations);
  }

  deleteTrend = () => {
    const { openTrend } = this.state;
    const { trendsInnovations, contentBuilding, update, setContentBuilding } = this.props;

    const index = trendsInnovations.indexOf(trendsInnovations.find(t => (t._id || t.tid) === openTrend));

    if (contentBuilding._id) {
      update(contentBuilding._id, {
        $pull: {
          trendsInnovations: {
            _id: trendsInnovations[index]._id
          }
        }
      }).then(({ value }) => setContentBuilding(value));

      this.setState({ deleteTrendAlert: false });
    }
  };

  render() {
    const { trendTitle, openTrend, deleteTrendAlert, showTrendsTitle, buyAction, allCapabilities } = this.state;
    const { trendsInnovations, dbReport, selectedIndustry, classes, isPreview, user } = this.props;
    return (
      <Box className={classes.centerContainer}>
        <Grid container item direction="column" className={classes.container} lg={10} xl={8}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/report" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                {user.loginType !== USER_ROLE.CUSTOMER ? 'Home' : 'Report'}
              </Typography>
            </LinkRouter>
            <LinkRouter to={canEdit(user, dbReport) ? '/report/report-publish-date' : '/content-building'} color="secondary" underline="none">
              {dbReport && (
                <Typography color="secondary" variant="body2">
                  {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
                </Typography>
              )}
            </LinkRouter>
            {user.loginType !== USER_ROLE.CUSTOMER && (
              <LinkRouter to="/content-building" color="secondary" underline="none">
                <Typography color="secondary" variant="body2">
                  Content Building
                </Typography>
              </LinkRouter>
            )}
            <Typography variant="body2">Trends & Innovations</Typography>
          </Breadcrumbs>
          <Box mt={5} mb={4}>
            <Typography variant="h4">Trends & Innovations</Typography>
            &nbsp;
            <Typography variant="body2" color="textSecondary">
              Here, we outline noteworthy trends and innovations occuring within the industry. These insights can provide new opportunities to evolve
              the product experience and improve customer satisfaction.
            </Typography>
          </Box>
          {!isPreview ? (
            <Fragment>
              <KLSecurity action={SECURITY_ACTIONS.EDIT}>
                <Box className={classes.box}>
                  <Typography className={classes.link} color={'secondary'} onClick={() => this.handleAddTrends()}>
                    Add Trends
                  </Typography>
                  {showTrendsTitle && (
                    <Box className={classes.box} display="flex" alignItems="center">
                      <TextField
                        variant="outlined"
                        color="secondary"
                        margin="dense"
                        style={{
                          backgroundColor: 'white'
                        }}
                        placeholder="Title"
                        onChange={({ target }) => this.setState({ trendTitle: target.value })}
                        value={trendTitle}
                      />
                      &nbsp;
                      <Button size="small" color="secondary" onClick={this.addTrends} variant="contained" disableElevation>
                        Add
                      </Button>
                    </Box>
                  )}
                </Box>
              </KLSecurity>
              {trendsInnovations.length > 0 && (
                <Box className={classes.box}>
                  <Typography className={classes.title}>Contents</Typography>
                  {trendsInnovations.map((t, i) => {
                    return (
                      <Link key={`${t}_${i}`} to={`${t.trendTitle}`} duration={1000} smooth="easeOutQuint">
                        <Typography color="secondary" variant="body2" className={classes.link}>
                          {t.trendTitle}
                        </Typography>
                      </Link>
                    );
                  })}
                </Box>
              )}
              {trendsInnovations.map((t, i) => {
                return (
                  <Element key={t._id || t.tid} name={t.trendTitle}>
                    <Box className={classes.box} id={t.trendTitle}>
                      <Box display="flex" alignItems="center">
                        {openTrend === (t._id || t.tid) ? (
                          <TextField
                            label="Title"
                            variant="outlined"
                            color="secondary"
                            margin="dense"
                            placeholder="Title"
                            style={{
                              backgroundColor: 'white'
                            }}
                            value={t.trendTitle}
                            onChange={({ target }) => this.handleTrendTitle(target.value, i)}
                          />
                        ) : (
                          <Typography className={classes.title}>{t.trendTitle}</Typography>
                        )}
                        &nbsp;
                        <KLSecurity action={SECURITY_ACTIONS.EDIT}>
                          <IconButton onClick={() => this.handleEditTrend(t._id || t.tid)} size="small">
                            <Edit style={{ marginLeft: 5, cursor: 'pointer' }} color={'secondary'} fontSize={'small'} />
                          </IconButton>
                        </KLSecurity>
                      </Box>
                      {openTrend === (t._id || t.tid) && (
                        <Collapse in={openTrend === (t._id || t.tid)}>
                          <KLEditor content={t.trendContent} setContent={c => this.trendsInput$.next({ c, i })} />
                          <ContentBuildingSave
                            params={
                              !t._id
                                ? {
                                    $push: {
                                      trendsInnovations: t
                                    }
                                  }
                                : {
                                    $set: {
                                      'trendsInnovations.$[elem]': t
                                    },
                                    loadedAt: this.props.contentBuilding.loadedAt
                                  }
                            }
                            query={{
                              query: {
                                $mongoose: {
                                  arrayFilters: [{ 'elem._id': t._id }],
                                  multi: true
                                }
                              }
                            }}
                            onSave={() => {
                              this.setState({ openTrend: '' });
                            }}
                            onCancel={() => {
                              const item = this.props.contentBuildingService.data
                                ? this.props.contentBuildingService.data.trendsInnovations.find(tr => tr._id === t._id)
                                : this.props.contentBuildingService.queryResult[0].trendsInnovations.find(tr => tr._id === t._id);

                              const index = trendsInnovations.findIndex(tr => tr._id === t._id);
                              this.props.setTrendsInnovations(
                                updateObjectInArray(trendsInnovations, {
                                  index,
                                  item
                                })
                              );
                              this.setState({ openTrend: '' });
                            }}
                          />
                          {t._id && (
                            <Button
                              size="small"
                              className={classes.delete}
                              onClick={() =>
                                this.setState({
                                  deleteTrendAlert: !deleteTrendAlert
                                })
                              }
                            >
                              Delete
                            </Button>
                          )}
                        </Collapse>
                      )}

                      {openTrend !== (t._id || t.tid) && (
                        <Typography
                          component="div"
                          name="ckEditorView"
                          color="textSecondary"
                          variant="body2"
                          dangerouslySetInnerHTML={{
                            __html: this.props.contentBuildingService.data
                              ? this.props.contentBuildingService.data.trendsInnovations.find(tr => tr._id === t._id)
                                ? this.props.contentBuildingService.data.trendsInnovations.find(tr => tr._id === t._id).trendContent
                                : ''
                              : this.props.contentBuildingService.queryResult[0]
                              ? this.props.contentBuildingService.queryResult[0].trendsInnovations.find(tr => tr._id === t._id)
                                ? this.props.contentBuildingService.queryResult[0].trendsInnovations.find(tr => tr._id === t._id).trendContent
                                : ''
                              : ''
                          }}
                        />
                      )}
                    </Box>
                  </Element>
                );
              })}
            </Fragment>
          ) : (
            <Box>
              <div className={classes.buyMessage}>
                <Typography variant="h5">Buy report to see this information.</Typography>
                &nbsp;
                <Button color="secondary" variant="contained" disableElevation>
                  <Typography
                    variant="overline"
                    onClick={() => {
                      this.setState({ buyAction: true });
                      this.props.buyReport({ rid: dbReport._id });
                    }}
                  >
                    Buy Report
                  </Typography>
                </Button>
                <Notification
                  message={'An email has been sent to Sales Department, we will contact you soon.'}
                  open={buyAction}
                  variant="success"
                  duration={10000}
                  onClose={(event, reason) => {
                    if (reason === 'clickaway') {
                      return;
                    }
                    this.setState({ buyAction: false });
                  }}
                />
              </div>
            </Box>
          )}
        </Grid>
        <KLDialog
          isOpen={deleteTrendAlert}
          onHandleClose={() => {
            this.setState({ deleteTrendAlert: false });
          }}
          onHandleConfirm={() => this.deleteTrend()}
          title={'Delete Trends & Innovations'}
          body={<DialogContentText>Are you sure you want to delete this item?</DialogContentText>}
          labelButtonConfirm={'Delete'}
        />
        {allCapabilities && Boolean(Object.keys(allCapabilities).length) && <ScreenshotModal filters={true} allCapabilities={allCapabilities} />}
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    dbReport: state.currentReport.dbReport,
    isPreview: state.currentReport.isPreview,
    selectedIndustry: state.currentReport.selectedIndustry,
    trendsInnovations: state.contentBuilding.trendsInnovations,
    contentBuilding: state.contentBuilding,
    isScoringRankingCompleted: state.currentReport.scoringRankingCompleted,
    contentBuildingService: state.services.contentBuilding
  };
};

const mapDispatchToProps = {
  update: feathersServices['content-building'].patch,
  buyReport: feathersServices['report-status'].create,
  getContentBuilding: feathersServices['content-building'].find,
  getCapabilityReports: feathersServices['capability-reports'].find,
  clearContentBuilding,
  setContentBuilding,
  setTrendsInnovations,
  setScreenshotModal,
  clearOverrideModal,
  push,
  fireNotification
};

export default compose(withStyles(TrendsInnovationsStyles), connect(mapStateToProps, mapDispatchToProps))(KLTrendsInnovations);
