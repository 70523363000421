import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import {
  Box,
  Breadcrumbs,
  DialogContentText,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import feathersServices from '../../../services/feathersServices';
import KLDialog from '../../../components/dialog/KLDialog';
import { fireNotification, toggleAppBar, toggleSideMenu } from '../../../components/layout/actions/layout.actions';
import LoadingIndicator from '../../../components/loadingIndicator/';
import LinkRouter from '../../../components/router-link/RouterLink';
import KLSelect from '../../../components/select/KLSelect';
import HeaderTable from '../../../components/tables/HeaderTable';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { canEdit } from '../../../utils/user.utils';
import REPORT_CONSTANTS from '../../reports/report.constants';
import { selectDate, selectDbReport, selectIndustry, setPreview } from '../actions/reports.actions';
import { setFilters, setPage, setSelectedReport } from './action/ReportList.actions';
import ReportExport from './ReportExport';
import ReportListStyles from './ReportList.styles';

const cells = ['Date', 'Industry', 'Last Modified', 'Status', 'Revision', ''];

const filterType = { STATUS: 'STATUS', INDUSTRY: 'INDUSTRY' };

class KLReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      anchorEl: null,
      selectedReport: null,
      statusReport: [],
      exportData: false,
      isOpenDeleteReport: false,
      editReportWarning: false,
      isDeletingReport: false
    };
  }

  setDeletingReport = () => {
    this.setState(state => {
      return { isDeletingReport: !state.isDeletingReport };
    });
  };

  componentDidUpdate(nextProps) {
    if (JSON.stringify(this.props.filters) !== JSON.stringify(nextProps.filters)) {
      this.getPaginatedReports();
    }
  }

  componentDidMount() {
    this.props.getIndustries({ query: { active: true } });
    this.props.onToggleSideMenu(false);
    this.props.toggleAppBar(true);
    this.getPaginatedReports();
    this.getStatusReport();
  }

  getStatusReport = () => {
    let status = [];
    Object.keys(REPORT_CONSTANTS.STATUS)
      .filter(val => val !== REPORT_CONSTANTS.STATUS.PUBLISHED_EDITED.STATUS)
      .map(val =>
        status.push({
          _id: REPORT_CONSTANTS.STATUS[val].STATUS,
          label: REPORT_CONSTANTS.STATUS[val].LABEL
        })
      );
    this.setState({ statusReport: status });
  };

  handleClick = (event, report) => {
    this.setState({ isOpen: true, anchorEl: event.currentTarget });
    this.props.setSelectedReport(report);
  };

  handleClose = () => {
    this.setState({ isOpen: false, anchorEl: null, exportData: false });
    this.props.setSelectedReport(null);
  };

  handleClickOnEdit = selectedReport => {
    this.props.selectDbReport(selectedReport);
    this.props.selectIndustry(selectedReport.industry);
    this.props.selectDate(selectedReport.date);
    this.props.setPreview(false);
    if (canEdit(this.props.user, selectedReport)) {
      this.props.push('/report/report-publish-date');
    } else {
      this.props.push('/content-building');
    }
  };

  handleClickScreenshot = () => {
    const { selectedReport } = this.props;
    this.props.selectDbReport(selectedReport);
    this.props.push('/report-list/screenshot');
  };

  openCloseDialogDeleteReport = report => {
    this.setState(state => {
      return {
        isOpenDeleteReport: !state.isOpenDeleteReport,
        isOpen: false,
        anchorEl: null
      };
    });
  };

  deleteReport = () => {
    const { selectedReport } = this.props;
    if (selectedReport) {
      this.setDeletingReport();
      // delete report to db
      this.props.deleteReport(selectedReport._id).then(
        data => {
          this.openCloseDialogDeleteReport(null);
          this.getPaginatedReports();
          this.setDeletingReport();
        },
        error => {
          this.openCloseDialogDeleteReport(null);
          this.setDeletingReport();
          this.getPaginatedReports();
        }
      );
    }
  };

  handleChangePage = async (event, newPage) => {
    await this.props.setPage(newPage);
    this.getPaginatedReports();
  };

  handlePrintReport = async () => {
    const { selectedReport } = this.props;
    this.handleClose();

    const { value: report } = await this.props.getReport(selectedReport._id);
    if (report && report.urlPdf) {
      window.open(report.urlPdf);
    } else {
      this.props.fireNotification('warning', 'PDF Report is being generated, please tray again in a few minutes', 6000);
    }
  };

  getPaginatedReports = () => {
    const { filterStatus, filterIndustry } = this.props;
    const query = {
      query: {
        $populate: true,
        $sort: {
          date: -1
        },
        $limit: this.props.rowsPerPage,
        $skip: this.props.rowsPerPage * this.props.page,
        status: {
          $in: [
            REPORT_CONSTANTS.STATUS.DRAFT.STATUS,
            REPORT_CONSTANTS.STATUS.REVIEWED.STATUS,
            REPORT_CONSTANTS.STATUS.APPROVED.STATUS,
            REPORT_CONSTANTS.STATUS.PUBLISHED_EDITING.STATUS,
            REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS
          ]
        }
      }
    };

    if (filterStatus) {
      query.query.status = filterStatus._id;
    }

    if (filterIndustry) {
      query.query.industry = filterIndustry._id;
    }
    this.props.getReports(query).then(
      data => data,
      error => this.props.push('/login')
    );
  };

  createReportVersion(report) {
    // call backend to create duplicate report
    this.props
      .editReport({
        date: report.date,
        reportId: report._id,
        industry: report.industry
      })
      .then(() => {
        this.getPaginatedReports();
        this.props.fireNotification('success', 'New report version generated', 5000);
      });
    this.openCloseReportWarning();
    this.props.fireNotification('warning', 'A new report version is being generated', 5000);

    // get the new report as response
    // redirect to app-site-select for that report
  }

  openCloseReportWarning = () => {
    this.setState(state => {
      return {
        editReportWarning: !state.editReportWarning,
        anchorEl: null,
        isOpen: false
      };
    });
  };

  render() {
    const { classes, reports, rowsPerPage, page, industries, setFilters, filterIndustry, filterStatus, selectedReport } = this.props;
    const { anchorEl, isOpen, statusReport, exportData, isOpenDeleteReport, isDeletingReport, editReportWarning } = this.state;

    return (
      <Grid container className={classes.container}>
        <Box className={classes.box} mb={3}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                Home
              </Typography>
            </LinkRouter>
            <LinkRouter to="/" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                Reports
              </Typography>
            </LinkRouter>
            <Typography variant="body2">All Reports</Typography>
          </Breadcrumbs>
        </Box>
        <Box className={classes.box}>
          <Typography variant="h5">All Reports</Typography>
          <div className={classes.filter}>
            <KLSelect
              id="status"
              selectedValue={filterStatus}
              acceptNone={true}
              label="Status"
              margin="dense"
              onChange={value => {
                const val = Object.assign({}, value);
                val.type = filterType.STATUS;
                setFilters(val);
              }}
              values={statusReport}
            />

            {!industries.isLoading && industries.queryResult && (
              <KLSelect
                id="industry"
                selectedValue={filterIndustry}
                acceptNone={true}
                label="Industries"
                margin="dense"
                onChange={value => {
                  const val = Object.assign({}, value);
                  val.type = filterType.INDUSTRY;
                  setFilters(val);
                }}
                values={industries.queryResult.data}
              />
            )}
          </div>
        </Box>
        <Paper elevation={0} className={classes.table}>
          {exportData && <ReportExport report={selectedReport} onClose={this.handleClose} />}
          <LoadingIndicator color="secondary" isLoading={reports.isLoading} width={40}>
            <Table aria-label="simple table" size="medium">
              <HeaderTable classes={classes} cells={cells} />
            </Table>
          </LoadingIndicator>

          {!reports.isLoading && (
            <Table aria-label="simple dense table" size="medium">
              <HeaderTable classes={classes} cells={cells} />
              <TableBody>
                {reports.queryResult &&
                  reports.queryResult.data?.map((report, index) => (
                    <TableRow
                      key={index}
                      hover
                      onClick={() => {
                        this.handleClickOnEdit(report);
                      }}
                      className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}
                    >
                      <TableCell>
                        <Typography className={classes.tableText} color="textSecondary">
                          {SingletonMomentUtils.moment(report.date).format('MMMM YYYY')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.tableText} color="textSecondary">
                          {report.industry.title}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.tableText} color="textSecondary">
                          {`${SingletonMomentUtils.moment(report.updatedAt).format('ll')}`}
                          {report.lastUpdateBy && ` by ${report.lastUpdateBy.name} ${report.lastUpdateBy.lastName}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.tableText} color="textSecondary">
                          {REPORT_CONSTANTS.STATUS[report.status].LABEL}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.tableText} color="textSecondary">
                          {report.revision}
                        </Typography>
                      </TableCell>
                      <TableCell onClick={event => event.stopPropagation()} width="5%">
                        <IconButton
                          size="small"
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={event => this.handleClick(event, report)}
                          ref
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[rowsPerPage]}
                    count={reports.queryResult ? reports.queryResult.total : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={this.handleChangePage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          )}
        </Paper>
        {selectedReport && (
          <>
            <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={isOpen} onClose={this.handleClose}>
              <MenuItem onClick={() => this.handleClickScreenshot()}>View Screenshots</MenuItem>
              <MenuItem onClick={() => this.setState({ exportData: true })}>Export Capabilities</MenuItem>

              {REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS === selectedReport.status && (
                <MenuItem onClick={() => this.openCloseReportWarning()}>Edit Report</MenuItem>
              )}
              {(REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS === selectedReport.status ||
                REPORT_CONSTANTS.STATUS.PUBLISHED_EDITING.STATUS === selectedReport.status) && (
                <MenuItem onClick={() => this.handlePrintReport()}>Print Report (PDF)</MenuItem>
              )}
              {REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS !== selectedReport.status &&
                REPORT_CONSTANTS.STATUS.PUBLISHED_EDITING.STATUS !== selectedReport.status && (
                  <MenuItem onClick={() => this.openCloseDialogDeleteReport()}>Delete</MenuItem>
                )}
            </Menu>

            <KLDialog
              isOpen={isOpenDeleteReport}
              onHandleClose={this.openCloseDialogDeleteReport}
              onHandleConfirm={this.deleteReport}
              title={'DELETE'}
              disableButtonConfirm={isDeletingReport}
              body={
                <DialogContentText>
                  {`The report dated ${SingletonMomentUtils.moment(selectedReport.date).format('MMMM YYYY')} and ${
                    selectedReport.industry.title
                  } industry will be deleted.`}
                </DialogContentText>
              }
              labelButtonConfirm={'Delete'}
            />
            <KLDialog
              title={'Edit Report'}
              body={<DialogContentText> A duplicate of the currently published report will be created. Do you want to continue? </DialogContentText>}
              onHandleConfirm={() => this.createReportVersion(selectedReport)}
              onHandleClose={this.openCloseReportWarning}
              isOpen={editReportWarning}
            />
          </>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    reports: state.services.reports,
    selectedReport: state.ui.listReport.selectedReport,
    page: state.ui.listReport.page,
    rowsPerPage: state.ui.listReport.rowsPerPage,
    industries: state.services.industries,
    filters: state.ui.listReport.filters,
    filterStatus: state.ui.listReport.filters ? state.ui.listReport.filters.find(ele => ele.type === filterType.STATUS) : '',
    filterIndustry: state.ui.listReport.filters ? state.ui.listReport.filters.find(ele => ele.type === filterType.INDUSTRY) : ''
  };
};

const mapDispatchToProps = {
  getReports: feathersServices.reports.find,
  getReport: feathersServices.reports.get,
  getIndustries: feathersServices.industries.find,
  deleteReport: feathersServices.reports.remove,
  generateReport: feathersServices['report-generator'].get,
  editReport: feathersServices['report-edit'].create,
  onToggleSideMenu: toggleSideMenu,
  toggleAppBar,
  selectDbReport,
  selectIndustry,
  setSelectedReport,
  setPage,
  selectDate,
  setFilters,
  fireNotification,
  setPreview,
  push
};

export default compose(withStyles(ReportListStyles), connect(mapStateToProps, mapDispatchToProps))(KLReportList);
