import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Box, Breadcrumbs, Card, CardContent, Grid, Typography, withStyles } from '@material-ui/core';
import KLDialog from '../../../components/dialog/KLDialog';
import LinkRouter from '../../../components/router-link/RouterLink';
import { setCompanyCustomerSelected } from '../action/CompaniesCustomertList.actions';
import CompaniesForm from './CompaniesForm';
import AboutCompany from './company/AboutCompany';
import Company from './company/Company';
import CompanyDashboardDetailsStyles from './CompanyDashboardDetails.styles';
import ReportsCompany from './reports/ReportsCompany';
import UsersCompany from './users/UsersCompany';

let CompanyDashboardDetails = ({ classes, companyCustomerSelected, setCompanyCustomerSelected }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      <Grid container className={classes.rootHeader}>
        <Box className={classes.box} mb={3}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                Home
              </Typography>
            </LinkRouter>
            <LinkRouter to="/" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                Client Management
              </Typography>
            </LinkRouter>
            <Typography variant="body2">{`Company Details Summary (${companyCustomerSelected.name})`}</Typography>
          </Breadcrumbs>
        </Box>
        <Box className={classes.box}>
          <Typography variant="h5" className={classes.title}>
            Company Details
          </Typography>
        </Box>
      </Grid>
      <Grid container className={classes.rootDetail} spacing={2}>
        <Grid item xs={4} className={classes.firstGrid}>
          <Box mb={2}>
            <Company />
          </Box>
          <AboutCompany editCallback={() => setOpenModal(true)} />
        </Grid>
        <Grid item xs={8}>
          <Card className={classes.cardHeader} elevation={0}>
            <CardContent>
              <UsersCompany />
            </CardContent>
          </Card>
          <Card className={classes.cardHeader} elevation={0}>
            <CardContent>
              <ReportsCompany />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <KLDialog
        isOpen={openModal}
        onHandleClose={() => {
          setOpenModal(!openModal);
        }}
        title="Update Company"
        body={
          <CompaniesForm
            onCallBack={editedCompany => {
              setCompanyCustomerSelected(editedCompany);
              setOpenModal(!openModal);
            }}
          />
        }
        showButtonConfirm={false}
        showButtonClose={false}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    companyCustomerSelected: state.ui.listCompaniesCustomer.companyCustomerSelected
  };
};

const mapDispatchToProps = {
  setCompanyCustomerSelected
};

CompanyDashboardDetails = compose(withStyles(CompanyDashboardDetailsStyles), connect(mapStateToProps, mapDispatchToProps))(CompanyDashboardDetails);

export default CompanyDashboardDetails;
