export default theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    minWidth: '23rem',
    marginTop: '6em',
    padding: '1rem',
    border: '1px solid  #0000001f'
  },
  hint: {
    marginTop: '0.3em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500]
  },
  label: {
    color: theme.palette.grey[500]
  },
  input: {
    marginTop: '1em'
  },
  snackError: {
    backgroundColor: theme.palette.secondary.error
  },
  button: {
    '&:hover': {
      backgroundColor: '#a4cb39'
    }
  },
  link: {
    cursor: 'pointer'
  },
  groupPass: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  formIcon: {
    width: '10rem',
    height: 'auto'
  }
});
