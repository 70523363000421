export default theme => ({
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer'
  },
  box: {
    marginTop: 30
  },
  title: {
    fontSize: '1rem',
    color: '#212121',
    fontWeight: 500,
    marginTop: 10,
    marginBottom: 10
  },
  delete: {
    float: 'right',
    marginTop: 8,
    color: theme.palette.error.main
  },
  buyMessage: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    border: '0.5px solid ' + theme.palette.grey['300'],
    justifyContent: 'center',
    padding: '3rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
});
