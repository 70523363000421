import { HOME_PAGE_CHANGE_TAB } from '../actions/home.actions';

const initialState = {
  tab_selected: 0
};

const homePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOME_PAGE_CHANGE_TAB:
      return Object.assign({}, state, {
        tab_selected: action.payload
      });
    default:
      return state;
  }
};

export default homePageReducer;
