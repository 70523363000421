export default {
  CAPABILITY_REPORT: {
    STATUS: {
      NOT_COMPLETED: 'NOT_COMPLETED',
      DRAFT: 'DRAFT',
      COMPLETED: 'COMPLETED'
    }
  },
  STATUS: {
    DRAFT: {
      STATUS: 'DRAFT',
      LABEL: 'Draft',
      STATUS_LABEL: 'Pending Completion'
    },
    REVIEWED: {
      STATUS: 'REVIEWED',
      LABEL: 'Reviewed',
      STATUS_LABEL: 'Pending Approval'
    },
    APPROVED: {
      STATUS: 'APPROVED',
      LABEL: 'Approved',
      STATUS_LABEL: 'Approved'
    },
    PUBLISHED: {
      STATUS: 'PUBLISHED',
      LABEL: 'Published',
      STATUS_LABEL: 'Published'
    },
    PUBLISHED_EDITING: {
      STATUS: 'PUBLISHED_EDITING',
      LABEL: 'Published Editing',
      STATUS_LABEL: 'Published Editing'
    },
    PUBLISHED_EDITED: {
      STATUS: 'PUBLISHED_EDITED',
      LABEL: 'Published Edited',
      STATUS_LABEL: 'Published Edited'
    }
  }
};
