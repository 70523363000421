import React from 'react';
import { Box, Container, Paper, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

function ReleaseNotes(props) {
  return (
    <Container
      style={{
        padding: '32px 0'
      }}
    >
      <Paper>
        <Box p={5} display="flex" flexDirection="column" style={{ gap: 16 }}>
          <div>
            <Typography color="secondary" variant="h6" align="center" style={{ textDecoration: 'underline' }}>
              Competitive Insights Product Updates
            </Typography>
            <Typography paragraph align="center">
              Changes and improvements to Competitive Insights will be announced here.
            </Typography>
          </div>

          <Divider />

          <div>
            <Typography color="secondary" variant="h6" paragraph style={{ textDecoration: 'underline' }}>
              March 2024
            </Typography>
            <ul>
              <li>
                <Typography>
                  <strong>Added Appendix and Methodology Section</strong>
                </Typography>
                <Typography paragraph>We’ve added both an appendix and a methodology section with the PDF version of all CI reports.</Typography>
              </li>
              <li>
                <Typography>
                  <strong>Color Added to Capabilities Table</strong>
                </Typography>
                <Typography paragraph>
                  In an effort to improve readability and understanding, color has been added to features in the capabilities tables for the PDF
                  version of the CI reports.
                </Typography>
              </li>
              <li>
                <Typography>
                  <strong>Graphs Improved for Readability</strong>
                </Typography>
                <Typography paragraph>All graphs have been improved for better readability in both the PDF and online version.</Typography>
              </li>
              <li>
                <Typography>
                  <strong>Aligned PDF with Portal</strong>
                </Typography>
                <Typography paragraph>
                  Users asked that the layout of the report reflects the order of information in the CI portal. This change has been implemented so
                  the two are aligned in order.
                </Typography>
              </li>
              <li>
                <Typography>
                  <strong>Blank Squares Replaced by Check Marks</strong>
                </Typography>
                <Typography paragraph>
                  The blank squares that were being produced in error have been replaced by check marks where appropriate to align with the design and
                  layout of the CI portal.
                </Typography>
              </li>
            </ul>
          </div>

          <div>
            <Typography color="secondary" variant="h6" paragraph style={{ textDecoration: 'underline' }}>
              February 2024
            </Typography>
            <ul>
              <li>
                <Typography>
                  <strong>Labels for Call Outs Fixed</strong>
                </Typography>
                <Typography paragraph>
                  The PDF version of the CI reports were missing labels for call outs for special features and designs. This has been fixed so the
                  labels are appearing in the PDF.
                </Typography>
              </li>
              <li>
                <Typography>
                  <strong>Links in the Table of Content</strong>
                </Typography>
                <Typography paragraph>
                  The table of contents has been adjusted to link to the respective section in the report, eliminating scrolling.
                </Typography>
              </li>
              <li>
                <Typography>
                  <strong>Copyright Year</strong>
                </Typography>
                <Typography paragraph>The copyright year has been fixed to reflect date of publication.</Typography>
              </li>
            </ul>
          </div>
        </Box>
      </Paper>
    </Container>
  );
}

export default ReleaseNotes;
