export default theme => ({
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer'
  },
  box: {
    marginTop: 30,
    width: '100%'
  },
  title: {
    fontSize: '1rem',
    color: '#212121',
    fontWeight: 500,
    marginTop: 10,
    marginBottom: 10
  },
  chart: {
    height: '500px !important'
  },
  table: {
    width: '80%',
    border: '1px solid ' + theme.palette.divider,
    overflow: 'hidden',
    '& tbody > tr:last-child > td': {
      border: 'none'
    }
  },
  tableBroad: {
    width: '100%',
    border: '1px solid ' + theme.palette.divider,
    overflow: 'hidden',
    '& tbody > tr:last-child > td': {
      border: 'none'
    }
  },
  row: {
    backgroundColor: '#f3f3f3'
  },
  oddRow: {
    backgroundColor: theme.palette.primary.main
  },
  checked: {
    border: '1px solid #555555',
    fontSize: '0.7rem'
  },
  unchecked: {
    border: '1px solid #555555',
    fontSize: '0.7rem',
    fill: 'transparent'
  },
  changedCapability: {
    color: '#ff9800',
    borderColor: '#ff9800'
  },
  reportDropdown: {
    backgroundColor: theme.palette.primary.main,
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  updateHistory: {
    color: '#555',
    '& p': {
      fontSize: '0.8rem',
      margin: 0
    },
    '& div': {
      marginBottom: 20
    }
  },
  tableText: {
    fontSize: '0.7rem'
  },
  tableHeader: {
    fontSize: '0.7rem',
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  },
  borderBottom: {
    borderBottom: '1px solid ' + theme.palette.divider
  }
});
