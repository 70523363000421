export const CONTENT_BUILDING_DATA = 'CONTENT_BUILDING_DATA';
export const CLEAR_CONTENT_BUILDING_DATA = 'CLEAR_CONTENT_BUILDING_DATA';
export const CONTENT_BUILDING_SET_PRODUCTRATING = 'CONTENT_BUILDING_SET_PRODUCTRATING';

export const CONTENT_BUILDING_SET_EXUCUTESUMMARY = 'CONTENT_BUILDING_SET_EXUCUTESUMMARY';
export const SET_CATEGORY_RANK_BY_DEVICE = 'SET_CATEGORY_RANK_BY_DEVICE';
export const CONTENT_BUILDING_SET_TRENDS = 'CONTENT_BUILDING_SET_TRENDS';
export const CONTENT_BUILDING_SET_CATEGORY_PERFORMANCE = 'CONTENT_BUILDING_SET_CATEGORY_PERFORMANCE';
export const SET_BACK_CONTENT_BUILDING = 'SET_BACK_CONTENT_BUILDING';

export function setContentBuilding(contentBuilding) {
  return {
    type: CONTENT_BUILDING_DATA,
    payload: contentBuilding
  };
}

export function setExucuteSummary(ExucuteSummary) {
  return {
    type: CONTENT_BUILDING_SET_EXUCUTESUMMARY,
    payload: ExucuteSummary
  };
}

export function setProductRating(productRating) {
  return {
    type: CONTENT_BUILDING_SET_PRODUCTRATING,
    payload: productRating
  };
}

export function setTrendsInnovations(trends) {
  return {
    type: CONTENT_BUILDING_SET_TRENDS,
    payload: trends
  };
}

export function setCategoryPerformance(notableCallouts) {
  return {
    type: CONTENT_BUILDING_SET_CATEGORY_PERFORMANCE,
    payload: notableCallouts
  };
}

export function setBackToContentBuilding(back) {
  return {
    type: SET_BACK_CONTENT_BUILDING,
    payload: back
  };
}

export function clearContentBuilding() {
  return {
    type: CLEAR_CONTENT_BUILDING_DATA
  };
}

export function setCategoryRankByDevice(category, scores) {
  return {
    type: SET_CATEGORY_RANK_BY_DEVICE,
    payload: {
      category,
      scores
    }
  };
}
