import {
  SET_CAPABILITY_RANK_BY_CATEGORY_UNWEIGHTED,
  SET_DEVICE_WEIGHT,
  SET_ORDERED_OVERALL_RANKS,
  SET_PREVIOUS_ORDERED_OVERALL_RANKS,
  SET_SCORING_AND_RANKING
} from '../action/ScoringRanking.actions';

const initialState = {
  scoringAndRanking: {},
  orderedOverallRanks: {},
  capabilityRankByCategoryUnweighted: {},
  deviceWeight: {},
  previousOrderedOverallRanks: {}
};

const scoringAndRankingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCORING_AND_RANKING:
      return Object.assign({}, state, {
        scoringAndRanking: action.payload
      });
    case SET_ORDERED_OVERALL_RANKS:
      return Object.assign({}, state, {
        orderedOverallRanks: action.payload
      });
    case SET_CAPABILITY_RANK_BY_CATEGORY_UNWEIGHTED:
      return Object.assign({}, state, {
        capabilityRankByCategoryUnweighted: action.payload
      });
    case SET_DEVICE_WEIGHT:
      return Object.assign({}, state, {
        deviceWeight: action.payload || {}
      });
    case SET_PREVIOUS_ORDERED_OVERALL_RANKS:
      return Object.assign({}, state, {
        previousOrderedOverallRanks: action.payload
      });
    default:
      return state;
  }
};

export default scoringAndRankingReducer;
