import React, { Component, Fragment } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { Box, Button, DialogActions, DialogContent, DialogContentText, Grid } from '@material-ui/core';
import feathersServices from '../../../../services/feathersServices';
import KLDialog from '../../../../components/dialog/KLDialog';
import RenderTextField from '../../../../components/renders/RenderTextField';
import { maxValue, minValue, number, required } from '../../../../utils/validation';
import { setScoringRankingCompleted } from '../../actions/reports.actions';
import UserRatingsFormStyles from './UserRatingsForm.styles';

const minValue0 = minValue(0);
const maxValue1 = maxValue(5);

class UserRatingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scoringWarning: false
    };
  }

  render() {
    const { valid, applications, handleSubmit, classes, handleClose } = this.props;

    return (
      <Fragment>
        <form onSubmit={handleSubmit}>
          <Box>
            <DialogContent>
              <DialogContentText>Define User Ratings values for the current Company:</DialogContentText>
              <Grid container>
                {applications &&
                  applications
                    .filter(app => app.key !== 'Mobile Web')
                    .map((application, index) => {
                      return (
                        <Grid item key={index}>
                          <Field
                            color="secondary"
                            variant="outlined"
                            margin="dense"
                            validate={[required, number, minValue0, maxValue1]}
                            name={application.key}
                            label={application.key}
                            component={RenderTextField}
                            className={classes.textField}
                          />
                        </Grid>
                      );
                    })}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="text">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (this.props.isScoringRankingCompleted) {
                    this.setState({ scoringWarning: true });
                  } else {
                    handleSubmit();
                  }
                }}
                disableElevation
                color="secondary"
                variant="contained"
                disabled={!valid}
              >
                SAVE
              </Button>
            </DialogActions>
          </Box>
        </form>
        <KLDialog
          title={'Scoring & Ranking actions'}
          body={
            <DialogContentText>
              This change will reset scoring and rankings calculations. You will need to review them. Do you want to proceed?
            </DialogContentText>
          }
          labelButtonConfirm={'Accept'}
          onHandleConfirm={async () => {
            await handleSubmit();

            this.setState({ scoringWarning: false });
            this.props.deleteScoringAndRanking(this.props.scoringAndRanking._id);
            this.props.setScoringRankingCompleted(false);
          }}
          onHandleClose={() => this.setState({ scoringWarning: false })}
          isOpen={this.state.scoringWarning}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  let initValues = {};
  if (state.currentReport.selectedCompany && state.currentReport.selectedCompany.userRatings) {
    initValues = state.currentReport.selectedCompany.userRatings;
  }
  return {
    initialValues: initValues,
    scoringAndRanking: state.scoringAndRanking.scoringAndRanking,
    isScoringRankingCompleted: state.currentReport.scoringRankingCompleted
  };
};

const mapDispatchToProps = {
  setScoringRankingCompleted,
  deleteScoringAndRanking: feathersServices['scoring-ranking'].remove,
  push
};

UserRatingsForm = compose(
  withStyles(UserRatingsFormStyles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(
    {
      form: 'userRatingsForm',
      enableReinitialize: true,
      validate: (values, props) => {
        const errors = {};
        return errors;
      }
    },
    mapStateToProps
  )
)(UserRatingsForm);

export default UserRatingsForm;
