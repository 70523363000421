const required = value => (value || typeof value === 'number' ? undefined : 'Required');

const validEmail = value => {
  return value.match(/^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/) ? undefined : 'Invalid Email';
};

const zipCode = value => {
  return value.match(/^(\d{5}(?:-\d{4})?)$/) ? undefined : 'Invalid Zip Code';
};

const onlyLetters = value => {
  return value.match(/^[a-zA-Z\s]+$/) ? undefined : 'Only letters allowed';
};

const number = value => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);

const minLength = min => value => value && value.length < min ? `Must contains at least ${min}` : undefined;

const minValue = min => value => value && value < min ? `Must be at least ${min}` : undefined;

const maxValue = max => value => value && value > max ? `Must be less than ${max}` : undefined;

export { required, validEmail, number, minValue, maxValue, zipCode, onlyLetters, minLength };
