import {
  CAPABILITIES_TABLE_PAGE_CHANGE_TAB,
  CAPABILITIES_TABLE_SET_APPLICATIONS,
  CAPABILITIES_TABLE_TOGGLE_USER_RATINGS_DIALOG
} from '../actions/capabilities-table.actions';

const initialState = {
  tab_selected: 0
};

const capabilitiesTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case CAPABILITIES_TABLE_PAGE_CHANGE_TAB:
      return Object.assign({}, state, {
        tab_selected: action.payload
      });

    case CAPABILITIES_TABLE_SET_APPLICATIONS:
      return Object.assign({}, state, {
        applications: action.payload
      });

    case CAPABILITIES_TABLE_TOGGLE_USER_RATINGS_DIALOG:
      return Object.assign({}, state, {
        userRatingsDialogOpen: !state.userRatingsDialogOpen
      });

    default:
      return state;
  }
};

export default capabilitiesTableReducer;
