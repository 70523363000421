import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import Search from '../../../components/search/Search';
import { InfoIcon } from '../../../images';
import RowDataTableStyles from './RawDataTable.styles';

const RowDataTable = ({ classes, data, openSourceModal }) => {
  const [dataRow, setDataRow] = useState(data);
  // refresh props
  useEffect(() => {
    setDataRow(data);
  }, [data]);

  const applySearch = string => {
    const dataFiltered = data.filter(o => o['name'].toLowerCase().includes(string.toLowerCase()));
    setDataRow(dataFiltered);
  };

  const open = () => {
    openSourceModal();
  };

  return (
    <Grid container>
      <Grid item container className={classes.panel} alignItems="center">
        <Grid item lg={3} xl={2}>
          <Button variant="outlined" color="secondary" className={classes.button} onClick={open} fullWidth>
            Import Data <InfoIcon className={classes.icon} />
          </Button>
        </Grid>
        <Grid item lg={9} xl={10} className={classes.flex}>
          <Search onChange={applySearch} />
        </Grid>
      </Grid>

      <Grid item container>
        <Paper elevation={0} className={classes.table}>
          <Table size="medium" aria-label="a dense table">
            <TableHead>
              <TableRow className={classes.row}>
                <TableCell className={classes.tableHeader}>Capability</TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  1 <InfoIcon className={classes.icon} />
                </TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  2 <InfoIcon className={classes.icon} />
                </TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  3 <InfoIcon className={classes.icon} />
                </TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  4 <InfoIcon className={classes.icon} />
                </TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  5 <InfoIcon className={classes.icon} />
                </TableCell>
                <TableCell align="center" className={classes.tableHeader}>
                  4&5 <InfoIcon className={classes.icon} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataRow?.map((row, index) => {
                return (
                  <TableRow key={index} className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}>
                    <TableCell>
                      <Typography className={classes.tableText} color="textSecondary">
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes.tableText} color="textSecondary">
                        {row.one + '%'}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes.tableText} color="textSecondary">
                        {row.two + '%'}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes.tableText} color="textSecondary">
                        {row.three + '%'}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes.tableText} color="textSecondary">
                        {row.four + '%'}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes.tableText} color="textSecondary">
                        {row.five + '%'}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className={classes.tableText} color="textSecondary">
                        {row.four + row.five + '%'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    data: state.consumerSurvey.rawData
  };
};

export default compose(withStyles(RowDataTableStyles), connect(mapStateToProps, null))(RowDataTable);
