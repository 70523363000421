export default theme => ({
  container: {
    marginTop: theme.spacing(4),
    overflow: 'hidden',
    padding: '0 10%',
    [theme.breakpoints.down('lg')]: {
      padding: '0 5%'
    }
  },
  grow: {
    flexGrow: 1
  },
  paper: {
    width: '10rem',
    height: '8rem'
  },
  borderBottom: {
    borderBottom: '1px solid ' + theme.palette.divider
  },
  paperButton: {
    width: '100%',
    height: '100%'
  },
  categories: {
    textAlign: 'left'
  },
  fieldset: {
    borderRadius: 10,
    border: '2px solid' + theme.palette.secondary.main,
    float: 'right'
  },
  legend: {
    display: 'flex',
    alignItems: 'center'
  },
  legendTitle: {
    float: 'left',
    marginRight: 5
  },
  weights: {
    float: 'left',
    marginRight: 20
  }
});
