export const SCORING_RANKING_TAB = 'SCORING_RANKING_TAB';
export const SET_SCORING_AND_RANKING = 'SET_SCORING_AND_RANKING';
export const SET_ORDERED_OVERALL_RANKS = 'SET_ORDERED_OVERALL_RANKS';
export const SET_CAPABILITY_RANK_BY_CATEGORY_UNWEIGHTED = 'SET_CAPABILIY_RANK_BY_CATEGORY_UNWEIGHTED';
export const TOGGLE_DEVICE_WEIGHT_DIALOG = 'TOGGLE_DEVICE_WEIGHT_DIALOG';
export const SET_DEVICE_WEIGHT = 'SET_DEVICE_WEIGHT';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_SCORING_AND_RANKING_CALCULATED = 'SET_SCORING_AND_RANKING_CALCULATED';
export const SET_PREVIOUS_ORDERED_OVERALL_RANKS = 'SET_PREVIOUS_ORDERED_OVERALL_RANKS';

export function setScoringRankingTab(tab) {
  return {
    type: SCORING_RANKING_TAB,
    payload: tab
  };
}

export function setScoringAndRanking(data) {
  return {
    type: SET_SCORING_AND_RANKING,
    payload: data
  };
}

export function setOrderedOverallRanks(data) {
  return {
    type: SET_ORDERED_OVERALL_RANKS,
    payload: data
  };
}

export function setDeviceWeight(data) {
  return {
    type: SET_DEVICE_WEIGHT,
    payload: data
  };
}

export function setCapabilityRankByCategoryUnweighted(data) {
  return {
    type: SET_CAPABILITY_RANK_BY_CATEGORY_UNWEIGHTED,
    payload: data
  };
}

export function toggleDeviceWeightDialog() {
  return {
    type: TOGGLE_DEVICE_WEIGHT_DIALOG
  };
}

export function setIsLoading(isLoading) {
  return {
    type: SET_IS_LOADING,
    payload: isLoading
  };
}

export function setScoringAndRankingCalculated(calculated) {
  return {
    type: SET_SCORING_AND_RANKING_CALCULATED,
    payload: calculated
  };
}

export function setPreviousOrderedOverallRanks(previousOrderedOverallRanks) {
  return {
    type: SET_PREVIOUS_ORDERED_OVERALL_RANKS,
    payload: previousOrderedOverallRanks
  };
}
