export default theme => ({
  icon: {
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    padding: '0 1em 1em 1em'
  },
  input: {
    marginTop: '1em'
  },
  textField: {
    width: 150,
    margin: 10
  },
  titleContainer: {
    justifyContent: 'flex-end'
  },
  infoIcon: {
    float: 'left',
    marginRight: 5
  },
  link: {
    textDecoration: 'none'
  }
});
