const drawerWidth = 550;

export default theme => ({
  root: {
    justifyContent: 'space-between',
    display: 'flex',
    marginBottom: '1rem'
  },
  icon: {
    verticalAlign: 'bottom'
  },
  container: {
    paddingTop: theme.spacing(1)
  },
  paper: {
    width: drawerWidth,
    zIndex: 1300
  },
  drawerRoot: {
    width: 0 // to allow click on the background
  },
  cell: {
    maxWidth: 150
  },
  table: {
    width: '100%',
    border: '1px solid ' + theme.palette.divider,
    overflow: 'hidden',
    '& tbody > tr:last-child > td': {
      border: 'none'
    }
  },
  row: {
    backgroundColor: '#f3f3f3'
  },
  oddRow: {
    backgroundColor: theme.palette.primary.main
  }
});
