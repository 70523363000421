import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = {
  viewBox: '0 0 45 40'
};

// viewBox
const DropboxIcon = props => (
  <SvgIcon {...useStyles} style={props}>
    <g id="icon/dropbox" stroke="none" strokeWidth="1" fillRule="evenodd">
      <path
        d="m12.5 0l-12.5 8.1 8.7 7 12.5-7.8-8.7-7.3zm-12.5 21.9l12.5 8.2 8.7-7.3-12.5-7.7-8.7 6.8zm21.2 0.9l8.8 7.3 12.4-8.1-8.6-6.9-12.6 7.7zm21.2-14.7l-12.4-8.1-8.8 7.3 12.6 7.8 8.6-7zm-21.1 16.3l-8.8 7.3-3.7-2.5v2.8l12.5 7.5 12.5-7.5v-2.8l-3.8 2.5-8.7-7.3z"
        fill="#007EE5"
      ></path>
    </g>
  </SvgIcon>
);

export default DropboxIcon;
