import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Search from '../../../components/search/Search';
import { InfoIcon } from '../../../images';
import SortedDataStyles from './SortedData.styles';

const SortedData = ({ classes, data, save }) => {
  const [dataRow, setDataRow] = useState(data || []);
  const [showDetailed, setShowDetailed] = useState(false);
  const [openFormula, setOpenFormula] = useState(false);

  const [formulaNormalize, setFormulaNormalize] = useState('');
  const [formulaNearest, setFormulaNearest] = useState('');
  const [formulaScoring, setFormulaScoring] = useState('');

  const onHandlerComplete = () => {
    if (save) {
      save('/report/report-publish-date');
    }
  };

  const applySearch = string => {
    const dataFiltered = data.filter(o => o['name'].toLowerCase().includes(string.toLowerCase()));
    setDataRow(dataFiltered);
  };

  return (
    <Grid container>
      <Grid container alignItems="center" className={classes.formula}>
        <Box>
          {/*<Button*/}
          {/*  variant="outlined"*/}
          {/*  color="secondary"*/}
          {/*  className={classes.button}*/}
          {/*  onClick={() => setOpenFormula(true)}*/}
          {/*>*/}
          {/*  Formula*/}
          {/*</Button>*/}
          &nbsp;
          <FormControlLabel
            control={<Checkbox checked={showDetailed} onChange={() => setShowDetailed(!showDetailed)} />}
            label="Show Detailed Columns"
          />
        </Box>
        <Search onChange={applySearch} />
      </Grid>
      <Paper className={classes.table} elevation={0}>
        <Table size="medium" aria-label="a dense table">
          <TableHead>
            <TableRow className={classes.row}>
              <TableCell className={classes.tableHeader}>Capability</TableCell>
              <TableCell align="center" className={classes.tableHeader}>
                5 <InfoIcon className={classes.icon} />
              </TableCell>
              <TableCell align="center" className={classes.tableHeader}>
                4&5 <InfoIcon className={classes.icon} />
              </TableCell>
              <TableCell align="center" className={classes.tableHeader}>
                Scoring Weight <InfoIcon className={classes.icon} />
              </TableCell>
              {showDetailed && (
                <Fragment>
                  <TableCell align="center" className={classes.tableHeader}>
                    Normalize to 5 <InfoIcon className={classes.icon} />
                  </TableCell>
                  <TableCell align="center" className={classes.tableHeader}>
                    Nearest to 5 <InfoIcon className={classes.icon} />
                  </TableCell>
                </Fragment>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRow.map((row, index) => {
              return (
                <TableRow className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)} key={index}>
                  <TableCell>
                    <Typography className={classes.tableText} color="textSecondary">
                      {index + 1 + ' - ' + row.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes.tableText} color="textSecondary">
                      {row.five + '%'}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes.tableText} color="textSecondary">
                      {row.four + row.five + '%'}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {' '}
                    <Typography className={classes.tableText} color="textSecondary">
                      {row.nearestPointFive}
                    </Typography>
                  </TableCell>
                  {showDetailed && (
                    <Fragment>
                      <TableCell align="center">
                        <Typography className={classes.tableText} color="textSecondary">
                          {row.normalized}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography className={classes.tableText} color="textSecondary">
                          {row.nearestPointFive}
                        </Typography>
                      </TableCell>
                    </Fragment>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <Grid className={classes.complete}>
        <Button variant="outlined" color="secondary" className={classes.button} onClick={onHandlerComplete}>
          Complete
        </Button>
      </Grid>
      <Dialog onClose={() => setOpenFormula(false)} aria-labelledby="customized-dialog-title" open={openFormula}>
        <DialogTitle id="customized-dialog-title" onClose={() => setOpenFormula(false)}>
          Current Formulas
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography color="secondary" className={classes.reset}>
                Reset to Default formula
              </Typography>
              <TextField
                fullWidth
                label="Normalize to 5"
                variant="outlined"
                id="normTo5"
                disabled={true}
                InputLabelProps={{
                  shrink: true
                }}
                value={formulaNormalize}
                onChange={({ target }) => setFormulaNormalize(target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="secondary" className={classes.reset}>
                Reset to Default formula
              </Typography>
              <TextField
                fullWidth
                label="Find Nearest 0.5"
                variant="outlined"
                id="nearest"
                disabled={true}
                InputLabelProps={{
                  shrink: true
                }}
                value={formulaNearest}
                onChange={({ target }) => setFormulaNearest(target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="secondary" className={classes.reset}>
                Reset to Default formula
              </Typography>
              <TextField
                fullWidth
                label="Scoring Weight"
                variant="outlined"
                id="scoring"
                disabled={true}
                InputLabelProps={{
                  shrink: true
                }}
                value={formulaScoring}
                onChange={({ target }) => setFormulaScoring(target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpenFormula(false)} color="default">
            Cancel
          </Button>
          <Button autoFocus variant="outlined" disabled={true} onClick={() => setOpenFormula(false)} color="secondary">
            Re-Sort Table
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const mapStateToProps = state => {
  let data = state.consumerSurvey.rawData ? [...state.consumerSurvey.rawData] : [];
  // sort array by Scoring Weight, major to minor
  data.sort((a, b) => {
    if (a.nearestPointFive > b.nearestPointFive) {
      return -1;
    }
    if (a.nearestPointFive < b.nearestPointFive) {
      return 1;
    }
    return 0;
  });
  return {
    data
  };
};

export default compose(withStyles(SortedDataStyles), connect(mapStateToProps, null))(SortedData);
