import {
  LAYOUT_CLEAR_OVERRIDE_MODAL,
  LAYOUT_CLOSE_NOTIFICATION,
  LAYOUT_FIRE_NOTIFICATION,
  LAYOUT_FORGOT_EXPIRED,
  LAYOUT_FORGOT_SUCCESS,
  LAYOUT_SET_OVERRIDE_MODAL,
  LAYOUT_SET_SCREENSHOT_MODAL,
  LAYOUT_TOGGLE_APP_BAR,
  LAYOUT_TOGGLE_CATEGORY,
  LAYOUT_TOGGLE_SIDE_MENU
} from '../actions/layout.actions';

const initialState = {
  showAppBar: true,
  sideMenu: {
    isOpen: false
  },
  expandCategory: true,
  notification: {
    notificationOpen: false,
    message: '',
    variant: 'error'
  },
  forgotLinkExpired: false,
  forgotPasswordSuccess: false,
  screenshotModal: {
    open: false,
    allowEmpty: true,
    onComplete: null
  },
  overrideModal: {
    open: false,
    params: null,
    query: null,
    actualContentBuilding: null
  }
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case LAYOUT_TOGGLE_SIDE_MENU:
      return Object.assign({}, state, {
        sideMenu: Object.assign({}, state.sideMenu, {
          isOpen: action.payload
        })
      });
    case LAYOUT_FIRE_NOTIFICATION:
      return Object.assign({}, state, {
        notification: Object.assign({}, state.notification, {
          notificationOpen: true,
          variant: action.payload.variant,
          message: action.payload.message,
          duration: action.payload.duration
        })
      });
    case LAYOUT_CLOSE_NOTIFICATION:
      return Object.assign({}, state, {
        notification: Object.assign({}, state.notification, {
          notificationOpen: false
        })
      });
    case LAYOUT_TOGGLE_APP_BAR:
      return Object.assign({}, state, {
        showAppBar: action.payload
      });
    case LAYOUT_TOGGLE_CATEGORY:
      return Object.assign({}, state, {
        expandCategory: action.payload
      });
    case LAYOUT_FORGOT_EXPIRED:
      return Object.assign({}, state, {
        forgotLinkExpired: action.payload
      });
    case LAYOUT_FORGOT_SUCCESS:
      return Object.assign({}, state, {
        forgotPasswordSuccess: action.payload
      });
    case LAYOUT_SET_SCREENSHOT_MODAL:
      return Object.assign({}, state, {
        screenshotModal: action.payload
      });
    case LAYOUT_SET_OVERRIDE_MODAL:
      return Object.assign({}, state, {
        overrideModal: action.payload
      });
    case LAYOUT_CLEAR_OVERRIDE_MODAL:
      return Object.assign({}, state, {
        overrideModal: initialState.overrideModal
      });
    default:
      return state;
  }
};

export default layoutReducer;
