export default theme => ({
  detailPaper: {
    height: '15rem'
  },
  paperButton: {
    width: '100%',
    height: '100%'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    minWidth: 300,
    marginTop: '6em'
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    backgroundColor: theme.palette.secondary.main
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500]
  },
  form: {
    padding: '0 1em 1em 1em'
  },
  input: {
    marginTop: '1em'
  },
  actions: {
    padding: '0 1em 1em 1em'
  },
  snackError: {
    backgroundColor: theme.palette.secondary.error
  },
  titleContainer: {
    justifyContent: 'flex-end',
    alignSelf: 'flex-start'
  },

  previewImage: {
    width: '100%',
    cursor: 'pointer',
    '& svg': {
      color: theme.palette.secondary.main
    },
    '& img': {
      borderRadius: 5
    }
  },
  uploadBox: {
    backgroundColor: '#f8f8f8',
    border: '1px dashed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 190,
    width: '99%',
    cursor: 'pointer',
    borderRadius: 5,
    marginTop: theme.spacing(4)
  },
  uploadText: {
    fontSize: '0.65rem'
  },

  imageInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  removeIcon: {
    fontSize: '1rem',
    cursor: 'pointer'
  },
  resize: {
    fontSize: '0.875rem',
    color: theme.palette.secondary.main
  },
  checkboxTiny: {
    padding: 0,
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1rem'
    }
  },
  checkboxLabel: {
    fontSize: '0.6rem',
    lineHeight: 1
  },
  header: {
    height: '4rem',
    backgroundColor: theme.palette.secondary.main
  }
});
