import React from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import KLDialogStyle from './KLDialog.style';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const KLDialog = ({
  title = 'Title',
  body,
  labelButtonConfirm = 'Continue',
  showButtonConfirm = true,
  disableButtonConfirm = false,
  labelButtonClose = 'Cancel',
  showButtonClose = true,
  dividers = false,
  isOpen,
  onHandleClose,
  onHandleConfirm,
  classes,
  ...rest
}) => {
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      disableEscapeKeyDown
      className={classes.root}
      onClose={() => {
        if (onHandleClose) {
          onHandleClose();
        }
      }}
      {...rest}
    >
      <DialogTitle>
        {title}
        {onHandleClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onHandleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers={dividers} className={classes.root}>
        {body}
      </DialogContent>
      <DialogActions>
        {showButtonConfirm && (
          <Button
            variant="contained"
            disabled={disableButtonConfirm}
            onClick={() => {
              if (onHandleConfirm) {
                onHandleConfirm();
              }
            }}
            disableElevation
            className={classes.button}
            color="primary"
          >
            {disableButtonConfirm && <CircularProgress color="secondary" size={25} thickness={3} />}
            {labelButtonConfirm}
          </Button>
        )}
        {showButtonClose && (
          <Button
            variant="contained"
            onClick={() => {
              if (onHandleClose) {
                onHandleClose();
              }
            }}
            disableElevation
            className={classes.button}
            color="secondary"
          >
            {labelButtonClose}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(KLDialogStyle)(KLDialog);
