import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField, withStyles } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import RenderTextFieldStyle from './RenderTextField.style';

const RenderPasswordField = ({ classes, label, input, variant, meta: { touched, invalid, error }, ...custom }) => {
  const [isVisibly, setIsVisibly] = useState(false);

  return (
    <TextField
      className={classes.root}
      InputLabelProps={{ className: classes.label }}
      label={label}
      placeholder={label}
      type={isVisibly ? 'text' : 'password'}
      error={touched && invalid}
      helperText={touched && error}
      autoComplete="current-password"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setIsVisibly(!isVisibly);
              }}
              onMouseDown={event => event.preventDefault()}
            >
              {isVisibly ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...input}
      {...custom}
      variant={variant}
    />
  );
};

export default withStyles(RenderTextFieldStyle)(RenderPasswordField);
