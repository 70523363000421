import ReactEcharts from 'echarts-for-react';
import React, { Component, Fragment } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Link, default as Scroll } from 'react-scroll/modules';
import { compose } from 'redux';
import * as _ from 'lodash';
import clsx from 'clsx';
import { debounceTime, Subject } from 'rxjs';
import { Box, Breadcrumbs, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Edit from '@material-ui/icons/Edit';
import feathersServices from '../../../services/feathersServices';
import KLEditor from '../../../components/editor/KLEditor';
import { clearOverrideModal, fireNotification, setScreenshotModal } from '../../../components/layout/actions/layout.actions';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import LinkRouter from '../../../components/router-link/RouterLink';
import ScreenshotModal from '../../../components/screenshotModal/ScreenshotModal';
import KLSecurity from '../../../components/security/Security';
import { SECURITY_ACTIONS } from '../../../components/security/Security.constants';
import { orderBy } from '../../../utils/common.utils';
import { validateProps, validateRoute } from '../../../utils/routeUtils';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { canEdit } from '../../../utils/user.utils';
import { USER_ROLE } from '../../../constants/user.constants';
import REPORT_CONSTANTS from '../../reports/report.constants';
import { clearContentBuilding, setBackToContentBuilding, setContentBuilding, setExucuteSummary } from '../action/ContentBuilding.actions';
import ContentBuildingSave from '../common/SaveContentBuilding';
import { EXECUTIVE_CONSTANTS } from './ExecutiveSummary.constants';
import ExecutiveSummaryStyles from './ExecutiveSummary.styles';
import SummaryTemplate from './SummaryTemplate';

class KLExecutiveSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allCapabilities: [],
      loadingChart: true,
      editTopPerformers: false,
      lineSeries: [],
      barSeries: [],
      options: EXECUTIVE_CONSTANTS.CHART_OPTIONS
    };
  }

  async componentDidMount() {
    const series = [];
    const reportsLegend = [];
    const legendData = [];

    setScreenshotModal({
      open: false,
      allowEmpty: true,
      onComplete: null
    });
    this.props.clearOverrideModal();

    const { push, fireNotification, dbReport, isPreview, isScoringRankingCompleted, contentBuilding } = this.props;

    validateProps(push, fireNotification, dbReport);
    validateRoute(push, dbReport.status !== REPORT_CONSTANTS.STATUS.DRAFT.STATUS || isScoringRankingCompleted);

    if (!contentBuilding._id) {
      this.props
        .getContentBuilding({
          query: {
            reportId: this.props.dbReport._id,
            $limit: 1
          }
        })
        .then(({ value }) => {
          value[0] ? this.props.setContentBuilding(value[0]) : this.props.clearContentBuilding();
        });
    }

    if (this.props.back2ContentBuilding) {
      this.setState(
        {
          editTopPerformers: true
        },
        () => {
          Scroll.scroller.scrollTo('topPerformers', {
            smooth: true,
            delay: 500,
            duration: 1000
          });
        }
      );
      this.props.setBackToContentBuilding(false);
    } else {
      Scroll.animateScroll.scrollToTop({
        smooth: 'easeOutQuint',
        delay: 500,
        duration: 1000
      });
    }

    // Find Same and previous reports.-
    if (dbReport && dbReport.industry && !isPreview) {
      const reportsFilter = await this.props.getPreviousReport({
        query: {
          industry: dbReport.industry._id,
          date: { $lt: dbReport.date },
          status: {
            $in: [REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS, REPORT_CONSTANTS.STATUS.PUBLISHED_EDITING.STATUS]
          },
          $populate: true,
          $limit: 7,
          $sort: {
            date: -1
          }
        }
      });

      const reports = reportsFilter.value.data;
      reports.push(dbReport);

      const sortedReports = reports.sort((a, b) => {
        if (new Date(a.date) < new Date(b.date)) {
          return -1;
        }
        return 1;
      });

      const allCompanies = [];
      sortedReports
        .reduce((arr, el) => arr.concat(el.companies), [])
        .forEach(c => allCompanies.indexOf(c.title) === -1 && allCompanies.push(c.title));

      let onlyOneReport = true;
      if (sortedReports.length > 1) {
        onlyOneReport = false;
      }
      // For each report.-
      for (const Key in sortedReports) {
        const report = sortedReports[Key];

        // Report legend.-
        reportsLegend.push(SingletonMomentUtils.moment(report.date).format('MMMM YYYY'));

        // Find report's scoring and ranking.-
        const { value: scoring } = await this.props.getScoringAndRanking({
          query: {
            reportId: report._id,
            $limit: 1
          }
        });

        // For each company.-
        if (scoring && scoring.data[0]) {
          const companies = orderBy(scoring.data[0].scoringAndRanking, 'overallPercentage');
          for (let comp of allCompanies) {
            const company = companies.find(c => c.companyName === comp);
            if (company) {
              // Put company legend.-
              legendData.push(company.companyName);

              // If the series for that company doesn't exists, create it.-
              if (!series.find(s => s.name === company.companyName)) {
                series.push({
                  id: Math.random(),
                  name: company.companyName,
                  smooth: false,
                  type: 'line',
                  symbolSize: 16,
                  symbol: 'circle',
                  data: [
                    {
                      value: (company.overallPercentage * 100).toFixed(3),
                      emphasis: {
                        focus: 'series'
                      },
                      itemStyle: {
                        opacity: onlyOneReport ? 1 : 0
                      }
                    }
                  ]
                });
              } else {
                const currentSeries = series.find(s => s.name === company.companyName);
                // If exists, find it and add the data.
                // eslint-disable-next-line
                if (Key == sortedReports.length - 1) {
                  // do not change to ===
                  currentSeries.data.push({
                    value: (company.overallPercentage * 100).toFixed(3),
                    emphasis: {
                      focus: 'series'
                    }
                  });
                } else {
                  currentSeries.data.push({
                    value: (company.overallPercentage * 100).toFixed(3),
                    emphasis: {
                      focus: 'series'
                    },
                    itemStyle: {
                      opacity: 0
                    }
                  });
                }
              }
            } else {
              if (!series.find(s => s.name === comp)) {
                series.push({
                  id: Math.random(),
                  name: comp,
                  smooth: false,
                  type: 'line',
                  symbolSize: 16,
                  symbol: 'circle',
                  data: [
                    {
                      value: null,
                      emphasis: {
                        focus: 'series'
                      },
                      itemStyle: {
                        opacity: onlyOneReport ? 1 : 0
                      }
                    }
                  ]
                });
              } else {
                const currentSeries = series.find(s => s.name === comp);
                // eslint-disable-next-line
                if (Key == sortedReports.length - 1) {
                  // do not change to ===
                  currentSeries.data.push({
                    value: null,
                    emphasis: {
                      focus: 'series'
                    }
                  });
                } else {
                  currentSeries.data.push({
                    value: null,
                    emphasis: {
                      focus: 'series'
                    },
                    itemStyle: {
                      opacity: 0
                    }
                  });
                }
              }
            }
          }
        }
      }

      series.sort((a, b) => {
        return parseFloat(_.last(a.data)) > parseFloat(_.last(b.data)) ? -1 : 1;
      });
      let addedStateFarm = false;
      series.forEach((s, i) => {
        if (s.name === 'State Farm') {
          s.color = '#92140C';
          addedStateFarm = true;
        } else {
          s.color = EXECUTIVE_CONSTANTS.CHART_OPTIONS.color[addedStateFarm ? i - 1 : i];
        }
      });

      let seriesCopy = JSON.parse(JSON.stringify(series));
      seriesCopy.forEach(s => {
        s.type = 'bar';
        s.data.forEach(d => {
          d.itemStyle = { opacity: 1 };
          d.type = 'bar';
        }); // always show symbol for bar graph
      });

      let options = Object.assign(
        {},
        this.state.options,
        { series: onlyOneReport ? seriesCopy : series },
        { legend: Object.assign({}, EXECUTIVE_CONSTANTS.CHART_OPTIONS.legend, { data: legendData }) },
        {
          xAxis: {
            data: reportsLegend
          }
        }
      );

      let toolBoxOptions = EXECUTIVE_CONSTANTS.CHART_OPTIONS.toolbox;
      if (onlyOneReport) {
        toolBoxOptions.feature.magicType = null;
      } else {
        toolBoxOptions.feature.restore = {
          show: true
        };
        toolBoxOptions.feature.magicType = {
          show: true,
          type: ['line', 'bar']
        };
      }
      // Update chart options.-
      this.setState({
        loadingChart: false,
        options: Object.assign({}, options, { toolbox: toolBoxOptions }),
        lineSeries: series,
        barSeries: seriesCopy
      });
    }

    this.props
      .getCapabilityReports({
        query: {
          report: dbReport._id,
          $limit: 'infinity'
        }
      })
      .then(data => {
        this.setState({
          allCapabilities: { [dbReport._id]: data.value }
        });
      });

    this.topInput$ = new Subject();
    this.topInput$.pipe(debounceTime(500)).subscribe(content => {
      this.handleEditorContent(content);
    });
  }

  handleEditorContent = content => {
    const es = Object.assign({}, this.props.executiveSummary);
    es.topPerformers = content;
    this.props.setExucuteSummary(es);
  };

  onChartTypeChanged = ({ currentType }) => {
    const { options, barSeries, lineSeries } = this.state;
    switch (currentType) {
      case 'bar':
        this.setState({
          options: Object.assign({}, options, { series: barSeries })
        });
        break;
      case 'line':
        this.setState({
          options: Object.assign({}, options, { series: lineSeries })
        });
        break;
      default:
        this.setState({
          options: Object.assign({}, options, { series: lineSeries })
        });
        break;
    }
  };

  render() {
    const { editTopPerformers, loadingChart, allCapabilities, options } = this.state;
    const { dbReport, selectedIndustry, classes, user, executiveSummary, orderedOverallRanks, isPreview, contentBuilding } = this.props;

    let topPerformersView = EXECUTIVE_CONSTANTS.TOP_PERFORMERS;
    if (!this.props.contentBuildingService.isLoading) {
      const execSum =
        (this.props.contentBuildingService.data && this.props.contentBuildingService.data.executiveSummary) ||
        (this.props.contentBuildingService.queryResult &&
          this.props.contentBuildingService.queryResult[0] &&
          this.props.contentBuildingService.queryResult[0].executiveSummary);
      if (execSum) {
        topPerformersView = execSum.topPerformers || EXECUTIVE_CONSTANTS.TOP_PERFORMERS;
      }
    }

    return (
      <Box className={classes.centerContainer}>
        <Grid container direction="column" className={classes.container} item lg={10} xl={8}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/report" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                {user.loginType !== USER_ROLE.CUSTOMER ? 'Home' : 'Report'}
              </Typography>
            </LinkRouter>
            <LinkRouter to={canEdit(user, dbReport) ? '/report/report-publish-date' : '/content-building'} color="secondary" underline="none">
              {dbReport && (
                <Typography color="secondary" variant="body2">
                  {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
                </Typography>
              )}
            </LinkRouter>
            {user.loginType !== USER_ROLE.CUSTOMER && (
              <LinkRouter to="/content-building" color="secondary" underline="none">
                <Typography color="secondary" variant="body2">
                  Content Building
                </Typography>
              </LinkRouter>
            )}
            <Typography variant="body2">Executive Summary</Typography>
          </Breadcrumbs>
          <Box mt={5} mb={4} className={classes.width100}>
            <Typography variant="h4">Executive Summary</Typography>
          </Box>
          <SummaryTemplate className={classes.width100} />
          <Box className={classes.box}>
            <Typography className={classes.title}>Contents</Typography>
            <Link to="topPerformers" duration={1000} smooth="easeOutQuint">
              <Typography color="secondary" variant="body2" className={classes.link}>
                Top Performers
              </Typography>
            </Link>
            {!isPreview && (
              <Fragment>
                <Link to="cr" duration={1000} smooth="easeOutQuint">
                  <Typography color="secondary" variant="body2" className={classes.link}>
                    Company Ranking History
                  </Typography>
                </Link>
                <Link to="ccr" duration={1000} smooth="easeOutQuint">
                  <Typography color="secondary" variant="body2" className={classes.link}>
                    Comparative Company Ranking
                  </Typography>
                </Link>
              </Fragment>
            )}
          </Box>
          <Box className={clsx(classes.box, isPreview ? classes.gradient : '')} id="topPerformers">
            <Typography className={classes.title}>
              Top Performers
              <KLSecurity action={SECURITY_ACTIONS.EDIT}>
                <IconButton onClick={() => this.setState({ editTopPerformers: !editTopPerformers })} size="small">
                  <Edit style={{ marginLeft: 5, cursor: 'pointer' }} color={'secondary'} fontSize={'small'} />
                </IconButton>
              </KLSecurity>
            </Typography>
            {editTopPerformers && (
              <Collapse in={editTopPerformers}>
                <KLEditor content={executiveSummary.topPerformers || EXECUTIVE_CONSTANTS.TOP_PERFORMERS} setContent={c => this.topInput$.next(c)} />
                <LinkRouter
                  to="/scoring-ranking"
                  color="secondary"
                  underline="none"
                  className={classes.floatLeft}
                  onClick={() => this.props.setBackToContentBuilding(true)}
                >
                  <Typography color="secondary" variant="body1">
                    Review Scoring & Ranking
                  </Typography>
                </LinkRouter>
                <ContentBuildingSave
                  params={{
                    $set: {
                      'executiveSummary.topPerformers': executiveSummary.topPerformers
                    },
                    loadedAt: contentBuilding.loadedAt
                  }}
                  onSave={() => this.setState({ editTopPerformers: !editTopPerformers })}
                  onCancel={() => {
                    this.props.setExucuteSummary({
                      ...executiveSummary,
                      topPerformers: topPerformersView
                    });
                    this.setState({ editTopPerformers: !editTopPerformers });
                  }}
                />
              </Collapse>
            )}
            {!editTopPerformers && (
              <Typography
                name="ckEditorView"
                component="div"
                variant="body2"
                color="textSecondary"
                dangerouslySetInnerHTML={{
                  __html: topPerformersView
                }}
              />
            )}
          </Box>
          {!isPreview && (
            <Fragment>
              <Box className={classes.box} id="cr">
                <Typography className={classes.title}>Compare Ranking</Typography>
                <LoadingIndicator color="secondary" isLoading={loadingChart} width={40} />
                {!loadingChart && (
                  <Fragment>
                    <ReactEcharts
                      className={classes.chart}
                      lazyUpdate={true}
                      option={options}
                      onEvents={{
                        magictypechanged: this.onChartTypeChanged
                      }}
                    />
                    <Box mt={4}>
                      <Typography variant="body2" color="textSecondary">
                        Click on a company name above to toggle view.
                      </Typography>
                    </Box>
                  </Fragment>
                )}
              </Box>
              <Box className={classes.box} id="ccr">
                <Typography className={classes.title}>Comparative Company Ranking</Typography>
                <Paper elevation={0} className={classes.table}>
                  <Table size="medium" aria-label="a dense table">
                    <TableHead>
                      <TableRow className={classes.row}>
                        <TableCell align="center" className={classes.tableHeader}>
                          Rank
                        </TableCell>
                        <TableCell align="center" className={classes.tableHeader}>
                          Overall Score
                        </TableCell>
                        <TableCell align="center" className={classes.tableHeader}>
                          Mobile Score
                        </TableCell>
                        <TableCell align="center" className={classes.tableHeader}>
                          iPhone Score
                        </TableCell>
                        <TableCell align="center" className={classes.tableHeader}>
                          Android Score
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderedOverallRanks &&
                        orderedOverallRanks.overall &&
                        orderedOverallRanks.overall.map((score, index) => {
                          return (
                            <TableRow key={index} className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}>
                              <TableCell align="center">
                                <Typography className={classes.tableText} color="textSecondary">
                                  #{index + 1}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                className={this.getStyleByCompany(index, 'overall')}
                                onMouseOver={() => this.overCompany(index, 'overall')}
                              >
                                <Typography color="textSecondary" className={clsx(classes.floatLeft, classes.tableText)} variant="body2">
                                  {orderedOverallRanks[['overall']][index].company}
                                </Typography>
                                <Typography color="textSecondary" className={clsx(classes.floatRight, classes.tableText)} variant="body2">
                                  {(orderedOverallRanks[['overall']][index].overallScore * 100).toFixed() + '%'}
                                </Typography>
                              </TableCell>

                              <TableCell
                                align="center"
                                className={this.getStyleByCompany(index, 'Mobile Web')}
                                onMouseOver={() => this.overCompany(index, 'Mobile Web')}
                              >
                                <Typography color="textSecondary" className={clsx(classes.floatLeft, classes.tableText)} variant="body2">
                                  {orderedOverallRanks[['Mobile Web']][index].company}
                                </Typography>
                                <Typography color="textSecondary" className={clsx(classes.floatRight, classes.tableText)} variant="body2">
                                  {(orderedOverallRanks[['Mobile Web']][index].overallScore * 100).toFixed() + '%'}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                className={this.getStyleByCompany(index, 'iPhone App')}
                                onMouseOver={() => this.overCompany(index, 'iPhone App')}
                              >
                                <Typography color="textSecondary" className={clsx(classes.floatLeft, classes.tableText)} variant="body2">
                                  {orderedOverallRanks[['iPhone App']][index].company}
                                </Typography>
                                <Typography color="textSecondary" className={clsx(classes.floatRight, classes.tableText)} variant="body2">
                                  {(orderedOverallRanks[['iPhone App']][index].overallScore * 100).toFixed() + '%'}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="center"
                                className={this.getStyleByCompany(index, 'Android App')}
                                onMouseOver={() => this.overCompany(index, 'Android App')}
                              >
                                <Typography color="textSecondary" className={clsx(classes.floatLeft, classes.tableText)} variant="body2">
                                  {orderedOverallRanks[['Android App']][index].company}
                                </Typography>
                                <Typography color="textSecondary" className={clsx(classes.floatRight, classes.tableText)} variant="body2">
                                  {(orderedOverallRanks[['Android App']][index].overallScore * 100).toFixed() + '%'}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
            </Fragment>
          )}
        </Grid>

        {allCapabilities && Boolean(Object.keys(allCapabilities).length) && <ScreenshotModal filters={true} allCapabilities={allCapabilities} />}
      </Box>
    );
  }

  overCompany(index, cell) {
    const { orderedOverallRanks } = this.props;
    this.setState({
      overCompany: orderedOverallRanks[cell][index].company
    });
  }

  getStyleByCompany(index, cell) {
    const { classes, orderedOverallRanks } = this.props;
    return this.state.overCompany === orderedOverallRanks[cell][index].company ? classes.selectedCompany : '';
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    dbReport: state.currentReport.dbReport,
    isPreview: state.currentReport.isPreview,
    selectedIndustry: state.currentReport.selectedIndustry,
    scoringAndRanking: state.scoringAndRanking.scoringAndRanking,
    orderedOverallRanks: state.scoringAndRanking.orderedOverallRanks,
    contentBuilding: state.contentBuilding,
    executiveSummary: state.contentBuilding.executiveSummary,
    back2ContentBuilding: state.contentBuilding.back2ContentBuilding,
    isScoringRankingCompleted: state.currentReport.scoringRankingCompleted,
    contentBuildingService: state.services.contentBuilding
  };
};

const mapDispatchToProps = {
  getPreviousReport: feathersServices.reports.find,
  getScoringAndRanking: feathersServices['scoring-ranking'].find,
  getContentBuilding: feathersServices['content-building'].find,
  getCapabilityReports: feathersServices['capability-reports'].find,
  clearContentBuilding,
  setContentBuilding,
  setExucuteSummary,
  setBackToContentBuilding,
  fireNotification,
  setScreenshotModal,
  clearOverrideModal,
  push
};

export default compose(withStyles(ExecutiveSummaryStyles), connect(mapStateToProps, mapDispatchToProps))(KLExecutiveSummary);
