import { CONSUMER_SURVEY_CLEAR, CONSUMER_SURVEY_SAVE, CONSUMER_SURVEY_SET_CSV_ROW_DATA, CONSUMER_SURVEY_TAB } from '../action/ConsumerSurvey.actions';

const initialState = {
  reportId: null,
  rawData: null,
  sortedData: [],
  _id: null,
  tab: 0
};

const consumerSurveyPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSUMER_SURVEY_SET_CSV_ROW_DATA:
      return Object.assign({}, state, {
        rawData: action.payload
      });
    case CONSUMER_SURVEY_SAVE:
      return Object.assign({}, state, action.payload, {
        rawData: action.payload ? action.payload.rawData : null
      });
    case CONSUMER_SURVEY_CLEAR:
      return Object.assign({}, state, initialState);
    case CONSUMER_SURVEY_TAB:
      return Object.assign({}, state, {
        tab: action.payload
      });
    default:
      return state;
  }
};

export default consumerSurveyPageReducer;
