export default theme => ({
  flex: {
    display: 'flex'
  },
  icon: {
    verticalAlign: 'bottom',
    width: '0.6em',
    marginLeft: '2%'
  },
  row: {
    backgroundColor: '#f3f3f3'
  },
  table: {
    width: '100%',
    border: '1px solid ' + theme.palette.divider,
    overflow: 'hidden',
    '& tbody > tr:last-child > td': {
      border: 'none'
    }
  },
  oddRow: {
    backgroundColor: theme.palette.primary.main
  },
  panel: {
    marginBottom: '1%'
  },
  paper: {
    width: '100%'
  },
  formula: {
    width: '100%',
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between'
  },
  complete: {
    width: '100%',
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  reset: {
    textAlign: 'right',
    fontSize: '0.75rem',
    cursor: 'pointer'
  },
  tableText: {
    fontSize: '0.7rem'
  },
  tableHeader: {
    fontSize: '0.7rem',
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  }
});
