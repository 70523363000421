import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reset } from 'redux-form';
import clsx from 'clsx';
import { Box, Breadcrumbs, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import feathersServices from '../../../services/feathersServices';
import { fireNotification } from '../../../components/layout/actions/layout.actions';
import LoadingIndicator from '../../../components/loadingIndicator';
import LinkRouter from '../../../components/router-link/RouterLink';
import { validateProps, validateRoute } from '../../../utils/routeUtils';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import {
  clearCapabilityReport,
  clearFiles,
  clearMarkedImagesForDelete,
  clearPreviousCapabilityImages,
  previousReport as previousReportAction,
  selectCapabilityReport,
  setCapabilityReports,
  setPreviousCapabilityImages
} from '../actions/reports.actions';
import REPORT_CONSTANTS from '../report.constants';
import { openCapabilityReportWarning, toggleCapabilityReportDrawer } from './actions/capability-detail.actions';
import KLCapabilityReportDetail from './capability-report-detail/CapabilityReportDetail';
import CapabilityDetailStyles from './CapabilityDetail.styles';

class KLCapabilityDetail extends Component {
  componentDidMount() {
    validateProps(
      this.props.push,
      this.props.fireNotification,
      this.props.currentReport,
      this.props.currentReport.selectedIndustry,
      this.props.currentReport.selectedCompany,
      this.props.currentReport.selectedApplication,
      this.props.currentReport.selectedCategory,
      this.props.currentReport.selectedDate
    );

    validateRoute(
      this.props.push,
      this.props.currentReport.dbReport.status !== REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS &&
        this.props.currentReport.dbReport.status !== REPORT_CONSTANTS.STATUS.PUBLISHED_EDITED.STATUS &&
        this.props.currentReport.dbReport.status !== REPORT_CONSTANTS.STATUS.PUBLISHED_EDITING.STATUS
    );

    this.props.getCapabilityChanges();
    if (this.props.drawerOpened) {
      this.props.toggleCapabilityReportDrawer();
    }

    if (this.props.currentReport.dbReport.industry) {
      this.props
        .getPreviousReport({
          query: {
            industry: this.props.currentReport.dbReport.industry._id,
            date: { $lt: this.props.currentReport.dbReport.date },
            status: REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS,
            $populate: true,
            $limit: 1,
            $sort: {
              date: -1
            }
          }
        })
        .then(({ value }) => {
          const { 0: re } = value.data;
          this.props.previousReportAction(re);
        });

      this.props
        .getCapabilityReports({
          query: {
            report: this.props.currentReport.dbReport._id,
            application: this.props.currentReport.applications.find(a => a.title === this.props.currentReport.selectedApplication).id,
            category: this.props.currentReport.selectedCategory.categoryId,
            company: this.props.currentReport.selectedCompany.companyId,
            $limit: 'infinity',
            $populate: true
          }
        })
        .then(({ value }) => {
          this.props.setCapabilityReports(value);
        });
    }
  }

  componentWillUnmount() {
    this.props.clearFiles();
    this.props.clearCapabilityReport();
  }

  onDrawerClick = (capability, index) => {
    if (this.props.savingReport) return;
    if (
      (this.props.files && this.props.files.length) ||
      (this.props.capabilityChangeForm && this.props.capabilityChangeForm.anyTouched) ||
      this.props.markedImagesForDelete.length
    ) {
      this.props.openCapabilityReportWarning();
    } else {
      this.props.selectCapabilityReport(capability, index);
      if (this.props.currentReport.previousReport) {
        this.props
          .getCapabilityReports({
            query: {
              $limit: 1,
              report: this.props.currentReport.previousReport._id,
              company: this.props.currentReport.selectedCompany.companyId,
              category: this.props.currentReport.selectedCategory.categoryId,
              application: this.props.currentReport.applications.find(a => {
                return a.title === this.props.currentReport.selectedApplication;
              }).id,
              capability: capability.capability._id
            }
          })
          .then(data => {
            let images = data.value && data.value[0] ? data.value[0].images : null;
            if (!images) {
              images = data.value && data.value.data && data.value.data[0] ? data.value.data[0].images : null;
            }
            this.props.setPreviousCapabilityImages(images);
          });
      }

      if (!this.props.drawerOpened) {
        this.props.toggleCapabilityReportDrawer();
      } else {
        this.props.reset('capabilityChangeForm');
        this.props.clearFiles();
        this.props.clearMarkedImagesForDelete();
        this.props.clearPreviousCapabilityImages();
      }
    }
  };

  getCapabilityChangeName(capabilityReport) {
    if (capabilityReport.capabilityChange && this.props.capabilityChanges.queryResult && this.props.capabilityChanges.queryResult.data.length) {
      let capabilityChange = this.props.capabilityChanges.queryResult.data.find(ch => ch._id === capabilityReport.capabilityChange);

      return capabilityChange ? capabilityChange.title : '';
    } else {
      return 'Uncompleted';
    }
  }

  getHasCapbility(hasCapbility) {
    if (hasCapbility === undefined) {
      return ' -- ';
    } else {
      return hasCapbility ? 'YES' : 'NO';
    }
  }
  render() {
    const { classes, currentReport, capabilityChanges } = this.props;
    const { selectedIndustry, selectedCompany, selectedApplication, selectedCategory, selectedDate, capabilityReports } = currentReport;

    return (
      <Box mb={4}>
        <Grid container direction="column" className={classes.container}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/report" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                Home
              </Typography>
            </LinkRouter>
            <LinkRouter to="/report/report-publish-date" color="secondary" underline="none">
              {selectedIndustry && (
                <Typography color="secondary" variant="body2">
                  {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(selectedDate.date).format('MMMM YYYY')}`}
                </Typography>
              )}
            </LinkRouter>
            <LinkRouter to="/report/app-site-select" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                Capabilities Table
              </Typography>
            </LinkRouter>
            <LinkRouter to="/report/capabilities-table" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                {selectedCompany && selectedCompany.title}
              </Typography>
            </LinkRouter>
            <Typography variant="body2">Capabilities Table</Typography>
          </Breadcrumbs>
          <Box mt={5} mb={4}>
            <Typography variant="h5">Capabilities Table: {selectedCompany && selectedCategory.title}</Typography>
          </Box>
          <Box mb={4}>
            <Typography variant="h6" color="textSecondary">
              {selectedApplication}
            </Typography>
          </Box>
          <div>
            <Paper className={classes.table} elevation={0}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.headerRow}>
                    <TableCell className={classes.tableHeader}>Capability</TableCell>
                    <TableCell align="center" className={classes.tableHeader}>
                      Last Modified
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeader}>
                      Is this feature available?
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeader}>
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {capabilityReports.length > 0 &&
                    capabilityReports
                      .sort((a, b) => {
                        if (a.capability.title.toLowerCase() > b.capability.title.toLowerCase()) {
                          return 1;
                        }
                        if (a.capability.title.toLowerCase() < b.capability.title.toLowerCase()) {
                          return -1;
                        }
                        return 0;
                      })
                      .map((capabilityReport, index) => (
                        <TableRow
                          key={index}
                          hover
                          onClick={() => this.onDrawerClick(capabilityReport, index)}
                          className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}
                        >
                          <TableCell className={classes.capabilityName}>
                            <Typography className={classes.tableText}>{`${index + 1}. ${capabilityReport.capability?.title}`}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography className={classes.tableText} color="textSecondary">
                              {capabilityReport.lastUpdate
                                ? `${SingletonMomentUtils.moment(capabilityReport.lastUpdate.date).format('MM/DD/YYYY')}`
                                : '--'}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography className={classes.tableText} color="textSecondary">
                              {this.getHasCapbility(capabilityReport.hasCapability)}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes[capabilityReport.status]} align="center">
                            <Typography className={classes.tableText} color="textSecondary">
                              {this.getCapabilityChangeName(capabilityReport)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>

              {capabilityReports.length === 0 && <LoadingIndicator color="secondary" width={40} isLoading={capabilityReports.length === 0} />}
            </Paper>
          </div>
          {!capabilityChanges.isLoading && capabilityChanges.queryResult && capabilityChanges.queryResult.data.length > 0 && (
            <KLCapabilityReportDetail />
          )}
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentReport: state.currentReport,
    drawerOpened: state.ui.capabilityDetail.drawer_opened,
    capabilityChanges: state.services.capabilityChanges, //TODO: review if its necessary.
    capabilityChangeForm: state.form.capabilityChangeForm,
    files: state.currentReport.files,
    savingReport: state.ui.capabilityDetail.saving_report,
    markedImagesForDelete: state.currentReport.markedImagesForDelete
  };
};

const mapDispatchToProps = {
  getCapabilityChanges: feathersServices['capability-changes'].find,
  getCapabilityReports: feathersServices['capability-reports'].find,
  setCapabilityReports,
  getPreviousReport: feathersServices.reports.find,
  previousReportAction: previousReportAction,
  selectCapabilityReport,
  clearFiles,
  clearCapabilityReport,
  clearMarkedImagesForDelete,
  setPreviousCapabilityImages,
  clearPreviousCapabilityImages,
  toggleCapabilityReportDrawer,
  openCapabilityReportWarning,
  fireNotification,
  reset,
  push
};

export default compose(withStyles(CapabilityDetailStyles), connect(mapStateToProps, mapDispatchToProps))(KLCapabilityDetail);
