import {
  SCORING_RANKING_TAB,
  SET_IS_LOADING,
  SET_SCORING_AND_RANKING_CALCULATED,
  TOGGLE_DEVICE_WEIGHT_DIALOG
} from '../action/ScoringRanking.actions';

const initialState = {
  tab: 0,
  device_dialog_open: false,
  isLoading: true,
  scoringAndRankingCalculated: false
};

const scoringAndRankingUiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCORING_RANKING_TAB:
      return Object.assign({}, state, {
        tab: action.payload
      });
    case TOGGLE_DEVICE_WEIGHT_DIALOG:
      return Object.assign({}, state, {
        device_dialog_open: !state.device_dialog_open
      });
    case SET_IS_LOADING:
      return Object.assign({}, state, {
        isLoading: action.payload
      });
    case SET_SCORING_AND_RANKING_CALCULATED:
      return Object.assign({}, state, {
        scoringAndRankingCalculated: action.payload
      });
    default:
      return state;
  }
};

export default scoringAndRankingUiReducer;
