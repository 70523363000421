export default theme => ({
  root: {
    marginTop: theme.spacing(4),
    padding: '0 10%',
    [theme.breakpoints.down('lg')]: {
      padding: '0 5%'
    }
  },
  gridBottom: {
    borderBottom: '1px solid ' + theme.palette.divider
  },
  tabPanel: {
    padding: '10%',
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      padding: '0 5%',
      paddingTop: theme.spacing(2)
    }
  }
});
