export default theme => ({
  root: {
    width: 550,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    height: '100%'
  },
  header: {
    height: '4rem',
    backgroundColor: theme.palette.secondary.main
  },
  fieldsGroup: {
    padding: theme.spacing(2)
  },
  headerTitle: {
    marginTop: '1rem',
    marginLeft: '1rem',
    color: theme.palette.primary.main
  },
  subtitle: {
    borderBottom: '1px solid ' + theme.palette.grey['800']
  },
  closeButton: {
    float: 'right',
    color: theme.palette.primary.main
  },
  cancelButton: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  loading: {
    textAlign: 'center',
    width: '100%'
  },
  dropIcon: {
    marginRight: 7,
    color: theme.palette.grey['600']
  }
});
