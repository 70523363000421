import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import MomentUtils from '@date-io/moment';
import 'echarts-gl';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/tooltip';
import 'typeface-roboto';
import 'zrender/lib/canvas/canvas';
import { MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MaintenanceBanner from './modules/maintenance/MaintenanceBanner';
import customTheme from './components/customTheme';
import KLLayout from './components/layout/components/Layout';
import KLForgotPassword from './components/login/forgot/ForgotPassword';
import Login from './components/login/Login';
import './App.css';
import { USER_ROLE } from './constants/user.constants';
import appRoutes from './routes';

const theme = responsiveFontSizes(customTheme);

class App extends Component {
  checkAuthentication = (Page, props) => {
    if (this.props.auth.isSignedIn) {
      return <Page {...props} />;
    } else {
      return <Redirect to="/login" />;
    }
  };
  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={theme}>
          <MaintenanceBanner />
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/forgotPassword&:param&:application&:redirectUrl" exact component={KLForgotPassword} />
            <KLLayout>
              {appRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={props =>
                    this.checkAuthentication(
                      this.props.auth.user && this.props.auth.user.loginType === USER_ROLE.CUSTOMER && route.customerComponent
                        ? route.customerComponent
                        : route.component,
                      props
                    )
                  }
                />
              ))}
            </KLLayout>
          </Switch>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default compose(connect(mapStateToProps, null), withWidth())(App);
