export default theme => ({
  flex: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  icon: {
    verticalAlign: 'bottom',
    width: '0.6em',
    marginLeft: '2%'
  },
  headerRow: {
    backgroundColor: '#f3f3f3'
  },
  row: {
    cursor: 'pointer',
    backgroundColor: '#f3f3f3',
    '&:hover': {
      backgroundColor: theme.overrides.MuiSelected.root['&:hover'].backgroundColor + '!important',
      '& th': {
        color: theme.palette.text.primary
      }
    }
  },
  oddRow: {
    backgroundColor: theme.palette.primary.main
  },
  panel: {
    marginBottom: '1%'
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  table: {
    border: '1px solid ' + theme.palette.divider,
    overflow: 'hidden',
    '& .MuiTableCell-footer': {
      border: 'none'
    }
  }
});
