export const LAYOUT_TOGGLE_SIDE_MENU = 'LAYOUT_TOGGLE_SIDE_MENU';
export const LAYOUT_FIRE_NOTIFICATION = 'LAYOUT_FIRE_NOTIFICATION';
export const LAYOUT_CLOSE_NOTIFICATION = 'LAYOUT_CLOSE_NOTIFICATION';
export const LAYOUT_TOGGLE_APP_BAR = 'LAYOUT_TOGGLE_APP_BAR';
export const LAYOUT_TOGGLE_CATEGORY = 'LAYOUT_TOGGLE_CATEGORY';
export const LAYOUT_FORGOT_EXPIRED = 'LAYOUT_FORGOT_EXPIRED';
export const LAYOUT_FORGOT_SUCCESS = 'LAYOUT_FORGOT_SUCCESS';
export const LAYOUT_SET_SCREENSHOT_MODAL = 'LAYOUT_SET_SCREENSHOT_MODAL_OPEN';
export const LAYOUT_SET_OVERRIDE_MODAL = 'LAYOUT_SET_OVERRIDE_MODAL';
export const LAYOUT_CLEAR_OVERRIDE_MODAL = 'LAYOUT_CLEAR_OVERRIDE_MODAL';

export function toggleSideMenu(open) {
  return {
    type: LAYOUT_TOGGLE_SIDE_MENU,
    payload: open
  };
}

export function fireNotification(variant = 'error', message, duration) {
  return {
    type: LAYOUT_FIRE_NOTIFICATION,
    payload: {
      variant,
      message,
      duration
    }
  };
}

export function closeNotification() {
  return {
    type: LAYOUT_CLOSE_NOTIFICATION
  };
}

export function toggleAppBar(open) {
  return {
    type: LAYOUT_TOGGLE_APP_BAR,
    payload: open
  };
}

export function toggleCategory(open) {
  return {
    type: LAYOUT_TOGGLE_CATEGORY,
    payload: open
  };
}

export function setForgotLinkExpired(expired) {
  return {
    type: LAYOUT_FORGOT_EXPIRED,
    payload: expired
  };
}

export function setForgotPasswordSuccess(success) {
  return {
    type: LAYOUT_FORGOT_SUCCESS,
    payload: success
  };
}

export function setScreenshotModal(payload) {
  return {
    type: LAYOUT_SET_SCREENSHOT_MODAL,
    payload
  };
}

export function setOverrideModal(payload) {
  return {
    type: LAYOUT_SET_OVERRIDE_MODAL,
    payload
  };
}

export function clearOverrideModal() {
  return {
    type: LAYOUT_CLEAR_OVERRIDE_MODAL
  };
}
