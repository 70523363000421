import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Box, Breadcrumbs, ButtonBase, Grid, Grow, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Done from '@material-ui/icons/Done';
import feathersServices from '../../../services/feathersServices';
import { fireNotification } from '../../../components/layout/actions/layout.actions';
import LoadingIndicator from '../../../components/loadingIndicator';
import LinkRouter from '../../../components/router-link/RouterLink';
import { checkMarkCompletedCompaniesAndCategories, getApplications } from '../../../utils/report.utils';
import { validateProps, validateRoute } from '../../../utils/routeUtils';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import { setScoringAndRanking } from '../../scoring-ranking/action/ScoringRanking.actions';
import { selectCompany, setCompleteMap } from '../actions/reports.actions';
import REPORT_CONSTANTS from '../report.constants';
import AppSiteSelectStyles from './AppSiteSelect.styles';

class KLAppSiteSelect extends Component {
  companies = [];
  componentDidMount() {
    validateProps(this.props.push, this.props.fireNotification, this.props.dbReport);

    validateRoute(
      this.props.push,
      this.props.dbReport.status !== REPORT_CONSTANTS.STATUS.PUBLISHED.STATUS &&
        this.props.dbReport.status !== REPORT_CONSTANTS.STATUS.PUBLISHED_EDITED.STATUS &&
        this.props.dbReport.status !== REPORT_CONSTANTS.STATUS.PUBLISHED_EDITING.STATUS
    );

    this.props
      .getCapabilityReports({
        query: {
          report: this.props.dbReport._id,
          $limit: 'infinity'
        }
      })
      .then(({ value: capabilityReport }) => {
        const dbReport = this.props.dbReport;
        const applications = getApplications(this.props.dbReport);

        const completedMap = checkMarkCompletedCompaniesAndCategories(capabilityReport, dbReport, applications);

        this.props.setCompleteMap(completedMap);
      });

    // Set scoring and ranking for later calculation.-
    this.props
      .getScoringAndRanking({
        query: {
          reportId: this.props.dbReport._id,
          $limit: 1
        }
      })
      .then(({ value: scoringAndRanking }) => {
        if (scoringAndRanking.data.length) {
          this.props.setScoringAndRanking(scoringAndRanking.data[0]);
        }
      });
  }

  selectCompany(company) {
    this.props.onSelectCompany(company);
    this.props.push('/report/capabilities-table');
  }

  render() {
    const { selectedIndustry, companies, classes, dbReport, companyCompleteMap } = this.props;
    return (
      <Grid container direction="column" className={classes.container}>
        <Breadcrumbs separator="›">
          <LinkRouter to="/report" color="secondary" underline="none">
            <Typography color="secondary" variant="body2">
              Home
            </Typography>
          </LinkRouter>
          <LinkRouter to="/report/report-publish-date" color="secondary" underline="none">
            {dbReport && (
              <Typography color="secondary" variant="body2">
                {`${selectedIndustry.title}: ${SingletonMomentUtils.moment(dbReport.date).format('MMMM YYYY')}`}
              </Typography>
            )}
          </LinkRouter>
          <Typography variant="body2">Capabilities Table</Typography>
        </Breadcrumbs>
        <Box mt={5} mb={4}>
          <Typography variant="h5">Please select the mobile app/website to collect data</Typography>
        </Box>

        <Grid container spacing={4}>
          <LoadingIndicator color="secondary" isLoading={companies.isLoading} width={40} />
          {dbReport &&
            dbReport.companies &&
            dbReport.companies
              .sort((a, b) => {
                if (a.title > b.title) {
                  return 1;
                }
                if (a.title < b.title) {
                  return -1;
                }
                return 0;
              })
              .map((company, index) => (
                <Grid item key={index}>
                  <Grow in={true} timeout={100 * (index + 1)}>
                    <ButtonBase>
                      <Typography color="secondary" onClick={() => this.selectCompany(company)} variant="body1">
                        {company.title}
                      </Typography>
                      {companyCompleteMap[company.title] && companyCompleteMap[company.title].completed && <Done color="secondary" />}
                    </ButtonBase>
                  </Grow>
                </Grid>
              ))}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    dbReport: state.currentReport.dbReport,
    selectedIndustry: state.currentReport.selectedIndustry,
    companies: state.services.companies,
    companyCompleteMap: state.currentReport.completedMap
  };
};

const mapDispatchToProps = {
  onSelectCompany: selectCompany,
  getCapabilityReports: feathersServices['capability-reports'].find,
  getScoringAndRanking: feathersServices['scoring-ranking'].find,
  fireNotification,
  setCompleteMap,
  setScoringAndRanking,
  push
};

export default compose(withStyles(AppSiteSelectStyles), connect(mapStateToProps, mapDispatchToProps))(KLAppSiteSelect);
