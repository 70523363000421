import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, reset } from 'redux-form';
import { withStyles } from '@material-ui/styles';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import feathersServices from '../../../../services/feathersServices';
import { fireNotification } from '../../../../components/layout/actions/layout.actions';
import LoadingIndicator from '../../../../components/loadingIndicator/LoadingIndicator';
import RenderDatePickerField from '../../../../components/renders/RenderDatePicker';
import RenderTextField from '../../../../components/renders/RenderTextField';
import SingletonMomentUtils from '../../../../utils/SingletonMomentUtils';
import { required } from '../../../../utils/validation';
import REPORT_STATUS from '../../../reports/report.constants';
import { setReportCompanySelected } from '../../action/CompaniesCustomertList.actions';
import ReportsCompanyFormStyles from './ReportsCompanyForm.styles';

const FORM_ID = 'reportsCompanyForm';

class ReportsCompanyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onSubmitForm: false,
      editionAssigned: false,
      editions: []
    };
  }

  componentWillMount() {
    const { editions } = this.state;
    const { reportCompanySelected } = this.props;
    if (reportCompanySelected && reportCompanySelected._id && editions.length === 0) {
      this.updateEditions(reportCompanySelected.report.industry);
    }
  }

  componentDidMount() {
    this.props.getIndustries({ query: { active: true } });
  }

  handleSubmit = () => {
    const { onCallBack, reportCompanySelected, reset, saveCompanyReport, updateCompanyReport } = this.props;

    this.setState({ onSubmitForm: true });
    if (reportCompanySelected && reportCompanySelected._id) {
      updateCompanyReport(reportCompanySelected._id, this.buildData(), {
        query: {
          $populate: true
        }
      }).then(
        ({ value }) => {
          this.updateSelectedReportCompany(value, reset, onCallBack);
        },
        error => {
          this.handleError(error);
        }
      );
    } else {
      saveCompanyReport(this.buildData(), {
        query: {
          $populate: true
        }
      }).then(
        ({ value }) => {
          this.updateSelectedReportCompany(value, reset, onCallBack);
        },
        error => {
          this.handleError(error);
        }
      );
    }
  };

  buildData() {
    const { companyCustomerSelected, reportsCompanyForm } = this.props;
    return {
      company: companyCustomerSelected._id,
      report: reportsCompanyForm.values.filterEdition,
      po: reportsCompanyForm.values.po,
      poDate: reportsCompanyForm.values.poDate
    };
  }

  handleError(error) {
    this.setState({ onSubmitForm: false, editionAssigned: true });
    if (error.code === 409) {
      this.props.fireNotification('error', error.message);
    }
  }

  updateSelectedReportCompany(value, reset, onCallBack) {
    this.props.setReportCompanySelected(value);
    reset(FORM_ID);
    if (onCallBack) {
      onCallBack();
    }
    this.setState({ onSubmitForm: false, editionAssigned: false });
  }

  updateEditions = industry => {
    const { reportsCompanyForm } = this.props;
    if (industry !== '') {
      const q = {
        query: {
          $sort: {
            date: -1
          },
          status: {
            $in: [REPORT_STATUS.STATUS.PUBLISHED.STATUS, REPORT_STATUS.STATUS.PUBLISHED_EDITING.STATUS]
          },
          industry
        }
      };
      this.props.getReports(q).then(editions => {
        this.setState({
          editions: editions.value.data
        });
      });
      if (reportsCompanyForm && reportsCompanyForm.values) {
        reportsCompanyForm.values.filterEdition = '';
      }
    }
  };

  render() {
    const { onSubmitForm, editions, editionAssigned } = this.state;
    const { reports, classes, valid, onCallBack, industries, reportCompanies, reportsCompanyForm, reportCompanySelected } = this.props;

    const reportData = reportCompanies.queryResult ? reportCompanies.queryResult.data : [];
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.headerTitle}>
              {reportCompanySelected && reportCompanySelected._id ? 'Edit Report' : 'Add Report'}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.fieldsGroup}>
          {industries.isLoading ? (
            <Box className={classes.loading}>
              <LoadingIndicator width={40} color="secondary" isLoading={industries && industries.isLoading} />
            </Box>
          ) : (
            <Fragment>
              <Grid item xs={12}>
                <Field
                  id="filterIndustry"
                  select
                  validate={[required]}
                  label="Industry *"
                  name="filterIndustry"
                  component={RenderTextField}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onChange={({ target }) => this.updateEditions(target.value)}
                >
                  {industries &&
                    industries.queryResult.data.map((option, index) => (
                      <MenuItem key={index} value={option._id}>
                        {option.title}
                      </MenuItem>
                    ))}
                </Field>
              </Grid>

              <Grid item xs={12}>
                <Field
                  id="filterEdition"
                  select
                  validate={[required]}
                  label="Edition *"
                  name="filterEdition"
                  component={RenderTextField}
                  variant="outlined"
                  fullWidth
                  color="secondary"
                  error={editionAssigned}
                  disabled={reportsCompanyForm && !reportsCompanyForm.values.filterIndustry}
                  SelectProps={{
                    IconComponent: () =>
                      reports.isLoading ? (
                        <LoadingIndicator isLoading={reports.isLoading} color="secondary" />
                      ) : (
                        <ArrowDropDown className={classes.dropIcon} />
                      )
                  }}
                >
                  {editions.length === 0 && (
                    <MenuItem value="">
                      <InfoOutlined fontSize="small" />
                      &nbsp; No Reports Available
                    </MenuItem>
                  )}
                  {editions &&
                    editions.length > 0 &&
                    editions.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option._id} disabled={reportData.find(r => r.report._id === option._id) !== undefined}>
                          {`${SingletonMomentUtils.moment(option.date).format('MMMM YYYY')} ${
                            reportData.find(r => r.report._id === option._id) !== undefined ? '(Purchased)' : ''
                          }`}
                        </MenuItem>
                      );
                    })}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Box mr={1}>
                  <Field
                    color="secondary"
                    name="po"
                    label="PO"
                    component={RenderTextField}
                    fullWidth
                    variant="outlined"
                    helperText="Purchase Order Number"
                  />
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box ml={1}>
                  <Field
                    color="secondary"
                    name="poDate"
                    label="PO Date"
                    inputVariant="outlined"
                    component={RenderDatePickerField}
                    fullWidth
                    helperText="Date of Purchase"
                  />
                </Box>
              </Grid>
            </Fragment>
          )}
        </Grid>
        <Box mt={3} className={classes.cancelButton}>
          <div>
            <Button onClick={onCallBack}>Cancel</Button>
          </div>
          &nbsp;&nbsp;
          <div>
            <Button
              onClick={() => this.handleSubmit()}
              color="secondary"
              variant="contained"
              disableElevation
              disabled={!valid || onSubmitForm}
              fullWidth
            >
              {onSubmitForm && <CircularProgress color="secondary" size={25} thickness={3} />}
              {reportCompanySelected && reportCompanySelected._id ? 'Update' : 'Add'}
            </Button>
          </div>
        </Box>
      </form>
    );
  }
}

const mapStateToProps = state => {
  const companyCustomerSelected = state.ui.listCompaniesCustomer.companyCustomerSelected;
  const reportCompanySelected = state.ui.listCompaniesCustomer.reportCompanySelected;

  let initValues = {
    filterIndustry: reportCompanySelected ? reportCompanySelected.report.industry._id : '',
    filterEdition: reportCompanySelected ? reportCompanySelected.report._id : '',
    po: reportCompanySelected ? reportCompanySelected.po : '',
    poDate: reportCompanySelected ? reportCompanySelected.poDate : SingletonMomentUtils.moment(new Date()).format('MM/DD/YYYY')
  };

  return {
    reports: state.services.reports,
    initialValues: initValues,
    industries: state.services.industries,
    reportsCompanyForm: state.form[FORM_ID],
    reportCompanies: state.services.companyReports,
    companyCustomerSelected,
    reportCompanySelected
  };
};

const mapDispatchToProps = {
  getReports: feathersServices.reports.find,
  saveCompanyReport: feathersServices['companies-reports'].create,
  updateCompanyReport: feathersServices['companies-reports'].patch,
  getIndustries: feathersServices.industries.find,
  setReportCompanySelected,
  reset,
  fireNotification
};

ReportsCompanyForm = compose(
  withStyles(ReportsCompanyFormStyles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(
    {
      form: FORM_ID,
      enableReinitialize: true,
      validate: (values, props) => {
        const errors = {};
        return errors;
      }
    },
    mapStateToProps
  )
)(ReportsCompanyForm);

export default ReportsCompanyForm;
