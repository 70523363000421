export default theme => ({
  root: {
    display: 'flex'
  },
  close: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textInfo: {
    fontSize: '1rem',
    fontWeight: 500,
    marginLeft: 15
  },
  textInfoSmall: {
    fontSize: '0.6rem',
    marginLeft: 15
  },
  textSource: {
    fontSize: '0.6rem'
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dropzone: {
    backgroundColor: 'transparent',
    border: 'none',
    minHeight: 0,
    '& svg': {
      color: 'transparent'
    },
    position: 'absolute !important',
    width: '21%'
  },
  sourceContainer2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pointerEvents: 'none'
  },
  sourceContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 110,
    justifyContent: 'center',
    cursor: 'pointer'
  },
  icon: {
    fontSize: '2.8rem'
  },
  box: {
    padding: 10
  }
});
