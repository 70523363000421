export default theme => ({
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    '& .MuiCircularProgress-root': {
      margin: 0
    }
  },
  formControl: {
    width: 300
  },
  box: {
    marginTop: 30,
    width: '100%'
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 400,
    marginTop: 10,
    marginBottom: 10
  },
  table: {
    border: '1px solid ' + theme.palette.divider,
    overflow: 'hidden',
    '& tbody > tr:last-child > td': {
      border: 'none'
    }
  },
  row: {
    backgroundColor: '#f3f3f3'
  },
  oddRow: {
    backgroundColor: theme.palette.primary.main
  },
  categoryRow: {
    backgroundColor: 'white',
    borderTop: '3px solid ' + theme.palette.secondary.main,
    borderBottom: '3px solid ' + theme.palette.secondary.main
  },
  categoryName: {
    padding: '10px 0',
    fontSize: '0.7rem',
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  },
  checked: {
    border: '1px solid #555555',
    fontSize: '0.7rem'
  },
  unchecked: {
    border: '1px solid #555555',
    fontSize: '0.7rem',
    fill: 'transparent'
  },
  changedCapability: {
    color: '#ff9800',
    borderColor: '#ff9800'
  },
  buyMessage: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    border: '0.5px solid ' + theme.palette.grey['300'],
    justifyContent: 'center',
    padding: '3rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  tableText: {
    fontSize: '0.7rem'
  },
  tableHeader: {
    fontSize: '0.7rem',
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  }
});
