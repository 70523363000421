export const METHODOLOGY_CONSTANTS = {
  METHODOLOGY: `<div>
    <p>KLI independently evaluates mobile applications and mobile websites as we recognize that there are differences between the target audiences that each of these mobile solutions serve.</p>
      <p>This report focuses only on the primary auto insurance mobile sites and mobile applications for the auto insurance companies reviewed: </p>
<table>
<tr>
        <th>
          <div>
            <h4>Mobile Apps</h4>
            <ul>
              <li>Comany A Mobile</li>
              <li>Company E Mobile</li>
              <li>Company G App</li>
              <li>Company L Mobile</li>
              <li>Company N Mobile</li>
              <li>Company P</li>
              <li>Company S</li>
              <li>Company U Mobile</li>
            </ul>
        </div>
        </th>

        <th>
        <div>
        <h4>Mobile Sites</h4>
        <ul>
          <li>companya.com</li>
          <li>companye.com</li>
            <li>companyg.com</li>
            <li>companyl.com</li>
            <li>companyn.com</li>
            <li>companyp.com</li>
            <li>companys.com</li>
            <li>mobile.companyu.com</li>
        </ul>
    </div>
        </th>
</tr>

</table>


      </div>
        <p>To create an overall score, we combine a capabilities assessment with user feature importance ratings. The capabilities assessment is created by examining the feature coverage of the auto insurance sites and applications. User ratings are determined by a consumer survey and card sort.</p>
        <p>Our review of the mobile properties includes a full verification of the capabilities. We review all of the features available on these mobile properties including what is available behind the login. Based on the consumer preferences identified through the user survey, the scorecards focus on the twenty-six (26) most important features identified. </p>
  </div>
  <h2>How We Incorporated User Feedback</h2>
  <p>We gathered feedback from five hundred (500) U.S. consumers who own smartphones. 77% of the survey participants have insurance with one of the following companies: Company A, Company E, Company G, Company L, Company N, Company P, Company S, and Company U. Our sample was obtained from Lightspeed GMI, our panel vendor, to understand which features and tasks were must-haves when it came to interacting with the mobile property of an auto insurance provider.</p>
  <p>From those results we were able to understand what users believed to be most important to them. We use this consumer data to determine the weighted score we give each feature when tabulating a final score for each mobile property.</p>
  <p>The goal of this report is to provide a view of the competitive landscape of this industry while highlighting the importance of how these companies are meeting the needs and expectations of their target users. KLI recognizes that business decisions made by the respective carriers about the capabilities and features to include in their mobile solutions may trump the decision to prioritize (or deprioritize) a particular feature that users have deemed important. Our goal is to provide unbiased third party evidence that can help strengthen such decisions and roadmap successful solutions.</p>

  `
};
