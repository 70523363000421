export default theme => ({
  compareArea: {
    border: '1px solid ' + theme.palette.divider,
    padding: 5,
    backgroundColor: theme.palette.divider,
    borderBottom: 'none',
    display: 'flex',
    justifyContent: 'space-between'
  },
  expansionDetail: {
    backgroundColor: '#FFF',
    padding: 0
  },
  changeContent: {
    border: '1px solid ' + theme.palette.divider,
    overflow: 'auto'
  }
});
