import React from 'react';
import { FormControl } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import RenderFormHelper from './RenderFormHelperText';

const RenderDatePickerField = ({ id, name, input, label, variant, meta: { touched, error }, children, ...custom }) => (
  <FormControl error={touched && error} fullWidth>
    <DatePicker variant="inline" format="MM/DD/YYYY" label={label} id={id} {...custom} {...input} />
    {RenderFormHelper({ touched, error })}
  </FormControl>
);

export default RenderDatePickerField;
