import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = {
  viewBox: '0 0 80 80'
};

// viewBox
const ScreenshotsIcon = props => (
  <SvgIcon {...useStyles} style={props}>
    <g id="icon/screenshots" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="006-payment-method" transform="translate(22.000000, 8.000000)" fill="#1E8545" fill-rule="nonzero">
        <path
          d="M31.9317307,0 C34.7263699,0 37,2.24289844 37,4.99964062 L37,4.99964062 L37,58.0003594 C37,60.7571016 34.7263699,63 31.9317307,63 L31.9317307,63 L5.06814458,63 C2.27363013,63 0,60.7571016 0,58.0003594 L0,58.0003594 L0,4.99964062 C0,2.24289844 2.27363013,0 5.06814458,0 L5.06814458,0 Z M1.87024962,9.95207369 L1.87099254,58.0003594 C1.87099254,59.7395039 3.30529541,61.1542969 5.06814458,61.1542969 L5.06814458,61.1542969 L31.9317307,61.1542969 C33.6947046,61.1542969 35.1290075,59.7395039 35.1290075,58.0003594 L35.1290075,58.0003594 L35.129,10 L2.25529432,10 C2.12097574,10 1.99158303,9.98319251 1.87024962,9.95207369 Z M31.9317307,1.84570313 L5.06814458,1.84570313 C3.30529541,1.84570313 1.87099254,3.26061914 1.87099254,4.99964063 L1.87099254,4.99964063 L1.87024962,8.04792631 C1.99158303,8.01680749 2.12097574,8 2.25529432,8 L2.25529432,8 L35.129,8 L35.1290075,4.99964063 C35.1290075,3.3205854 33.7919166,1.94366892 32.1128895,1.85070566 L31.9317307,1.84570313 Z"
          id="Combined-Shape"
        ></path>
        <path
          d="M21.9567556,4 L15.0432444,4 C14.4670953,4 14,4.44773333 14,5 C14,5.55226667 14.4670953,6 15.0432444,6 L21.9567556,6 C22.5329047,6 23,5.55226667 23,5 C23,4.44773333 22.5330438,4 21.9567556,4 Z"
          id="Path"
        ></path>
        <path
          d="M36.0644974,53 L0.935502576,53 C0.418855687,53 0,53.4476 0,54 C0,54.5524 0.418855687,55 0.935502576,55 L36.0644974,55 C36.581269,55 37,54.5524 37,54 C37,53.4476 36.581269,53 36.0644974,53 Z"
          id="Path"
        ></path>
      </g>
      <g id="Group" transform="translate(27.000000, 22.000000)" fill="#1E8545" fill-rule="nonzero">
        <polygon id="Rectangle" points="8 0 8 2 2 2 2 8 0 8 0 0"></polygon>
        <polygon
          id="Rectangle"
          transform="translate(22.000000, 4.000000) scale(-1, 1) translate(-22.000000, -4.000000) "
          points="26 0 26 2 20 2 20 8 18 8 18 0"
        ></polygon>
      </g>
      <g
        id="Group"
        transform="translate(40.000000, 53.000000) scale(1, -1) translate(-40.000000, -53.000000) translate(27.000000, 49.000000)"
        fill="#1E8545"
        fill-rule="nonzero"
      >
        <polygon id="Rectangle" points="8 0 8 2 2 2 2 8 0 8 0 0"></polygon>
        <polygon
          id="Rectangle"
          transform="translate(22.000000, 4.000000) scale(-1, 1) translate(-22.000000, -4.000000) "
          points="26 0 26 2 20 2 20 8 18 8 18 0"
        ></polygon>
      </g>
      <path
        d="M40,32 C40.5128358,32 40.9355072,32.3860402 40.9932723,32.8833789 L41,33 L41,38 L46,38 C46.5522847,38 47,38.4477153 47,39 C47,39.5128358 46.6139598,39.9355072 46.1166211,39.9932723 L46,40 L41,40 L41,45 C41,45.5522847 40.5522847,46 40,46 C39.4871642,46 39.0644928,45.6139598 39.0067277,45.1166211 L39,45 L39,40 L34,40 C33.4477153,40 33,39.5522847 33,39 C33,38.4871642 33.3860402,38.0644928 33.8833789,38.0067277 L34,38 L39,38 L39,33 C39,32.4477153 39.4477153,32 40,32 Z"
        id="Combined-Shape"
        fill="#1E8545"
        fill-rule="nonzero"
      ></path>
    </g>
  </SvgIcon>
);

export default ScreenshotsIcon;
