import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Button, FormControlLabel, Grid, Switch, TextField, Typography } from '@material-ui/core';
import feathersServices from '../../../services/feathersServices';

function IndustryManagementForm(props) {
  const { onClose, onSave, selectedElement, classes } = props;

  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState('');
  const [svgIcon, setSvgIcon] = useState(`<svg width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" fill="none">
  <g stroke="null" id="svg_11">
   <path stroke="#585858" d="m39.84539,50.10734l16.57434,0" stroke-width="2" id="svg_1"/>
   <path stroke="#585858" d="m39.84539,53.71046l25.22183,0" stroke-width="2" id="svg_2"/>
   <path stroke="#585858" d="m16.78543,53.71046l18.73621,0" stroke-width="2" id="svg_3"/>
   <path stroke="#585858" d="m32.4097,13.72875l-0.31542,0.48606c-0.34251,0.52779 -0.90295,0.88025 -1.53378,0.96462l-1.34555,0.18001c-1.03813,0.13879 -1.82527,0.9874 -1.87297,2.01926l-0.02601,0.56079c-0.04878,1.05485 0.68855,1.99122 1.73843,2.2077l0.88248,0.18196c0.82966,0.17108 1.48556,0.80025 1.68158,1.61312l0.21914,0.90899c0.12698,0.52648 0.04778,1.07986 -0.22159,1.54963l-0.23269,0.40572c-0.53881,0.93941 -0.28262,2.13024 0.5979,2.77902l0.41025,0.30223c0.8414,0.61995 2.00889,0.56303 2.77815,-0.13548l1.00643,-0.91375c0.46199,-0.41955 1.08655,-0.6206 1.71249,-0.55142l0.91973,0.10168c0.61967,0.06853 1.18103,0.39526 1.54199,0.89753l0.40455,0.56303c0.6278,0.87368 1.81006,1.162 2.77353,0.67645l0.64359,-0.32443c0.97356,-0.49075 1.42244,-1.61946 1.04822,-2.63575l-0.20271,-0.55042c-0.24343,-0.66103 -0.14384,-1.39614 0.2667,-1.96831l0.83067,-1.15782c0.43936,-0.61246 1.17289,-0.95223 1.93365,-0.89581l0.4607,0.03416c1.17592,0.08719 2.20453,-0.76328 2.32077,-1.91895l0.04028,-0.40009c0.10485,-1.04282 -0.57045,-2.01105 -1.59719,-2.28978l-0.95771,-0.26008c-0.61411,-0.16675 -1.12475,-0.58961 -1.3975,-1.15732l-0.2533,-0.52714c-0.24753,-0.51524 -0.27773,-1.10536 -0.08395,-1.64057l0.3949,-1.09052c0.32276,-0.89148 0.01289,-1.88868 -0.76227,-2.45321l-0.56144,-0.40881c-0.84119,-0.61246 -2.00239,-0.55265 -2.76827,0.14275l-1.00462,0.91217c-0.46747,0.42445 -1.1009,0.625 -1.73346,0.54897l-0.37595,-0.04519c-0.61109,-0.07343 -1.1633,-0.39814 -1.52002,-0.89379l-0.58515,-0.81293c-0.61224,-0.8507 -1.75364,-1.14847 -2.70775,-0.7065l-1.15531,0.53528c-0.83318,0.38596 -1.32696,1.24841 -1.23263,2.15286l0.17122,1.64216c0.05023,0.48159 -0.06608,0.96506 -0.33011,1.37192l-0.00002,0.00001z" stroke-width="2" id="svg_4"/>
   <path stroke="#585858" d="m44.22347,15.63977c1.39074,2.89445 0.13057,6.35467 -2.82635,7.72451c-2.95694,1.3699 -6.47804,0.12467 -7.86878,-2.76979c-1.39074,-2.89438 -0.13057,-6.35467 2.82635,-7.72451c2.95694,-1.36983 6.47804,-0.12467 7.86878,2.76979z" stroke-width="2" id="svg_5"/>
   <path stroke="#585858" d="m39.84539,71.00543l25.94245,0c1.194,0 2.16187,-0.96787 2.16187,-2.16187l0,-15.04821c0,-0.83787 -0.48411,-1.60029 -1.24243,-1.95664l-13.95963,-6.55984c-0.75832,-0.35635 -1.24243,-1.11876 -1.24243,-1.95664l0,-2.21296c0,-1.02047 -0.71356,-1.90194 -1.71155,-2.11446l-9.49796,-2.02293c-1.34504,-0.28652 -2.61219,0.73921 -2.61219,2.11446l0,29.75722c0,1.194 0.96787,2.16187 2.16187,2.16187l-0.00001,0z" stroke-width="2" id="svg_6"/>
   <path stroke="#585858" d="m24.94808,69.6152l0,-4.06857c0,-1.194 0.96794,-2.16187 2.16187,-2.16187l0.7524,0c1.194,0 2.16187,0.96787 2.16187,2.16187l0,4.06857" stroke-width="2" id="svg_7"/>
   <path stroke="#585858" d="m37.97011,47.94548l-22.62592,0c-1.19396,0 -2.16187,0.96787 -2.16187,2.16187l0,17.29497c0,1.194 0.9679,2.16187 2.16187,2.16187l23.05995,0" stroke-width="2" id="svg_8"/>
   <path stroke="#585858" d="m44.35332,34.52934l0,0c-0.20322,-1.84682 1.24286,-3.46079 3.10077,-3.46079l5.33824,0l0.65483,0c3.97986,0 7.20624,-3.2263 7.20624,-7.20624l0,-11.41007c0,-3.97986 -3.22637,-7.20623 -7.20624,-7.20623l-28.73486,0c-3.97986,0 -7.20624,3.22637 -7.20624,7.20623l0,11.16808c0,3.97986 3.22637,7.20624 7.20624,7.20624l9.6413,0l4.87257,0c1.93638,0 3.35147,1.82822 2.86607,3.70278l0,0" stroke-width="2" id="svg_9"/>
  </g>
</svg>
`);
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (selectedElement) {
      setTitle(selectedElement.title);
      setDescription(selectedElement.description);
      setActive(selectedElement.active);
      setSvgIcon(selectedElement.svgIcon);
    }
  }, [selectedElement]);

  const handleSave = async () => {
    if (selectedElement?._id) {
      await props.saveIndustry(selectedElement._id, {
        title,
        description,
        svgIcon,
        active
      });
    } else {
      await props.createIndustry({
        title,
        description,
        svgIcon,
        active
      });
    }
    onSave();
    onClose();
  };

  return (
    <>
      <Box className={classes.header} display="flex" alignItems="center" p={2} mb={2}>
        <Typography variant="h5" color="primary">
          {selectedElement?.title || 'Add new industry'}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" justifyContent="space-between" p={2} height={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              value={title || ''}
              color="secondary"
              label="Title"
              fullWidth
              error={title === ''}
              variant="outlined"
              onChange={({ target }) => setTitle(target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={description}
              multiline
              minRows={4}
              maxRows={4}
              color="secondary"
              label="Description"
              fullWidth
              variant="outlined"
              onChange={({ target }) => setDescription(target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              value={svgIcon || ''}
              multiline
              minRows={24}
              maxRows={24}
              InputProps={{
                style: {
                  fontSize: 12
                }
              }}
              color="secondary"
              label="SVG Icon"
              fullWidth
              error={svgIcon === ''}
              variant="outlined"
              onChange={({ target }) => setSvgIcon(target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              p={1}
              border="1px solid"
              borderColor="grey.400"
              alignItems="center"
              borderRadius="borderRadius"
              className={classes.fieldset}
            >
              <FormControlLabel
                control={<Switch checked={active} onChange={({ target }) => setActive(target.checked)} />}
                labelPlacement="start"
                label="Status"
              />
              &nbsp;&nbsp;
              <Typography component="span">{active ? 'Active' : 'Disabled'}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Button onClick={onClose}>Cancel</Button>
          &nbsp;
          <Button disabled={!title || !svgIcon} onClick={handleSave} color="secondary" variant="contained" disableElevation>
            {selectedElement ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </Box>
    </>
  );
}

const mapDispatchToProps = {
  saveIndustry: feathersServices.industries.patch,
  createIndustry: feathersServices.industries.create
};

export default connect(null, mapDispatchToProps)(IndustryManagementForm);
