import React, { Component } from 'react';
import { MenuItem, TextField, withStyles } from '@material-ui/core';
import SelectStyle from './KLSelect.style';

class KLSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  handleChange = event => {
    const { onChange, values } = this.props;
    if (onChange) {
      onChange(values.find(ele => ele._id === event.target.value));
    }
  };

  handleOpenClose = () => {
    this.setState(state => {
      return { isOpen: !state.isOpen };
    });
  };

  render() {
    const { classes, id, label, acceptNone, values, selectedValue, margin } = this.props;
    const { isOpen } = this.state;

    return (
      <TextField
        id={id}
        select
        className={classes.root}
        variant="outlined"
        color="secondary"
        open={isOpen}
        label={label}
        margin={margin}
        SelectProps={{
          displayEmpty: acceptNone,
          onClose: this.handleOpenClose,
          onOpen: this.handleOpenClose,
          onChange: this.handleChange
        }}
        InputLabelProps={{
          shrink: acceptNone !== undefined || selectedValue !== ''
        }}
        value={selectedValue ? selectedValue._id : ''}
      >
        {acceptNone && (
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
        )}
        {values &&
          values.map((val, i) => (
            <MenuItem key={i} value={val._id}>
              {val.title || val.label}
            </MenuItem>
          ))}
      </TextField>
    );
  }
}

export default withStyles(SelectStyle)(KLSelect);
