export default theme => ({
  flex: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  icon: {
    verticalAlign: 'bottom',
    width: '0.6em',
    marginLeft: '2%'
  },
  row: {
    backgroundColor: '#f3f3f3'
  },
  table: {
    width: '100%',
    marginTop: theme.spacing(1),
    border: '1px solid ' + theme.palette.divider,
    overflow: 'hidden',
    '& tbody > tr:last-child > td': {
      border: 'none'
    }
  },
  oddRow: {
    backgroundColor: theme.palette.primary.main
  },
  panel: {
    marginBottom: '1%'
  },
  paper: {
    width: '100%'
  },
  tableText: {
    fontSize: '0.7rem'
  },
  tableHeader: {
    fontSize: '0.7rem',
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  }
});
