import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Drawer, List } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import { getReportCategoriesGrouped } from '../../../../utils/report.utils';
import { USER_ROLE } from '../../../../constants/user.constants';
import Concurrency from '../../../concurrency/Concurrency';
import KLSecurity from '../../../security/Security';
import { SECURITY_ACTIONS } from '../../../security/Security.constants';
import { toggleCategory } from '../../actions/layout.actions';
import SideMenuStyles from './SideMenu.styles';

const useStyles = SideMenuStyles;

const KLSideMenu = props => {
  const classes = useStyles();
  const {
    open,
    user,
    location,
    dbReport,
    isPreview,
    expandCategory,
    toggleCategory,
    isConsumerSurveyCompleted,
    isScoringRankingCompleted,
    completedMap,
    overrideModal
  } = props;

  return (
    <Fragment>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
      >
        {user && (
          <KLSecurity action={SECURITY_ACTIONS.EDIT}>
            <List className={classes.toolbarContainer}>
              <Box ml={2}>
                <ListSubheader classes={{ root: classes.listTitle }}>Data Entry</ListSubheader>
              </Box>
              <MenuItem
                button
                dense
                component={Link}
                selected={
                  location === '/report/app-site-select' || location === '/report/capabilities-table' || location === '/report/capability-detail'
                }
                to="/report/app-site-select"
              >
                <ListItemText primary="Capabilities Table" />
              </MenuItem>
              <MenuItem button dense component={Link} selected={location === '/consumer-survey'} to="/consumer-survey">
                <ListItemText primary="Consumer Survey" />
              </MenuItem>
              <MenuItem
                button
                dense
                selected={location === '/scoring-ranking'}
                disabled={!(completedMap.capabilitiesTable && isConsumerSurveyCompleted)}
                component={Link}
                to="/scoring-ranking"
              >
                <ListItemText primary="Scoring & Ranking" />
              </MenuItem>
              <MenuItem
                button
                dense
                component={Link}
                to="/content-building"
                selected={location === '/content-building'}
                disabled={!isScoringRankingCompleted}
              >
                <ListItemText primary="Content Building" />
              </MenuItem>
            </List>
          </KLSecurity>
        )}

        {(Boolean(isScoringRankingCompleted) || (user && user.loginType === USER_ROLE.CUSTOMER)) &&
          (location.indexOf('/content-building') !== -1 || location.indexOf('/appendix') !== -1) && (
            <List className={classes.mTop}>
              <Box ml={2} mt={user.loginType === USER_ROLE.CUSTOMER ? 5 : 0}>
                <ListSubheader classes={{ root: classes.listTitle }}>
                  {user.loginType === USER_ROLE.CUSTOMER ? 'Contents' : 'Content Building'}
                </ListSubheader>
              </Box>

              <MenuItem
                button
                dense
                component={Link}
                selected={location === '/content-building/executive-summary'}
                to="/content-building/executive-summary"
              >
                <ListItemText primary="Executive Summary" />
              </MenuItem>
              <MenuItem
                button
                dense
                component={Link}
                selected={location === '/content-building/consumer-survey'}
                to="/content-building/consumer-survey"
              >
                <ListItemText primary="Consumer Survey" />
              </MenuItem>
              <MenuItem
                button
                dense
                component={Link}
                selected={location === '/content-building/product-rating'}
                to="/content-building/product-rating"
              >
                <ListItemText primary="Product Ratings" />
              </MenuItem>
              <MenuItem
                button
                dense
                component={Link}
                selected={location === '/content-building/trends-innovations'}
                to="/content-building/trends-innovations"
              >
                <ListItemText primary="Trends & Innovations" />
              </MenuItem>
              <MenuItem
                button
                dense
                component={Link}
                to="/content-building/category-performance"
                selected={location.indexOf('/content-building/category-performance') !== -1}
              >
                <ListItemText primary="Category Performance" onClick={() => toggleCategory(!expandCategory)} />
              </MenuItem>
              <Collapse in={expandCategory}>
                <List>
                  {getReportCategoriesGrouped(dbReport).map((c, index) => {
                    if (isPreview && index > 0) {
                      return null;
                    }
                    return (
                      <Box key={index} ml={3}>
                        <MenuItem
                          button
                          dense
                          component={Link}
                          selected={
                            decodeURIComponent(location) === `/content-building/category-performance&category${decodeURIComponent(c.categoryGroup)}`
                          }
                          to={`/content-building/category-performance&category${encodeURIComponent(c.categoryGroup)}`}
                        >
                          <ListItemText primary={c.categoryGroup} title={c.categoryGroup} />
                        </MenuItem>
                      </Box>
                    );
                  })}
                </List>
              </Collapse>
            </List>
          )}

        {
          <List>
            <Box ml={2} className={classes.mTop}>
              <ListSubheader classes={{ root: classes.listTitle }}>Appendix</ListSubheader>
            </Box>

            <MenuItem button dense component={Link} selected={location === '/appendix/methodology'} to="/appendix/methodology">
              <ListItemText primary="Methodology" />
            </MenuItem>

            <MenuItem button dense component={Link} selected={location === '/appendix/raw-data'} to="/appendix/raw-data">
              <ListItemText primary="Raw Data" />
            </MenuItem>

            <MenuItem button dense component={Link} selected={location === '/appendix/screenshots'} to="/appendix/screenshots">
              <ListItemText primary="Screenshots" />
            </MenuItem>
          </List>
        }
      </Drawer>
      {overrideModal && overrideModal.open && <Concurrency />}
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    dbReport: state.currentReport.dbReport,
    completedMap: state.currentReport.completedMap,
    isPreview: state.currentReport.isPreview,
    expandCategory: state.ui.layout.expandCategory,
    overrideModal: state.ui.layout.overrideModal,
    isCapabilityCompleted: state.currentReport.capabilityCompleted,
    isConsumerSurveyCompleted: state.currentReport.consumerSurveyCompleted,
    isScoringRankingCompleted: state.currentReport.scoringRankingCompleted
  };
};

const mapDispatchToProps = {
  toggleCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(KLSideMenu);
