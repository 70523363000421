import MomentUtils from '@date-io/moment';

class SingletonMomentUtils {
  constructor() {
    if (!SingletonMomentUtils.instance) {
      this.mom = new MomentUtils();
      SingletonMomentUtils.instance = this;
    }
    return SingletonMomentUtils.instance.mom;
  }
}

const instance = new SingletonMomentUtils();
Object.freeze(instance);

export default instance;
