export default theme => ({
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  buyMessage: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    border: '0.5px solid ' + theme.palette.grey['300'],
    justifyContent: 'center',
    padding: '3rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
});
