import React, { useState } from 'react';
import { Button, CircularProgress, IconButton, InputAdornment, Link, TextField, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import KLDialog from '../dialog/KLDialog';
import Notification from '../notification/Notification';
import ForgotPasswordForm from './forgot/ForgotPasswordForm';

let LoginForm = ({ onSubmit, classes, onSubmitState }) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState(true);

  return (
    <>
      <div className={classes.input}>
        <TextField
          name="email"
          label="Email"
          fullWidth
          variant="outlined"
          autoComplete="username"
          color="secondary"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
        />
      </div>
      <div className={classes.input}>
        <TextField
          name="password"
          label="Password"
          fullWidth
          variant="outlined"
          color="secondary"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type={passwordType ? 'password' : 'text'}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setPasswordType(!passwordType);
                  }}
                  onMouseDown={event => event.preventDefault()}
                >
                  {passwordType ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <div className={classes.groupPass}>
          <Link color="secondary">
            <Typography color="secondary" variant="body2" className={classes.link} onClick={() => setOpenModal(!isOpenModal)}>
              Forgot password?
            </Typography>
          </Link>
        </div>
      </div>

      <Box mt={2}>
        <Typography variant="caption">
          By entering the system you accept our{' '}
          <Link color="secondary" href="https://labs.keylimeinteractive.com/ci-termsofuse" target="_blank">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link color="secondary" href="https://labs.keylimeinteractive.com/ci-privacypolicy" target="_blank">
            Privacy Policy
          </Link>
        </Typography>
      </Box>
      <Button
        variant="contained"
        type="submit"
        color="secondary"
        disableElevation
        size="large"
        className={classes.button}
        fullWidth
        onClick={() => onSubmit({ email, password })}
        disabled={onSubmitState || !email || !password}
      >
        {onSubmitState && <CircularProgress color="secondary" size={25} thickness={3} />}
        Sign In
      </Button>
      <KLDialog
        isOpen={isOpenModal}
        onHandleClose={() => {
          setOpenModal(!isOpenModal);
        }}
        title={'Forgot Password'}
        body={
          <Box mx={1}>
            <ForgotPasswordForm
              onCallBack={() => {
                setOpenModal(!isOpenModal);
                setEmailSent(!emailSent);
              }}
            />
          </Box>
        }
        showButtonConfirm={false}
        showButtonClose={false}
        dividers={true}
      />
      <Notification message={'An email has been sent to your account.'} open={emailSent} variant="success" onClose={() => setEmailSent(false)} />
    </>
  );
};

export default LoginForm;
