export default theme => ({
  container: {
    marginTop: theme.spacing(4)
  },
  paper: {
    width: '10rem',
    height: '8rem',
    border: '1px solid ' + theme.palette.divider,
    '&:hover': {
      color: theme.palette.secondary.main,
      fontWeight: 400
    }
  },
  icon: {
    paddingTop: theme.spacing(2),
    display: 'block',
    height: '60%',
    width: 'auto',
    '& svg': {
      height: '100%',
      width: 'auto'
    }
  },
  borderBottom: {
    borderBottom: '1px solid ' + theme.palette.divider
  },
  title: {
    lineHeight: '1.25',
    fontSize: '0.85rem'
  }
});
