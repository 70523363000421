export default theme => ({
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  chart: {
    height: '500px !important'
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer'
  },
  box: {
    marginTop: 30,
    width: '100%'
  },
  title: {
    fontSize: '1rem',
    color: '#212121',
    fontWeight: 500,
    marginTop: 10,
    marginBottom: 10
  },
  floatLeft: {
    width: '80%',
    textAlign: 'left',
    float: 'left'
  },
  floatRight: {
    float: 'right'
  },
  width100: {
    width: '100%'
  },
  table: {
    border: '1px solid ' + theme.palette.divider,
    overflow: 'hidden',
    '& tbody > tr:last-child > td': {
      border: 'none'
    }
  },
  row: {
    backgroundColor: '#f3f3f3'
  },
  oddRow: {
    backgroundColor: theme.palette.primary.main
  },
  selectedCompany: {
    transition: theme.transitions.create(['background-color', 'color'], {
      duration: 500
    }),
    backgroundColor: '#0a3360',
    '& .MuiTypography-colorTextSecondary': {
      color: theme.palette.primary.main
    }
  },
  gradient: {
    background: '-webkit-linear-gradient(90deg, #f8f8f8, #000)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent'
  },
  tableText: {
    fontSize: '0.7rem'
  },
  tableHeader: {
    fontSize: '0.7rem',
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  }
});
