import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { AppBar, Box, Button, Toolbar, Typography } from '@material-ui/core';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import MaintenanceService from '../../../../modules/maintenance/services/Maintenance.service';
import LinkRouter from '../../../router-link/RouterLink';
import AppBarStyles from './AppBar.styles';

const useStyles = AppBarStyles;

function KLAppBar(props) {
  const classes = useStyles();
  const { onLogout } = props;

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar)}
      elevation={0}
      style={{
        transition: 'all 1s ease 0s',
        top: props.maintenance && !props.dismissedBanner ? MaintenanceService.getBannerHeight() : 0
      }}
    >
      <Toolbar className={classes.toolbar}>
        <Box className={classes.title}>
          <LinkRouter to="/" color="secondary" underline="none">
            <img src="/logo.png" alt="keylime interactive logo" width="30px" />
          </LinkRouter>
        </Box>
        <Button href="/release-notes" className={classes.barOption}>
          <Typography variant="body2">Release Notes</Typography>
        </Button>

        {/*<Button className={classes.barOption}>My Account</Button>*/}
        <Button onClick={onLogout} className={classes.barOption} endIcon={<ExitToAppOutlinedIcon color="action" fontSize="small" />}>
          <Typography variant="body2">Logout</Typography>
        </Button>
      </Toolbar>
    </AppBar>
  );
}

const mapStateToProps = state => {
  return {
    maintenance: state.ui.maintenanceStore.maintenance,
    dismissedBanner: localStorage.getItem('dismiss-maintenance-insights')
  };
};

export default connect(mapStateToProps)(KLAppBar);
