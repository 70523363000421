import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import {
  Box,
  Breadcrumbs,
  DialogContentText,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import feathersServices from '../../../services/feathersServices';
import KLDialog from '../../../components/dialog/KLDialog';
import LoadingIndicator from '../../../components/loadingIndicator';
import LinkRouter from '../../../components/router-link/RouterLink';
import HeaderTable from '../../../components/tables/HeaderTable';
import SingletonMomentUtils from '../../../utils/SingletonMomentUtils';
import IndustryManagementStyles from '../industry-management/IndustryManagement.styles';
import CompanyManagementForm from './CompanyManagementForm';

const cells = [
  { label: 'Company', value: 'title', sort: true },
  { label: 'Description', value: 'description', sort: true },
  { label: 'Created at', value: 'createdAt', sort: true },
  { label: 'Status', value: 'active', sort: true },
  { label: '' }
];

const rowsPerPage = 8;

function CompanyManagement(props) {
  const { classes, companies } = props;

  const [sort, setSort] = useState({
    key: 'title',
    value: 1
  });
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [industry, setIndustry] = useState(null);
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    props.getIndustries({ $limit: 'infinity' }).then(res => {
      if (res.value?.data) {
        setIndustries(res.value.data);
        setIndustry(res.value.data[0]._id);
        findCompanies(0, res.value.data[0]._id);
      }
    });
    // eslint-disable-next-line
  }, []);

  const findCompanies = (page, i = industry) => {
    const { key, value } = sort;
    const query = {
      $sort: {
        [key]: value
      },
      industry: i,
      $limit: rowsPerPage,
      $skip: rowsPerPage * page
    };
    props.getCompanies({ query });
  };

  const createSortHandler = value => {
    setSort({
      key: value,
      value: sort.value === -1 ? 1 : -1
    });
    findCompanies(0);
  };

  const deleteElement = async () => {
    await props.deleteCompany(selectedElement._id);
    setOpenDelete(false);
    setSelectedElement(null);
    findCompanies(page);
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Box className={classes.box} mb={3}>
          <Breadcrumbs separator="›">
            <LinkRouter to="/" color="secondary" underline="none">
              <Typography color="secondary" variant="body2">
                Admin
              </Typography>
            </LinkRouter>
            <Typography variant="body2">Company Management</Typography>
          </Breadcrumbs>
        </Box>
        <Grid container justifyContent={'space-between'} alignItems="center">
          <Typography variant="h5" className={classes.title}>
            Key Lime Companies
          </Typography>
          {Boolean(industries.length) && (
            <div>
              <FormControl
                variant="outlined"
                color="secondary"
                style={{
                  minWidth: 200,
                  backgroundColor: 'white'
                }}
              >
                <InputLabel
                  shrink={true}
                  id="industry-form-label"
                  style={{
                    backgroundColor: 'white'
                  }}
                >
                  Industry
                </InputLabel>
                <Select
                  labelId="industry-form-label"
                  labelWidth={60}
                  margin="dense"
                  value={industry}
                  onChange={({ target }) => {
                    setIndustry(target.value);
                    findCompanies(0, target.value);
                  }}
                >
                  {industries?.map(industry => (
                    <MenuItem value={industry._id} key={industry._id}>
                      {industry.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          &nbsp;&nbsp;
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setSelectedElement(null);
              setOpen(true);
            }}
            disableElevation
            size="small"
          >
            New Company
          </Button>
        </Grid>

        <Box my={3}>
          <Paper elevation={0} className={classes.table}>
            <LoadingIndicator isLoading={companies.isLoading} width={40}>
              <Table aria-label="simple table" size="medium">
                <HeaderTable classes={classes} cells={cells.map(c => c.label)} />
              </Table>
            </LoadingIndicator>

            {!companies.isLoading && (
              <Table aria-label="simple dense table" size="medium">
                <TableHead>
                  <TableRow className={classes.headerRow}>
                    {cells.map((cell, index) => (
                      <TableCell key={index}>
                        {cell.sort ? (
                          <TableSortLabel
                            active={sort.key === cell.value}
                            direction={sort.value === 1 ? 'asc' : 'desc'}
                            onClick={() => createSortHandler(cell.value)}
                          >
                            {cell.label}
                          </TableSortLabel>
                        ) : (
                          cell.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companies &&
                    companies.queryResult &&
                    companies.queryResult.data.map((company, index) => (
                      <TableRow key={index} hover className={clsx(classes.row, index % 2 !== 1 && classes.oddRow)}>
                        <TableCell width="25%">
                          <Typography className={classes.tableText} color="textSecondary">
                            {company.title}
                          </Typography>
                        </TableCell>
                        <TableCell width="40%">
                          <Typography className={classes.tableText} color="textSecondary">
                            {company.description}
                          </Typography>
                        </TableCell>
                        <TableCell width="20%">
                          <Typography className={classes.tableText} color="textSecondary">
                            {SingletonMomentUtils.moment(company.createdAt).format('LLL')}
                          </Typography>
                        </TableCell>
                        <TableCell width="10%">
                          <Typography className={classes.tableText} color="textSecondary">
                            {company.active ? 'Active' : 'Disabled'}
                          </Typography>
                        </TableCell>
                        <TableCell onClick={event => event.stopPropagation()} width="5%">
                          <IconButton
                            size="small"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={e => {
                              setAnchorEl(e.currentTarget);
                              setSelectedElement(company);
                            }}
                          >
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[rowsPerPage]}
                      count={companies.queryResult ? companies.queryResult.total : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={(e, p) => {
                        setPage(p);
                        findCompanies(p);
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </Paper>
        </Box>
      </Grid>

      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            setOpen(true);
            setAnchorEl(null);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDelete(true);
            setAnchorEl(null);
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      <Drawer anchor="right" open={open} classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}>
        <CompanyManagementForm
          selectedElement={selectedElement}
          industry={industries.find(i => i._id === industry)}
          onClose={() => {
            setOpen(false);
            setSelectedElement(null);
          }}
          classes={classes}
          onSave={() => findCompanies(page)}
        />
      </Drawer>

      {selectedElement && (
        <KLDialog
          isOpen={openDelete}
          onHandleClose={() => setOpenDelete(false)}
          onHandleConfirm={deleteElement}
          title={`Delete ${selectedElement.title}`}
          body={<DialogContentText>The company will be deleted, do you want to continue?</DialogContentText>}
          labelButtonConfirm="Delete"
        />
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    companies: state.services.companies
  };
}

const mapDispatchToProps = {
  getIndustries: feathersServices.industries.find,
  getCompanies: feathersServices.companies.find,
  deleteCompany: feathersServices.companies.remove
};

export default compose(withStyles(IndustryManagementStyles), connect(mapStateToProps, mapDispatchToProps))(CompanyManagement);
