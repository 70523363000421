export default theme => ({
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  box: {
    marginTop: 30,
    width: '100%'
  },
  gradient: {
    background: '-webkit-linear-gradient(90deg, #f8f8f8, #000)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent'
  }
});
