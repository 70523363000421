import React, { Component } from 'react';
import InlineSVG from 'svg-inline-react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Box, Breadcrumbs, ButtonBase, Container, Grid, Grow, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import feathersServices from '../../../services/feathersServices';
import { toggleSideMenu } from '../../../components/layout/actions/layout.actions';
import LoadingIndicator from '../../../components/loadingIndicator';
import LinkRouter from '../../../components/router-link/RouterLink';
import { clearConsumerSurvey } from '../../consumer-survey/action/ConsumerSurvey.actions';
import { clearSelectDbReport, selectDate, selectIndustry } from '../actions/reports.actions';
import SelectIndustryStyles from './SelectIndustry.styles';

class KLSelectIndustry extends Component {
  componentDidMount() {
    this.props.selectDate(new Date());
    this.props.clearSelectDbReport();
    this.props.clearConsumerSurvey();
    this.props.onToggleSideMenu(false);
    this.props.getIndustries({ query: { active: true } });
  }

  async selectIndustry(industry) {
    this.props.onSelectIndustry(industry);
    this.props.push('/report/report-publish-date');
  }

  render() {
    const { industries, classes } = this.props;
    return (
      <Container>
        <Grid container direction="column" className={classes.container}>
          <Box mb={8}>
            <Breadcrumbs separator="›">
              <LinkRouter to="/report" color="secondary" underline="none">
                <Typography color="secondary" variant="body2">
                  Home
                </Typography>
              </LinkRouter>
              <Typography variant="body2">Select Industry</Typography>
            </Breadcrumbs>
          </Box>
          <Box mb={8}>
            <Typography variant="h5">Please select an industry</Typography>
          </Box>
          <Grid item xs={12}>
            <LoadingIndicator color="secondary" isLoading={industries.isLoading} width={40} />
          </Grid>

          <Grid container spacing={2}>
            {!industries.isLoading &&
              industries.queryResult &&
              industries.queryResult.data &&
              industries.queryResult.data
                .sort((a, b) => {
                  if (a.title > b.title) {
                    return 1;
                  }
                  if (a.title < b.title) {
                    return -1;
                  }
                  return 0;
                })
                .map((industry, index) => (
                  <Grow in={true} timeout={300 * index + 1} key={`industry_${index}`}>
                    <Grid item>
                      <ButtonBase>
                        <Paper elevation={0} className={classes.paper} onClick={() => this.selectIndustry(industry)}>
                          <InlineSVG src={industry.svgIcon} className={classes.icon} />
                          <Typography variant="body1" className={classes.title}>
                            {industry.title}
                          </Typography>
                        </Paper>
                      </ButtonBase>
                    </Grid>
                  </Grow>
                ))}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    industries: state.services.industries
  };
};
const mapDispatchToProps = {
  getIndustries: feathersServices.industries.find,
  onSelectIndustry: selectIndustry,
  onToggleSideMenu: toggleSideMenu,
  clearSelectDbReport,
  clearConsumerSurvey,
  selectDate,
  push
};

export default compose(withStyles(SelectIndustryStyles), connect(mapStateToProps, mapDispatchToProps))(KLSelectIndustry);
