export default theme => ({
  date: {
    width: 'fit-content',
    '& .MuiInputBase-root::before': {
      display: 'none'
    },
    '& .MuiInputBase-root::after': {
      display: 'none'
    },
    '& .MuiInputBase-input': {
      padding: '3px 0 0',
      fontSize: '0.85rem',
      borderBottom: '1px dashed gray',
      cursor: 'pointer',
      color: '#555'
    }
  },
  autosize: {
    '& :first-child': {
      fontSize: '0.8rem',
      fontWeight: 400,
      border: 'none',
      color: theme.palette.text.secondary,
      borderBottom: '1px dashed',
      backgroundColor: 'inherit',
      minWidth: 20,
      '&:disabled': {
        border: 'none'
      }
    }
  }
});
